<template>
  <BaseTemplate app-name="Gestão de Ativos" page-title="">
    <template #content>
      <div class="container mx-auto px-4 py-4 md:py-8">
        <!-- Cabeçalho -->
        <button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
        <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
          
          <h1 class="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
            <ShieldCheck class="w-6 h-6 text-blue-500" />
            Gerenciamento de Tokens de Acesso
          </h1>
        </div>

        <!-- Descrição -->
        <div class="bg-blue-50 dark:bg-gray-800 p-4 rounded-lg mb-6 border-l-4 border-blue-500">
          <p class="text-gray-700 dark:text-gray-300">
            Este módulo permite gerar e gerenciar tokens de acesso para equipamentos. Cada token é único e pode ser associado ao endereço MAC de uma máquina para autorização de acesso.
          </p>
        </div>

        <!-- Formulário de Associação -->
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 sm:p-6 mb-6">
          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mb-4 flex items-center gap-2">
            <LinkIcon class="w-5 h-5 text-blue-500" />
            Associar Token a Equipamento
          </h2>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Token Gerado</label>
              <div class="flex flex-col sm:flex-row gap-2">
                <input type="text" v-model="tokenAtual" readonly class="w-full border rounded-lg px-4 py-2 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
                <button @click="copiarToken" class="w-full sm:w-auto bg-gray-200 hover:bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-500 px-4 py-2 rounded-lg flex items-center justify-center">
                  <Copy class="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
              </div>
              <p class="text-xs text-gray-500 dark:text-gray-400 mt-1">Token gerado automaticamente no formato xxxxx-xxxxx-xxxxx-xxxxx</p>
            </div>

            <!-- Filtros -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full mt-4 sm:mt-0">
              <div>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Tipo de Equipamento</label>
                <select v-model="filtroTipoEquipamento" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                  <option value="">Todos os tipos</option>
                  <option v-for="tipo in tiposDisponiveis" :key="tipo" :value="tipo">{{ tipo }}</option>
                </select>
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Modelo</label>
                <select v-model="filtroModeloEquipamento" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                  <option value="">Todos os modelos</option>
                  <option v-for="modelo in modelosDisponiveis" :key="modelo" :value="modelo">{{ modelo }}</option>
                </select>
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Serial Number</label>
                <input type="text" v-model="filtroSerialNumber" placeholder="Digite o serial number" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
              </div>
            </div>
          </div>

          <!-- Equipamentos -->
          <div class="mt-4">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Equipamento</label>
            <select v-model="selectedEquipment" @change="selecionarEquipamento" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <option value="" disabled>Selecione um equipamento</option>
              <option v-for="modelo in equipamentosFiltrados" :key="modelo.id" :value="modelo.id">{{ modelo.serial_number }} - {{ modelo.modelo_nome }}</option>
            </select>
          </div>

          <!-- Detalhes -->
          <div v-if="equipamentoSelecionado" class="mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-white mb-4">Detalhes do Equipamento</h3>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div v-for="(valor, chave) in equipamentoSelecionado" :key="chave">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 capitalize">{{ chave.replaceAll('_', ' ') }}</label>
                <p class="text-sm text-gray-900 dark:text-white">{{ valor }}</p>
              </div>
            </div>
          </div>

          <div class="flex justify-end mt-6">
            <button @click="handleGerarToken" class="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all duration-200 shadow-md">
              <KeyIcon class="w-5 h-5" />
              Gerar Token
            </button>
          </div>
        </div>

        <!-- Lista de Tokens -->
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          <div class="p-4 bg-gray-50 dark:bg-gray-700 border-b dark:border-gray-600">
            <h2 class="text-xl font-semibold text-gray-800 dark:text-white flex items-center gap-2">
              <ListIcon class="w-5 h-5 text-blue-500" />
              Tokens Ativos
            </h2>
          </div>

          <!-- Filtros da Tabela -->
          <div class="p-4 border-b dark:border-gray-600 bg-gray-50 dark:bg-gray-700">
            <div class="flex flex-col sm:flex-row gap-4">
              <input type="text" v-model="filtroToken" placeholder="Filtrar por token" class="flex-1 border rounded-lg px-4 py-2 dark:bg-gray-600 dark:border-gray-500 dark:text-white" />
              <input type="text" v-model="filtroEquipamento" placeholder="Filtrar por equipamento" class="flex-1 border rounded-lg px-4 py-2 dark:bg-gray-600 dark:border-gray-500 dark:text-white" />
            </div>
          </div>

          <!-- Tabela -->
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-blue-500 dark:bg-blue-600 text-white">
                <tr>
                  <th class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Token</th>
                  <th class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Equipamento</th>
                  <th class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Descrição</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                <tr v-for="(token, index) in tokensFiltered" :key="index" class="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td class="px-4 py-2 text-sm font-medium text-gray-900 dark:text-white">{{ token.token }}</td>
                  <td class="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">{{ token.equipmentName }}</td>
                  <td class="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">{{ token.description }}</td>
                </tr>
                <tr v-if="tokensFiltered.length === 0">
                  <td colspan="3" class="px-4 py-4 text-center text-gray-500 dark:text-gray-400">Nenhum token encontrado</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
          © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
        </p>
      </div>
    </template>
  </BaseTemplate>
</template>

  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import BaseTemplate from './BaseTemplate.vue';
  import { useRouter } from 'vue-router'
  import { 
    ShieldCheck, 
    KeyIcon, 
    LinkIcon, 
    Copy, 
    ListIcon,
    SquareArrowLeft
  } from 'lucide-vue-next';
  
  const router = useRouter()
  const tokenAtual = ref('');
  const selectedEquipment = ref('');
  const equipments = ref([]);
  const tokens = ref([]);
  const filtroToken = ref('');
  const filtroEquipamento = ref('');
  const equipamentoSelecionado = ref(null);
  const modelos = ref([]);
  const API_URL = process.env.VUE_APP_API_URL;
  
  // Configuração da API

  const apiToken = sessionStorage.getItem("api_token");


  const handleGerarToken = async () => {
  if (!selectedEquipment.value) {
    alert('Por favor, selecione um equipamento antes de gerar o token.');
    return;
  }

  await gerarNovoToken(selectedEquipment.value);

  // Remove o equipamento gerado da lista
  modelos.value = modelos.value.filter(modelo => modelo.id !== selectedEquipment.value);

  // Limpa seleção após gerar
  selectedEquipment.value = '';
  equipamentoSelecionado.value = null;


};

  // Funções
  const gerarNovoToken = async (ativoId) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'X-API-KEY': apiToken,
            'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
        };

        const response = await fetch(`${API_URL}/api/v1/ativos/${ativoId}/gerar-token/`, {
            method: 'POST',
            headers: headers
        });

        if (response.ok) {
            const data = await response.json();
            tokenAtual.value = data.token; // Atualiza o token na tela
        } else {
            throw new Error('Erro ao gerar token');
        }
    } catch (error) {
        console.error('Erro ao gerar novo token:', error);
        alert(`Erro ao gerar novo token: ${error.message}`);
    }
};

  
  const copiarToken = () => {
    navigator.clipboard.writeText(tokenAtual.value)
      .then(() => {
        alert('Token copiado para a área de transferência!');
      })
      .catch(err => {
        console.error('Erro ao copiar token: ', err);
      });
  };

  
  const selecionarEquipamento = () => {
    const equipment = equipments.value.find(e => e.id === selectedEquipment.value);
    equipamentoSelecionado.value = equipment ? {
      hostname: equipment.hostname,
      service_tag: equipment.service_tag,
      model: equipment.model,
      processor: equipment.processor,
      ram: equipment.ram,
      storage: equipment.storage,
      ip_address: equipment.ip_address,
      domain: equipment.domain,
      os: equipment.os,
      received_at: equipment.received_at
    } : null;
  };
  


  
  // Computed properties
  const tokensFiltered = computed(() => {
    return tokens.value.filter(token => {
      const matchToken = token.token.toLowerCase().includes(filtroToken.value.toLowerCase());
      const matchEquipment = token.equipmentName.toLowerCase().includes(filtroEquipamento.value.toLowerCase());
      return matchToken && matchEquipment;
    });
  });
  
  
  // Lifecycle hooks
  onMounted(async () => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': apiToken,
      'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
    };

    // 🔹 Carrega os equipamentos disponíveis (sem token)
    const modelosResponse = await fetch(`${API_URL}/api/v1/ativos/?disponiveis=true`, {
      method: 'GET',
      headers: headers
    });

    if (modelosResponse.ok) {
      const data = await modelosResponse.json();
      modelos.value = data;
    } else {
      throw new Error('Erro ao carregar modelos');
    }

    // 🔹 Carrega os tokens ativos (ativos com token)
    const tokensResponse = await fetch(`${API_URL}/api/v1/ativos/?com_token=true`, {
      method: 'GET',
      headers: headers
    });

    if (tokensResponse.ok) {
      const data = await tokensResponse.json();
      tokens.value = data.map(ativo => ({
        token: ativo.token,
        equipmentName: `${ativo.serial_number} - ${ativo.modelo_nome}`,
        description: ativo.modelo_nome,  // ou outro campo se quiser
        active: true,
        id: ativo.id
        }));

    } else {
      throw new Error('Erro ao carregar tokens');
    }

  } catch (error) {
    console.error('Erro ao carregar dados:', error);
    alert(`Erro ao carregar dados: ${error.message}`);
  }
});

const voltar = () => router.go(-1);

const filtroTipoEquipamento = ref('');
const filtroSerialNumber = ref('');
const filtroModeloEquipamento = ref('');

// Computed: tipos únicos de equipamento
const tiposDisponiveis = computed(() => {
  const tipos = modelos.value.map(m => m.tipo); // Certifique-se de que existe a propriedade `tipo`
  return [...new Set(tipos)].sort();
});


// Computed: modelos únicos baseados no tipo selecionado
const modelosDisponiveis = computed(() => {
  const modelosFiltrados = modelos.value.filter(m => {
    return filtroTipoEquipamento.value === '' || m.tipo === filtroTipoEquipamento.value;
  });

  const modelosUnicos = modelosFiltrados.map(m => m.modelo_nome);
  return [...new Set(modelosUnicos)].sort();
});

// Computed: equipamentos filtrados com base nos filtros
const equipamentosFiltrados = computed(() => {
  return modelos.value.filter(modelo => {
    const matchTipo = filtroTipoEquipamento.value === '' || modelo.tipo === filtroTipoEquipamento.value;
    const matchModelo = filtroModeloEquipamento.value === '' || modelo.modelo_nome === filtroModeloEquipamento.value;
    const matchSerial = modelo.serial_number?.toLowerCase().includes(filtroSerialNumber.value.toLowerCase());
    return matchTipo && matchModelo && matchSerial;
  });
});


  </script>
  
  <style scoped>
  </style>