<template>
  <BaseTemplate app-name="Gestão de Usuários" page-title="">
    <template #content>
      <div class="container mx-auto p-6">
        <!-- Cabeçalho -->
             <!-- Cabeçalho --><button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
        <div class="flex justify-between items-center mb-6">
          <h1 class="flex items-center gap-2 text-2xl font-bold text-gray-900 dark:text-white">
            <BriefcaseIcon class="w-6 h-6 text-blue-500" />
            Gestão de Usuários
          </h1>
          <button @click="openModal()"
            class="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg shadow">
            <UserPlusIcon class="w-5 h-5" />
            Novo Usuário
          </button>
        </div>

        <!-- Filtros -->
        <div class="flex flex-col sm:flex-row gap-4 mb-6">
          <input v-model="filter" type="text" placeholder="Buscar por nome, usuário ou email..."
            class="w-full sm:w-1/2 border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
        </div>

                <!-- Tabela para telas maiores -->
        <div class="hidden sm:block bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto dark:text-white text-gray-800">
          <table class="w-full">
            <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
              <tr>
                <th @click="ordenarPor('nome')" class="cursor-pointer px-4 py-3 text-left">
                  Nome <span v-if="colunaOrdenada === 'nome'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('usuario')" class="cursor-pointer px-4 py-3 text-left">
                  Usuário <span v-if="colunaOrdenada === 'usuario'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('email')" class="cursor-pointer px-4 py-3 text-left">
                  Email <span v-if="colunaOrdenada === 'email'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('status')" class="cursor-pointer px-4 py-3 text-left">
                  Status <span v-if="colunaOrdenada === 'status'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th class="px-4 py-3 text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in paginatedUsers" :key="user.id"
                class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                <td class="px-4 py-3">{{ user.first_name }} {{ user.last_name }}</td>
                <td class="px-4 py-3">{{ user.username }}</td>
                <td class="px-4 py-3">{{ user.email || '-' }}</td>
                <td class="px-4 py-3 text-center">
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" class="sr-only peer" :checked="user.isActive"
                      @change="toggleUserActive(user, $event)">
                    <div class="w-11 h-6 flex items-center justify-center rounded-full transition-all"
                      :class="user.isActive ? ' text-green-600' : 'text-gray-600'"
                      @click="toggleUserActiveFromClick(user)">
                      <ToggleRight v-if="user.isActive" class="w-8 h-8 transition-all" />
                      <ToggleLeft v-else class="w-8 h-8 transition-all" />
                    </div>
                  </label>
                </td>
                <td class="px-4 py-3 text-center">
                  <div class="flex justify-center space-x-2">
                    <button @click="openModal(user)" class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                      <Edit2Icon class="w-4 h-4" /> Editar
                    </button>
                    <button @click="deleteUser(user.id)" class="flex items-center gap-1 text-red-500 hover:text-red-700">
                      <Trash2Icon class="w-4 h-4" /> Excluir
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginação -->
        <div class="flex justify-between items-center mt-4">
          <button @click="prevPage" :disabled="currentPage === 1"
            class="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-white rounded-lg shadow disabled:opacity-50">
            Anterior
          </button>
          <span class="text-gray-800 dark:text-white">Página {{ currentPage }} de {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage >= totalPages"
            class="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-white rounded-lg shadow disabled:opacity-50">
            Próxima
          </button>
        </div>

        
        <!-- Mensagem de erro -->
        <p v-if="error" class="text-red-500 text-center mb-4">{{ error }}</p>


        <!-- Versão mobile em cards -->
        <div class="sm:hidden space-y-6">
          <div v-for="user in usuariosOrdenados" :key="user.id"
            class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
            <div class="flex justify-between items-center">
              <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                {{ user.first_name }} {{ user.last_name }}
              </h3>
              <div class="flex items-center gap-2">
                <button @click="openModal(user)" class="text-blue-500 hover:text-blue-700">
                  <Edit2Icon class="w-5 h-5" />
                </button>
                <button @click="deleteUser(user.id)" class="text-red-500 hover:text-red-700">
                  <Trash2Icon class="w-5 h-5" />
                </button>
              </div>
            </div>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Usuário:</strong> {{ user.username }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Email:</strong> {{ user.email || '-' }}</p>
            <div class="flex items-center justify-between mt-4">
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" class="sr-only peer" :checked="user.isActive"
                  @change="toggleUserActive(user, $event)">
                <div class="flex items-center gap-1 cursor-pointer"
                  :class="user.isActive ? 'text-green-500' : 'text-gray-500'" @click="toggleUserActiveFromClick(user)">
                  <span>{{ user.isActive ? 'Ativo' : 'Inativo' }}</span>
                  <ToggleRight v-if="user.isActive" class="w-8 h-8 transition-all" />
                  <ToggleLeft v-else class="w-8 h-8 transition-all" />

                </div>
              </label>
            </div>
          </div>
        </div>

                  <!-- Modal -->
          <div v-if="isModalOpen" 
              class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
              @click.self="closeModal">
            <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
              <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                <UserIcon class="w-5 h-5 text-blue-500" />
                {{ isEditMode ? 'Editar Usuário' : 'Novo Usuário' }}
              </h2>

              <!-- Alerta de erro dentro do modal -->
              <div v-if="showErrorPopup" class="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-4">
                <strong>Erro:</strong> {{ errorMessage }}
                <button @click="showErrorPopup = false" class="absolute top-0 right-0 px-3 py-1">
                  &times;
                </button>
              </div>

              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Primeiro Nome</label>
              <input v-model="currentUser.first_name" type="text"
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Sobrenome</label>
              <input v-model="currentUser.last_name" type="text"
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
              <input v-model="currentUser.email" type="email"
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Senha</label>
              <input v-model="currentUser.password" type="password"
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

              <div class="flex justify-end space-x-3">
                <button @click="closeModal"
                        class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                  <XIcon class="w-4 h-4" /> Cancelar
                </button>
                <button @click="saveUser"
                        class="flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                  <SaveIcon class="w-4 h-4" />
                  <span>Salvar</span>
                </button>
              </div>
            </div>
          </div>
      </div>
      <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
    </template>
  </BaseTemplate>
</template>

<script setup>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
const apiToken = sessionStorage.getItem("api_token");
const showErrorPopup = ref(false);
const errorMessage = ref('');
import BaseTemplate from './BaseTemplate.vue';
import { ref, computed, onMounted } from 'vue';
import {
  BriefcaseIcon,
  UserPlusIcon,
  Edit2Icon,
  Trash2Icon,
  UserIcon,
  SquareArrowLeft,
  XIcon,
  SaveIcon,
  ToggleLeft,
  ToggleRight
} from 'lucide-vue-next';

const headers = {
  'Content-Type': 'application/json',
  'X-API-KEY': apiToken
};

const users = ref([]);
const filter = ref('');
const error = ref(null);
const isModalOpen = ref(false);
const isEditMode = ref(false);
const currentUser = ref({
  id: '',
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  password: '',
  isActive: true
});

// Variáveis para ordenação
const colunaOrdenada = ref('nome'); // Coluna padrão para ordenação
const ordemAscendente = ref(true); // Ordem ascendente por padrão

// Filtra os usuários com base no campo de busca
const filteredUsers = computed(() =>
  users.value.filter(user =>
    ((user.first_name || '') + ' ' + (user.last_name || ''))
      .toLowerCase()
      .includes(filter.value.toLowerCase()) ||
    (user.username || '').toLowerCase().includes(filter.value.toLowerCase()) ||
    (user.email || '').toLowerCase().includes(filter.value.toLowerCase())
  )
);

// Ordena os usuários com base na coluna selecionada
const usuariosOrdenados = computed(() => {
  const usuariosFiltrados = filteredUsers.value.slice(); // Cria uma cópia dos usuários filtrados

  return usuariosFiltrados.sort((a, b) => {
    let valorA, valorB;

    switch (colunaOrdenada.value) {
      case 'nome':
        valorA = `${a.first_name} ${a.last_name}`.toLowerCase();
        valorB = `${b.first_name} ${b.last_name}`.toLowerCase();
        break;
      case 'usuario':
        valorA = a.username.toLowerCase();
        valorB = b.username.toLowerCase();
        break;
      case 'email':
        valorA = a.email.toLowerCase();
        valorB = b.email.toLowerCase();
        break;
      case 'status':
        valorA = a.isActive ? 1 : 0;
        valorB = b.isActive ? 1 : 0;
        break;
      default:
        return 0;
    }

    if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
    if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
    return 0;
  });
});

// Função para ordenar por coluna
function ordenarPor(coluna) {
  if (colunaOrdenada.value === coluna) {
    // Inverte a ordem se a mesma coluna for clicada novamente
    ordemAscendente.value = !ordemAscendente.value;
  } else {
    // Define a nova coluna e a ordem ascendente por padrão
    colunaOrdenada.value = coluna;
    ordemAscendente.value = true;
  }
}

onMounted(fetchUsers);

async function fetchUsers() {
  try {
    const response = await fetch(`${API_URL}/api/v1/usuarios/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiToken
      }
    });
    const data = await response.json();
    users.value = data.map(user => ({
      ...user,
      isActive: user.is_active !== undefined ? user.is_active : true
    }));
  } catch (err) {
    error.value = 'Erro ao carregar usuários';
  }
}

function openModal(user = null) {
  isEditMode.value = !!user;
  if (user) {
    // Em modo de edição, mantém os dados existentes e limpa a senha
    currentUser.value = { ...user, password: '' };
  } else {
    // Para novo usuário, inicializa os campos
    currentUser.value = {
      id: '',
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: '',
      isActive: true
    };
  }
  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
}

async function saveUser() {
  if (!isEditMode.value) {
    if (currentUser.value.password.length < 8) {
      errorMessage.value = "A senha deve ter pelo menos 8 caracteres.";
      showErrorPopup.value = true;
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/register/`,
        {
          first_name: currentUser.value.first_name,
          last_name: currentUser.value.last_name,
          email: currentUser.value.email,
          password: currentUser.value.password,
        },
        { headers }
      );
      const newUser = response.data;
      users.value.push({
        ...newUser,
        isActive: newUser.is_active
      });
      closeModal();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Se o erro vier no formato { "detail": "Mensagem de erro" }, extrai apenas a mensagem
        if (typeof err.response.data === "object" && err.response.data.detail) {
          errorMessage.value = err.response.data.detail; // Captura somente a mensagem
        } else {
          errorMessage.value = err.response.data || "Erro ao cadastrar usuário.";
        }
      } else {
        errorMessage.value = "Erro ao cadastrar usuário. Verifique se o Nome e o Sobrenome estão preenchidos";
      }
      showErrorPopup.value = true;
    }
  } else {
    await updateUser();
  }
}


async function updateUser() {
  try {
    // Cria o objeto de dados para atualização sem a senha inicialmente
    const updateData = {
      first_name: currentUser.value.first_name,
      last_name: currentUser.value.last_name,
      email: currentUser.value.email,
    };

    // Se a senha for informada, valida o comprimento e inclui no objeto
    if (currentUser.value.password) {
      if (currentUser.value.password.length < 8) {
        error.value = "A senha deve ter pelo menos 8 caracteres.";
        return;
      }
      updateData.password = currentUser.value.password;
    }

    const response = await axios.put(
      `${API_URL}/api/v1/usuarios/${currentUser.value.id}/`,
      updateData,
      { headers }
    );
    const updatedUser = response.data;

    // Atualiza o usuário na lista local
    const index = users.value.findIndex(u => u.id === updatedUser.id);
    if (index !== -1) {
      users.value[index] = {
        ...updatedUser,
        isActive: updatedUser.is_active
      };
    }
    closeModal();
  } catch (err) {
    error.value = err.response?.data?.detail || "Erro ao editar usuário";
  }
}


const currentPage = ref(1);
const itemsPerPage = 10; // Quantidade de usuários por página

// Total de páginas com base na quantidade de usuários
const totalPages = computed(() => Math.ceil(users.value.length / itemsPerPage));

// Retorna apenas os usuários da página atual
const paginatedUsers = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return usuariosOrdenados.value.slice(start, end);
});

// Função para avançar página
function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
  
async function deleteUser(userId) {
  const confirmDeletion = window.confirm("Tem certeza de que deseja excluir este usuário? É possível desabilitá-lo.");
  if (!confirmDeletion) return;
  try {
    await axios.delete(`${API_URL}/api/v1/usuarios/${userId}/`, { headers });
    users.value = users.value.filter(user => user.id !== userId);
  } catch (err) {
    error.value = err.response?.data?.detail || "Erro ao deletar usuário";
  }
}

const toggleUserActiveFromClick = (user) => {
  // Encontra o checkbox invisível e dispara o evento
  const checkbox = document.querySelector(`#user-checkbox-${user.id}`);
  if (checkbox) {
    checkbox.click();  // Simula o clique no checkbox, disparando o @change corretamente
  }
};

async function toggleUserActive(user, event) {
  if (!event || !event.target) return;

  const newStatus = event.target.checked;
  if (!newStatus) {
    const confirmDisable = window.confirm("Tem certeza que deseja desabilitar o usuário?");
    if (!confirmDisable) {
      event.target.checked = true;
      return;
    }
  }
  try {
    const response = await axios.patch(
      `${API_URL}/api/v1/usuarios/${user.id}/`,
      { is_active: newStatus },
      { headers }
    );
    // Atualiza o status com o valor retornado pelo backend
    user.isActive = response.data.is_active;
  } catch (err) {
    error.value = "Erro ao atualizar status do usuário";
    user.isActive = !newStatus;
    event.target.checked = !newStatus;
  }
}
</script>