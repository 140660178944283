<template>
    <div class="prose dark:prose-dark max-w-full">
      <!-- Renderizando o conteúdo com as classes de indentação diretamente -->
      <div v-html="props.conteudo" class="ql-editor"></div>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  // Definindo as propriedades passadas para o componente
  const props = defineProps({
    conteudo: {
      type: String,
      required: true
    }
  });
  </script>
  
  <style scoped>
  /* Estilos para garantir que as indentations sejam preservadas */
  .ql-editor {
    line-height: 1.5;
    font-size: 1rem;
    color: inherit;
    background-color: slate;
    border-radius: 15px; /* Garantir que o fundo seja branco */
    /* Outros estilos para garantir que as indentações sejam aplicadas corretamente */
  }
  
  /* Se houver necessidade de ajustar indentação, você pode adicionar um seletor para as classes */
  .ql-editor .ql-indent-1 {
    padding-left: 20px;
  }
  
  .ql-editor .ql-indent-2 {
    padding-left: 40px;
  }
  
  .ql-editor .ql-indent-3 {
    padding-left: 60px;
  }
  
  .ql-editor .ql-indent-4 {
    padding-left: 80px;
  }
  
  /* Outros ajustes para garantir que o conteúdo se ajuste */
  .prose {
    max-width: 100%;
  }
  
  .prose img {
    max-width: 100%;
    height: auto;
  }
  </style>
  