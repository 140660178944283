<template>
    <BaseTemplate app-name="Informes" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-4 md:px-6 lg:px-1">
                <!-- Loading state -->
                <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-screen">
                    <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                </div>

                <!-- Content when loaded -->
                <div v-else>
                      <!-- Cabeçalho --><button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
                    <!-- Header -->
                    <div class="flex flex-col gap-5 mb-8">
                        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                            <div class="flex items-center gap-3">
                                <h2 class="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-white tracking-tight">
                                    Informes do Portal
                                </h2>
                                <span
                                    class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-semibold px-3 py-1 rounded-full flex items-center justify-center min-w-[72px]">
                                    {{ informesFiltrados.length }} itens
                                </span>
                            </div>

                            <div class="flex flex-wrap sm:flex-nowrap gap-3 w-full sm:w-auto">
                                <!-- Search field -->
                                <div class="relative w-full sm:w-72">
                                    <input v-model="filtroTitulo" type="text" placeholder="Buscar por título..."
                                        class="pl-10 pr-4 py-2.5 w-full border rounded-lg text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none" />
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Search class="w-5 h-5 text-gray-400" />
                                    </div>
                                </div>

                                <!-- Filter button -->
                                <button @click="abrirModalFiltro"
                                    class="relative flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2.5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-all duration-200 focus:ring-2 focus:ring-gray-400 focus:outline-none shadow-md w-full sm:w-auto">
                                    <Filter class="w-5 h-5" />
                                    <span class="font-medium">Filtros</span>
                                    <span v-if="totalFiltros > 0"
                                        class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center animate-pulse">
                                        {{ totalFiltros }}
                                    </span>
                                </button>

                                <!-- New announcement button -->
                                <button @click="abrirModalCriarInforme"
                                    class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 focus:ring-2 focus:ring-blue-300 focus:outline-none flex items-center justify-center gap-2 font-medium shadow-md w-full sm:w-auto">
                                    <PlusCircle class="w-5 h-5" />
                                    <span>Novo Informe</span>
                                </button>
                            </div>
                        </div>

                        <!-- Additional filters -->
                        <div class="flex flex-wrap items-center gap-6 mt-1">
                            <label class="flex items-center gap-2 cursor-pointer group">
                                <div class="relative">
                                    <input type="checkbox" v-model="mostrarInativos" class="peer sr-only" />
                                    <div
                                        class="w-5 h-5 border-2 border-gray-300 dark:border-gray-600 rounded-md peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all duration-200">
                                    </div>
                                    <div
                                        class="absolute top-[2px] left-[2px] w-4 h-4 text-white opacity-0 peer-checked:opacity-100 pointer-events-none transition-opacity duration-200">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="2.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </div>
                                <span
                                    class="text-sm text-gray-700 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">Mostrar
                                    Inativos</span>
                            </label>
                        </div>
                    </div>

                    <!-- Success message toast -->
                    <div v-if="mensagemSucesso"
                        class="fixed top-5 right-5 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in z-50">
                        {{ mensagemSucesso }}
                    </div>

                    <!-- No announcements state -->
                    <div v-if="informes.length === 0"
                        class="bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 p-8 text-center">
                        <div class="max-w-md mx-auto">
                            <Megaphone class="h-16 w-16 text-blue-500 dark:text-blue-400 mx-auto mb-4" />
                            <h3 class="text-xl font-semibold text-gray-800 dark:text-white mb-2">Nenhum informe
                                cadastrado</h3>
                            <p class="text-gray-600 dark:text-gray-300 mb-6">
                                Crie informes para o Portal do Paciente que serão exibidos através da API.
                            </p>
                            <button @click="abrirModalCriarInforme"
                                class="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all duration-200 focus:ring-2 focus:ring-blue-300 focus:outline-none flex items-center justify-center gap-2 font-medium shadow-md mx-auto">
                                <PlusCircle class="w-5 h-5" />
                                <span>Criar Informe</span>
                            </button>
                        </div>
                    </div>

                    <!-- No announcements after filtering -->
                    <div v-else-if="informesFiltrados.length === 0"
                        class="bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 p-8 text-center">
                        <div class="max-w-md mx-auto">
                            <Megaphone class="h-16 w-16 text-blue-500 dark:text-blue-400 mx-auto mb-4" />
                            <h3 class="text-xl font-semibold text-gray-800 dark:text-white mb-2">Nenhum informe
                                encontrado com os filtros aplicados.</h3>


                            <p class="text-gray-600 dark:text-gray-300 mb-6">

                                Se desejar visualizar informes inativos, ative a opção <span class="text-blue-600">
                                    "Mostrar Inativos". </span>

                            </p>
                        </div>
                    </div>

                    <!-- Announcements table (desktop) -->
                    <div class="hidden md:block rounded-lg overflow-hidden">
                        <table class="w-full">
                            <thead v-if="informesFiltrados && informesFiltrados.length">
                                <tr
                                    class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                                    <th @click="ordenarPor('id')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                                        ID <span v-if="colunaOrdenada === 'id'">{{ ordemAscendente ? '🠑' : '🠓'
                                        }}</span>
                                    </th>
                                    <th @click="ordenarPor('titulo')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                                        Título <span v-if="colunaOrdenada === 'titulo'">{{ ordemAscendente ? '🠑' : '🠓'
                                        }}</span>
                                    </th>
                                    <th @click="ordenarPor('data_publicacao')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                                        Data de Publicação <span v-if="colunaOrdenada === 'data_publicacao'">{{
                                            ordemAscendente ? '🠑' : '🠓' }}</span>
                                    </th>
                                    <th class="text-left px-6 py-4 whitespace-nowrap">
                                        Data de Expiração
                                    </th>
                                    <th class="text-left px-6 py-4 whitespace-nowrap">
                                        Criado Por
                                    </th>
                                    <th @click="ordenarPor('ativo')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                                        Status <span v-if="colunaOrdenada === 'ativo'">{{ ordemAscendente ? '🠑' : '🠓'
                                        }}</span>
                                    </th>
                                    <th
                                        class="px-6 py-4 text-sm font-semibold text-gray-200 dark:text-gray-300 text-center whitespace-nowrap">
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                class="divide-y divide-gray-300 dark:divide-gray-600 border-t border-gray-300 dark:border-gray-600 dark:bg-gray-700 bg-white">
                                <tr v-for="informe in informesFiltrados" :key="informe.id"
                                    class="group hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200">
                                    <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ informe.id }}</td>
                                    <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ informe.titulo }}
                                    </td>
                                    <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                        formatarData(informe.data_publicacao) }}</td>
                                    <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                        formatarData(informe.data_expiracao) }}</td>
                                    <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ informe.criado_por
                                        || 'Desconhecido' }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <span :class="getStatusClass(informe.ativo)"
                                            class="px-3 py-1 rounded-full text-sm text-xs font-medium whitespace-nowrap">
                                            {{ informe.ativo ? 'Ativo' : 'Inativo' }}
                                        </span>
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        <div class="flex justify-center gap-3">
                                            <button @click="verDetalhes(informe.id)"
                                                class="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                                                <Eye class="w-5 h-5" />
                                            </button>
                                            <button @click="editarInforme(informe)"
                                                class="text-amber-600 hover:text-amber-800 dark:text-amber-400 dark:hover:text-amber-300">
                                                <Edit class="w-5 h-5" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <!-- Announcements cards (mobile) -->
                    <div class="md:hidden space-y-6">
                        <div v-for="informe in informesFiltrados" :key="informe.id"
                            class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
                            <div class="flex justify-between items-center mb-2">
                                <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                                    {{ informe.titulo }}
                                </h3>
                                <span :class="getStatusClass(informe.ativo)"
                                    class="px-4 py-1 rounded-full text-xs font-semibold">
                                    {{ informe.ativo ? 'Ativo' : 'Inativo' }}
                                </span>
                            </div>

                            <div class="space-y-2">
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">ID:</span> {{ informe.id }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Data de Publicação:</span> {{
                                        formatarData(informe.data_publicacao) }}
                                </p>
                                <p v-if="informe && informe.data_expiracao"
                                    class="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                    <span class="font-medium">Expira em:</span> {{ formatarData(informe.data_expiracao)
                                    }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Criado por:</span> {{ informe.criado_por || 'Desconhecido'
                                    }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300 line-clamp-2">
                                    <span class="font-medium">Resumo:</span> {{ informe.resumo }}
                                </p>
                            </div>

                            <div class="mt-6 flex flex-col gap-2 sm:flex-row sm:gap-3">
                                <button @click="verDetalhes(informe.id)"
                                    class="flex-1 bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 rounded-md shadow flex items-center justify-center gap-1">
                                    <Eye class="w-4 h-4" />
                                    <span>Ver</span>
                                </button>
                                <button @click="editarInforme(informe)"
                                    class="flex-1 bg-amber-600 hover:bg-amber-700 text-white text-sm font-semibold py-2 rounded-md shadow flex items-center justify-center gap-1">
                                    <Edit class="w-4 h-4" />
                                    <span>Editar</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Filter Modal -->
                <transition name="modal">
                    <div v-if="modalFiltro"
                        class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70 z-50"
                        @click.self="fecharModalFiltro">
                        <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-lg animate-fade-in p-6">
                            <div class="flex justify-between items-center mb-4">
                                <h3 class="text-xl font-bold text-gray-800 dark:text-white">Filtros</h3>
                                <button @click="fecharModalFiltro"
                                    class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition-all">
                                    <X class="w-5 h-5" />
                                </button>
                            </div>

                            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <!-- Status filter -->
                                <div>
                                    <label
                                        class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Status</label>
                                    <select v-model="filtroStatus"
                                        class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
                                        <option value="">Todos</option>
                                        <option value="true">Ativo</option>
                                        <option value="false">Inativo</option>
                                    </select>
                                </div>

                                <!-- Date range filter -->
                                <div>
                                    <label
                                        class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Data
                                        de Publicação</label>
                                    <input type="date" v-model="filtroDataInicio"
                                        class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white mb-2"
                                        placeholder="Data inicial" />
                                    <input type="date" v-model="filtroDataFim"
                                        class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white"
                                        placeholder="Data final" />
                                </div>
                            </div>

                            <!-- Filter buttons -->
                            <div class="flex justify-between mt-6">
                                <button @click="limparFiltros"
                                    class="px-6 py-2.5 bg-gray-500 text-white rounded-lg font-medium hover:bg-gray-600 transition-all">
                                    Limpar Filtros
                                </button>
                                <button @click="aplicarEFecharFiltro"
                                    class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-all">
                                    Aplicar
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>

                <!-- Create/Edit Announcement Modal -->
                <transition name="modal">
                    <div v-if="modalInforme"
                        class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70 z-50"
                        @click.self="fecharModalInforme">
                        <div v-if="mensagemErro"
                            class="fixed top-5 right-5 mt-16 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in z-50">
                            {{ mensagemErro }}
                        </div>

                        <div
                            class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-4xl animate-fade-in p-6 max-h-[90vh] overflow-y-auto">
                            <div class="flex justify-between items-center mb-4">
                                <h3 class="text-xl font-bold text-gray-800 dark:text-white">
                                    {{ modoEdicao ? 'Editar Informe' : 'Novo Informe' }}
                                </h3>
                                <button @click="fecharModalInforme"
                                    class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition-all">
                                    <X class="w-5 h-5" />
                                </button>
                            </div>

                            <div class="space-y-4">
                                <!-- Title -->
                                <div>
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Título
                                    </label>
                                    <input v-model="novoInforme.titulo" type="text" required
                                        placeholder="Digite o título do informe"
                                        class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white" />
                                </div>


                                <div class="flex flex-col items-center justify-center gap-4 w-full">
                                    <div
                                        class="w-full max-w-[600px] h-[300px] border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg flex items-center justify-center overflow-hidden relative">
                                        <img v-if="imagemPreview" :src="imagemPreview" alt="Preview"
                                            class="w-full h-full object-cover" />
                                        <div v-else class="text-gray-400 text-center p-2">
                                            <ImageIcon class="w-8 h-8 mx-auto mb-1" />
                                            <span class="text-xs">Nenhuma imagem</span>
                                        </div>
                                        <input type="file" @change="onImagemSelecionada" accept="image/*"
                                            class="absolute inset-0 opacity-0 cursor-pointer" ref="inputImagem" />

                                    </div>

                                    <div class="text-center max-w-xl">
                                        <p class="text-sm text-gray-600 dark:text-gray-400 mb-2">
                                            Selecione uma imagem para o informe no tamanho <strong>450x300</strong>.
                                            Formatos aceitos: JPG e PNG.
                                        </p>
                                        <button v-if="imagemPreview" @click="removerImagem"
                                            class="px-3 py-1 bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300 rounded-md text-sm hover:bg-red-200 dark:hover:bg-red-800 transition-colors">
                                            Remover imagem
                                        </button>
                                    </div>
                                </div>
                                <!-- Full content -->
                                <!-- Full content -->
                                <div>
    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
      Conteúdo Completo (opcional)
    </label>
    <div class="border border-gray-300 rounded-md">
      <QuillEditor
        v-model:content="novoInforme.conteudo"
        contentType="html"
        theme="snow"
        :modules="editorModules"
        :toolbar="editorToolbarOptions"
      />
    </div>
  </div>

                                <!-- Publication date -->
                                <div>
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Data de Publicação
                                    </label>
                                    <input v-model="novoInforme.data_publicacao" type="date" required
                                        :readonly="!modoEdicao" :disabled="!modoEdicao"
                                        class="w-full p-2.5 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white text-gray-500" />
                                </div>
                                <div>
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Data de Expiração (opcional)
                                    </label>
                                    <input v-model="novoInforme.data_expiracao" type="date"
                                        class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white" />
                                </div>
                                <!-- Status -->
                                <div>
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Status
                                    </label>
                                    <div class="flex items-center gap-3">
                                        <button @click="novoInforme.ativo = !novoInforme.ativo"
                                            class="flex items-center justify-center w-10 h-10  transition-colors"
                                            :title="novoInforme.ativo ? 'Clique para desativar' : 'Clique para ativar'">
                                            <component :is="novoInforme.ativo ? ToggleRight : ToggleLeft"
                                                :class="novoInforme.ativo ? 'w-6 h-6 text-green-600 dark:text-green-300' : 'w-6 h-6 text-red-600 dark:text-red-300'" />
                                        </button>
                                        <span class="text-sm font-semibold"
                                            :class="novoInforme.ativo ? 'text-green-600' : 'text-red-500'">
                                            {{ novoInforme.ativo ? 'Ativo' : 'Inativo' }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Form actions -->
                            <div class="flex justify-end gap-3 mt-6">
                                <button @click="fecharModalInforme"
                                    class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                                    Cancelar
                                </button>
                                <button @click="salvarInforme" :disabled="!isFormValid"
                                    class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                                    {{ modoEdicao ? 'Atualizar' : 'Criar' }} Informe
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>

<!-- View Announcement Modal -->
<transition name="fade">
    <div v-if="modalVisualizacao" role="dialog" aria-modal="true" aria-labelledby="modal-title"
      class="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      @click.self="modalVisualizacao = false" @keydown.esc="modalVisualizacao = false">
      <div class="bg-white dark:bg-gray-900 w-full max-w-3xl rounded-2xl shadow-2xl border border-gray-200 dark:border-gray-700 overflow-hidden animate-fadeIn flex flex-col" tabindex="-1">
        <!-- Cabeçalho -->
        <div class="flex justify-between items-center p-5 border-b border-gray-200 dark:border-gray-700">
          <h3 id="modal-title" class="text-2xl font-bold text-[#005baa] dark:text-blue-300">Detalhes do Informe</h3>
          <button @click="modalVisualizacao = false"
            class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition focus:outline-none focus:ring-2 focus:ring-[#005baa]"
            aria-label="Fechar modal">
            <X class="w-6 h-6" aria-hidden="true" />
          </button>
        </div>

        <!-- Corpo -->
        <div class="p-6 space-y-6 max-h-[70vh] overflow-y-auto flex-grow">
          <!-- Imagem -->
          <div v-if="informeSelecionado && informeSelecionado.imagem_base64"
            class="relative w-full flex items-center justify-center">
            <div class="relative">
              <img :src="informeSelecionado.imagem_base64" :alt="`Imagem do informe: ${informeSelecionado.titulo}`"
                class="max-h-[300px] w-auto object-contain mx-auto block rounded-lg" />
              <div class="absolute top-2 right-2 bg-[#005baa] text-white text-xs px-3 py-1 rounded-lg shadow z-10"
                aria-label="Data de publicação">
                {{ formatarData(informeSelecionado.data_publicacao) }}
              </div>
            </div>
          </div>

          <!-- Título -->
          <h2 class="text-xl font-bold text-[#005baa] dark:text-blue-200">
            {{ informeSelecionado?.titulo || '' }}
          </h2>

          <!-- Conteúdo utilizando QuillContent -->
          <div v-if="informeSelecionado && informeSelecionado.conteudo">
            <QuillContent :conteudo="informeSelecionado.conteudo" />
          </div>
        </div>

        <!-- Rodapé com botão fechar -->
        <div class="px-6 py-4 border-t border-gray-200 dark:border-gray-700 flex justify-end">
          <button @click="modalVisualizacao = false"
            class="bg-[#005baa] hover:bg-[#004a8c] text-white text-sm font-medium px-6 py-2 rounded-md transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#005baa]"
            aria-label="Fechar detalhes do informe">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </transition>


            </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>
</template>


<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import axios from 'axios';
import BaseTemplate from './BaseTemplate.vue';
import { QuillEditor } from '@vueup/vue-quill';
import QuillContent from '@/components/QuillContent.vue'; // Importando o componente QuillContent
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageResize from 'quill-image-resize-vue';
import {
    Filter,
    PlusCircle,
    RefreshCw,
    Search,
    Eye,
    Edit,
    ToggleLeft,
    ToggleRight,
    X,
    ImageIcon,
    SquareArrowLeft,
    Megaphone
} from 'lucide-vue-next';

// API Token from session
const apiToken = sessionStorage.getItem("api_token");
import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
// State variables
const informes = ref([]);
const informesFiltrados = ref([]);
const isLoading = ref(true);
const mostrarInativos = ref(false);
const filtroTitulo = ref('');
const filtroStatus = ref('');
const filtroDataInicio = ref('');
const filtroDataFim = ref('');
const modalFiltro = ref(false);
const modalInforme = ref(false);
const modalVisualizacao = ref(false);
const modoEdicao = ref(false);
const mensagemSucesso = ref('');
const informeSelecionado = ref({});


// Configurações para o editor
const editorModules = {
  name: 'imageResize',
  module: ImageResize,
  options: {
    displaySize: true,
  },
};

const editorToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['clean'],
  ['link', 'image', 'video'],
];

// Exemplo do objeto novoInforme, que pode estar no seu script principal
const novoInforme = ref({
  id: null,
  titulo: '',
  resumo: '',
  conteudo: '', // Esse campo será preenchido pelo editor
  imagem: null,
  data_publicacao: new Date().toISOString().split('T')[0],
  data_expiracao: null,
  ativo: true
});

// Sorting state
const colunaOrdenada = ref('id');
const ordemAscendente = ref(true);




// Image preview
const imagemPreview = ref(null);

// Form validation
const isFormValid = computed(() => {
    return (
        novoInforme.value.titulo &&
        novoInforme.value.data_publicacao
    );
});


// Filter count
const totalFiltros = computed(() => {
    return [filtroStatus.value, filtroDataInicio.value, filtroDataFim.value]
        .filter(f => f !== '').length;
});

// Apply filters
const aplicarFiltros = () => {
    // Normalize search text
    const filtroTituloTexto = filtroTitulo.value ? filtroTitulo.value.toString().trim().toLowerCase() : '';

    // Filter announcements
    informesFiltrados.value = informes.value.filter(informe => {
        // Extract and normalize data
        const titulo = informe.titulo ? informe.titulo.toString().toLowerCase() : '';
        const ativo = informe.ativo.toString();
        const dataPublicacao = new Date(informe.data_publicacao);

        // Hide inactive by default
        if (!mostrarInativos.value && !informe.ativo) return false;

        // Apply text filter
        if (filtroTituloTexto && !titulo.includes(filtroTituloTexto)) return false;

        // Apply status filter
        if (filtroStatus.value && ativo !== filtroStatus.value) return false;

        // Apply date range filter
        if (filtroDataInicio.value) {
            const dataInicio = new Date(filtroDataInicio.value);
            if (dataPublicacao < dataInicio) return false;
        }

        if (filtroDataFim.value) {
            const dataFim = new Date(filtroDataFim.value);
            dataFim.setHours(23, 59, 59); // End of day
            if (dataPublicacao > dataFim) return false;
        }

        return true;
    });
};

// Watch for filter changes
watch([
    filtroTitulo,
    filtroStatus,
    filtroDataInicio,
    filtroDataFim,
    mostrarInativos
], () => {
    aplicarFiltros();
});

// Apply filters and close modal
const aplicarEFecharFiltro = () => {
    aplicarFiltros();
    fecharModalFiltro();
};

// Clear filters
const limparFiltros = () => {
    filtroStatus.value = '';
    filtroDataInicio.value = '';
    filtroDataFim.value = '';
    filtroTitulo.value = '';
};

// Format date
const formatarData = (data) => {
    if (!data) return "Sem data";
    return new Date(data).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};


// Get status class
const getStatusClass = (ativo) => {
    return ativo
        ? 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200'
        : 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
};

// Sort function
const ordenarPor = (coluna) => {
    if (colunaOrdenada.value === coluna) {
        ordemAscendente.value = !ordemAscendente.value;
    } else {
        colunaOrdenada.value = coluna;
        ordemAscendente.value = true;
    }

    // Sort the announcements
    informesFiltrados.value.sort((a, b) => {
        let valorA = a[coluna];
        let valorB = b[coluna];

        // Convert dates to timestamps for comparison
        if (coluna === "data_publicacao" && valorA && valorB) {
            valorA = new Date(valorA).getTime();
            valorB = new Date(valorB).getTime();
        }

        // Alphabetical sorting
        if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
        if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
        return 0;
    });
};

// Modal functions
const abrirModalFiltro = () => modalFiltro.value = true;
const fecharModalFiltro = () => modalFiltro.value = false;
const abrirModalCriarInforme = () => {
    resetarFormulario();
    modoEdicao.value = false;
    modalInforme.value = true;
};
const fecharModalInforme = () => {
    modalInforme.value = false;
    resetarFormulario();
};


// Reset form
const resetarFormulario = () => {
    novoInforme.value = {
        id: null,
        titulo: '',
        resumo: '',
        conteudo: '',
        imagem: null,
        data_publicacao: new Date().toISOString().split('T')[0],
        ativo: true
    };
    imagemPreview.value = null;
};

const mensagemErro = ref('');
const inputImagem = ref(null); // Referência para o input file

const onImagemSelecionada = (event) => {
    const file = event.target.files[0];
    if (file) {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            img.onload = () => {
                // Verifica resolução
                if (img.width !== 450 || img.height !== 300) {
                    mensagemErro.value = "A imagem deve ter exatamente 450x300 pixels.";
                    imagemPreview.value = null;
                    novoInforme.value.imagem = null;

                    // Reseta input para permitir reenvio da mesma imagem
                    if (inputImagem.value) inputImagem.value.value = '';

                    // Remove a mensagem após 3 segundos
                    setTimeout(() => {
                        mensagemErro.value = '';
                    }, 3000);

                    return;
                }

                // Caso a imagem seja válida
                mensagemErro.value = '';
                imagemPreview.value = e.target.result;
                novoInforme.value.imagem = e.target.result;
            };

            img.src = e.target.result;
        };

        reader.readAsDataURL(file);
    }
};


const removerImagem = async () => {
    try {
        // Se for edição, remove imagem no backend
        if (modoEdicao.value && novoInforme.value.id) {
            const payload = {
                titulo: novoInforme.value.titulo,
                resumo: novoInforme.value.resumo,
                conteudo: novoInforme.value.conteudo,
                data_publicacao: novoInforme.value.data_publicacao,
                ativo: novoInforme.value.ativo,
                imagem_base64: null, // ← removendo imagem via PUT
            };

            await axios.put(
                `/api/v1/informes/${novoInforme.value.id}`,
                payload,
                { headers: getHeaders() }
            );

            // Atualiza localmente
            const index = informes.value.findIndex(item => item.id === novoInforme.value.id);
            if (index !== -1) {
                informes.value[index].imagem_base64 = null;
            }
        }

        imagemPreview.value = null;
        novoInforme.value.imagem = null;

        mensagemSucesso.value = 'Imagem removida com sucesso!';
        setTimeout(() => mensagemSucesso.value = '', 3000);
    } catch (error) {
        console.error("Erro ao remover imagem:", error);
        alert("Erro ao remover imagem do informe.");
    }
};
// View announcement details
const verDetalhes = (id) => {
    const informe = informes.value.find(item => item.id === id);
    if (informe) {
        informeSelecionado.value = { ...informe };
        modalVisualizacao.value = true;

    }
};

// Edit announcement
const editarInforme = (informe) => {
    novoInforme.value = {
        id: informe.id,
        titulo: informe.titulo,
        resumo: informe.resumo,
        conteudo: informe.conteudo,
        imagem: informe.imagem_base64, // esse é o que será enviado no payload
        data_publicacao: informe.data_publicacao,
        data_expiracao: informe.data_expiracao ? new Date(informe.data_expiracao).toISOString().split('T')[0] : null, // Formato yyyy-mm-dd
        ativo: informe.ativo,
    };

    // Renderiza corretamente a imagem no modo de edição
    if (informe.imagem_base64) {
        // Verifica se já contém o prefixo "data:image"
        if (!informe.imagem_base64.startsWith("data:image")) {
            // Detecta tipo da imagem
            const tipo = informe.imagem_base64.startsWith('/9j/') ? 'jpeg'
                : informe.imagem_base64.startsWith('iVBOR') ? 'png'
                    : informe.imagem_base64.startsWith('R0lG') ? 'gif'
                        : 'jpeg';

            imagemPreview.value = `data:image/${tipo};base64,${informe.imagem_base64}`;
        } else {
            imagemPreview.value = informe.imagem_base64;
        }

        novoInforme.value.imagem = informe.imagem_base64;
    } else {
        imagemPreview.value = null;
        novoInforme.value.imagem = null;
    }

    modoEdicao.value = true;
    modalInforme.value = true;
};

// Save announcement (create or update)
const salvarInforme = async () => {
    console.log("Conteúdo antes de salvar:", novoInforme.value.conteudo); // Verifique o conteúdo aqui

    if (!novoInforme.value.conteudo) {
        alert("O conteúdo completo é obrigatório.");
        return;
    }

    const payload = {
        titulo: novoInforme.value.titulo,
        resumo: novoInforme.value.resumo,
        conteudo: novoInforme.value.conteudo,
        imagem_base64: novoInforme.value.imagem,
        data_publicacao: novoInforme.value.data_publicacao,
        data_expiracao: novoInforme.value.data_expiracao,
        ativo: novoInforme.value.ativo
    };

    let response;
    try {
        if (modoEdicao.value) {
            response = await axios.put(`/api/v1/informes/${novoInforme.value.id}`, payload, { headers: getHeaders() });
            // Atualiza a lista de informes localmente
            const index = informes.value.findIndex(item => item.id === novoInforme.value.id);
            if (index !== -1) informes.value[index] = response.data;
            mensagemSucesso.value = "Informe atualizado com sucesso!";
        } else {
            response = await axios.post('/api/v1/informes', payload, { headers: getHeaders() });
            informes.value.push(response.data);
            mensagemSucesso.value = "Informe criado com sucesso!";
        }

        aplicarFiltros();
        fecharModalInforme();

        setTimeout(() => {
            mensagemSucesso.value = '';
        }, 3000);
    } catch (error) {
        console.error("Erro ao salvar informe:", error);
        alert("Erro ao salvar informe!");
    }
};


// API headers
const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'X-API-KEY': `${apiToken}`,
    };
};

const carregarInformes = async () => {
    isLoading.value = true;
    try {
        const response = await axios.get('/api/v1/informes', { headers: getHeaders() });

        // Ajuste de formato ISO para data_expiracao
        const informesTratados = response.data.map(item => {
            return {
                ...item,
                imagem: item.imagem_base64 ?? null,
                data_expiracao: item.data_expiracao
                    ? new Date(item.data_expiracao).toISOString().split('T')[0] // Formato yyyy-mm-dd
                    : null
            };
        });

        informes.value = informesTratados;
        aplicarFiltros();
    } catch (error) {
        console.error("Erro ao carregar informes:", error);
    } finally {
        isLoading.value = false;
    }
};

// Initialize
onMounted(() => {
    carregarInformes();
});
</script>

<style scoped>
.animate-fade-in {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Certifique-se de que a formatação de conteúdo seja consistente com o fundo branco */
.prose {
  background-color: white; /* Garantir fundo branco */
  max-width: 100%; /* Permitir que o conteúdo ocupe toda a largura */
  border-radius: 15px;
}

.prose img {
  max-width: 100%;
  height: auto;
}

.prose pre {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 5px;
  color: #333;
}

.prose code {
  font-size: 1rem;
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
}

/* Ajustes para classes de indentação */
.ql-editor .ql-indent-1 {
  padding-left: 20px;
}

.ql-editor .ql-indent-2 {
  padding-left: 40px;
}

.ql-editor .ql-indent-3 {
  padding-left: 60px;
}

.ql-editor .ql-indent-4 {
  padding-left: 80px;
}
</style>