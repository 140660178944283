<template>
    <BaseTemplate app-name="Detalhes da Pendência" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-8 min-h-screen">
                <!-- Loading Spinner -->
                <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
                    <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                </div>

                <div v-else>
                    <!-- Action Buttons -->
                    <div class="flex justify-between items-center w-full mb-6">
                        <button
  @click="voltar"
  class="hidden md:inline-flex items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>

                        <div v-if="isCriador">
                        <button @click="confirmarExclusao"
                            class="px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 transition-all transform hover:scale-105 flex items-center space-x-2">
                            <Trash2 class="w-5 h-5" />
                            <span class="hidden sm:inline">Excluir Pendencia</span>
                        </button>
                    </div>
                    </div>

                    <div v-if="pendencia" class="grid grid-cols-1 lg:grid-cols-3 gap-6">
                        <!-- Main Section -->
                        <div class="lg:col-span-2 space-y-6">
                            <!-- Task Details -->
                            <div class="bg-white dark:bg-gray-700 rounded-2xl shadow-2xl p-6 space-y-6">
                                <div
                                    class="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-200 dark:border-gray-600 pb-4">
                                    <h1
                                        class="text-3xl font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 mb-2 sm:mb-0">
                                        #{{ pendencia.codigo }}
                                    </h1>
                                    <span :class="[
                                        getPrioridadeClass(pendencia.prioridade),
                                        'px-3 py-1 rounded-full text-xs font-bold uppercase tracking-wider shadow-md'
                                    ]">
                                        {{ pendencia.prioridade }}
                                    </span>
                                </div>
                                <!-- Unidade e Setor -->
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <!-- Unidade -->
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Unidade
                                        </label>
                                        <input type="text"
                                            :value="pendencia.unidade?.nome || 'Nenhuma unidade definida'" readonly
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 cursor-not-allowed" />
                                    </div>

                                    <!-- Setor -->
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Setor
                                        </label>
                                        <select v-if="setoresFiltrados.length > 0" v-model="pendencia.setor_id"
                                            @change="atualizarSetor" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-300">
                                            <option :value="null">Nenhum</option>
                                            <option v-for="setor in setoresFiltrados" :key="setor.id" :value="setor.id"> 
                                                {{ setor.nome }}
                                            </option>
                                        </select>


                                        <!-- Caso não tenha setores, mostrar apenas um input readonly -->
                                        <input v-else type="text"
                                            :value="pendencia.setor?.nome || 'Nenhum setor definido'" readonly :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 cursor-not-allowed" />
                                    </div>

                                </div>


                                <!-- Problema e Solução -->
                                <div class="space-y-4">
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">Problema</label>
                                        <textarea v-model="pendencia.problema" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[120px] transition-all"
                                            @change="salvarAlteracoes"></textarea> 
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">Solução</label>
                                        <textarea v-model="pendencia.solucao" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[120px] transition-all"
                                            @change="salvarAlteracoes"></textarea>
                                    </div>
                                </div>
                            </div>

                            <!-- Observações Section -->
                            <div class="bg-white dark:bg-gray-700 rounded-2xl shadow-2xl p-6 space-y-6">
                                <h3
                                    class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                                    Observações
                                </h3>
                                <!-- Add Observation -->
                                                            <!-- Adicionar Observação -->
                            <!-- Seção de Observações - Apenas Criador pode ver -->
                            <div v-if="isCriador" class="space-y-4">
                                <textarea v-model="novaObservacao.texto"
                                    class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[100px] transition-all placeholder-gray-400 dark:placeholder-gray-500"
                                    placeholder="Digite sua observação aqui..." @input="validarObservacao">
                                </textarea>

                                <!-- Exibe erro caso a observação seja inválida -->
                                <p v-if="erroObservacao" class="text-red-500 text-sm">
                                    ❌ O campo de observação não pode estar vazio.
                                </p>

                                <button @click="adicionarObservacao"
                                    :disabled="botaoDesativado || isAddingObservation"
                                    class="w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg shadow-md transform transition-all"
                                    :class="(botaoDesativado || isAddingObservation)
                                        ? 'bg-gray-400 cursor-not-allowed hover:scale-100'
                                        : 'bg-blue-600 text-white hover:bg-blue-700 hover:scale-100'">
                                    <Send class="w-5 h-5" />
                                    <span v-if="!isAddingObservation">Adicionar Observação</span>
                                    <span v-else>Adicionando...</span>
                                </button>
                            </div>


                                <!-- Lista de Observações -->
                                <div v-if="observacoes.length > 0" class="space-y-4 mt-6">
                                    <div v-for="obs in observacoes" :key="obs.id"
                                        class="p-4 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md transition-all hover:shadow-lg dark:hover:shadow-xl bg-white dark:bg-gray-800">

                                        <!-- Cabeçalho da Observação -->
                                        <div class="flex items-center justify-between">
                                            <div class="flex items-center space-x-3">
                                                <UserCircle class="w-6 h-6 text-gray-500 dark:text-gray-400" />
                                                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">{{
                                                    obs.usuario_nome }}</span>
                                                <span class="text-xs text-gray-500 dark:text-gray-400">{{
                                                    formatarData(obs.data_criacao) }}</span>
                                            </div>

                                            <!-- Botões de Ação (Somente para o usuário dono da observação) -->
                                            <div v-if="obs.usuario_id === userId" class="flex space-x-2">
                                                <button @click="editarObservacao(obs)"
                                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-yellow-500 hover:bg-yellow-600 transition-all text-white shadow-md active:scale-90">
                                                    <Pencil class="w-5 h-5" />
                                                </button>
                                                <button @click="abrirModalExclusaoPendencia(obs.id)"
                                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-red-500 hover:bg-red-600 transition-all text-white shadow-md active:scale-90">
                                                    <Trash2 class="w-5 h-5" />
                                                </button>
                                            </div>

                                        </div>

                                        <!-- Modo de Edição -->
                                        <div v-if="obs.editando" class="mt-3">
                                            <textarea v-model="obs.textoEditado"
                                                class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[80px] transition-all">
            </textarea>
                                            <div class="flex justify-end space-x-2 mt-2">
                                                <button @click="cancelarEdicao(obs)"
                                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-gray-500 hover:bg-gray-600 transition-all text-white shadow-md active:scale-90">
                                                    <X class="w-5 h-5" />
                                                </button>
                                                <button @click="salvarEdicao(obs)"
                                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-green-500 hover:bg-green-600 transition-all text-white shadow-md active:scale-90">
                                                    <Check class="w-5 h-5" />
                                                </button>
                                            </div>
                                        </div>

                                        <!-- Exibição da Observação (Se não estiver editando) -->
                                        <p v-else class="text-gray-600 dark:text-gray-300 mt-2 leading-relaxed">{{
                                            obs.texto }}</p>
                                    </div>
                                </div>

                                <!-- Nenhuma Observação -->
                                <div v-else class="text-gray-500 dark:text-gray-400 text-center mt-4">
                                    <FileText class="w-8 h-8 mx-auto mb-2 text-gray-400 dark:text-gray-500" />
                                    Nenhuma observação adicionada ainda.
                                </div>
                            </div>
                        </div>

                        <!-- Sidebar -->
                        <div class="space-y-6">
                            <!-- Informações Adicionais -->
                            <div class="bg-white dark:bg-gray-700 rounded-2xl shadow-2xl p-6 space-y-6">
                                <h3
                                    class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                                    Informações Adicionais
                                </h3>
                                <div class="space-y-4">
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Criado por
                                        </label>
                                        <input type="text" :value="pendencia.criado_por.name|| 'Nenhum Criador'"
                                            readonly
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 cursor-not-allowed" />
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Tipo
                                        </label>
                                        <input type="text" :value="pendencia.tipo?.nome || 'Nenhum tipo definido'"
                                            readonly 
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 cursor-not-allowed" />
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Status
                                        </label>
                                        <select v-model="pendencia.status_id" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-all"
                                            @change="salvarAlteracoes">
                                            <option v-for="status in statusFiltrados" :key="status.id"
                                                :value="status.id">
                                                {{ status.nome }}
                                            </option>
                                        </select>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Prioridade
                                        </label>
                                        <select v-model="pendencia.prioridade" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-all"
                                            @change="salvarAlteracoes">
                                            <option value="baixa">Baixa</option>
                                            <option value="media">Média</option>
                                            <option value="alta">Alta</option>
                                            <option value="critica">Crítica</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Prazo
                                        </label>
                                        <input type="date" v-model="pendencia.prazo" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-all"
                                            @change="salvarAlteracoes" />
                                    </div>


                                    <!-- Número do GLPI -->
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Número do GLPI
                                        </label>
                                        <input type="text" v-model="pendencia.glpi" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-all"
                                            placeholder="Digite o número do GLPI" @change="salvarAlteracoes" />
                                    </div>

                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Orçamento
                                        </label>
                                        <div class="relative">
                                            <div
                                                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm">R$</span>
                                            </div>
                                            <input type="text" v-model="orcamentoFormatado" :disabled="!isCriador"
                                                @input="handleOrcamentoInput" @blur="handleOrcamentoBlur"
                                                @change="salvarAlteracoes"
                                                class="w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-all"
                                                placeholder="0,00" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Responsáveis -->
                            <div class="bg-white dark:bg-gray-700 rounded-2xl shadow-2xl p-6 space-y-6">
                                <h3
                                    class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                                    Responsáveis
                                </h3>
                                <div class="space-y-4">
                                    <!-- Responsável Interno -->
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Responsável Interno
                                        </label>
                                        <select v-model="pendencia.responsavel_interno_id" :disabled="!isCriador"
                                            class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all"
                                            @change="salvarAlteracoes">
                                            <option :value="null">Nenhum</option>
                                            <option v-for="resp in responsaveisInternos" :key="resp.id"
                                                :value="resp.id">
                                                {{ resp.nome_completo }}
                                            </option>
                                        </select>
                                    </div>
                                    <!-- Responsável Externo (permanece igual) -->
                                    <!-- Responsável Externo -->
                                    <!-- Responsável Externo -->
                                    <div>
                                        <label
                                            class="block text-sm font-bold text-gray-600 dark:text-gray-300 mb-2 uppercase tracking-wider">
                                            Responsável Externo
                                        </label>
                                        <div class="flex gap-2">
                                            <select v-model.number="pendencia.responsavel_externo_id" :disabled="!isCriador"
                                                class="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all"
                                                @change="salvarAlteracoes">
                                                <option :value="null">Nenhum</option>
                                                <option v-for="fornecedor in fornecedores" :key="fornecedor.id"
                                                    :value="fornecedor.id">
                                                    {{ fornecedor.nome }}
                                                </option>
                                            </select>
                                            <div v-if="isCriador">
                                            <button @click="abrirModalFornecedor('novo')" 
                                                class="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow">
                                                <Plus class="w-5 h-5" />
                                            </button>
                                        </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Success Banner -->
                <div v-show="exibirBanner"
                    class="fixed top-5 right-5 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg transition-opacity duration-500 animate-fade-in"
                    :class="{ 'animate-fade-out': !exibirBanner }">
                    {{ mensagemSucesso }}
                </div>

                <!-- Footer -->
                <p class="text-center text-sm mt-8 text-gray-500 dark:text-gray-400">
                    © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
                </p>

                <!-- Confirmation Modal -->
                <transition name="modal">
                    <div v-if="modalExcluir"
                        class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                        <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                            <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
                                ⚠️ Confirmação de Exclusão
                            </h3>
                            <p class="text-gray-600 dark:text-gray-300">
                                Tem certeza de que deseja excluir esta pendência? <br>
                                <strong>Esta ação é irreversível!</strong>
                            </p>
                            <div class="flex justify-end gap-3 mt-6">
                                <button @click="modalExcluir = false"
                                    class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-all">
                                    Cancelar
                                </button>
                                <button @click="excluirPendencia" :disabled="isDeletingPendencia"
                                    class="px-6 py-2.5 bg-red-600 text-white rounded-lg font-medium hover:bg-red-700 transition-all"
                                    :class="{ 'opacity-50 cursor-not-allowed': isDeletingPendencia }">
                                    <span v-if="!isDeletingPendencia">Sim, Excluir</span>
                                    <span v-else>Excluindo...</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Confirmation Modal -->
                <transition name="modal">
                    <div v-if="modalExcluiObservacao"
                        class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                        <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                            <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
                                ⚠️ Confirmação de Exclusão
                            </h3>
                            <p class="text-gray-600 dark:text-gray-300">
                                Tem certeza de que deseja excluir esta observação? <br>
                                <strong>Esta ação é irreversível!</strong>
                            </p>
                            <div class="flex justify-end gap-3 mt-6">
                                <button @click="modalExcluiObservacao = false"
                                    class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-all">
                                    Cancelar
                                </button>
                                <button @click="excluirObservacao" :disabled="isDeletingObservation"
                                    class="px-6 py-2.5 bg-red-600 text-white rounded-lg font-medium hover:bg-red-700 transition-all"
                                    :class="{ 'opacity-50 cursor-not-allowed': isDeletingObservation }">
                                    <span v-if="!isDeletingObservation">Sim, Excluir</span>
                                    <span v-else>Excluindo...</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="modal">
                    <div v-if="modalFornecedor"
                        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
                            <h2
                                class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                                <PackageIcon class="w-5 h-5 text-green-500" />
                                {{ modoEdicaoFornecedor ? 'Editar Fornecedor' : 'Novo Fornecedor' }}
                            </h2>
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Nome</label>
                            <input v-model="formFornecedor.nome" type="text" placeholder="Digite o nome"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">CNPJ</label>
                            <input v-model="formFornecedor.cnpj" type="text" placeholder="Digite o CNPJ"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <label
                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Telefone</label>
                            <input v-model="formFornecedor.telefone" type="text" placeholder="Digite o telefone"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
                            <input v-model="formFornecedor.email" type="email" placeholder="Digite o email"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <label
                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Endereço</label>
                            <input v-model="formFornecedor.endereco" type="text" placeholder="Digite o endereço"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Contato
                                Responsável</label>
                            <input v-model="formFornecedor.contato_responsavel" type="text"
                                placeholder="Digite o contato responsável"
                                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                            <div class="flex justify-end space-x-3">
                                <button @click="fecharModalFornecedor"
                                    class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                                    <XIcon class="w-4 h-4" /> Cancelar
                                </button>
                                <button @click="salvarFornecedor" :disabled="isSaving"
                                    class="flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                                    <SaveIcon class="w-4 h-4" />
                                    <span v-if="!isSaving">Salvar</span>
                                    <span v-else>Salvando...</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </template>
    </BaseTemplate>
</template>


<script setup>
import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import {
    RefreshCw,
    SquareArrowLeft,
    Trash2,
    Send,
    UserCircle,
    Pencil,
    X,
    Check,
    FileText,
    Plus,
    SaveIcon,
    PackageIcon,
    XIcon
} from 'lucide-vue-next';

const isLoading = ref(true); // 🔹 Estado de carregamento
const route = useRoute();
const observacaoParaExcluir = ref(null);  // ID da observação que será excluída
const mensagemSucesso = ref("");
const exibirBanner = ref(false);
const router = useRouter();
const pendencia = ref(null);
const unidades = ref([]);
const userId = ref({ }); // Exemplo, precisa vir do Vuex ou API
const novaObservacao = ref({ texto: "" });
const observacoes = ref([]);
const modalExcluir = ref(false);  // Estado do modal
const isDeletingPendencia = ref(false); // Estado de carregamento da exclusão de pendência
const modalExcluiObservacao = ref(false);  // Estado do modal
const setores = ref([]);
const tiposPendencias = ref([]);
const isSaving = ref(false)
// Estado para modal de fornecedores
const modalFornecedor = ref(false);
const modoEdicaoFornecedor = ref(false);
const statusPendencias = ref([]);
const usuarios = ref([]);
const fornecedores = ref([]);
const isAddingObservation = ref(false); // Estado para bloquear o botão de adicionar observação
const isDeletingObservation = ref(false); // Estado para bloquear o botão de excluir observação
const erroObservacao = ref(false);
const botaoDesativado = computed(() => novaObservacao.value.texto.trim() === "");
const apiToken = sessionStorage.getItem("api_token");
const headers = { "X-API-KEY": apiToken, "Content-Type": "application/json" };
const userGroups = ref(JSON.parse(sessionStorage.getItem("user_groups")) || []);
userId.value = parseInt(sessionStorage.getItem("user_id"), 10) || null;
// Função para formatar o número para moeda brasileira


function formatarMoeda(valor) {
    // Remove tudo que não for dígito
    let numeros = valor.replace(/\D/g, '');
    if (numeros === '') return '0,00';

    // Garante que haja pelo menos 3 dígitos para separar parte inteira e centavos
    while (numeros.length < 3) {
        numeros = '0' + numeros;
    }

    // Separa a parte inteira dos centavos
    const parteInteira = numeros.slice(0, -2);
    const parteDecimal = numeros.slice(-2);

    // Insere separador de milhar – essa regex insere um ponto a cada grupo de 3 dígitos (exceto no início)
    const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parteInteiraFormatada + ',' + parteDecimal;
}

/**
 * Propriedade computada para o orçamento formatado:
 * - No getter, converte o valor armazenado para string e aplica a formatação.
 * - No setter, remove a formatação para armazenar o valor puro (somente dígitos).
 */
const orcamentoFormatado = computed({
    get() {
        // Converte o valor numérico para string e formata
        return formatarMoeda(String(pendencia.value.orcamento));
    },
    set(valor) {
        // Remove todos os caracteres não numéricos para obter apenas os dígitos
        let somenteDigitos = valor.replace(/\D/g, '');
        pendencia.value.orcamento = parseInt(somenteDigitos) || 0;
    }
});

// Permite que o usuário digite apenas os caracteres válidos e atualiza a variável
const handleOrcamentoInput = (event) => {
    // Permite apenas dígitos, ponto e vírgula (para que o usuário possa digitar livremente)
    let valor = event.target.value.replace(/[^0-9.,]/g, '');
    // Atualiza o valor sem aplicar a formatação completa (para não atrapalhar a digitação)
    orcamentoFormatado.value = valor;
};

// Computed para verificar se o usuário atual é o criador
// Computed para verificar se o usuário pode editar (criador ou admin)
const isCriador = computed(() => {
    return (
        pendencia.value &&
        (pendencia.value.criado_por_id === userId.value ||
        userGroups.value.includes("superadmin") ||
        userGroups.value.includes("direção"))
    );
});



// Ao perder o foco, aplica a formatação completa conforme a função formatarMoeda
const handleOrcamentoBlur = (event) => {
    const valorFormatado = formatarMoeda(event.target.value);
    event.target.value = valorFormatado;
    orcamentoFormatado.value = valorFormatado;
};

const carregarDados = async () => {
    isLoading.value = true;
    try {
        const [
            pendenciaRes, unidadesRes, setoresRes,
            tiposRes, statusRes, usuariosRes
            // Removemos fornecedoresRes daqui para não sobrescrever
        ] = await Promise.all([
            axios.get(`/api/v1/pendencias/${route.params.id}`, { headers }),
            axios.get("/api/v1/unidades/", { headers }),
            axios.get("/api/v1/setores/", { headers }),
            axios.get("/api/v1/tipos_pendencias/", { headers }),
            axios.get("/api/v1/status_pendencias/", { headers }),
            axios.get("/api/v1/usuarios/", { headers }),
            axios.get("/api/v1/fornecedores/", { headers }),
        ]);

        let data = pendenciaRes.data;
        pendencia.value = {
            ...data,
            unidade_id: data.unidade?.id || null,
            setor_id: data.setor?.id || null,
            tipo_id: data.tipo?.id || null,
            status_id: data.status?.id || null,
            responsavel_interno_id: data.responsavel_interno || null,
            responsavel_externo_id: data.responsavel_externo || null,
        };

        unidades.value = unidadesRes.data;
        setores.value = setoresRes.data;
        tiposPendencias.value = tiposRes.data;
        statusPendencias.value = statusRes.data;
        usuarios.value = usuariosRes.data;

        console.log("🔹 Dados carregados:", {
            pendencia: pendencia.value,
            unidades: unidades.value,
            setores: setores.value,
            tiposPendencias: tiposPendencias.value,
            statusPendencias: statusPendencias.value,
            usuarios: usuarios.value,
            // fornecedores: fornecedores.value, // Não atribuímos aqui
        });
    } catch (error) {
        console.error("Erro ao carregar dados:", error);
    } finally {
        isLoading.value = false;
    }
};




const editarObservacao = (obs) => {
    obs.editando = true;
    obs.textoEditado = obs.texto;  // Mantém o valor original
};

const cancelarEdicao = (obs) => {
    obs.editando = false;
    obs.textoEditado = "";
};

const salvarEdicao = async (obs) => {
    try {
        await axios.put(`/api/v1/pendencias/${pendencia.value.id}/observacoes/${obs.id}/`, {
            texto: obs.textoEditado,
        }, { headers });

        obs.texto = obs.textoEditado;
        obs.editando = false;
    } catch (error) {
        console.error("Erro ao editar observação:", error);
    }
};

const statusFiltrados = computed(() => {
    if (!pendencia.value || !pendencia.value.tipo_id) return [];

    const tipoSelecionado = tiposPendencias.value.find(tipo => tipo.id === pendencia.value.tipo_id);

    return tipoSelecionado && Array.isArray(tipoSelecionado.status_disponiveis)
        ? tipoSelecionado.status_disponiveis
        : [];
});



// Função para abrir o modal de exclusão
const abrirModalExclusaoPendencia = (obsId) => {
    observacaoParaExcluir.value = obsId;  // Define a observação a ser excluída
    modalExcluiObservacao.value = true;  // Abre o modal
};

const excluirObservacao = async () => {
    if (!observacaoParaExcluir.value || isDeletingObservation.value) return;

    try {
        isDeletingObservation.value = true; // Bloqueia o botão
        await axios.delete(`/api/v1/pendencias/${pendencia.value.id}/observacoes/${observacaoParaExcluir.value}/`, { headers });

        // Remove a observação da lista sem recarregar a página
        observacoes.value = observacoes.value.filter(obs => obs.id !== observacaoParaExcluir.value);

        // Fecha o modal após a exclusão
        modalExcluiObservacao.value = false;
        observacaoParaExcluir.value = null;
    } catch (error) {
        console.error("Erro ao excluir observação:", error);
    } finally {
        isDeletingObservation.value = false; // Libera o botão
    }
};

const setoresFiltrados = computed(() => {
    if (!pendencia.value?.unidade_id) return [];
    return setores.value.filter(setor => setor.unidade?.id === pendencia.value.unidade_id);
});


// Valida se a observação não está vazia
const validarObservacao = () => {
    erroObservacao.value = novaObservacao.value.texto.trim() === "";
};

const adicionarObservacao = async () => {
    if (novaObservacao.value.texto.trim() === "" || isAddingObservation.value) {
        erroObservacao.value = true;
        return;
    }

    try {
        isAddingObservation.value = true; // Bloqueia o botão
        await axios.post(`/api/v1/pendencias/${pendencia.value.id}/observacoes/`, {
            texto: novaObservacao.value.texto.trim()
        }, { headers });

        novaObservacao.value.texto = ""; // Limpa o campo
        erroObservacao.value = false; // Reseta erro
        await carregarObservacoes(); // Atualiza a lista
    } catch (error) {
        console.error("Erro ao adicionar observação:", error);
    } finally {
        isAddingObservation.value = false; // Libera o botão
    }
};


// Função para carregar observações da API
const carregarObservacoes = async () => {
    try {
        const response = await axios.get(`/api/v1/pendencias/${pendencia.value.id}/observacoes/`, { headers });
        observacoes.value = response.data;  // Atualiza a lista de observações
    } catch (error) {
        console.error("Erro ao carregar observações:", error);
    }
};

// Função para formatar data no estilo DD/MM/AAAA HH:mm
const formatarData = (data) => {
    const dt = new Date(data);
    return dt.toLocaleDateString("pt-BR") + " " + dt.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
};


const carregarPendencia = async () => {
    try {
        const res = await axios.get(`/api/v1/pendencias/${route.params.id}`, { headers });
        let data = res.data;

        pendencia.value = {
            ...data,
            unidade_id: data.unidade?.id || null,
            setor_id: data.setor?.id || null,
            tipo_id: data.tipo?.id || null,
            status_id: data.status?.id || null,
            responsavel_interno_id: data.responsavel_interno || null,
            responsavel_externo_id: data.responsavel_externo || null,
            glpi: data.glpi || "" // ✅ Garante que o GLPI seja armazenado corretamente
        };

        console.log("Dados ajustados:", pendencia.value);
    } catch (error) {
        console.error("Erro ao carregar pendência:", error);
        router.push({ name: "PendenciasDirecao" });
    }
};

const atualizarSetor = () => {
    const setorSelecionado = setores.value.find(setor => setor.id === pendencia.value.setor_id) || null;
    pendencia.value.setor = setorSelecionado;
    salvarAlteracoes();
};

const salvarAlteracoes = async () => {
    try {
        let payload = { 
            ...pendencia.value,
            setor_id: pendencia.value.setor_id  // Inclui setor_id corretamente
        };

        await axios.put(`/api/v1/pendencias/${route.params.id}/`, payload, { headers });

        console.log("Pendência atualizada!", payload);
        mensagemSucesso.value = "Alterações salvas com sucesso!";
        exibirBanner.value = true;

        setTimeout(() => exibirBanner.value = false, 2500);
        setTimeout(() => mensagemSucesso.value = "", 3000);
    } catch (error) {
        console.error("Erro ao atualizar pendência:", error);
    }
};






// 🔹 Função para definir a classe CSS de prioridade
const getPrioridadeClass = (prioridade) => {
    const classes = {
        critica: "bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200",
        alta: "bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-200",
        media: "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200",
        baixa: "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
    };
    return classes[prioridade] || "bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200";
};



// 🔹 Carrega tudo ao iniciar
onMounted(async () => {
    await carregarPendencia();
    await carregarObservacoes();
});

// Estado para fornecedores – incluindo os novos campos
const formFornecedor = ref({
    id: null,
    nome: "",
    cnpj: "",
    telefone: "",
    email: "",
    endereco: "",
    contato_responsavel: ""
})

// Função para abrir o modal de fornecedores
const abrirModalFornecedor = (modo, registro = null) => {
    modoEdicaoFornecedor.value = modo === "editar"
    if (modoEdicaoFornecedor.value && registro) {
        // Aqui, certifique-se de que os campos "endereco" e "contato_responsavel" estão presentes
        formFornecedor.value = {
            id: registro.id,
            nome: registro.nome,
            cnpj: registro.cnpj || "",
            telefone: registro.telefone || "",
            email: registro.email || "",
            endereco: registro.endereco || "",
            contato_responsavel: registro.contato_responsavel || ""
        }
    } else {
        formFornecedor.value = { id: null, nome: "", cnpj: "", telefone: "", email: "", endereco: "", contato_responsavel: "" }
    }
    modalFornecedor.value = true
}



const fecharModalFornecedor = () => {
    modalFornecedor.value = false
    formFornecedor.value = { id: null, nome: "", cnpj: "", telefone: "", email: "", endereco: "", contato_responsavel: "" }
}

const API_FORNECEDORES = '/api/v1/fornecedores/'
const getHeaders = () => {
    const token = sessionStorage.getItem("api_token")
    return {
        "Content-Type": "application/json",
        "X-API-KEY": token
    }
}

// Watchers para aplicar a formatação enquanto o usuário digita
watch(() => formFornecedor.value.cnpj, (newVal) => {
    formFornecedor.value.cnpj = formatCNPJ(newVal)
})


const error = ref(null)
const salvarFornecedor = async () => {
    if (isSaving.value) return
    isSaving.value = true
    const headers = getHeaders()
    if (!headers) return

    if (!formFornecedor.value.nome) {
        error.value = "Preencha o campo Nome para o fornecedor."
        isSaving.value = false
        return
    }

    try {
        const payload = {
            nome: formFornecedor.value.nome,
            cnpj: formFornecedor.value.cnpj ? formFornecedor.value.cnpj.replace(/\D/g, "") : null,
            telefone: formFornecedor.value.telefone ? formFornecedor.value.telefone.replace(/\D/g, "") : null,
            email: formFornecedor.value.email || null,
            endereco: formFornecedor.value.endereco || null,
            contato_responsavel: formFornecedor.value.contato_responsavel || null
        }
        const endpoint = API_FORNECEDORES
        if (modoEdicaoFornecedor.value) {
            await axios.put(`${endpoint}/${formFornecedor.value.id}/`, payload, { headers })
        } else {
            await axios.post(endpoint, payload, { headers })
        }
        await carregarDados()
        fecharModalFornecedor()
        carregarFornecedores()
    } catch (err) {
        console.error("Erro ao salvar fornecedor:", err)
        error.value = "Erro ao salvar o fornecedor."
    } finally {
        isSaving.value = false
    }
}

// Funções de formatação
function formatCNPJ(value) {
    if (!value) return ""
    value = value.replace(/\D/g, '').slice(0, 14)
    if (value.length > 12) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5')
    } else if (value.length > 8) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4')
    } else if (value.length > 5) {
        return value.replace(/(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3')
    } else if (value.length > 2) {
        return value.replace(/(\d{2})(\d{0,3})/, '$1.$2')
    }
    return value
}

function formatPhone(value) {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    if (value.length >= 11) {
        return value.replace(/^(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3')
    } else {
        return value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3')
    }
}

watch(() => formFornecedor.value.telefone, (newVal) => {
    formFornecedor.value.telefone = formatPhone(newVal)
})


// 🔹 Abre o modal antes de excluir
const confirmarExclusao = () => {
    modalExcluir.value = true;
};

const excluirPendencia = async () => {
    if (isDeletingPendencia.value) return; // Evita múltiplos cliques

    try {
        isDeletingPendencia.value = true; // Bloqueia o botão
        await axios.delete(`/api/v1/pendencias/${route.params.id}/`, { headers });

        router.push({ name: "PendenciasDirecao" });
    } catch (error) {
        console.error("Erro ao excluir pendência:", error);
        alert("Erro ao excluir pendência!");
    } finally {
        isDeletingPendencia.value = false; // Libera o botão
        modalExcluir.value = false; // Fecha o modal
    }
};

// Crie uma variável para os responsáveis internos
const responsaveisInternos = ref([]);

// Função para carregar os responsáveis internos
const carregarResponsaveisInternos = async () => {
    try {
        const response = await axios.get("/api/v1/responsaveis/", { headers });
        // Se a resposta tiver a chave "items", usamos ela; caso contrário, usamos a resposta direta.
        responsaveisInternos.value = response.data.items || response.data;
    } catch (error) {
        console.error("Erro ao carregar responsáveis internos:", error);
    }
};

// Função para carregar fornecedores (única função)
const carregarFornecedores = async () => {
    try {
        const response = await axios.get("/api/v1/fornecedores/", { headers });
        fornecedores.value = response.data.items || response.data;
        console.log("Fornecedores carregados:", fornecedores.value);
    } catch (error) {
        console.error("Erro ao carregar fornecedores:", error);
    }
};





// 🔹 Voltar
const voltar = () => router.go(-1);

onMounted(carregarDados);
onMounted(carregarResponsaveisInternos);
onMounted(carregarFornecedores);
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
