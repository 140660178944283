<template>
  <BaseTemplate app-name="Gestão de Equipamentos" page-title="">
    <template #content>
      <div class="container mx-auto p-6">

             <!-- Cabeçalho --><button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
        <!-- Cabeçalho com botões alinhados -->
        <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
          <h1 class="flex items-center gap-2 text-2xl font-bold text-gray-900 dark:text-white">
            <HardDriveIcon class="w-6 h-6 text-blue-500" />
            Gestão de Equipamentos
          </h1>
          <div class="flex flex-wrap items-center gap-3 mt-4 sm:mt-0">
            <!-- Botão de Filtro -->
            <button @click="abrirModalFiltro"
              class="relative flex items-center gap-2 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-white px-4 py-2 rounded-lg shadow">
              <FilterIcon class="w-5 h-5" />
              <span class="font-medium">Filtros</span>
              <span v-if="totalFiltros > 0"
                class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                {{ totalFiltros }}
              </span>
            </button>
            <button @click="openModal()"
              class="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg shadow">
              <PlusIcon class="w-5 h-5" />
              Novo Equipamento
            </button>
          </div>
        </div>


        <!-- Filtros -->
        <div class="flex flex-col sm:flex-row gap-4 mb-6">
          <input v-model="filter" type="text" placeholder="Buscar por nome ou modelo..."
            class="w-full sm:w-1/2 border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
        </div>

        <!-- Mensagem de erro -->
        <p v-if="error" class="text-red-500 text-center mb-4">{{ error }}</p>

        <!-- Modal de Filtro -->
        <div v-if="isFiltroModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          @click.self="fecharModalFiltro">
          <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
            <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
              <FilterIcon class="w-5 h-5 text-blue-500" />
              Filtros
            </h2>

            <!-- Filtro por unidade -->
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade</label>
              <select v-model="filtroUnidade"
                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                <option value="">Todas</option>
                <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                  {{ unidade.nome }}
                </option>
              </select>
            </div>

            <!-- Filtro por status -->
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Status</label>
              <select v-model="filtroStatus"
                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                <option value="">Todos os Status</option>
                <option value="em_funcionamento">Em Funcionamento</option>
                <option value="em_atendimento">Em Atendimento</option>
                <option value="parado">Parado</option>
              </select>
            </div>

            <!-- Filtro por canais -->
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Canais</label>
              <select v-model="filtroCanais"
                class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                <option value="">Todos os Canais</option>
                <option value="16">16 Canais</option>
                <option value="64">64 Canais</option>
                <option value="80">80 Canais</option>
                <option value="128">128 Canais</option>
              </select>
            </div>

            <!-- Botões do modal -->
            <div class="flex justify-end space-x-3">
              <button @click="limparFiltros"
                class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                Limpar Filtros
              </button>
              <button @click="aplicarFiltros"
                class="flex items-center gap-1 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg">
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <!-- Tabela para telas maiores -->
        <div class="hidden sm:block bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto dark:text-white text-gray-800">
          <table class="w-full">
            <thead
              class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
              <tr>
                <th @click="ordenarPor('unidade')" class="cursor-pointer px-4 py-3 text-left">
                  Unidade <span v-if="colunaOrdenada === 'unidade'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('nome')" class="cursor-pointer px-4 py-3 text-left">
                  Nome <span v-if="colunaOrdenada === 'nome'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('modelo')" class="cursor-pointer px-4 py-3 text-left">
                  Modelo <span v-if="colunaOrdenada === 'modelo'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('canais')" class="cursor-pointer px-4 py-3 text-left">
                  Canais <span v-if="colunaOrdenada === 'canais'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th @click="ordenarPor('status')" class="cursor-pointer px-4 py-3 text-left">
                  Status <span v-if="colunaOrdenada === 'status'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th class="px-4 py-3 text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="equipment in equipamentosOrdenados" :key="equipment.id"
                class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                <td class="px-4 py-3">{{ equipment.unidade }}</td>
                <td class="px-4 py-3">{{ equipment.nome }}</td>
                <td class="px-4 py-3">{{ equipment.modelo }}</td>
                <td class="px-4 py-3">{{ equipment.canais }}</td>
                <td class="px-4 py-3">{{ formatarStatus(equipment.status) }}</td>
                <td class="px-4 py-3 text-center">
                  <div class="flex justify-center space-x-2">
                    <button @click="openModal(equipment)" class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                      <Edit2Icon class="w-4 h-4" /> Editar
                    </button>
                    <button @click="deleteEquipment(equipment.id)" class="flex items-center gap-1 text-red-500 hover:text-red-700">
                      <Trash2Icon class="w-4 h-4" /> Excluir
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Versão mobile em cards -->
        <div class="sm:hidden space-y-6">
          <div v-for="equipment in equipamentosOrdenados" :key="equipment.id"
            class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
            <div class="flex justify-between items-center">
              <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                {{ equipment.nome }}
              </h3>
              <div class="flex items-center gap-2">
                <button @click="openModal(equipment)" class="text-blue-500 hover:text-blue-700">
                  <Edit2Icon class="w-5 h-5" />
                </button>
                <button @click="deleteEquipment(equipment.id)" class="text-red-500 hover:text-red-700">
                  <Trash2Icon class="w-5 h-5" />
                </button>
              </div>
            </div>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Modelo:</strong> {{ equipment.modelo }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Canais:</strong> {{ equipment.canais }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Status:</strong> {{ formatarStatus(equipment.status) }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Unidade:</strong> {{ equipment.unidade }}</p>
          </div>
        </div>

        <!-- Modal -->
        <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          @click.self="closeModal">
          <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
            <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
              <HardDriveIcon class="w-5 h-5 text-blue-500" />
              {{ isEditMode ? 'Editar Equipamento' : 'Novo Equipamento' }}
            </h2>

            <!-- Campos de Cadastro -->
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Nome</label>
            <input v-model="currentEquipment.nome" type="text"
              class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Modelo</label>
            <input v-model="currentEquipment.modelo" type="text"
              class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Canais</label>
            <select v-model="currentEquipment.canais"
              class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
              <option value="16">16 Canais</option>
              <option value="64">64 Canais</option>
              <option value="80">80 Canais</option>
              <option value="128">128 Canais</option>
            </select>

            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Status</label>
            <select v-model="currentEquipment.status"
              class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
              <option value="em_funcionamento">EM FUNCIONAMENTO</option>
              <option value="em_atendimento">EM ATENDIMENTO</option>
              <option value="parado">PARADO</option>
            </select>

            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade</label>
            <select v-model.number="currentEquipment.unidade_id"
              class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
              <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                {{ unidade.nome }}
              </option>
            </select>

            <div class="flex justify-end space-x-3">
              <button @click="closeModal"
                class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                <XIcon class="w-4 h-4" /> Cancelar
              </button>
              <button @click="saveEquipment"
                class="flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                <SaveIcon class="w-4 h-4" />
                <span>Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
    </template>
  </BaseTemplate>
</template>

<script setup>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
const apiToken = sessionStorage.getItem("api_token");

import BaseTemplate from './BaseTemplate.vue';
import { ref, computed, onMounted } from 'vue';
import {
  HardDriveIcon,
  PlusIcon,
  Edit2Icon,
  Trash2Icon,
  XIcon,
  SaveIcon,
  FilterIcon,
  SquareArrowLeft
} from 'lucide-vue-next';

const headers = {
  'Content-Type': 'application/json',
  'X-API-KEY': apiToken
};


import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
const equipments = ref([]);
const filter = ref('');
const error = ref(null);
const isModalOpen = ref(false);
const isEditMode = ref(false);
const currentEquipment = ref({
  id: '',
  nome: '',
  modelo: '',
  status: '',
  unidade_id: '',
  canais: 0
});

// Variáveis para ordenação
const colunaOrdenada = ref('nome');
const ordemAscendente = ref(true);

// Variáveis para o modal de filtro
const isFiltroModalOpen = ref(false);

// Variáveis para os filtros
const filtroUnidade = ref('');
const filtroStatus = ref('');
const filtroCanais = ref('');

// Computed para contar o número de filtros ativos
const totalFiltros = computed(() => {
  let count = 0;
  if (filtroUnidade.value) count++;
  if (filtroStatus.value) count++;
  if (filtroCanais.value) count++;
  return count;
});

const filteredEquipments = computed(() =>
  equipments.value.filter(equipment => {
    const nomeModeloMatch =
      equipment.nome.toLowerCase().includes(filter.value.toLowerCase()) ||
      equipment.modelo.toLowerCase().includes(filter.value.toLowerCase());

    // Comparação entre números para o filtro de unidade
    const unidadeMatch =
      !filtroUnidade.value || Number(equipment.unidade_id) === Number(filtroUnidade.value);

    const statusMatch =
      !filtroStatus.value || equipment.status === filtroStatus.value;

    const canaisMatch =
      !filtroCanais.value || equipment.canais.toString() === filtroCanais.value;

    return nomeModeloMatch && unidadeMatch && statusMatch && canaisMatch;
  })
);

// Função para abrir o modal de filtro
function abrirModalFiltro() {
  isFiltroModalOpen.value = true;
}

// Função para fechar o modal de filtro
function fecharModalFiltro() {
  isFiltroModalOpen.value = false;
}

// Função para limpar todos os filtros
function limparFiltros() {
  filtroUnidade.value = '';
  filtroStatus.value = '';
  filtroCanais.value = '';
  fecharModalFiltro();
}

// Função para aplicar os filtros
function aplicarFiltros() {
  console.log("Unidade Selecionada:", filtroUnidade.value);
  fecharModalFiltro(); // Fecha o modal após aplicar os filtros
}

// Ordena os equipamentos com base na coluna selecionada
const equipamentosOrdenados = computed(() => {
  const equipamentosFiltrados = filteredEquipments.value.slice();

  return equipamentosFiltrados.sort((a, b) => {
    let valorA, valorB;

    switch (colunaOrdenada.value) {
      case 'unidade':
        valorA = a.unidade.toLowerCase();
        valorB = b.unidade.toLowerCase();
        break;
      case 'nome':
        valorA = a.nome.toLowerCase();
        valorB = b.nome.toLowerCase();
        break;
      case 'modelo':
        valorA = a.modelo.toLowerCase();
        valorB = b.modelo.toLowerCase();
        break;
      case 'canais':
        valorA = a.canais;
        valorB = b.canais;
        break;
      case 'status':
        valorA = a.status.toLowerCase();
        valorB = b.status.toLowerCase();
        break;
      default:
        return 0;
    }

    if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
    if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
    return 0;
  });
});

function formatarStatus(status) {
  const statusMap = {
    "em_funcionamento": "Em funcionamento",
    "em_atendimento": "Em atendimento",
    "parado": "Parado"
  };
  return statusMap[status] || status; // Retorna o status formatado ou o original se não estiver no mapeamento
}

// Função para ordenar por coluna
function ordenarPor(coluna) {
  if (colunaOrdenada.value === coluna) {
    ordemAscendente.value = !ordemAscendente.value;
  } else {
    colunaOrdenada.value = coluna;
    ordemAscendente.value = true;
  }
}

onMounted(() => {
  fetchEquipments();
  fetchUnidades(); // Carregar unidades
});

async function fetchEquipments() {
  try {
    const response = await fetch(`${API_URL}/api/v1/equipamentos/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiToken
      }
    });
    const data = await response.json();
    equipments.value = data;
  } catch (err) {
    error.value = 'Erro ao carregar equipamentos';
  }
}

const unidades = ref([]);

async function fetchUnidades() {
  try {
    const response = await fetch(`${API_URL}/api/v1/unidades/`, { headers });
    unidades.value = await response.json();
  } catch (err) {
    error.value = "Erro ao carregar unidades";
  }
}

function openModal(equipment = null) {
  isEditMode.value = !!equipment;

  if (equipment) {
    // Buscar o ID da unidade pelo nome recebido da API
    const unidadeEncontrada = unidades.value.find(u => u.nome === equipment.unidade);

    currentEquipment.value = {
      id: equipment.id,
      nome: equipment.nome,
      modelo: equipment.modelo,
      status: equipment.status,
      canais: equipment.canais,
      unidade_id: unidadeEncontrada ? Number(unidadeEncontrada.id) : '' // Converta para número
    };
  } else {
    currentEquipment.value = {
      id: '',
      nome: '',
      modelo: '',
      status: 'em_funcionamento',
      unidade_id: '',
      canais: 16
    };
  }

  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
}

async function saveEquipment() {
  try {
    if (isEditMode.value) {
      const response = await axios.put(
        `${API_URL}/api/v1/equipamentos/${currentEquipment.value.id}/`,
        {
          nome: currentEquipment.value.nome,
          modelo: currentEquipment.value.modelo,
          status: currentEquipment.value.status,
          canais: currentEquipment.value.canais,
          unidade_id: currentEquipment.value.unidade_id
        },
        { headers }
      );
      const updatedEquipment = response.data;
      const index = equipments.value.findIndex(e => e.id === updatedEquipment.id);
      if (index !== -1) {
        equipments.value[index] = updatedEquipment;
      }
    } else {
      const response = await axios.post(
        `${API_URL}/api/v1/equipamentos/`,
        {
          nome: currentEquipment.value.nome,
          modelo: currentEquipment.value.modelo,
          status: currentEquipment.value.status,
          canais: currentEquipment.value.canais,
          unidade_id: currentEquipment.value.unidade_id
        },
        { headers }
      );
      equipments.value.push(response.data);
    }
    closeModal();
  } catch (err) {
    error.value = err.response?.data?.detail || "Erro ao salvar equipamento";
  }
}

async function deleteEquipment(equipmentId) {
  const confirmDeletion = window.confirm("Tem certeza de que deseja excluir este equipamento? Esta ação não pode ser desfeita e isso afetará o Dashboard.");
  if (!confirmDeletion) return;

  try {
    await axios.delete(`${API_URL}/api/v1/equipamentos/${equipmentId}/`, { headers });
    equipments.value = equipments.value.filter(equipment => equipment.id !== equipmentId);
  } catch (err) {
    error.value = err.response?.data?.detail || "Erro ao deletar equipamento";
  }
}
</script>

