<template>
    <BaseTemplate app-name="Feed" page-title="">
        <template #content>
            <!-- Animated Sidebar Toggle Button - Improved positioning for mobile -->
            <button @click="toggleSidebar"
                class="fixed top-4 right-4 z-50 bg-gradient-to-r from-blue-500 to-blue-600 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 flex items-center gap-1 sm:gap-2">
                <span v-if="isSidebarVisible">
                    <ChevronRight class="w-4 h-4" />
                </span>
                <span v-else>
                    <ChevronLeft class="w-4 h-4" />
                </span>
                {{ isSidebarVisible ? '' : '' }}
            </button>

            <div class="fixed right-0 top-0 h-full bg-white dark:bg-gray-800 shadow-xl z-40 transition-all duration-300 ease-in-out overflow-hidden"
            :class="[isSidebarVisible ? 'w-[85vw] sm:w-64' : 'w-0']">
            <div class="p-4 sm:p-5 border-b border-gray-200 dark:border-gray-700 flex items-center">
                <Users class="w-5 h-5 mr-2 text-blue-500" />
                <h2 class="text-lg font-semibold text-gray-900 dark:text-white">Grupos</h2>
            </div>
            <div class="p-3 sm:p-4">
                <ul class="space-y-2 sm:space-y-3">
                    <li v-if="userEhResponsavel">
                        <button @click="openGroupModal"
                            class="w-full text-left py-2 px-3 rounded-lg bg-blue-50 dark:bg-blue-900/20 text-blue-600 hover:bg-blue-100 dark:text-blue-400 dark:hover:bg-blue-900/30 transition-colors duration-200 flex items-center">
                            <Plus class="w-4 h-4 mr-2" />
                            Criar Grupo
                        </button>
                    </li>

                    <transition name="modal-fade">
                        <div v-if="isGroupEditModalOpen"
                            class="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center p-4">
                            <div
                                class="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-4 sm:p-6 w-full max-w-lg animate-zoom-in">
                                <h2 class="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Editar Membros
                                    do Grupo</h2>

                                <input v-model="editMemberSearch" placeholder="Buscar usuário..."
                                    class="w-full mb-3 p-2 rounded border" />

                                <div class="max-h-60 overflow-y-auto space-y-2">
                                    <label v-for="user in filteredEditUsers" :key="user.id"
                                        class="flex items-center gap-2 text-sm">
                                        <input type="checkbox" v-model="editSelectedMembers" :value="user.id" />
                                        {{ user.name }}
                                    </label>
                                </div>

                                <div class="flex justify-end gap-3 mt-5">
                                    <button @click="closeGroupEditModal" class="text-gray-600">Cancelar</button>
                                    <button @click="saveGroupMembers"
                                        class="bg-blue-600 text-white px-4 py-2 rounded">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <li v-for="group in groups" :key="group.id" class="transition-all duration-200">
                        <div class="w-full py-2 px-3 rounded-lg transition-all duration-200 flex justify-between items-center"
                            :class="[
                                selectedGroupId === group.id
                                    ? 'bg-blue-100 text-blue-600 font-semibold dark:bg-blue-900/30 dark:text-blue-400'
                                    : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700/50'
                            ]">
                            <div class="flex items-center cursor-pointer flex-1 text-sm sm:text-base" @click="selectGroup(group.id)">
                                <Users2 class="w-4 h-4 mr-2"
                                    :class="selectedGroupId === group.id ? 'text-blue-500' : 'text-gray-400'" />
                                {{ group.nome }}
                            </div>

                            <button v-if="userIsResponsavelDoGrupo(group)" @click.stop="openGroupEditModal(group)"
                                class="text-xs text-blue-500 hover:underline ml-2">
                                ⚙
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

            <!-- Main Content - Improved padding for mobile -->
            <div class="container mx-auto px-3 sm:px-4 py-4 md:py-8 transition-all duration-300"
                :class="{ 'pr-[90vw] sm:pr-72': isSidebarVisible }">
                <!-- Create Post Card - Improved for mobile -->
                <div class="bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 p-4 sm:p-6 mb-6" v-if="userPodePostar">
                    <div class="flex items-start gap-3 sm:gap-4">
                        <!-- Avatar -->
                        <div class="w-10 h-10 sm:w-12 sm:h-12 rounded-full overflow-hidden shadow-sm flex-shrink-0">
                            <img 
                                :src="profileImage || '/default-avatar.png'" 
                                alt="User profile" 
                                class="w-full h-full object-cover"
                            />
                        </div>

                        <div class="flex-1 space-y-3 sm:space-y-4">
                            <!-- Textarea -->
                            <textarea
                                v-model="newPostContent"
                                placeholder="O que você está pensando?"
                                rows="3"
                                class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-3 sm:p-4 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none transition"
                            ></textarea>

                            <!-- Ações - Improved layout for mobile -->
                            <div class="flex flex-col sm:flex-row justify-center sm:justify-between gap-3 sm:gap-4">

                                <!-- Uploads - Made more compact on mobile -->
                                <div class="flex flex-wrap gap-2 sm:gap-3">
                                    <!-- Botão de imagem -->
                                    <label for="image-upload" class="upload-btn text-xs sm:text-sm">
                                        <Image class="w-4 h-4 sm:w-5 sm:h-5" />
                                        <span class="hidden xs:inline">Imagem</span>
                                    </label>
                                    <input type="file" id="image-upload" accept="image/*" @change="handleImageUpload" class="hidden" />

                                    <!-- Botão de vídeo -->
                                    <label for="video-upload" class="upload-btn text-xs sm:text-sm">
                                        <Video class="w-4 h-4 sm:w-5 sm:h-5" />
                                        <span class="hidden xs:inline">Vídeo</span>
                                    </label>
                                    <input type="file" id="video-upload" accept="video/*" @change="handleVideoUpload" class="hidden" />

                                    <!-- Botão de documento -->
                                    <label for="document-upload" class="upload-btn text-xs sm:text-sm">
                                        <FileText class="w-4 h-4 sm:w-5 sm:h-5" />
                                        <span class="hidden xs:inline">Documento</span>
                                    </label>
                                    <input type="file" id="document-upload" accept=".pdf,.doc,.docx" @change="handleDocumentUpload" class="hidden" />
                                </div>

                                <!-- Botão de publicar -->
                                <button
                                    @click="createPost"
                                    class="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-4 sm:px-6 py-2 rounded-lg shadow-md transition hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                                    :disabled="!newPostContent.trim() || !selectedGroupId"
                                >
                                    <div class="flex items-center gap-2 justify-center">
                                        <Send class="w-4 h-4" />
                                        <span>Publicar</span>
                                    </div>
                                </button>
                            </div>

                            <!-- Aviso de grupo -->
                            <div v-if="!selectedGroupId" class="flex items-center text-xs sm:text-sm text-amber-600 dark:text-amber-400 gap-1">
                                <AlertCircle class="w-4 h-4" />
                                <span>Selecione um grupo antes de publicar</span>
                            </div>

                            <!-- Preview de imagem - Improved for mobile -->
                            <div v-if="newPostImage" class="preview-box">
                                <img :src="newPostImage" alt="Uploaded image" class="rounded-lg w-24 h-24 sm:w-32 sm:h-32 object-cover" />
                                <button @click="removeImage" class="remove-btn"><X class="w-4 h-4 sm:w-5 sm:h-5" /></button>
                            </div>

                            <!-- Preview de vídeo - Improved for mobile -->
                            <div v-if="newPostVideo?.previewUrl" class="preview-box">
                                <video :src="newPostVideo.previewUrl" controls class="rounded-lg w-36 sm:w-48 max-h-36 sm:max-h-48 object-cover"></video>
                                <button @click="removeVideo" class="remove-btn"><X class="w-4 h-4 sm:w-5 sm:h-5" /></button>
                            </div>

                            <!-- Preview de documento -->
                            <div v-if="newPostDocumentName" class="preview-box">
                                <FileText class="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                                <span class="text-xs sm:text-sm text-gray-700 dark:text-gray-300 truncate max-w-[150px] sm:max-w-none">{{ newPostDocumentName }}</span>
                                <button @click="removeDocument" class="remove-btn"><X class="w-4 h-4 sm:w-5 sm:h-5" /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Posts Feed -->
                <div class="space-y-6" v-if="selectedGroupId">
                    <div v-if="posts.length === 0" class="text-center py-8 sm:py-10">
                        <div class="inline-flex rounded-full bg-blue-50 dark:bg-blue-900/20 p-3 sm:p-4 mb-3 sm:mb-4">
                            <MessageSquare class="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />
                        </div>
                        <h3 class="text-base sm:text-lg font-medium text-gray-900 dark:text-white mb-1">Nenhuma publicação neste
                            grupo ainda</h3>
                        <p class="text-sm text-gray-500 dark:text-gray-400">Seja o primeiro a compartilhar algo interessante!
                        </p>
                    </div>

                    <transition-group name="post-list" tag="div" class="space-y-6">
                        <div v-for="post in posts" :key="post.id"
                            class="bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden">
                            <!-- Post Header -->
                            <div class="p-4 sm:p-5 flex justify-between items-center">
                                <div class="flex items-center space-x-2 sm:space-x-3">
                                    <div
                                        class="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center text-white overflow-hidden">
                                        <img 
                                            :src="post.author.avatar || CircleUserRound" 
                                            :alt="post.author.name"
                                            class="w-full h-full object-cover" 
                                        />
                                    </div>
                                    <div>
                                        <h3 class="font-semibold text-sm sm:text-base text-gray-900 dark:text-white">{{ post.author.name }}</h3>
                                        <p class="text-xs text-gray-500 dark:text-gray-400">{{ formatDate(post.createdAt) }}</p>
                                    </div>
                                </div>

                                <div class="relative">
                                    <button @click="togglePostMenu(post.id)"
                                        class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200">
                                        <MoreVertical class="w-5 h-5" />
                                    </button>

                                    <div v-if="activePostMenuId === post.id"
                                        class="absolute right-0 mt-2 w-40 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-50">
                                        <button v-if="userPodePostar" @click="startEditPost(post)"
                                            class="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm">Editar</button>
                                        <button v-if="userPodePostar" @click="deletePost(post.id)"
                                            class="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm text-red-600">Excluir</button>
                                        <button v-if="userPodePostar" @click="toggleComentariosOcultos(post)"
                                            class="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm">
                                            {{ post.comentariosOcultos ? 'Exibir comentários' : 'Ocultar comentários' }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- Post Content -->
                            <div class="px-4 sm:px-5 pb-3">
                                <div v-if="editingPostId === post.id" class="mb-4">
                                    <textarea v-model="editedContent"
                                        class="w-full p-2 rounded border dark:bg-gray-700 dark:text-white mb-2"
                                        rows="3"></textarea>
                                    <div class="flex gap-3">
                                        <button @click="saveEditedPost(post)"
                                            class="text-blue-600 font-medium">Salvar</button>
                                        <button @click="cancelEditPost" class="text-gray-500">Cancelar</button>
                                    </div>
                                </div>
                                <p v-else class="text-sm sm:text-base text-gray-800 dark:text-gray-200 mb-4 whitespace-pre-line">
                                    {{ post.content }}
                                </p>

                                <img v-if="post.image" :src="post.image" :alt="'Post image by ' + post.author.name"
                                    class="w-full max-h-96 object-cover rounded-lg mb-4 cursor-pointer transform transition-transform duration-300 hover:scale-[1.01]"
                                    @click="openImage(post.image)" />

                                <video v-if="post.video" :src="post.video" controls
                                    class="w-full h-auto rounded-lg mb-4"></video>
                                
                                <div v-if="Array.isArray(post.documentos) && post.documentos.length > 0" class="space-y-2 mb-4">
                                    <div v-for="(document, index) in post.documentos" :key="index"
                                        class="flex items-center space-x-2 p-2 sm:p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                        <FileText class="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                                        <a :href="document.url" download
                                            class="text-xs sm:text-sm text-blue-600 dark:text-blue-400 hover:underline flex-1 truncate">
                                            {{ document.nome ? document.nome.split('_').slice(1).join('_') : document.split('/').pop() }}
                                        </a>
                                        <Download class="w-4 h-4 text-gray-500" />
                                    </div>
                                </div>
                            </div>

                            <!-- Post Stats -->
                            <div
                                class="px-4 sm:px-5 py-2 sm:py-3 border-t border-gray-200 dark:border-gray-700 flex justify-between items-center text-xs sm:text-sm">
                                <div class="relative inline-block text-left">
                                    <!-- Menu de emojis -->
                                    <div v-show="isEmojiMenuOpen(post.id)"
                                        class="absolute z-10 -top-20 mt-2 w-48 sm:w-56 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-xl animate-fade-in">
                                        <div class="grid grid-cols-5 gap-1 sm:gap-2 p-2 sm:p-3 text-base sm:text-lg cursor-pointer">
                                            <span
                                                v-for="emoji in ['👍', '❤️', '😂', '😮', '😢', '👏', '🔥', '🎉', '👎', '💯']"
                                                :key="emoji" @click="reactToPost(post.id, emoji)"
                                                class="flex items-center justify-center p-1 sm:p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200 transform hover:scale-110">
                                                {{ emoji }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="flex items-center gap-1">
                                        <div class="flex items-center gap-1">
                                            <template
                                                v-for="(emoji, index) in Array.from(new Set(post.reactionUsersEmojis || [])).slice(0, 3)"
                                                :key="index">
                                                <span class="text-base sm:text-lg cursor-pointer" @click="openReactionsModal(post)">
                                                    {{ emoji }}
                                                </span>
                                            </template>

                                            <span v-if="post.reactionUsersEmojis.length > 3"
                                                class="text-xs sm:text-sm font-semibold cursor-pointer hover:text-blue-600 dark:hover:text-blue-400"
                                                @click="openReactionsModal(post)">
                                                +{{ post.reactionUsersEmojis.length - 3 }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center space-x-1 relative group">
                                    <!-- Tooltip com usuários que reagiram -->
                                    <div
                                        class="absolute hidden group-hover:block bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-xs text-gray-700 dark:text-white rounded-lg shadow-lg p-2 sm:p-3 -top-24 left-0 z-20 min-w-[120px] sm:min-w-[140px] max-w-[180px] sm:max-w-[220px] animate-fade-in">
                                        <p v-if="!post.reactionUsers || post.reactionUsers.length === 0">Sem reações
                                            ainda</p>
                                        <ul v-else class="space-y-1">
                                            <li v-for="user in post.reactionUsers" :key="user"
                                                class="flex items-center">
                                                <span class="w-1 h-1 bg-blue-500 rounded-full mr-2"></span>
                                                {{ user }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button v-if="!post.comentariosOcultos" @click="toggleComments(post.id)"
                                    class="flex items-center text-xs sm:text-sm text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200">
                                    <MessageSquare class="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
                                    {{ post.total_comentarios || 0 }} comentários
                                </button>
                            </div>
                            
                            <!-- Modal: Todas as Reações -->
                            <transition name="modal-fade">
                                <div v-if="isReactionsModalOpen"
                                    class="fixed inset-0 z-50 bg-black bg-opacity-60 flex items-center justify-center px-4"
                                    @click.self="closeReactionsModal">
                                    <div
                                        class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md p-4 sm:p-6 animate-zoom-in relative">

                                        <!-- Header Modal -->
                                        <div class="flex justify-between items-center mb-4 sm:mb-5">
                                            <h2 class="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">Reações do
                                                Post</h2>
                                            <button @click="closeReactionsModal"
                                                class="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 transition-colors">
                                                <X class="w-5 h-5" />
                                            </button>
                                        </div>

                                        <!-- Contagem total -->
                                        <div
                                            class="text-xs sm:text-sm text-gray-600 dark:text-gray-300 mb-3 sm:mb-4 text-center font-medium">
                                            Total de reações: {{ reactionsModalPost.likes || 0 }}
                                        </div>

                                        <!-- Emoji Tabs com contagem por emoji -->
                                        <div class="flex flex-wrap gap-1 sm:gap-2 mb-3 sm:mb-4 justify-center">
                                            <button v-for="(users, emoji) in reactionsModalPost.reactionUsersPerEmoji"
                                                :key="emoji" @click="selectedEmojiTab = emoji" :class="[
                                                    'px-2 sm:px-3 py-1 rounded-full transition-all duration-200 cursor-pointer flex items-center gap-1 text-xs sm:text-sm',
                                                    selectedEmojiTab === emoji
                                                        ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 font-semibold'
                                                        : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300'
                                                ]">
                                                <span class="text-base sm:text-xl">{{ emoji }}</span>
                                                <span class="text-[10px] sm:text-xs font-medium text-gray-500 dark:text-gray-400">{{
                                                    users.length }}</span>
                                            </button>
                                        </div>

                                        <!-- Lista de usuários por reação -->
                                        <div class="max-h-[30vh] sm:max-h-[40vh] overflow-y-auto border border-gray-200 dark:border-gray-700 rounded-lg p-3 sm:p-4"
                                            v-if="selectedEmojiTab">
                                            <h3 class="text-xs sm:text-sm font-semibold mb-2 text-gray-700 dark:text-white">
                                                Usuários que reagiram com {{ selectedEmojiTab }}
                                            </h3>
                                            <ul class="list-disc list-inside text-xs sm:text-sm text-gray-800 dark:text-gray-200">
                                                <li v-for="user in reactionsModalPost.reactionUsersPerEmoji[selectedEmojiTab]"
                                                    :key="user">
                                                    {{ user }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            
                            <!-- Post Actions -->
                            <div
                                class="px-4 sm:px-5 py-2 sm:py-3 flex justify-center gap-4 sm:gap-6 border-t border-gray-200 dark:border-gray-700">
                                <!-- Botão Reagir -->
                                <button @click="toggleEmojiMenu(post.id)"
                                    class="flex items-center gap-1 sm:gap-2 px-3 sm:px-4 py-1 sm:py-2 rounded-lg transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-700 text-xs sm:text-sm"
                                    :class="post.userReaction ? 'text-blue-600 dark:text-blue-400 font-semibold' : 'text-gray-600 dark:text-gray-400'">
                                    <span v-if="post.userReaction" class="text-base sm:text-lg">{{ post.userReaction }}</span>
                                    <ThumbsUp v-else class="w-4 h-4 sm:w-5 sm:h-5" />
                                    <span>Reagir</span>
                                </button>

                                <!-- Botão Comentar -->
                                <button v-if="!post.comentariosOcultos" @click="toggleComments(post.id)"
                                    class="flex items-center gap-1 sm:gap-2 px-3 sm:px-4 py-1 sm:py-2 rounded-lg transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-400 text-xs sm:text-sm">
                                    <MessageSquare class="w-4 h-4 sm:w-5 sm:h-5" />
                                    <span>Comentar</span>
                                </button>
                            </div>
                            
                            <!-- Comments Section -->
                            <div v-if="post.showComments"
                                class="px-4 sm:px-5 py-3 sm:py-4 bg-white dark:bg-white-750 border-t border-gray-200 dark:border-gray-700 animate-slide-down">
                                <div v-if="post.showComments">
                                    <div v-if="post.comentariosOcultos"
                                        class="text-center text-xs sm:text-sm text-gray-500 dark:text-gray-400 italic">
                                        Comentários ocultos
                                    </div>
                                    <div v-else>
                                        <!-- Renderiza os comentários normalmente -->
                                    </div>
                                </div>
                                
                                <!-- Comment Input -->
                                <div class="flex items-start space-x-2 sm:space-x-3 mb-4 sm:mb-5">
                                    <div
                                        class="w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center text-white overflow-hidden flex-shrink-0">
                                        <img 
                                            :src="profileImage || '/default-avatar.png'" 
                                            alt="User profile" 
                                            class="w-full h-full object-cover"
                                        />
                                    </div>

                                    <div class="flex-1 relative">
                                        <textarea v-model="post.newComment" placeholder="Escreva um comentário..."
                                            class="w-full border border-gray-200 dark:border-gray-600 rounded-lg p-2 sm:p-3 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white transition-all duration-200 text-xs sm:text-sm"
                                            rows="1"></textarea>

                                        <button @click="addComment(post.id)"
                                            class="absolute right-2 sm:right-3 bottom-2 sm:bottom-3 text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 transition-colors duration-200"
                                            :disabled="!post.newComment.trim()">
                                            <Send class="w-4 h-4 sm:w-5 sm:h-5" />
                                        </button>
                                    </div>
                                </div>

                                <!-- Comments List -->
                                <div class="space-y-3 sm:space-y-4">
                                    <transition-group name="comment-list" tag="div" class="space-y-3 sm:space-y-4">
                                        <div v-for="comment in post.comments" :key="comment.id"
                                            class="flex space-x-2 sm:space-x-3 animate-fade-in">
                                            <div
                                                class="w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center text-white overflow-hidden flex-shrink-0">
                                                <img 
                                                    :src="comment.usuario_imagem || CircleUserRound" 
                                                    alt="User profile" 
                                                    class="w-full h-full object-cover"
                                                />
                                            </div>
                                            <div class="flex-1">
                                                <div
                                                    class="bg-white dark:bg-gray-700 rounded-lg p-2 sm:p-3 shadow-sm border border-gray-200 dark:border-gray-600">
                                                    <h4 class="font-semibold text-gray-900 dark:text-white text-xs sm:text-sm">{{
                                                        comment.usuario_nome }}</h4>
                                                    <p class="text-gray-800 dark:text-gray-200 text-xs sm:text-sm mt-1">{{
                                                        comment.comentario }}</p>
                                                </div>

                                                <div class="flex items-center mt-1 sm:mt-2 space-x-3 sm:space-x-4 text-[10px] sm:text-xs">
                                                    <button @click="toggleReplyInput(post.id, comment.id)"
                                                        class="font-medium text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200 flex items-center">
                                                        <Reply class="w-3 h-3 mr-1" />
                                                        Responder
                                                    </button>

                                                    <span class="text-gray-500 dark:text-gray-400">{{
                                                        formatDate(comment.data_criacao) }}</span>
                                                </div>

                                                <!-- Reply Input -->
                                                <div v-if="comment.showReplyInput"
                                                    class="mt-2 sm:mt-3 flex items-start space-x-2 animate-slide-down">
                                                    <div
                                                        class="w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center text-white overflow-hidden flex-shrink-0">
                                                        <img 
                                                            :src="profileImage || '/default-avatar.png'" 
                                                            alt="User profile" 
                                                            class="w-full h-full object-cover"
                                                        />
                                                    </div>

                                                    <div class="flex-1 relative">
                                                        <textarea v-model="comment.newReply"
                                                            placeholder="Escreva uma resposta..."
                                                            class="w-full border border-gray-200 dark:border-gray-600 rounded-lg p-1.5 sm:p-2 pr-8 sm:pr-10 text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white transition-all duration-200"
                                                            rows="1"></textarea>

                                                        <button @click="addReply(post.id, comment)"
                                                            class="absolute right-1.5 sm:right-2 bottom-1.5 sm:bottom-2 text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 transition-colors duration-200"
                                                            :disabled="!(comment.newReply && comment.newReply.trim())">
                                                            <Send class="w-3 h-3 sm:w-4 sm:h-4" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- Replies -->
                                                <div v-if="comment.replies && comment.replies.length > 0"
                                                    class="mt-2 sm:mt-3 space-y-2 sm:space-y-3 pl-3 sm:pl-4 border-l-2 border-gray-200 dark:border-gray-600">
                                                    <transition-group name="reply-list" tag="div" class="space-y-2 sm:space-y-3">
                                                        <div v-for="reply in comment.replies" :key="reply.id"
                                                            class="flex space-x-1.5 sm:space-x-2 animate-fade-in">
                                                            <div
                                                                class="w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-gradient-to-r from-gray-400 to-gray-500 flex items-center justify-center text-white overflow-hidden flex-shrink-0">
                                                                <img :src="reply.author.avatar" :alt="reply.author.name"
                                                                    class="w-full h-full object-cover" />
                                                            </div>

                                                            <div>
                                                                <div
                                                                    class="bg-white dark:bg-gray-700 rounded-lg p-1.5 sm:p-2 shadow-sm">
                                                                    <h5
                                                                        class="font-semibold text-gray-900 dark:text-white text-[10px] sm:text-xs">
                                                                        {{ reply.author.name }}</h5>
                                                                    <p
                                                                        class="text-gray-800 dark:text-gray-200 text-[10px] sm:text-xs mt-0.5 sm:mt-1">
                                                                        {{ reply.content }}</p>
                                                                </div>

                                                                <div class="flex items-center mt-1 space-x-2 sm:space-x-3 text-[10px] sm:text-xs">
                                                                    <button
                                                                        @click="likeReply(post.id, comment.id, reply.id)"
                                                                        class="font-medium transition-colors duration-200 flex items-center"
                                                                        :class="reply.userLiked ? 'text-blue-600 dark:text-blue-400' : 'text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400'">
                                                                        <ThumbsUp class="w-2 h-2 sm:w-3 sm:h-3 mr-0.5 sm:mr-1" />
                                                                        Curtir
                                                                    </button>

                                                                    <span class="text-gray-500 dark:text-gray-400">{{
                                                                        formatDate(reply.createdAt) }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </transition-group>
                                                </div>
                                            </div>
                                        </div>
                                    </transition-group>

                                    <div v-if="post.total_comentarios > post.comments.length" class="mt-3 sm:mt-4 text-center">
                                        <button
                                            class="text-xs sm:text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg hover:bg-blue-50 dark:hover:bg-blue-900/20 transition-all duration-200"
                                            @click="carregarMaisComentarios(post)">
                                            Carregar mais comentários
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </div>

                <!-- Footer -->
                <p class="text-center text-xs sm:text-sm mb-8 sm:mb-11 mt-8 sm:mt-10 text-gray-500 dark:text-gray-400">
                    © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
                </p>
            </div>

            <!-- Modal para exibir a imagem em tamanho original -->
            <transition name="modal-fade">
                <div v-if="isModalOpen"
                    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
                    @click.self="closeModal">
                    <div class="relative max-w-full max-h-full animate-zoom-in">
                        <img :src="selectedImage" alt="Imagem em tamanho original"
                            class="max-w-full max-h-[85vh] rounded-lg shadow-2xl" />

                        <button @click="closeModal"
                            class="absolute top-2 sm:top-4 right-2 sm:right-4 text-white bg-black bg-opacity-50 rounded-full p-1.5 sm:p-2 hover:bg-opacity-70 transition-colors duration-200">
                            <X class="h-5 w-5 sm:h-6 sm:w-6" />
                        </button>
                    </div>
                </div>
            </transition>

            <!-- Modal de Grupos -->
            <transition name="modal-fade">
                <div v-if="isGroupModalOpen"
                    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
                    <div
                        class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md p-4 sm:p-6 animate-zoom-in">
                        <div class="flex justify-between items-center mb-4 sm:mb-5">
                            <h2 class="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                                <Users class="w-5 h-5 mr-2 text-blue-500" />
                                Criar Novo Grupo
                            </h2>
                            <button @click="closeGroupModal"
                                class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200">
                                <X class="w-5 h-5" />
                            </button>
                        </div>

                        <form @submit.prevent="createGroup">
                            <div class="mb-4 sm:mb-5">
                                <label for="groupName"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 sm:mb-2">Nome
                                    do Grupo</label>
                                <input v-model="newGroupName" type="text" id="groupName" required
                                    class="block w-full border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm p-2 sm:p-3 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white transition-all duration-200" />
                            </div>

                            <div class="mb-4 sm:mb-5">
                                <label
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 sm:mb-2">Membros</label>
                                <input v-model="memberSearch" type="text" placeholder="Buscar usuários..."
                                    class="mb-2 sm:mb-3 w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white" />

                                <div class="max-h-40 sm:max-h-60 overflow-y-auto space-y-1 sm:space-y-2">
                                    <label v-for="user in filteredUsers" :key="user.id"
                                        class="flex items-center space-x-2 cursor-pointer text-xs sm:text-sm text-gray-700 dark:text-gray-300">
                                        <input type="checkbox" :value="user.id" v-model="selectedMembers"
                                            class="accent-blue-600" />
                                        <span>{{ user.name }}</span>
                                    </label>
                                </div>
                            </div>

                            <div class="flex justify-end space-x-3">
                                <button type="button" @click="closeGroupModal"
                                    class="px-3 sm:px-4 py-1.5 sm:py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200">
                                    Cancelar
                                </button>

                                <button type="submit"
                                    class="px-3 sm:px-4 py-1.5 sm:py-2 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg transition-all duration-300 transform hover:scale-105 shadow-md text-sm">
                                    Criar Grupo
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </transition>
        </template>
    </BaseTemplate>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import BaseTemplate from './BaseTemplate.vue';
import {
    Image,
    CircleUserRound,
    Video,
    FileText,
    Send,
    MoreVertical,
    ThumbsUp,
    MessageSquare,
    X,
    ChevronLeft,
    ChevronRight,
    Users,
    Users2,
    Plus,
    Reply,
    Download,
    AlertCircle
} from 'lucide-vue-next';
import axios from 'axios';

// API Configuration
const apiToken = sessionStorage.getItem("api_token");
const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': apiToken,
    'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
};


// Estado para novo post
const newPostContent = ref('');
const newPostImage = ref(null);
const newPostVideo = ref(null);
const newPostDocuments = ref([]);
const newPostDocumentName = ref(null);
const selectedEmojiTab = ref(null);



// Estado para posts
const posts = ref([]);

// Estado para controlar o modal
const isModalOpen = ref(false);
const selectedImage = ref(null);

// Estado para o menu de emojis
const activeEmojiPostId = ref(null);
const userPodePostar = ref(false);

const verificarPermissaoPostagem = async () => {
    try {
        const response = await axios.get('/api/v1/posts/permissao-postar', { headers });
        userPodePostar.value = response.data.pode_postar === true;
    } catch (error) {
        console.error("Erro ao verificar permissão de postagem:", error);
        userPodePostar.value = false;
    }
};

// Estado para grupos
const selectedGroupId = ref(1);
const isSidebarVisible = ref(true);
const isGroupModalOpen = ref(false);
const newGroupName = ref('');
const selectedMembers = ref([]);
const groups = ref([]);
const users = ref([]);

// Funções para o modal de imagem
const openImage = (imageUrl) => {
    selectedImage.value = imageUrl;
    isModalOpen.value = true;
    document.body.style.overflow = 'hidden'; // Previne rolagem quando modal está aberto
};

const closeModal = () => {
    isModalOpen.value = false;
    selectedImage.value = null;
    document.body.style.overflow = ''; // Restaura rolagem
};

// Funções para o menu de emojis
const toggleEmojiMenu = (postId) => {
    activeEmojiPostId.value = activeEmojiPostId.value === postId ? null : postId;
};

const isEmojiMenuOpen = (postId) => activeEmojiPostId.value === postId;

// Funções para upload de mídia
const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            newPostImage.value = e.target.result;
        };
        reader.readAsDataURL(file);
    }
};

const isGroupEditModalOpen = ref(false);
const editSelectedMembers = ref([]);
const groupBeingEdited = ref(null);
const editMemberSearch = ref('');

const openGroupEditModal = (group) => {
    groupBeingEdited.value = group;
    editSelectedMembers.value = [...(group.membros_ids || [])]; // carregar membros atuais
    isGroupEditModalOpen.value = true;
};

const closeGroupEditModal = () => {
    isGroupEditModalOpen.value = false;
    editSelectedMembers.value = [];
    groupBeingEdited.value = null;
};

const filteredEditUsers = computed(() => {
    if (!editMemberSearch.value.trim()) return users.value;
    return users.value.filter(u =>
        u.name.toLowerCase().includes(editMemberSearch.value.toLowerCase())
    );
});

const userIsResponsavelDoGrupo = (group) => {
    const currentUserId = Number(sessionStorage.getItem("user_id"));
    return group.responsavel_id === currentUserId;
};

const saveGroupMembers = async () => {
    try {
        const payload = { membros_ids: editSelectedMembers.value };
        await axios.put(`/api/v1/gruposfeed/${groupBeingEdited.value.id}/membros`, payload, { headers });
        alert("Membros atualizados com sucesso.");
        await fetchGroups(); // recarregar lista
        closeGroupEditModal();
    } catch (err) {
        console.error("Erro ao atualizar membros:", err.response?.data || err);
        alert("Erro ao salvar membros.");
    }
};

const userEhResponsavel = ref(false);

const verificarSeEhResponsavel = async () => {
    try {
        const response = await axios.get('/api/v1/gruposfeed/eh-responsavel', { headers });
        userEhResponsavel.value = response.data.eh_responsavel;
    } catch (error) {
        console.error("Erro ao verificar se é responsável:", error);
    }
};


const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        newPostVideo.value = {
            file,
            previewUrl: URL.createObjectURL(file)
        };
    }
};

const removeVideo = () => {
    if (newPostVideo.value?.previewUrl) {
        URL.revokeObjectURL(newPostVideo.value.previewUrl); // limpeza
    }
    newPostVideo.value = null;
};


const handleDocumentUpload = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        const file = files[0];
        newPostDocuments.value = file;
        newPostDocumentName.value = file.name;
    }
};

const toggleComentariosOcultos = async (post) => {
    try {
        const response = await axios.put(`/api/v1/posts/${post.id}/ocultar-comentarios`, null, { headers });
        post.comentariosOcultos = response.data.comentarios_ocultos;
    } catch (err) {
        console.error("Erro ao ocultar comentários:", err.response?.data || err);
    }
};

const removeImage = () => {
    newPostImage.value = null;
};

const removeDocument = () => {
    newPostDocuments.value = [];
    newPostDocumentName.value = null;
};

// Funções para posts
const createPost = async () => {
    if (!selectedGroupId.value) {
        alert("Selecione um grupo antes de publicar.");
        return;
    }

    if (!newPostContent.value.trim()) return;

    try {
        const formData = new FormData();
        formData.append("conteudo", newPostContent.value);
        formData.append("grupo_id", selectedGroupId.value);

        if (newPostImage.value) {
            const file = dataURLtoFile(newPostImage.value, "imagem.png");
            formData.append("imagem", file); // Aqui é "imagem", não "imagem_base64"
        }

        if (newPostVideo.value?.file) {
        formData.append("video", newPostVideo.value.file);
    }


        if (newPostDocuments.value) {
            formData.append("documentos", newPostDocuments.value);
        }

        const response = await axios.post('/api/v1/posts', formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data'
            }
        });

        posts.value.unshift({
            id: response.data.id,
            author: {
                id: response.data.usuario_id,
                name: response.data.usuario_nome,
                avatar: response.data.usuario_imagem || CircleUserRound
            },
            content: response.data.conteudo,
            image: response.data.imagem_base64,
            createdAt: response.data.data_criacao,
            likes: 0,
            total_comentarios: 0,
            userLiked: false,
            showComments: false,
            newComment: '',
            comments: [],
            documentos: [], // ← Adicione esta linha
            video: response.data.video_url || null,
            reactionUsersEmojis: [],
            reactionUsersPerEmoji: {},
            comentariosOcultos: false,
        });


        newPostContent.value = '';
        newPostImage.value = null;
        newPostVideo.value = null;
        newPostDocuments.value = null;
        newPostDocumentName.value = null;

    } catch (error) {
        console.error('Erro ao criar post:', error);
    }
    fetchPosts()
};

// função auxiliar para converter base64 em File
function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


// Funções para comentários
const toggleComments = (postId) => {
    const post = posts.value.find(p => p.id === postId);
    if (post) {
        post.showComments = !post.showComments;
    }
};

const addComment = async (postId) => {
    const post = posts.value.find(p => p.id === postId);
    if (post && post.newComment.trim()) {
        try {
            const response = await axios.post(`/api/v1/posts/${postId}/comentarios`, {
                comentario: post.newComment
            }, {
                headers
            });

            post.comments.push({
                id: response.data.id,
                usuario_nome: response.data.usuario_nome,
                comentario: response.data.comentario,
                usuario_imagem: response.data.usuario_imagem || '/default-avatar.png', // ✅ ADICIONE ESTA LINHA
                data_criacao: response.data.data_criacao,
                userLiked: false,
                showReplyInput: false,
                newReply: '',
                replies: []
            });

            post.total_comentarios = (post.total_comentarios || 0) + 1;
            post.newComment = '';
        } catch (error) {
            console.error('Erro ao adicionar comentário:', error);
        }
    }
};

const toggleReplyInput = (postId, commentId) => {
    const post = posts.value.find(p => p.id === postId);
    if (post) {
        post.comments.forEach(comment => {
            comment.showReplyInput = comment.id === commentId ? !comment.showReplyInput : false;
        });
    }
};

const addReply = async (postId, comment) => {
    if (!comment || !comment.newReply?.trim()) return;

    try {
        const formData = new FormData();
        formData.append("resposta", comment.newReply);

        const comentarioIdInt = parseInt(comment.id);
        if (isNaN(comentarioIdInt)) {
            console.error("comentario_id inválido:", comment.id);
            return;
        }

        const response = await axios.post(`/api/v1/comentarios/${comentarioIdInt}/respostas`, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data'
            }
        });

        const post = posts.value.find(p => p.id === postId);
        if (post) {
            const targetComment = post.comments.find(c => c.id === comment.id);
            if (targetComment) {
                targetComment.replies.push({
                    id: response.data.id || Date.now(),
                    author: {
                        name: response.data.usuario,
                        avatar: CircleUserRound
                    },
                    content: response.data.resposta,
                    createdAt: response.data.data_criacao,
                    userLiked: false
                });

                targetComment.newReply = '';
                targetComment.showReplyInput = false;
            }
        }

    } catch (err) {
        console.error('Erro ao enviar resposta:', err.response?.data || err);
    }
    fetchPosts()
};




// Funções para reações
const reactToPost = async (postId, emoji) => {
    const post = posts.value.find(p => p.id === postId);
    if (!post) return;

    try {
        await axios.post(`/api/v1/posts/${postId}/reacoes`, { reacao: emoji }, { headers });
        post.userReaction = emoji;
        post.likes = (post.likes || 0) + 1;
        activeEmojiPostId.value = null; // Fecha o menu de emojis
    } catch (error) {
        console.error('Erro ao reagir ao post:', error.response?.data || error);
    }
};

//const likeComment = (postId, commentId) => {
//    const post = posts.value.find(p => p.id === postId);
//   if (post) {
//       const comment = post.comments.find(c => c.id === commentId);
//        if (comment) {
//           comment.userLiked = !comment.userLiked;
//       }
//   }
//};

const likeReply = (postId, commentId, replyId) => {
    const post = posts.value.find(p => p.id === postId);
    if (post) {
        const comment = post.comments.find(c => c.id === commentId);
        if (comment && comment.replies) {
            const reply = comment.replies.find(r => r.id === replyId);
            if (reply) {
                reply.userLiked = !reply.userLiked;
            }
        }
    }
};
const profileImage = ref('');
const editingPostId = ref(null);
const editedContent = ref('');
const startEditPost = (post) => {
    editingPostId.value = post.id;
    editedContent.value = post.content;
    closePostMenu();
};

const carregarPerfil = async () => {
  try {
    const apiToken = sessionStorage.getItem("api_token");
    const headers = { "X-API-KEY": apiToken };
    const { data } = await axios.get('/api/v1/profile', { headers });

    if (data.imagem_base64) {
      profileImage.value = data.imagem_base64
        ? data.imagem_base64
        : data.imagem_base64;
    }
  } catch (error) {
    console.error("Erro ao carregar perfil:", error);
    alert("Erro ao carregar perfil.");
  }
};

const saveEditedPost = async (post) => {
    try {
        await axios.put(`/api/v1/posts/${post.id}`, {
            conteudo: editedContent.value
        }, {
            headers: {
                ...headers,
                'Content-Type': 'application/json'
            }
        });

        post.content = editedContent.value + " (Editada)";
        editingPostId.value = null;
        editedContent.value = '';
    } catch (err) {
        console.error("Erro ao editar post:", err.response?.data || err);
    }
};

const isReactionsModalOpen = ref(false);
const reactionsModalPost = ref(null);
watch(() => reactionsModalPost.value, (newVal) => {
    if (newVal && newVal.reactionUsersPerEmoji) {
        selectedEmojiTab.value = Object.keys(newVal.reactionUsersPerEmoji)[0] || null;
    }
});


const openReactionsModal = (post) => {
    reactionsModalPost.value = post;
    isReactionsModalOpen.value = true;
};

const closeReactionsModal = () => {
    isReactionsModalOpen.value = false;
    reactionsModalPost.value = null;
};


const cancelEditPost = () => {
    editingPostId.value = null;
    editedContent.value = '';
};

const deletePost = async (postId) => {
    if (!confirm("Tem certeza que deseja excluir esta publicação?")) return;

    try {
        await axios.delete(`/api/v1/posts/${postId}`, { headers });
        posts.value = posts.value.filter(p => p.id !== postId);
    } catch (err) {
        console.error("Erro ao excluir post:", err);
    }
};

const activePostMenuId = ref(null);


const togglePostMenu = (postId) => {
    activePostMenuId.value = activePostMenuId.value === postId ? null : postId;
};

const closePostMenu = () => {
    activePostMenuId.value = null;
};

document.addEventListener('click', (e) => {
    if (!e.target.closest('.relative')) closePostMenu();
});

// Funções para grupos
const toggleSidebar = () => {
    isSidebarVisible.value = !isSidebarVisible.value;
};

const selectGroup = (groupId) => {
    selectedGroupId.value = groupId;
    posts.value = [];
    fetchPosts();
    isSidebarVisible.value = false; // Fecha o sidebar após selecionar
};

const openGroupModal = () => {
    isGroupModalOpen.value = true;
};

const closeGroupModal = () => {
    isGroupModalOpen.value = false;
    newGroupName.value = '';
    selectedMembers.value = [];
};

const createGroup = async () => {
    if (newGroupName.value.trim() && selectedMembers.value.length > 0) {
        try {
            const payload = {
                nome: newGroupName.value,
                membros_ids: selectedMembers.value,
                setor_id: null,
                unidade_id: null
            };

            await axios.post('/api/v1/gruposfeed/', payload, {
                headers
            });

            await fetchGroups();
            closeGroupModal();

        } catch (error) {
            console.error('Erro ao criar grupo:', error.response?.data || error);
            alert('Erro ao criar grupo. Verifique as permissões ou dados.');
        }
    }
};
const fetchPosts = async () => {
  try {
    const url = selectedGroupId.value
      ? `/api/v1/posts?grupo_id=${selectedGroupId.value}`
      : '/api/v1/posts';

    const response = await axios.get(url, { headers });

    posts.value = response.data.length
  ? response.data.map(post => ({
      id: post.id,
      author: {
        id: post.usuario_id,
        name: post.usuario_nome,
        avatar: post.usuario_imagem || '/default-avatar.png'
      },
      content: post.conteudo,
      image: post.imagem_base64 || null,
      createdAt: post.data_criacao,
      likes: post.reacoes.total,
      total_comentarios: post.total_comentarios,
      userLiked: post.reacoes.usuario_curtiu,
      reactionUsers: post.reacoes.usuarios_curtiram || [],
      userReaction: post.reacoes.reacao_usuario || null,
      showEmojiMenu: false,
      showComments: false,
      newComment: '',
      reactionUsersEmojis: post.reacoes.emojis_distintos || [],
      reactionUsersPerEmoji: post.reacoes.emojis_por_usuario || {},
      comments: post.comentarios.map(c => ({
        ...c,
        id: c.id ? Number(c.id) : null,
        usuario_imagem: c.usuario_imagem || '/default-avatar.png',
        showReplyInput: false,
        newReply: '',
        replies: c.respostas.map(r => ({
          ...r,
          usuario_imagem: r.usuario_imagem || '/default-avatar.png'
        }))
      })),
      comentariosOcultos: post.comentarios_ocultos,
      video: post.video_url || null,
      documentos: Array.isArray(post.documentos) ? post.documentos : []
    }))
  : [];

  } catch (error) {
    console.error('Erro ao buscar posts:', error);
  }
};


const fetchGroups = async () => {
    try {
        const permissaoUsuario = sessionStorage.getItem("permissao") || ""; // Ex: 'usuario', 'responsavel', 'desenvolvedor', etc.

        let url = '/api/v1/gruposfeedusuarios/';
        if (permissaoUsuario === 'desenvolvedor') {
            url = '/api/v1/gruposfeed/'; // endpoint que retorna todos os grupos
        }

        const response = await axios.get(url, { headers });
        groups.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar grupos:", error);
    }
};

const memberSearch = ref('');

const filteredUsers = computed(() => {
    if (!memberSearch.value.trim()) return users.value;
    const term = memberSearch.value.toLowerCase();
    return users.value.filter(user =>
        user.name.toLowerCase().includes(term)
    );
});

const fetchUsers = async () => {
    try {
        const response = await axios.get('/api/v1/usuarios/', { headers });
        users.value = response.data.map(u => ({
            id: u.id,
            name: `${u.first_name} ${u.last_name}`.trim() || u.username

        }));
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
    }
};




const carregarMaisComentarios = async (post) => {
    try {
        const offset = post.comments.length;
        const response = await axios.get(`/api/v1/posts?offset=${offset}&limit=3`, { headers });
        const postAtualizado = response.data.find(p => p.id === post.id);
        if (postAtualizado) {
            post.comments = [...post.comments, ...postAtualizado.comentarios];
        }
    } catch (error) {
        console.error("Erro ao carregar mais comentários:", error);
    }
};

// Formatar data
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));

    if (diffInMinutes < 1) return 'Agora mesmo';
    if (diffInMinutes < 60) return `${diffInMinutes} min atrás`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} h atrás`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays} d atrás`;

    return date.toLocaleDateString('pt-BR');
};

// Inicialização
onMounted(() => {
    fetchPosts();
    fetchUsers();
    fetchGroups();
    verificarSeEhResponsavel();
    verificarPermissaoPostagem();
    carregarPerfil();

});

// Fechar menu de emojis quando clicar fora
watch(() => activeEmojiPostId.value, (newVal) => {
    if (newVal) {
        const closeOnClickOutside = (e) => {
            const emojiMenu = document.querySelector('.emoji-menu');
            if (emojiMenu && !emojiMenu.contains(e.target)) {
                activeEmojiPostId.value = null;
                document.removeEventListener('click', closeOnClickOutside);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', closeOnClickOutside);
        }, 100);
    }
});
</script>

<style>
/* Estilos base */
.dark\:bg-gray-750 {
    background-color: #1e2837;
}

/* Animações */
@keyframes like-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

button:active svg {
    animation: like-animation 0.3s ease;
}

/* Animações de transição */
.post-list-enter-active,
.comment-list-enter-active,
.reply-list-enter-active {
    transition: all 0.3s ease-out;
}

.post-list-leave-active,
.comment-list-leave-active,
.reply-list-leave-active {
    transition: all 0.3s ease-in;
    position: absolute;
}

.post-list-enter-from,
.comment-list-enter-from,
.reply-list-enter-from {
    opacity: 0;
    transform: translateY(20px);
}

.post-list-leave-to,
.comment-list-leave-to,
.reply-list-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

/* Animação de slide down para comentários */
.animate-slide-down {
    animation: slide-down 0.3s ease-out forwards;
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animação de fade in */
.animate-fade-in {
    animation: fade-in 0.3s ease-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Animação de zoom in para modais */
.animate-zoom-in {
    animation: zoom-in 0.3s ease-out forwards;
}

@keyframes zoom-in {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Transições de modal */
.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
}

/* Estilos para botões de upload e previews - Melhorados para mobile */
.upload-btn {
  @apply text-gray-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400 cursor-pointer transition flex items-center gap-1 px-2 py-1 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700;
}

.preview-box {
  @apply flex items-center gap-2 sm:gap-3 p-1.5 sm:p-2 bg-gray-50 dark:bg-gray-700/50 rounded-lg w-fit;
}

.remove-btn {
  @apply text-red-600 hover:text-red-800 p-0.5 sm:p-1 rounded-full hover:bg-red-100 dark:hover:bg-red-900/20 transition;
}

/* Adicione uma classe de utilidade para telas muito pequenas */
@media (max-width: 360px) {
  .xs\:inline {
    display: inline;
  }
}
</style>