<template>
    <BaseTemplate app-name="Gestão de Clínicas e Setores" page-title="">
        <template #content>
            <div class="container mx-auto p-6">
                
            <!-- Cabeçalho --><button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
            <div class="flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-6">
            <h1 class="hidden sm:flex items-center gap-2 text-2xl font-bold text-gray-900 dark:text-white">
                <BuildingIcon class="w-6 h-6 text-blue-500" />
                Cadastro de Clínicas e Setores
            </h1>
            <div class="flex gap-2 justify-center w-full sm:w-auto mt-4 sm:mt-0">
                <button @click="openModal('clinica')"
                class="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg shadow">
                <PlusIcon class="w-5 h-5" />
                Nova Clínica
                </button>
                <button @click="openModal('setor')"
                class="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow">
                <PlusIcon class="w-5 h-5" />
                Novo Setor
                </button>
            </div>
            </div>
                <!-- Filtros -->
                <div class="flex flex-col sm:flex-row gap-4 mb-6">
                    <input v-model="filter" type="text" placeholder="Buscar por nome da clínica ou setor..."
                        class="w-full sm:w-1/2 border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                </div>

                <!-- Mensagem de erro -->
                <p v-if="error" class="text-red-500 text-center mb-4">{{ error }}</p>

                <!-- Tabela para telas maiores -->
                <div
                    class="hidden sm:block bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto dark:text-white text-gray-800">
                    <table class="w-full">
                        <thead
                            class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
                            <tr>
                                <th @click="ordenarPor('nome')" class="cursor-pointer px-4 py-3 text-left">
                                    Nome <span v-if="colunaOrdenada === 'nome'">{{ ordemAscendente ? '🠕' : '🠗'
                                        }}</span>
                                </th>
                                <th @click="ordenarPor('setores')" class="cursor-pointer px-4 py-3 text-left">
                                    Setores <span v-if="colunaOrdenada === 'setores'">{{ ordemAscendente ? '🠕' : '🠗'
                                        }}</span>
                                </th>
                                <th @click="ordenarPor('status')" class="cursor-pointer px-4 py-3 text-left">
                                    Status <span v-if="colunaOrdenada === 'status'">{{ ordemAscendente ? '🠕' : '🠗'
                                        }}</span>
                                </th>
                                <th class="px-4 py-3 text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in itensOrdenados" :key="item.id"
                                class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                                <td class="px-4 py-3">{{ item.nome }}</td>
                                <td class="px-4 py-3">
                                    <div class="flex flex-wrap gap-2">
                                        <div v-for="setor in item.setores" :key="setor.id"
                                            class="flex items-center gap-2 bg-blue-100 text-blue-800 dark:bg-blue-700 dark:text-white px-2 py-1 text-xs rounded-md">
                                            {{ setor.nome }}
                                        </div>
                                        <span v-if="item.setores.length === 0" class="text-gray-400">Nenhum setor vinculado</span>
                                    </div>
                                </td>
                                <td class="px-4 py-3 text-center">
                                    <span class="text-sm font-semibold" :class="{
                                        'text-green-600 dark:text-green-400': item.status === 'em_funcionamento',
                                        'text-yellow-600 dark:text-yellow-400': item.status === 'em_atendimento',
                                        'text-red-600 dark:text-red-400': item.status === 'parado'
                                    }">
                                        {{ item.status === 'em_funcionamento' ? 'OK' : item.status === 'em_atendimento'
                                        ? 'Em Atendimento' : 'Parado' }}
                                    </span>
                                </td>

                                <td class="px-4 py-3 text-center">
                                    <div class="flex justify-center space-x-2">
                                        <button @click="openModal(item.tipo, item)"
                                            class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                                            <Edit2Icon class="w-4 h-4" /> Editar
                                        </button>
                                        <button @click="deleteItem(item.id)"
                                            class="flex items-center gap-1 text-red-500 hover:text-red-700">
                                            <Trash2Icon class="w-4 h-4" /> Excluir
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Versão mobile em cards -->
                <div class="sm:hidden space-y-6">
                    <div v-for="item in itensOrdenados" :key="item.id"
                        class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
                        <div class="flex justify-between items-center">
                            <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                                {{ item.nome }}
                            </h3>
                            <div class="flex items-center gap-2">
                                <button @click="openModal(item.tipo, item)" class="text-blue-500 hover:text-blue-700">
                                    <Edit2Icon class="w-5 h-5" />
                                </button>
                                <button @click="deleteItem(item.id)" class="text-red-500 hover:text-red-700">
                                    <Trash2Icon class="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                        <p v-if="item.tipo === 'unidade'" class="text-sm text-gray-700 dark:text-gray-300">
                            <strong>Setores:</strong> {{item.setores.map(s => s.nome).join(', ') || '-'}}
                        </p>
                        <div class="flex items-center justify-between mt-4">
                            <span class="text-sm font-semibold" :class="{
                                'text-green-600 dark:text-green-400': item.status === 'em_funcionamento',
                                'text-yellow-600 dark:text-yellow-400': item.status === 'em_atendimento',
                                'text-red-600 dark:text-red-400': item.status === 'parado'
                            }">
                                {{ item.status === 'em_funcionamento' ? 'OK' : item.status === 'em_atendimento' ? 'Em Atendimento' : 'Parado' }}
                            </span>
                        </div>
                    </div>
                </div>

              <div v-if="isSetorModalOpen"
              class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
              @click.self="closeSetorModal">
            <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
              <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                <BuildingIcon class="w-5 h-5 text-blue-500" />
                Novo Setor
              </h2>

              <!-- Campos de Cadastro do Setor -->
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Nome do Setor</label>
              <input v-model="novoSetor.nome" type="text"
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

              <!-- Selecionar Unidade Médica -->
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade Médica</label>
              <select v-model="novoSetor.unidadeId"
                      class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                <option v-for="unidade in unidadesMedicas" :key="unidade.id" :value="unidade.id">
                  {{ unidade.nome }}
                </option>
              </select>

              <div class="flex justify-end space-x-3">
                <button @click="closeSetorModal"
                        class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                  <XIcon class="w-4 h-4" /> Cancelar
                </button>
                <button @click="salvarSetor"
                        class="flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                  <SaveIcon class="w-4 h-4" />
                  <span>Salvar</span>
                </button>
              </div>
            </div>
          </div>


                  <div v-if="isModalOpen"
                  class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                  @click.self="closeModal">
                <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
                  <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    <BuildingIcon class="w-5 h-5 text-blue-500" />
                    {{ isEditMode ? `Editar Unidade Médica` : `Nova Unidade Médica` }}
                  </h2>

                  <!-- Nome da Unidade -->
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Nome da Unidade</label>
                  <input v-model="currentItem.nome" type="text"
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />

                  <!-- Status da Unidade -->
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Status</label>
                  <select v-model="currentItem.status"
                          class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3"
                          required>
                    <option value="">Selecione um status</option>
                    <option value="em_funcionamento">OK</option>
                    <option value="em_atendimento">Em Atendimento</option>
                    <option value="parado">Parado</option>
                  </select>

                  <!-- Lista de Setores Vinculados -->
                  <div v-if="isEditMode" class="mb-4">
                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Setores Vinculados</label>
                    <div v-if="currentItem.setores.length > 0" class="space-y-2 max-h-40 overflow-y-auto">
                      <div v-for="setor in currentItem.setores" :key="setor.id"
                          class="flex items-center justify-between bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded-lg">
                        <span class="text-sm text-gray-800 dark:text-gray-200">{{ setor.nome }}</span>
                        <button @click="removerSetor(currentItem, setor.id)"
                                class="text-red-500 hover:text-red-700">
                          <XIcon class="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    <p v-else class="text-sm text-gray-500 dark:text-gray-400">Nenhum setor vinculado.</p>
                  </div>

                  <!-- Botões de Ação -->
                  <div class="flex justify-end space-x-3 mt-4">
                    <button @click="closeModal"
                            class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                      <XIcon class="w-4 h-4" /> Cancelar
                    </button>
                    <button @click="saveItem"
                            :disabled="!currentItem.nome || !currentItem.status"
                            :class="[
                              'flex items-center gap-1 text-white px-4 py-2 rounded-lg',
                              currentItem.nome && currentItem.status ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400 cursor-not-allowed'
                            ]">
                      <SaveIcon class="w-4 h-4" />
                      <span>Salvar</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import {
    BuildingIcon,
    PlusIcon,
    Edit2Icon,
    Trash2Icon,
    SquareArrowLeft,
    XIcon,
    SaveIcon
  } from 'lucide-vue-next';
  import BaseTemplate from './BaseTemplate.vue';
  
  const API_URL = process.env.VUE_APP_API_URL;
  const apiToken = sessionStorage.getItem("api_token");
  
  const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': apiToken
  };
  
  const items = ref([]);
  const filter = ref('');
  const error = ref(null);
  const isModalOpen = ref(false);
  const isEditMode = ref(false);
  const currentItem = ref({
    id: '',
    nome: '',
    setores: [],
    status: '',
  });
  
  const colunaOrdenada = ref('nome');
  const ordemAscendente = ref(true);
  
  const filteredItems = computed(() =>
  items.value.filter(item =>
    item.nome.toLowerCase().includes(filter.value.toLowerCase()) ||
    (item.tipo && item.tipo.toLowerCase().includes(filter.value.toLowerCase())) ||
    ((item.endereco || '').toLowerCase().includes(filter.value.toLowerCase())) ||
    (item.setores && item.setores.some(setor =>
      setor.nome.toLowerCase().includes(filter.value.toLowerCase())
    )
  ))
);
  const itensOrdenados = computed(() => {
    const itensFiltrados = filteredItems.value.slice();
  
    return itensFiltrados.sort((a, b) => {
      let valorA, valorB;
  
      switch (colunaOrdenada.value) {
        case 'nome':
          valorA = a.nome.toLowerCase();
          valorB = b.nome.toLowerCase();
          break;
        case 'tipo':
          valorA = a.tipo.toLowerCase();
          valorB = b.tipo.toLowerCase();
          break;
        case 'endereco':
          valorA = (a.endereco || '').toLowerCase();
          valorB = (b.endereco || '').toLowerCase();
          break;
        case 'setores':
          valorA = a.setores ? a.setores.length : 0;
          valorB = b.setores ? b.setores.length : 0;
          break;
        case 'status':
          valorA = a.isActive ? 1 : 0;
          valorB = b.isActive ? 1 : 0;
          break;
        default:
          return 0;
      }
  
      if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
      if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
      return 0;
    });
  });
  
  const setoresDisponiveis = computed(() => {
    return items.value.filter(item => item.tipo === 'setor' && item.isActive);
  });
  
  function ordenarPor(coluna) {
    if (colunaOrdenada.value === coluna) {
      ordemAscendente.value = !ordemAscendente.value;
    } else {
      colunaOrdenada.value = coluna;
      ordemAscendente.value = true;
    }
  }
  
  onMounted(async () => {
    await fetchItems();
    await fetchUnidadesMedicas();
  });
  
  async function removerSetor(unidade, setorId) {
  try {
    const response = await fetch(`${API_URL}/api/v1/clinicas-setores/${unidade.id}/remover-setor/${setorId}/`, {
      method: 'DELETE',
      headers
    });

    if (!response.ok) {
      throw new Error('Erro ao remover setor da unidade');
    }

    // Atualiza a lista de setores da unidade no frontend
    unidade.setores = unidade.setores.filter(setor => setor.id !== setorId);
  } catch (err) {
    error.value = err.message || 'Erro ao remover setor da unidade';
  }
}

import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
  
  async function fetchUnidadesMedicas() {
    try {
      const response = await fetch(`${API_URL}/api/v1/clinicas-setores/`, { headers });
      const data = await response.json();
      unidadesMedicas.value = data;
    } catch (err) {
      error.value = 'Erro ao carregar unidades médicas';
    }
  }
  
  // Função para abrir o modal de setor
  function openSetorModal() {
    isSetorModalOpen.value = true;
  }
  
  // Estados para o modal de setor
  const isSetorModalOpen = ref(false);
  const novoSetor = ref({
    nome: '',
    unidadeId: null
  });
  
  // Lista de unidades médicas (carregada do backend)
  const unidadesMedicas = ref([]);
  
  // Função para abrir o modal de setor
  function openModal(tipo, item = null) {
  if (tipo === 'setor') {
    openSetorModal(); // Abre o modal de setor
  } else {
    isEditMode.value = !!item;
    if (item) {
    currentItem.value = { ...item };
    } else {
    currentItem.value = {
        id: '',
        nome: '',
        tipo: 'unidade', // Define o tipo como 'unidade'
        setores: [],
        status: '', // valor padrão vazio para forçar a escolha
        isActive: true
    };
    }

    isModalOpen.value = true; // Abre o modal de unidade
  }
}
  
  // Função para fechar o modal de setor
  function closeSetorModal() {
    isSetorModalOpen.value = false;
    novoSetor.value = { nome: '', unidadeId: null }; // Limpa os campos

    fetchItems();
  }
  
  function closeModal() {
    isModalOpen.value = false;
    currentItem.value = {
        id: '',
        nome: '',
        tipo: 'clinica',
        setores: [],
        status: '', // reinicia para vazio
        isActive: true
    };
    fetchItems();
    fetchUnidadesMedicas();
}

  
  // Função para salvar o setor
async function salvarSetor() {
  try {
    // Verifica se o nome do setor e a unidade foram preenchidos
    if (!novoSetor.value.nome || !novoSetor.value.unidadeId) {
      throw new Error("Preencha o nome do setor e selecione a unidade médica.");
    }

    // Payload para criar o setor
    const payload = {
      nome: novoSetor.value.nome,
      unidade_id: novoSetor.value.unidadeId, // Campo esperado pelo backend
    };

    console.log("Payload enviado:", payload);

    // Faz a requisição POST para criar o setor
    const response = await fetch(`${API_URL}/api/v1/clinicas-setor/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiToken, // Certifique-se de que o token está sendo enviado
      },
      body: JSON.stringify(payload),
    });

    console.log("Resposta da API:", response);

    // Verifica se a requisição foi bem-sucedida
    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Erro ao salvar setor:", errorResponse);
      throw new Error(errorResponse.detail?.[0]?.msg || 'Erro ao salvar setor');
    }

    // Processa a resposta da API
    const savedSetor = await response.json();
    console.log("Setor salvo:", savedSetor);

    // Adiciona o setor à lista de setores disponíveis
    setoresDisponiveis.value.push({
      id: savedSetor.id,
      nome: savedSetor.nome,
      unidade_id: savedSetor.unidade_id,
      tipo: 'setor',
      isActive: true,
    });

    // Fecha o modal de cadastro de setor
    closeSetorModal();
  } catch (err) {
    console.error("Erro no catch:", err);
    error.value = err.message || 'Erro ao salvar setor';
  }
  fetchItems();
  fetchUnidadesMedicas();
}
  async function fetchItems() {
    try {
      const response = await fetch(`${API_URL}/api/v1/clinicas-setores/`, { headers });
      const data = await response.json();
  
      // Mapeia os dados da API para o formato esperado no frontend
      items.value = data.map(unidade => ({
        id: unidade.id,
        nome: unidade.nome,
        status: unidade.status,
        setores: unidade.setores.map(setor => ({
          id: setor.id,
          nome: setor.nome,
          unidade_id: setor.unidade_id
        })),
        isActive: true
      }));
    } catch (err) {
      error.value = 'Erro ao carregar unidades médicas e setores';
    }
  }
  
  async function saveItem() {
  try {
    const url = isEditMode.value
      ? `${API_URL}/api/v1/clinicas-setores/${currentItem.value.id}/`
      : `${API_URL}/api/v1/clinicas-setores/`;

    const method = isEditMode.value ? 'PUT' : 'POST';

    const payload = {
      nome: currentItem.value.nome,
      dns: currentItem.value.dns || "",
      status: currentItem.value.status, // ✅ Certifique-se de que este valor está correto
    };

    console.log("Enviando atualização de status:", payload);

    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error('Erro ao salvar unidade médica');
    }

    const savedItem = await response.json();
    console.log("Resposta do backend:", savedItem);

    const index = items.value.findIndex(item => item.id === savedItem.id);
    if (index !== -1) {
      items.value[index] = { ...savedItem };
    }

    closeModal();
    fetchItems();
  } catch (err) {
    error.value = err.message || 'Erro ao salvar unidade médica';
  }
}

  async function deleteItem(itemId) {
    const confirmDeletion = window.confirm("Tem certeza de que deseja excluir esta clínica/setor? Esta ação não pode ser desfeita.");
    if (!confirmDeletion) return;
  
    try {
      const response = await fetch(`${API_URL}/api/v1/clinicas-setores/${itemId}/`, {
        method: 'DELETE',
        headers
      });
  
      if (!response.ok) {
        throw new Error('Erro ao excluir clínica/setor');
      }
  
      items.value = items.value.filter(item => item.id !== itemId);
    } catch (err) {
      error.value = err.message || 'Erro ao excluir clínica/setor';
    }
  }
  

  </script>