<template>
  <BaseTemplate app-name="Documentos" page-title="">
    <template #content>
      <div class="min-h-screen bg-slate-200 dark:bg-gray-900 transition-colors duration-300">
        <div class="container mx-auto px-4 py-6">
          <!-- Header with Search -->
          <div class="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md mb-6">
            <div class="flex flex-col md:flex-row md:items-center gap-4 md:gap-6">
              <!-- Campo de Busca por Pasta -->
              <div class="flex-1">
                <label class="text-base md:text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Buscar pasta</label>
                <div class="relative">
                  <input 
                    v-model="termoBuscaPasta" 
                    type="text" 
                    class="w-full border border-gray-300 dark:border-gray-600 rounded-lg pl-10 pr-4 py-2 bg-gray-100 dark:bg-gray-700 dark:text-white text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    placeholder="Digite o nome da pasta..."
                  >
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon class="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Breadcrumb Navigation -->
          <div class="flex items-center mb-6 text-sm overflow-x-auto whitespace-nowrap pb-2">
            <button @click="navigateToRoot" class="flex items-center text-blue-600 dark:text-blue-400 hover:underline">
              <HomeIcon class="h-4 w-4 mr-1" />
              Início
            </button>
            <template v-if="currentPath.length > 0">
              <ChevronRightIcon class="h-4 w-4 mx-1 text-gray-500" />
              <div v-for="(item, index) in currentPath" :key="index" class="flex items-center">
                <button @click="navigateTo(item)" class="text-blue-600 dark:text-blue-400 hover:underline">
                  {{ item.nome }}
                </button>
                <ChevronRightIcon v-if="index < currentPath.length - 1" class="h-4 w-4 mx-1 text-gray-500" />
              </div>
            </template>
          </div>

          <!-- Loading State -->
          <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
            <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
          </div>

          <!-- Mensagem de Acesso Negado -->
          <div v-else-if="showAccessDenied" class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8 text-center mb-8">
            <AlertTriangleIcon class="h-16 w-16 mx-auto text-yellow-500 mb-4" />
            <h3 class="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">Acesso Negado</h3>
            <p class="text-gray-500 dark:text-gray-400 mb-6">
              Você não possui permissão para acessar esta pasta. Entre em contato com o administrador para solicitar acesso.
            </p>
            <button @click="navigateToRoot" class="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700">
              Voltar para Início
            </button>
          </div>

          <!-- Folders Grid -->
          <div v-else-if="!isLoading" class="mb-8">
            <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4 flex items-center">
              <FolderIcon class="h-5 w-5 mr-2 text-blue-500" />
              Pastas
              <button 
                v-if="userTemPermissaoCriar() && currentSetor" 
                @click="openNewFolderModal(currentSetor.id)" 
                class="ml-2 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
              >
                <PlusCircleIcon class="h-5 w-5" />
              </button>
            </h2>

            <!-- Setores Grid (Root Level) -->
            <div v-if="!currentSetor" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div v-for="setor in setores" :key="setor.id" 
                   class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 border-l-4 border-blue-500 hover:shadow-lg transition-shadow cursor-pointer"
                   @click="selectSetor(setor)">
                <div class="flex items-center mb-2">
                  <BuildingIcon class="h-6 w-6 text-blue-500 mr-2" />
                  <h3 class="font-semibold text-gray-800 dark:text-gray-200">{{ setor.nome }}</h3>
                </div>
                <p class="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                  {{ setor.descricao || 'Setor de documentação' }}
                </p>
                <div class="flex justify-between items-center mt-3">
                  <span class="text-xs text-gray-500 dark:text-gray-400">
                    {{ pastasPorSetor[setor.id]?.length || 0 }} pastas
                  </span>
                  <button 
                    v-if="userTemPermissaoCriar()" 
                    @click.stop="openNewFolderModal(setor.id)" 
                    class="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    <FolderPlusIcon class="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>

            <!-- Pastas Grid (Inside Setor) -->
            <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div v-for="pasta in pastasFiltradas" :key="pasta.id" 
                   class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 border-l-4 border-blue-500 hover:shadow-lg transition-shadow cursor-pointer group"
                   @click="selectPasta(pasta)">
                <div class="flex items-center justify-between mb-2">
                  <div class="flex items-center space-x-2">
                    <!-- Ícone de pasta (fechada por padrão, aberta se selecionada) -->
                    <FolderIcon v-if="currentPasta?.id !== pasta.id" class="h-6 w-6 text-blue-500" />
                    <FolderOpenIcon v-else class="h-6 w-6 text-blue-500" />
                    <h3 class="font-semibold text-gray-800 dark:text-gray-200">{{ pasta.nome }}</h3>
                  </div>
                  <div class="flex items-center space-x-1">
                    <button
                      v-if="userTemPermissaoCriar()"
                      @click.stop="abrirModalEditarMembros(pasta)"
                      class="text-gray-400 hover:text-blue-500 dark:hover:text-blue-300 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                      title="Editar Membros"
                    >
                      <Settings class="h-4 w-4" />
                    </button>
                    <button 
                      v-if="userTemPermissaoCriar()" 
                      @click.stop="confirmarExclusaoPasta(pasta)" 
                      class="text-gray-400 hover:text-red-500 dark:hover:text-red-400 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                      title="Excluir Pasta"
                    >
                      <Trash2Icon class="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <p class="text-sm text-gray-600 dark:text-gray-400 line-clamp-2 mb-3">
                  {{ pasta.descricao || 'Pasta de documentos' }}
                </p>
                <div class="flex justify-between items-center">
                  <span class="text-xs text-gray-500 dark:text-gray-400">
                    {{ pasta.documentCount || 0 }} documentos
                  </span>
                  <button 
                    v-if="userTemPermissaoCriar()" 
                    @click.stop="openUploadModalForFolder(pasta)"
                    class="text-xs bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition-colors opacity-0 group-hover:opacity-100"
                  >
                    Novo Documento
                  </button>
                </div>
              </div>
              
              <!-- Add Folder Card -->
              <div v-if="userTemPermissaoCriar()" 
                   class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 border border-dashed border-gray-300 dark:border-gray-600 hover:border-blue-500 dark:hover:border-blue-500 flex flex-col items-center justify-center cursor-pointer min-h-[150px]"
                   @click="openNewFolderModal(currentSetor.id)">
                <FolderPlusIcon class="h-10 w-10 text-gray-400 dark:text-gray-500 mb-2" />
                <p class="text-gray-600 dark:text-gray-400 text-center">Adicionar Pasta</p>
              </div>
            </div>
            
          </div>

          <!-- Documents Section -->
          <div v-if="!isLoading && currentPasta && !showAccessDenied" class="mb-8">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200 flex items-center">
                <FileIcon class="h-5 w-5 mr-2 text-blue-500" />
                Documentos
              </h2>
              <button 
                v-if="userTemPermissaoCriar()" 
                @click="openUploadModal()" 
                class="bg-blue-500 text-white px-3 py-1.5 rounded-lg text-sm hover:bg-blue-600 transition-colors flex items-center gap-1"
              >
                <PlusCircleIcon class="h-4 w-4" />
                Novo Documento
              </button>
            </div>

            <!-- Documents Grid -->
            <div v-if="documentos.length > 0" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div v-for="documento in documentos" :key="documento.id" 
                   class="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden transition transform hover:scale-[1.02] hover:shadow-lg border border-gray-200 dark:border-gray-700">
                <!-- Cabeçalho do Card -->
                <div class="p-4 border-b border-gray-200 dark:border-gray-700">
                  <div class="flex justify-between items-start">
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200 flex items-center truncate max-w-[70%]">
                      <FileIcon class="h-5 w-5 mr-2 flex-shrink-0" />
                      <span class="truncate">{{ documento.nome }}</span>
                    </h3>
                    <span class="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300 px-3 py-1 rounded-full text-xs font-medium">
                      {{ formatarTexto('tipo', documento.tipo) }}
                    </span>
                  </div>
                  <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    <span class="font-medium">Data:</span> {{ formatarData(documento.data_upload) }}
                  </p>
                </div>

                <!-- Botões de Ação -->
                <div class="flex divide-x divide-gray-200 dark:divide-gray-700">
                  <button @click.stop="visualizarDocumento(documento)" class="flex-1 flex items-center justify-center py-3 text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20 transition-colors text-sm font-medium">
                    <EyeIcon class="h-4 w-4 mr-1.5" />
                    Visualizar
                  </button>
                  <button @click.stop="downloadDocumento(documento)" class="flex-1 flex items-center justify-center py-3 text-green-600 dark:text-green-400 hover:bg-green-50 dark:hover:bg-green-900/20 transition-colors text-sm font-medium">
                    <DownloadIcon class="h-4 w-4 mr-1.5" />
                    Download
                  </button>
                  <button 
                    v-if="userTemPermissaoCriar()"
                    @click.stop="confirmarExclusao(documento)" 
                    class="flex-1 flex items-center justify-center py-3 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors text-sm font-medium"
                  >
                    <Trash2Icon class="h-4 w-4 mr-1.5" />
                    Excluir
                  </button>
                </div>
              </div>
            </div>

            <!-- Empty State -->
            <div v-else class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8 text-center">
              <FileX class="h-16 w-16 mx-auto text-gray-400 dark:text-gray-500 mb-4" />
              <h3 class="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">Nenhum documento encontrado</h3>
              <p class="text-gray-500 dark:text-gray-400 mb-6">Não existem documentos nesta pasta.</p>
              <button @click="openUploadModal" class="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700">
                Adicionar Documento
              </button>
            </div>
          </div>

          <!-- Rodapé fixo -->
          <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
            © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
          </p>
        </div>
      </div>

      <!-- Modal de Upload -->
      <div v-if="isUploadModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
        <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl">
          <h2 class="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
            <FileUpIcon class="h-6 w-6 mr-2 text-blue-500" />
            Adicionar Documento
          </h2>
          <form @submit.prevent="uploadDocumento" class="space-y-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- Nome do Documento -->
              <div class="col-span-full">
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Nome do Documento</label>
                <input v-model="novoDocumento.nome" type="text" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" placeholder="Digite o nome do documento" required>
              </div>
              
              <!-- Setor -->
              <div>
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Setor</label>
                <select v-model="novoDocumento.setor" @change="carregarPastasDoSetor" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" required>
                  <option value="" disabled>Selecione o Setor</option>
                  <option v-for="setor in setores" :key="setor.id" :value="setor.id">
                    {{ setor.nome }}
                  </option>
                </select>
              </div>
              
              <!-- Pasta -->
              <div>
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Pasta</label>
                <select v-model="novoDocumento.pasta" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                  <option value="">Raiz</option>
                  <option v-for="pasta in pastasDoSetorSelecionado" :key="pasta.id" :value="pasta.id">
                    {{ pasta.nome }}
                  </option>
                </select>
              </div>
              
              <!-- Grupo -->
              <div>
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Grupo</label>
                <select v-model="novoDocumento.grupo" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" required>
                  <option value="" disabled>Selecione o Grupo</option>
                  <option v-for="grupo in grupos" :key="grupo.id" :value="grupo.id">
                    {{ grupo.nome }}
                  </option>
                </select>
              </div>
              
              <!-- Tipo de Documento -->
              <div>
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Tipo</label>
                <select v-model="novoDocumento.tipo" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" required>
                  <option value="" disabled>Selecione o Tipo</option>
                  <option value="contrato">Contrato</option>
                  <option value="nota_fiscal">Termo</option>
                  <option value="laudo">Declaração</option>
                  <option value="manual">Manual</option>
                </select>
              </div>

              <!-- Upload de Arquivo -->
              <div class="col-span-full">
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Arquivo</label>
                <div class="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 text-center" :class="{ 'border-blue-500 bg-blue-50 dark:bg-blue-900/20': isDragging }" @dragover.prevent="isDragging = true" @dragleave.prevent="isDragging = false" @drop.prevent="handleFileDrop">
                  <input type="file" id="file-upload" ref="fileInput" @change="handleFileChange" accept=".pdf,.jpg,.jpeg,.png" class="hidden">
                  
                  <div v-if="!selectedFile">
                    <FileUpIcon class="h-12 w-12 mx-auto text-gray-400 dark:text-gray-500 mb-2" />
                    <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Arraste e solte seu arquivo aqui ou</p>
                    <button type="button" @click="$refs.fileInput.click()" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700 text-sm">
                      Selecionar Arquivo
                    </button>
                    <p class="text-xs text-gray-500 dark:text-gray-400 mt-2">PDF, JPG ou PNG (máx. 10MB)</p>
                  </div>
                  
                  <div v-else class="flex items-center justify-between">
                    <div class="flex items-center">
                      <FileIcon class="h-8 w-8 text-blue-500 dark:text-blue-400 mr-2" />
                      <div class="text-left">
                        <p class="text-sm font-medium text-gray-700 dark:text-gray-300 truncate max-w-xs">{{ selectedFile.name }}</p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">{{ formatarTamanho(selectedFile.size) }}</p>
                      </div>
                    </div>
                    <button type="button" @click="removerArquivo" class="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600">
                      <XIcon class="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>

              <!-- Descrição -->
              <div class="col-span-full">
                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Descrição (opcional)</label>
                <textarea v-model="novoDocumento.descricao" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" placeholder="Digite uma descrição para o documento" rows="3"></textarea>
              </div>
            </div>

            <!-- Modal Actions -->
            <div class="flex justify-end space-x-3 mt-6">
              <button type="button" @click="closeUploadModal" class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
                Cancelar
              </button>
              <button type="submit" :disabled="isSubmitting || !selectedFile" class="bg-blue-500 dark:bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                {{ isSubmitting ? 'Enviando...' : 'Enviar' }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Modal de Nova Pasta -->
      <div v-if="isNewFolderModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
        <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md shadow-xl">
          <h2 class="text-xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
            <FolderPlusIcon class="h-5 w-5 mr-2 text-blue-500" />
            Nova Pasta
          </h2>
          <form @submit.prevent="criarPasta" class="space-y-6">
            <div>
              <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Nome da Pasta</label>
              <input v-model="novaPasta.nome" type="text" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" placeholder="Digite o nome da pasta" required>
            </div>
            <div>
              <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Descrição (opcional)</label>
              <textarea v-model="novaPasta.descricao" class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" placeholder="Digite uma descrição para a pasta" rows="3"></textarea>
            </div>
            <div class="flex justify-end space-x-3 mt-6">
              <button type="button" @click="closeNewFolderModal" class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
                Cancelar
              </button>
              <button type="submit" :disabled="isSubmitting" class="bg-blue-500 dark:bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                {{ isSubmitting ? 'Criando...' : 'Criar' }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Modal de Visualização -->
      <div v-if="isViewModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
        <div class="bg-white dark:bg-gray-800 rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col shadow-xl">
          <div class="flex items-center justify-between p-4 border-b dark:border-gray-700">
            <h3 class="text-xl font-semibold text-gray-800 dark:text-white truncate max-w-[80%]">{{ documentoAtual?.nome }}</h3>
            <button @click="closeViewModal" class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700">
              <XIcon class="h-6 w-6" />
            </button>
          </div>
          <div class="flex-1 overflow-auto p-1 bg-gray-100 dark:bg-gray-900 min-h-[60vh]">
            <!-- Visualizador de PDF/Imagem -->
            <iframe v-if="documentoAtual?.url" :src="documentoAtual.url" class="w-full h-full min-h-[60vh]" frameborder="0"></iframe>
            <div v-else class="flex items-center justify-center h-full">
              <p class="text-gray-500 dark:text-gray-400">Não foi possível carregar o documento.</p>
            </div>
          </div>
          <div class="p-4 border-t dark:border-gray-700 flex justify-between">
            <div class="text-sm text-gray-600 dark:text-gray-400">
              <p>{{ formatarTexto('tipo', documentoAtual?.tipo) }} | {{ formatarData(documentoAtual?.data_upload) }}</p>
            </div>
            <button @click="downloadDocumento(documentoAtual)" class="bg-green-500 text-white px-4 py-1.5 rounded-lg text-sm hover:bg-green-600 transition-colors flex items-center">
              <DownloadIcon class="h-4 w-4 mr-1.5" />
              Download
            </button>
          </div>
        </div>
      </div>

      <!-- Modal de Confirmação de Exclusão -->
      <div v-if="isDeleteModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
        <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md shadow-xl">
          <div class="text-center mb-6">
            <AlertTriangleIcon class="h-12 w-12 text-red-500 mx-auto mb-4" />
            <h2 class="text-xl font-bold text-gray-800 dark:text-white mb-2">Confirmar Exclusão</h2>
            <p class="text-gray-600 dark:text-gray-400">
              Tem certeza que deseja excluir o documento <span class="font-semibold">{{ documentoAtual?.nome }}</span>?
              Esta ação não pode ser desfeita.
            </p>
          </div>
          <div class="flex justify-center space-x-4">
            <button @click="closeDeleteModal" class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
              Cancelar
            </button>
            <button @click="excluirDocumento" :disabled="isSubmitting" class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
              {{ isSubmitting ? 'Excluindo...' : 'Excluir' }}
            </button>
          </div>
        </div>
      </div>

      <!-- Modal de Confirmação de Exclusão de Pasta -->
      <div v-if="isDeleteFolderModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
        <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md shadow-xl">
          <div class="text-center mb-6">
            <AlertTriangleIcon class="h-12 w-12 text-red-500 mx-auto mb-4" />
            <h2 class="text-xl font-bold text-gray-800 dark:text-white mb-2">Confirmar Exclusão</h2>
            <p class="text-gray-600 dark:text-gray-400">
              Tem certeza que deseja excluir a pasta <span class="font-semibold">{{ pastaAtual?.nome }}</span>?
              Todos os documentos dentro desta pasta serão movidos para a raiz.
            </p>
          </div>
          <div class="flex justify-center space-x-4">
            <button @click="closeDeleteFolderModal" class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
              Cancelar
            </button>
            <button @click="excluirPasta" :disabled="isSubmitting" class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
              {{ isSubmitting ? 'Excluindo...' : 'Excluir' }}
            </button>
          </div>
        </div>
      </div>

      <!-- Modal Editar Membros -->
      <div v-if="isEditMembersModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md max-h-[90vh] flex flex-col">
          <div class="p-6 border-b border-gray-200 dark:border-gray-700">
            <h2 class="text-xl font-bold text-gray-800 dark:text-white">
              Adicionar membros à pasta: {{ pastaSelecionadaParaEdicao?.nome }}
            </h2>
          </div>
          
          <div class="p-6 flex-1 overflow-auto">
            <div class="mb-4">
              <input
                v-model="termoBuscaMembro"
                placeholder="Buscar usuários..."
                class="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div class="border rounded-lg divide-y dark:divide-gray-700 max-h-96 overflow-y-auto">
              <div 
                v-for="usuario in usuariosFiltrados" 
                :key="usuario.id"
                class="p-3 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center"
              >
                <input
                  type="checkbox"
                  :id="'usuario-'+usuario.id"
                  v-model="membrosSelecionados"
                  :value="usuario.id"
                  class="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                />
                <label 
                  :for="'usuario-'+usuario.id"
                  class="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300 cursor-pointer w-full"
                >
                  {{ usuario.nome }}
                </label>
              </div>
              
              <div 
                v-if="usuariosFiltrados.length === 0" 
                class="p-4 text-center text-gray-500 dark:text-gray-400"
              >
                Nenhum usuário encontrado
              </div>
            </div>
          </div>
          
          <div class="bg-gray-50 dark:bg-gray-700 px-6 py-4 flex justify-end gap-3 border-t border-gray-200 dark:border-gray-600">
            <button
              @click="isEditMembersModalOpen = false"
              class="px-4 py-2 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
            >
              Cancelar
            </button>
            <button
              @click="salvarMembrosPasta"
              class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseTemplate>
</template>

<script setup>
import BaseTemplate from './BaseTemplate.vue';
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import {
  EyeIcon,
  DownloadIcon,
  Trash2Icon,
  RefreshCw,
  FileIcon,
  FileX,
  FileUpIcon,
  XIcon,
  AlertTriangleIcon,
  FolderIcon,
  FolderOpenIcon,
  FolderPlusIcon,
  BuildingIcon,
  PlusCircleIcon,
  ChevronRightIcon,
  HomeIcon,
  Settings,
  SearchIcon
} from 'lucide-vue-next';

// Estados
const documentos = ref([]);
const setores = ref([]);
const pastasPorSetor = ref({});
const isLoading = ref(false);
const error = ref(null);
const isUploadModalOpen = ref(false);
const isViewModalOpen = ref(false);
const isDeleteModalOpen = ref(false);
const isNewFolderModalOpen = ref(false);
const isDeleteFolderModalOpen = ref(false);
const grupos = ref([]);
const isSubmitting = ref(false);
const selectedFile = ref(null);
const isDragging = ref(false);
const documentoAtual = ref(null);
const pastaAtual = ref(null);
const currentSetor = ref(null);
const currentPasta = ref(null);
const currentPath = ref([]);
const pastasDoSetorSelecionado = ref([]);
const isEditMembersModalOpen = ref(false);
const pastaSelecionadaParaEdicao = ref(null);
const usuariosDisponiveis = ref([]);
const membrosSelecionados = ref([]);
const termoBuscaMembro = ref('');
const termoBuscaPasta = ref('');
const showAccessDenied = ref(false);
const documentCountCache = ref({});

// Adicionar estado para armazenar o ID do usuário atual
const usuarioAtual = ref(null);

// Computed para filtrar pastas com base na busca
const pastasFiltradas = computed(() => {
  if (!currentSetor.value || !pastasPorSetor.value[currentSetor.value.id]) {
    return [];
  }
  
  const pastas = pastasPorSetor.value[currentSetor.value.id] || [];
  
  if (!termoBuscaPasta.value) {
    return pastas;
  }
  
  const termo = termoBuscaPasta.value.toLowerCase().trim();
  return pastas.filter(pasta => 
    pasta.nome.toLowerCase().includes(termo)
  );
});

async function abrirModalEditarMembros(pasta) {
  try {
    // Abre o modal imediatamente (melhor UX)
    isEditMembersModalOpen.value = true;
    pastaSelecionadaParaEdicao.value = pasta;
    termoBuscaMembro.value = '';
    
    // Carrega todos os usuários disponíveis
    const response = await axios.get('/api/v1/usuarios/');
    usuariosDisponiveis.value = response.data.map(u => ({
      ...u,
      nome: `${u.first_name || ''} ${u.last_name || ''}`.trim() || u.username
    }));
    
    // Inicializa os membros selecionados com os membros atuais da pasta
    membrosSelecionados.value = pasta.membros || [];
    
  } catch (err) {
    console.error('Erro ao carregar usuários:', err);
    alert('Erro ao carregar lista de usuários');
  }
}

async function salvarMembrosPasta() {
  if (!pastaSelecionadaParaEdicao.value) return;
  
  try {
    await axios.put(`/api/v1/pastas/${pastaSelecionadaParaEdicao.value.id}/membros`, {
      membros: membrosSelecionados.value
    });
    
    // Atualizar a lista de membros na pasta
    const pastaIndex = pastasPorSetor.value[pastaSelecionadaParaEdicao.value.setor_id].findIndex(
      p => p.id === pastaSelecionadaParaEdicao.value.id
    );
    
    if (pastaIndex !== -1) {
      pastasPorSetor.value[pastaSelecionadaParaEdicao.value.setor_id][pastaIndex].membros = [...membrosSelecionados.value];
    }
    
    isEditMembersModalOpen.value = false;
    alert('Membros atualizados com sucesso!');
    
  } catch (err) {
    console.error('Erro ao salvar membros:', err);
    alert('Erro ao salvar associação de membros');
  }
}

function userTemPermissaoCriar() {
  return sessionStorage.permissoes?.includes('superadmin') ||
         sessionStorage.permissoes?.includes('desenvolvedor') ||
         sessionStorage.permissoes?.includes('responsavel_setor');
}

function usuarioPodeAcessarPasta(pasta) {
  // Se o usuário é superadmin, desenvolvedor ou responsável pelo setor, tem acesso a todas as pastas
  if (
    sessionStorage.permissoes?.includes('superadmin') ||
    sessionStorage.permissoes?.includes('desenvolvedor') ||
    sessionStorage.permissoes?.includes('responsavel_setor')
  ) {
    return true;
  }
  
  // Verificar se o usuário está na lista de membros da pasta
  return pasta.membros.includes(usuarioAtual.value);
}

// Modificar a função para carregar permissões para também obter o ID do usuário
async function carregarPermissoes() {
  try {
    const response = await axios.get('/api/v1/permissoes/');
    const grupos = response.data.groups || [];
    sessionStorage.setItem('permissoes', JSON.stringify(grupos));
    
    // Armazenar o ID do usuário atual
    usuarioAtual.value = response.data.user_id;
  } catch (error) {
    console.error('Erro ao carregar permissões:', error);
  }
}

// Novo documento
const novoDocumento = ref({
  nome: '',
  tipo: '',
  grupo: '',
  setor: '',
  pasta: '',
  data_documento: '',
  descricao: ''
});

// Nova pasta
const novaPasta = ref({
  nome: '',
  descricao: '',
  setor_id: null
});

// Configuração do Axios
axios.defaults.headers.common['X-API-KEY'] = sessionStorage.api_token;

// Funções de navegação
function navigateToRoot() {
  currentSetor.value = null;
  currentPasta.value = null;
  currentPath.value = [];
  termoBuscaPasta.value = '';
  showAccessDenied.value = false;
}

function navigateTo(item) {
  if (item.type === 'setor') {
    selectSetor(item);
  } else if (item.type === 'pasta') {
    // Encontrar a pasta pelo ID
    const pasta = pastasPorSetor.value[currentSetor.value.id]?.find(p => p.id === item.id);
    if (pasta) {
      selectPasta(pasta);
    }
  }
}

function selectSetor(setor) {
  currentSetor.value = setor;
  currentPasta.value = null;
  currentPath.value = [{
    id: setor.id,
    nome: setor.nome,
    type: 'setor'
  }];
  termoBuscaPasta.value = '';
  showAccessDenied.value = false;
}

function selectPasta(pasta) {
  // Verificar se o usuário tem acesso à pasta
  if (!usuarioPodeAcessarPasta(pasta)) {
    showAccessDenied.value = true;
    return;
  }
  
  showAccessDenied.value = false;
  currentPasta.value = pasta;
  // Atualiza o caminho mantendo o setor e adicionando a pasta
  currentPath.value = [
    currentPath.value[0], // Mantém o setor
    {
      id: pasta.id,
      nome: pasta.nome,
      type: 'pasta'
    }
  ];
  
  // Recarregar os documentos da pasta selecionada
  fetchDocumentos();
}

// Funções de busca de dados
async function fetchDocumentos() {
  isLoading.value = true;
  try {
    if (currentPasta.value) {
      // Se estiver visualizando uma pasta específica, usar o endpoint específico
      const response = await axios.get(`/api/v1/documentos/pasta/${currentPasta.value.id}`);
      documentos.value = response.data.map(doc => ({
        ...doc,
        url: doc.url || ''
      }));
      
      // Atualizar a contagem de documentos no cache
      documentCountCache.value[currentPasta.value.id] = documentos.value.length;
    } else {
      // Caso contrário, carregar todos os documentos acessíveis
      const response = await axios.get('/api/v1/documentos/');
      documentos.value = response.data.map(doc => ({
        ...doc,
        url: doc.url || ''
      }));
      
      // Atualizar contagens de documentos por pasta
      const contagens = {};
      documentos.value.forEach(doc => {
        if (doc.pasta_id) {
          contagens[doc.pasta_id] = (contagens[doc.pasta_id] || 0) + 1;
        }
      });
      
      // Atualizar o cache de contagem
      documentCountCache.value = { ...documentCountCache.value, ...contagens };
    }
  } catch (err) {
    error.value = 'Erro ao carregar documentos';
    console.error(err);
  } finally {
    isLoading.value = false;
  }
}

async function fetchGrupos() {
  try {
    const response = await axios.get('/api/v1/documentos/meus-grupos/');
    grupos.value = response.data;
  } catch (error) {
    console.error('Erro ao carregar grupos do usuário:', error);
  }
}

async function fetchSetores() {
  try {
    const response = await axios.get('/api/v1/setores/');
    setores.value = response.data;
    
    // Inicializa o objeto de pastas por setor
    setores.value.forEach(setor => {
      if (!pastasPorSetor.value[setor.id]) {
        pastasPorSetor.value[setor.id] = [];
      }
    });
    
    // Carrega as pastas para cada setor
    await fetchPastas();
  } catch (error) {
    console.error('Erro ao carregar setores:', error);
  }
}

async function fetchPastas() {
  try {
    for (const setor of setores.value) {
      const response = await axios.get('/api/v1/pastas/', {
        params: { setor_id: setor.id }
      });

      // Obter contagens de documentos para cada pasta
      const pastas = response.data.map(pasta => ({
        ...pasta,
        documentCount: documentCountCache.value[pasta.id] || 0
      }));
      
      pastasPorSetor.value[setor.id] = pastas;
    }
    
    // Após carregar todas as pastas, buscar contagens de documentos
    await fetchDocumentCounts();
  } catch (error) {
    console.error('Erro ao carregar pastas:', error);
  }
}

// Nova função para buscar contagens de documentos de forma eficiente
async function fetchDocumentCounts() {
  try {
    // Buscar todos os documentos para contar
    const response = await axios.get('/api/v1/documentos/');
    const docs = response.data;
    
    // Calcular contagens por pasta
    const contagens = {};
    docs.forEach(doc => {
      if (doc.pasta_id) {
        contagens[doc.pasta_id] = (contagens[doc.pasta_id] || 0) + 1;
      }
    });
    
    // Atualizar o cache de contagem
    documentCountCache.value = contagens;
    
    // Atualizar as contagens nas pastas
    Object.keys(pastasPorSetor.value).forEach(setorId => {
      pastasPorSetor.value[setorId] = pastasPorSetor.value[setorId].map(pasta => ({
        ...pasta,
        documentCount: contagens[pasta.id] || 0
      }));
    });
  } catch (error) {
    console.error('Erro ao buscar contagens de documentos:', error);
  }
}

const usuariosFiltrados = computed(() => {
  const termo = termoBuscaMembro.value.toLowerCase();
  return usuariosDisponiveis.value.filter(u => 
    (u.nome ?? '').toLowerCase().includes(termo)
  );
});

// Funções de formatação
function formatarData(data) {
  if (!data) return '';
  return new Date(data).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
}

function formatarTamanho(bytes) {
  if (!bytes) return '0 B';
  const units = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
}


function formatarTexto(chave, valor) {
  if (!valor) return '';
  
  const map = {
    tipo: {
      contrato: 'Contrato',
      nota_fiscal: 'Nota Fiscal',
      laudo: 'Laudo Técnico',
      manual: 'Manual',
      certificado: 'Certificado'
    }
  };

  return map[chave]?.[valor] || valor.charAt(0).toUpperCase() + valor.slice(1).replace('_', ' ');
}

// Funções de contagem - Otimizada para usar o cache

// Funções de manipulação de arquivos
function handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 10 * 1024 * 1024) { // 10MB
      alert('O arquivo é muito grande. O tamanho máximo permitido é 10MB.');
      return;
    }
    
    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      alert('Formato de arquivo não suportado. Por favor, envie um arquivo PDF, JPG ou PNG.');
      return;
    }
    
    selectedFile.value = file;
  }
}

function handleFileDrop(event) {
  isDragging.value = false;
  const file = event.dataTransfer.files[0];
  if (file) {
    if (file.size > 10 * 1024 * 1024) { // 10MB
      alert('O arquivo é muito grande. O tamanho máximo permitido é 10MB.');
      return;
    }
    
    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      alert('Formato de arquivo não suportado. Por favor, envie um arquivo PDF, JPG ou PNG.');
      return;
    }
    
    selectedFile.value = file;
  }
}

function removerArquivo() {
  selectedFile.value = null;
  if (this.$refs.fileInput) {
    this.$refs.fileInput.value = '';
  }
}

// Funções de modal
function openUploadModal() {
  isUploadModalOpen.value = true;
  resetNovoDocumento();
  
  // Pré-seleciona o setor e pasta atuais se estiverem navegando
  if (currentSetor.value) {
    novoDocumento.value.setor = currentSetor.value.id;
    carregarPastasDoSetor();
    
    if (currentPasta.value) {
      novoDocumento.value.pasta = currentPasta.value.id;
    }
  }
}

// Nova função para abrir o modal de upload diretamente para uma pasta específica
function openUploadModalForFolder(pasta) {
  isUploadModalOpen.value = true;
  resetNovoDocumento();
  
  // Pré-seleciona o setor e a pasta específica
  novoDocumento.value.setor = pasta.setor_id;
  carregarPastasDoSetor();
  novoDocumento.value.pasta = pasta.id;
}

function closeUploadModal() {
  isUploadModalOpen.value = false;
  selectedFile.value = null;
}

function openNewFolderModal(setorId) {
  isNewFolderModalOpen.value = true;
  novaPasta.value = { nome: '', descricao: '', setor_id: setorId };
}

function closeNewFolderModal() {
  isNewFolderModalOpen.value = false;
}

function visualizarDocumento(documento) {
  documentoAtual.value = {
    ...documento,
    url: documento.url // Certifique-se que esta propriedade está sendo passada
  };
  isViewModalOpen.value = true;
}

function closeViewModal() {
  isViewModalOpen.value = false;
  documentoAtual.value = null;
}

function confirmarExclusao(documento) {
  documentoAtual.value = documento;
  isDeleteModalOpen.value = true;
}

function closeDeleteModal() {
  isDeleteModalOpen.value = false;
  documentoAtual.value = null;
}

function confirmarExclusaoPasta(pasta) {
  pastaAtual.value = pasta;
  isDeleteFolderModalOpen.value = true;
}

function closeDeleteFolderModal() {
  isDeleteFolderModalOpen.value = false;
  pastaAtual.value = null;
}

// Funções de ação
async function uploadDocumento() {
  if (isSubmitting.value || !selectedFile.value) return;
  
  isSubmitting.value = true;
  
  try {
    // Validação básica
    if (!novoDocumento.value.nome || !novoDocumento.value.tipo || !novoDocumento.value.grupo || !novoDocumento.value.setor) {
      alert("Preencha todos os campos obrigatórios.");
      isSubmitting.value = false;
      return;
    }

    // Criar FormData para envio do arquivo
    const formData = new FormData();
    formData.append('arquivo', selectedFile.value);
    formData.append('nome', novoDocumento.value.nome);
    formData.append('tipo', novoDocumento.value.tipo);
    formData.append('grupo_id', parseInt(novoDocumento.value.grupo));
    formData.append('setor_id', parseInt(novoDocumento.value.setor));
    
    if (novoDocumento.value.pasta) {
      formData.append('pasta_id', parseInt(novoDocumento.value.pasta));
    }
    
    if (novoDocumento.value.descricao) {
      formData.append('descricao', novoDocumento.value.descricao);
    }
    
    // Envio dos dados
    const response = await axios.post('/api/v1/documentos/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    // Adiciona o novo documento à lista
    documentos.value.push(response.data);
    
    // Atualizar a contagem de documentos
    if (novoDocumento.value.pasta) {
      const pastaId = parseInt(novoDocumento.value.pasta);
      documentCountCache.value[pastaId] = (documentCountCache.value[pastaId] || 0) + 1;
      
      // Atualizar a contagem na pasta
      if (currentSetor.value) {
        const pastaIndex = pastasPorSetor.value[currentSetor.value.id].findIndex(p => p.id === pastaId);
        if (pastaIndex !== -1) {
          pastasPorSetor.value[currentSetor.value.id][pastaIndex].documentCount = documentCountCache.value[pastaId];
        }
      }
    }
    
    // Fecha o modal
    closeUploadModal();
    
    alert('Documento enviado com sucesso!');
  } catch (error) {
    alert('Erro ao enviar documento. Verifique os dados e tente novamente.');
    console.error('Erro ao enviar documento:', error);
  } finally {
    isSubmitting.value = false;
  }
}

async function criarPasta() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  try {
    if (!novaPasta.value.nome || !novaPasta.value.setor_id) {
      alert("O nome da pasta e o setor são obrigatórios.");
      isSubmitting.value = false;
      return;
    }

    const formData = new FormData();
    formData.append("nome", novaPasta.value.nome);
    formData.append("setor_id", novaPasta.value.setor_id);

    const response = await axios.post('/api/v1/pastas/', formData);
    
    // Adicionar a contagem de documentos à nova pasta
    const novaPastaCompleta = {
      ...response.data,
      documentCount: 0
    };
    
    // Atualiza o frontend com a nova pasta
    if (!pastasPorSetor.value[novaPasta.value.setor_id]) {
      pastasPorSetor.value[novaPasta.value.setor_id] = [];
    }
    pastasPorSetor.value[novaPasta.value.setor_id].push(novaPastaCompleta);

    closeNewFolderModal();
    alert('Pasta criada com sucesso!');
  } catch (error) {
    alert('Erro ao criar pasta.');
    console.error('Erro ao criar pasta:', error);
  } finally {
    isSubmitting.value = false;
  }
}

// Função de download modificada para preservar o formato original do arquivo
async function downloadDocumento(documento) {
  if (!documento) return;
  
  try {
    // Usar responseType: 'blob' para manter o formato original do arquivo
    const response = await axios.get(`/api/v1/documentos/${documento.id}/download`, {
      responseType: 'blob'
    });
    
    // Determinar o tipo de arquivo com base no nome ou no Content-Type
    let fileName = documento.nome;
    const contentType = response.headers['content-type'];
    
    // Se o nome do arquivo não tiver extensão, tentar adicionar com base no Content-Type
    if (!fileName.includes('.')) {
      if (contentType === 'application/pdf') {
        fileName += '.pdf';
      } else if (contentType === 'image/jpeg') {
        fileName += '.jpg';
      } else if (contentType === 'image/png') {
        fileName += '.png';
      }
    }
    
    // Criar URL para download
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Liberar memória
  } catch (error) {
    alert('Erro ao baixar o documento.');
    console.error('Erro ao baixar documento:', error);
  }
}

async function excluirDocumento() {
  if (!documentoAtual.value || isSubmitting.value) return;
  
  isSubmitting.value = true;
  
  try {
    await axios.delete(`/api/v1/documentos/${documentoAtual.value.id}`);
    
    // Remove o documento da lista
    documentos.value = documentos.value.filter(doc => doc.id !== documentoAtual.value.id);
    
    // Atualizar a contagem de documentos
    if (documentoAtual.value.pasta_id) {
      const pastaId = documentoAtual.value.pasta_id;
      documentCountCache.value[pastaId] = Math.max(0, (documentCountCache.value[pastaId] || 0) - 1);
      
      // Atualizar a contagem na pasta
      if (currentSetor.value) {
        const pastaIndex = pastasPorSetor.value[currentSetor.value.id].findIndex(p => p.id === pastaId);
        if (pastaIndex !== -1) {
          pastasPorSetor.value[currentSetor.value.id][pastaIndex].documentCount = documentCountCache.value[pastaId];
        }
      }
    }
    
    // Fecha o modal
    closeDeleteModal();
    
    alert('Documento excluído com sucesso!');
  } catch (error) {
    alert('Erro ao excluir documento.');
    console.error('Erro ao excluir documento:', error);
  } finally {
    isSubmitting.value = false;
  }
}

async function excluirPasta() {
  if (!pastaAtual.value || isSubmitting.value) return;
  
  isSubmitting.value = true;
  
  try {
    await axios.delete(`/api/v1/pastas/${pastaAtual.value.id}`);
    
    // Remove a pasta do setor correspondente
    if (pastasPorSetor.value[pastaAtual.value.setor_id]) {
      pastasPorSetor.value[pastaAtual.value.setor_id] = pastasPorSetor.value[pastaAtual.value.setor_id].filter(
        pasta => pasta.id !== pastaAtual.value.id
      );
    }
    
    // Remover a contagem de documentos do cache
    delete documentCountCache.value[pastaAtual.value.id];
    
    // Atualiza os documentos que estavam na pasta (agora estão na raiz)
    await fetchDocumentos();
    
    // Fecha o modal
    closeDeleteFolderModal();
    
    // Se estiver visualizando a pasta que foi excluída, volta para o setor
    if (currentPasta.value && currentPasta.value.id === pastaAtual.value.id) {
      currentPasta.value = null;
      currentPath.value = currentPath.value.slice(0, 1); // Mantém apenas o setor no caminho
    }
    
    alert('Pasta excluída com sucesso!');
  } catch (error) {
    alert('Erro ao excluir pasta.');
    console.error('Erro ao excluir pasta:', error);
  } finally {
    isSubmitting.value = false;
  }
}

// Funções auxiliares
function resetNovoDocumento() {
  novoDocumento.value = {
    nome: '',
    tipo: '',
    grupo: '',
    setor: '',
    pasta: '',
    data_documento: new Date().toISOString().split('T')[0],
    descricao: ''
  };
  selectedFile.value = null;
  pastasDoSetorSelecionado.value = [];
}

function carregarPastasDoSetor() {
  if (!novoDocumento.value.setor) {
    pastasDoSetorSelecionado.value = [];
    return;
  }
  
  pastasDoSetorSelecionado.value = pastasPorSetor.value[novoDocumento.value.setor] || [];
}

// Watchers
watch(() => novoDocumento.value.setor, (newValue) => {
  if (newValue) {
    carregarPastasDoSetor();
  }
});

// Lifecycle hooks
onMounted(async () => {
  await carregarPermissoes();
  await Promise.all([
    fetchDocumentos(),
    fetchGrupos(),
    fetchSetores()
  ]);
});
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Garante que o modal fique acima de outros elementos */
.modal-overlay {
  z-index: 9999;
}

/* Transição para o botão de novo documento nas pastas */
.group:hover .opacity-0 {
  transition: opacity 0.2s ease-in-out;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>