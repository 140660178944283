<template>
    <BaseTemplate :page-title="''" :app-name="'CCDTI'">
      <template #content>
        <div class="p-4 space-y-6">
          <!-- Welcome Section -->
          <div class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6">
            <div class="flex items-center space-x-4">
              <div class="p-3 bg-blue-100 dark:bg-blue-900 rounded-full">
                <Home class="h-6 w-6 text-blue-600 dark:text-blue-300" />
              </div>
              <div>
                <h2 class="text-2xl font-bold text-gray-800 dark:text-white">Bem-vindo(a), {{ firstName }}!</h2>
                <p class="text-gray-500 dark:text-gray-400">{{ getCurrentDateMessage() }}</p>
              </div>
            </div>
          </div>
  
          <!-- Modules Grid -->
          <div class="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div v-for="(module, index) in modules" :key="index" 
                 @click="navigateTo(module.route)"
                 class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:translate-y-[-5px] cursor-pointer">
              <div class="flex flex-col items-center text-center space-y-4">
                <div class="p-4 rounded-full" :class="module.bgColor">
                  <component :is="module.icon" class="h-8 w-8" :class="module.iconColor" />
                </div>
                <div>
                  <h3 class="text-lg font-semibold text-gray-800 dark:text-white">{{ module.title }}</h3>
                  <p class="text-sm text-gray-500 dark:text-gray-400 mt-1">{{ module.description }}</p>
                </div>
                <div class="mt-2 text-blue-600 dark:text-blue-400 text-sm font-medium flex items-center">
                  <span>Acessar</span>
                  <ArrowRight class="h-4 w-4 ml-1" />
                </div>
              </div>
            </div>
          </div>
  
          <!-- Modules Carousel for Mobile -->
    <!-- Modules Carousel for Mobile -->
    <div class="md:hidden px-4 py-4">
    <div class="grid grid-cols-2 gap-4">
        <div v-for="(module, index) in modules" :key="index"
            @click="navigateTo(module.route)"
            class="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-4 flex flex-col items-center justify-between h-36 transition-transform hover:scale-105 cursor-pointer">
        <div class="p-3 rounded-full mb-2" :class="module.bgColor">
            <component :is="module.icon" class="h-6 w-6" :class="module.iconColor" />
        </div>
        <div class="text-center">
            <h3 class="text-sm font-semibold text-gray-800 dark:text-white leading-tight">{{ module.title }}</h3>
            <p class="text-xs text-gray-500 dark:text-gray-400 mt-1">{{ module.description }}</p>
        </div>
        </div>
    </div>
    </div>

          <!-- Quick Stats -->
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div v-for="(stat, index) in stats" :key="index" 
                 class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 border-l-4" 
                 :class="stat.borderColor">
              <div class="flex justify-between items-center">
                <div>
                  <p class="text-sm font-medium text-gray-500 dark:text-gray-400">{{ stat.title }}</p>
                  <h3 class="text-2xl font-bold text-gray-800 dark:text-white mt-1">{{ stat.value }}</h3>
                </div>
                <div class="p-3 rounded-full" :class="stat.bgColor">
                  <component :is="stat.icon" class="h-6 w-6" :class="stat.iconColor" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseTemplate>


  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { 
    Home, 
    Server, 
    Activity, 
    FileText,
    Settings,
    Database,
    Monitor,
    FolderIcon,
    Cloud,
    ArrowRight,

    MessageCircleQuestion
  } from 'lucide-vue-next';
  import BaseTemplate from './BaseTemplate.vue'; // Importando o template base
  
  import 'swiper/swiper-bundle.css';
  
  const router = useRouter();
  
  // Dados do usuário
  const firstName = ref(sessionStorage.getItem('firstname') || 'Usuário');
  
  // Função para obter mensagem baseada na hora do dia
  const getCurrentDateMessage = () => {
    const now = new Date();
    const hour = now.getHours();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateStr = now.toLocaleDateString('pt-BR', options);
    
    let greeting = '';
    if (hour < 12) greeting = 'Bom dia';
    else if (hour < 18) greeting = 'Boa tarde';
    else greeting = 'Boa noite';
    
    return `${greeting}! Hoje é ${dateStr}`;
  };
  
  const userGroups = JSON.parse(sessionStorage.getItem('user_groups') || '[]');
  
  const allModules = [
    {
      title: 'Eventos',
      description: 'Gerenciar eventos e ocorrências',
      icon: FileText,
      route: '/eventos',
      bgColor: 'bg-blue-100 dark:bg-blue-900',
      iconColor: 'text-blue-600 dark:text-blue-300',
      visibleFor: ['superadmin', 'engenhariaclinica'],
    },
    {
      title: 'Feed',
      description: 'Visualizar Postagens',
      icon: Cloud,
      route: '/feed',
      bgColor: 'bg-blue-100 dark:bg-blue-900',
      iconColor: 'text-blue-600 dark:text-blue-300',
      visibleFor: ['admin', 'superadmin', 'desenvolvedor', 'manager', 'ti', 'direcao', 'engenhariaclinica', 'user'],
    },
    {
      title: 'Dashboard Equipamentos',
      description: 'Monitorar equipamentos',
      icon: Monitor,
      route: '/dashboardequipamentos',
      bgColor: 'bg-green-100 dark:bg-green-900',
      iconColor: 'text-green-600 dark:text-green-300',
      visibleFor: ['admin', 'superadmin', 'desenvolvedor', 'manager', 'ti', 'direcao', 'engenhariaclinica', 'user'],
    },
    {
      title: 'Instâncias',
      description: 'Gerenciar instâncias do sistema',
      icon: Activity,
      route: '/dashboardinstancias',
      bgColor: 'bg-purple-100 dark:bg-purple-900',
      iconColor: 'text-purple-600 dark:text-purple-300',
      visibleFor: ['superadmin', 'ti'],
    },
    {
      title: 'Servidores',
      description: 'Monitorar servidores',
      icon: Server,
      route: '/dashboardunidades',
      bgColor: 'bg-yellow-100 dark:bg-yellow-900',
      iconColor: 'text-yellow-600 dark:text-yellow-300',
      visibleFor: ['superadmin', 'ti'],
    },
    {
      title: 'Ativos',
      description: 'Gerenciar ativos de TI',
      icon: Database,
      route: '/ativos',
      bgColor: 'bg-red-100 dark:bg-red-900',
      iconColor: 'text-red-600 dark:text-red-300',
      visibleFor: ['superadmin', 'ti'],
    },
    {
      title: 'Pendências',
      description: 'Visualizar tarefas pendentes',
      icon: FileText,
      route: '/pendencias',
      bgColor: 'bg-indigo-100 dark:bg-indigo-900',
      iconColor: 'text-indigo-600 dark:text-indigo-300',
      visibleFor: ['direcao', 'superadmin', 'coordenacao'],
    },
    {
      title: 'Documentos',
      description: 'Visualizar meus documentos',
      icon: FolderIcon,
      route: '/documents',
      bgColor: 'bg-yellow-100 dark:bg-yellow-900',
      iconColor: 'text-yellow-600 dark:text-yellow-300',
      visibleFor: ['ti','direcao', 'superadmin', 'coordenacao'],
    },
    {
      title: 'Ajuda',
      description: 'Suporte e documentação',
      icon: MessageCircleQuestion,
      route: '/help',
      bgColor: 'bg-teal-100 dark:bg-teal-900',
      iconColor: 'text-teal-600 dark:text-teal-300',
      visibleFor: ['superadmin', 'ti', 'desenvolvedor', 'user', 'manager', 'direcao', 'admin'],
    },
    {
      title: 'Configurações',
      description: 'Configurações do Sistema',
      icon: Settings,
      route: '/config',
      bgColor: 'bg-teal-100 dark:bg-teal-900',
      iconColor: 'text-teal-600 dark:text-teal-300',
      visibleFor: ['superadmin', 'ti', 'direcao', 'engenhariaclinica'],
    },

  ];
  
  const modules = computed(() => 
    allModules.filter(module => module.visibleFor.some(group => userGroups.includes(group)))
  );
  
  // Função para navegação
  const navigateTo = (route) => {
    router.push(route);
  };
  
  onMounted(() => {
    // Qualquer lógica de inicialização pode ser adicionada aqui
  });
  </script>
  
  <style scoped>
  /* Transições suaves para hover */
  .hover\:translate-y-\[-5px\]:hover {
    transform: translateY(-5px);
  }
  </style>