<template>
  <BaseTemplate app-name="Tópicos" page-title="">
    <template #content>
      <div class="container mx-auto px-4 py-4 md:px-6 lg:px-1">
        <!-- Loading state -->
        <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-screen">
          <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
        </div>
        

        <!-- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA -->
        <div v-else>
          
          <!-- Header -->
          <div class="flex flex-col gap-5 mb-8">
            <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div class="flex items-center gap-3">
                <h2 class="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-white tracking-tight">
                  Tópicos
                </h2>
                <span
                  class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-semibold px-3 py-1 rounded-full flex items-center justify-center min-w-[72px]">
                  {{ chamadosFiltrados.length }} itens
                </span>
              </div>

            <!-- Modal de Auditoria -->
            <transition name="modal">
            <div
              v-if="modalAuditoria"
              class="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50"
              @click.self="fecharModalAuditoria"
            >
              <div class="bg-white dark:bg-gray-900 rounded-2xl shadow-2xl w-full max-w-2xl p-6 animate-fade-in border border-gray-200 dark:border-gray-700">
                <!-- Cabeçalho -->
                <div class="flex justify-between items-center mb-6 border-b border-gray-200 dark:border-gray-700 pb-3">
                  <h3 class="text-2xl font-semibold text-gray-800 dark:text-white">Auditoria de Tópicos</h3>
                  <button @click="fecharModalAuditoria" class="text-gray-500 dark:text-gray-300 hover:text-red-500 transition">
                    <X class="w-5 h-5" />
                  </button>
                </div>

                <!-- Conteúdo -->
                <div class="space-y-4 max-h-[60vh] overflow-y-auto pr-1">
                  <div v-if="auditorias.length === 0" class="text-center text-gray-500 dark:text-gray-400">
                    Nenhum registro de auditoria encontrado.
                  </div>

                  <div
                    v-else
                    v-for="audit in auditorias"
                    :key="audit.id"
                    class="rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 p-4 shadow-sm transition hover:shadow-md"
                  >
                    <div class="flex justify-between items-center mb-2">
                      <div class="text-sm font-semibold text-gray-800 dark:text-white">
                        {{ audit.action.charAt(0).toUpperCase() + audit.action.slice(1) }}
                      </div>
                      <div class="text-xs text-gray-500 dark:text-gray-400">
                        {{ formatarData(audit.timestamp) }}
                      </div>
                    </div>

                    <p class="text-sm text-gray-700 dark:text-gray-300 mb-1">
                      <span class="font-medium">Tópico:</span> {{ audit.titulo || 'N/A' }}
                    </p>

                    <p class="text-sm text-gray-700 dark:text-gray-300 mb-1">
                      <span class="font-medium">Realizado por:</span> {{ audit.performed_by }}
                    </p>

                    <p v-if="audit.details" class="text-sm text-gray-600 dark:text-gray-400 italic mt-1 break-words whitespace-pre-line">
  {{ audit.details }}
</p>

                  </div>
                </div>

                <!-- Rodapé -->
                <div class="flex justify-end pt-4 border-t border-gray-200 dark:border-gray-700 mt-4">
                  <button
                    @click="fecharModalAuditoria"
                    class="px-5 py-2 bg-gray-700 hover:bg-gray-800 text-white text-sm font-medium rounded-lg transition-all"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </transition>


              <div class="flex flex-wrap sm:flex-nowrap gap-3 w-full sm:w-auto">
                <!-- Search field -->
                <div class="relative w-full sm:w-72">
                  <input v-model="filtroTitulo" type="text" placeholder="Buscar por título..."
                    class="pl-10 pr-4 py-2.5 w-full border rounded-lg text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none" />
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search class="w-5 h-5 text-gray-400" />
                  </div>
                </div>
                <button
                v-if="isDesenvolvedor"
                @click="abrirModalAuditoria"
                class="px-4 py-2 bg-gray-700 text-white rounded-md">
                Auditoria
              </button>

                <!-- Filter button -->
                <button @click="abrirModalFiltro"
                  class="relative flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2.5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-all duration-200 focus:ring-2 focus:ring-gray-400 focus:outline-none shadow-md w-full sm:w-auto">
                  <Filter class="w-5 h-5" />
                  <span class="font-medium">Filtros</span>
                  <span v-if="totalFiltros > 0"
                    class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center animate-pulse">
                    {{ totalFiltros }}
                  </span>
                </button>

                <!-- New ticket button -->
                <button @click="abrirModalCriarChamado"
                  class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 focus:ring-2 focus:ring-blue-300 focus:outline-none flex items-center justify-center gap-2 font-medium shadow-md w-full sm:w-auto">
                  <PlusCircle class="w-5 h-5" />
                  <span>Novo Tópico</span>
                </button>
              </div>
            </div>

            <!-- Additional filters -->
            <div class="flex flex-wrap items-center gap-6 mt-1">
              <label class="flex items-center gap-2 cursor-pointer group">
                <div class="relative">
                  <input type="checkbox" v-model="mostrarResolvidos" class="peer sr-only" />
                  <div
                    class="w-5 h-5 border-2 border-gray-300 dark:border-gray-600 rounded-md peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all duration-200">
                  </div>
                  <div
                    class="absolute top-[2px] left-[2px] w-4 h-4 text-white opacity-0 peer-checked:opacity-100 pointer-events-none transition-opacity duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                </div>
                <span
                  class="text-sm text-gray-700 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">Mostrar
                  Resolvidos</span>
              </label>
            </div>
          </div>

          <!-- Success message toast -->
          <div v-if="mensagemSucesso"
            class="fixed top-5 right-5 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in z-50">
            {{ mensagemSucesso }}
          </div>

          <!-- No tickets state -->
          <div v-if="chamados.length === 0"
            class="bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 p-8 text-center">
            <div class="max-w-md mx-auto">
              <TicketIcon class="h-16 w-16 text-blue-500 dark:text-blue-400 mx-auto mb-4" />
              <h3 class="text-xl font-semibold text-gray-800 dark:text-white mb-2">Nenhum tópico
                cadastrado</h3>
              <p class="text-gray-600 dark:text-gray-300 mb-6">
                Crie tópicos para reportar bugs ou sugerir melhorias nos sistemas.
              </p>
              <button @click="abrirModalCriarChamado"
                class="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all duration-200 focus:ring-2 focus:ring-blue-300 focus:outline-none flex items-center justify-center gap-2 font-medium shadow-md mx-auto">
                <PlusCircle class="w-5 h-5" />
                <span>Criar Tópico</span>
              </button>
            </div>
          </div>

          <!-- No tickets after filtering -->
          <div v-else-if="chamadosFiltrados.length === 0"
            class="bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 p-8 text-center">
            <div class="max-w-md mx-auto">
              <TicketIcon class="h-16 w-16 text-blue-500 dark:text-blue-400 mx-auto mb-4" />
              <h3 class="text-xl font-semibold text-gray-800 dark:text-white mb-2">Nenhum tópico
                encontrado com os filtros aplicados.</h3>
              <p class="text-gray-600 dark:text-gray-300 mb-6">
                Se desejar visualizar chamados resolvidos, ative a opção <span class="text-blue-600">
                  "Mostrar Resolvidos". </span>
              </p>
            </div>
          </div>

          <!-- Tickets table (desktop) -->
          <div v-else class="hidden md:block rounded-lg overflow-hidden">
            <table class="w-full">
              <thead v-if="chamadosFiltrados && chamadosFiltrados.length">
                <tr class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                  <th @click="ordenarPor('id')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    ID <span v-if="colunaOrdenada === 'id'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('titulo')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Título <span v-if="colunaOrdenada === 'titulo'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('sistema')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Módulo <span v-if="colunaOrdenada === 'sistema'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('tipo')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Tipo <span v-if="colunaOrdenada === 'tipo'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('prioridade')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Prioridade <span v-if="colunaOrdenada === 'prioridade'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('analista')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Analista <span v-if="colunaOrdenada === 'analista'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th @click="ordenarPor('status')" class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">
                    Status <span v-if="colunaOrdenada === 'status'">{{ ordemAscendente ? '🠑' : '🠓' }}</span>
                  </th>
                  <th
                    class="px-6 py-4 text-sm font-semibold text-gray-200 dark:text-gray-300 text-center whitespace-nowrap">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-300 dark:divide-gray-600 border-t border-gray-300 dark:border-gray-600 dark:bg-gray-700 bg-white">
                <tr v-for="chamado in chamadosFiltrados" :key="chamado.id"
                  class="group hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200">
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ chamado.id }}</td>
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ chamado.titulo }}</td>
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{ chamado.sistema }}</td>
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">
                    <span :class="getTipoClass(chamado.tipo)" class="px-3 py-1 rounded-full text-xs font-medium">
                      {{ chamado.tipo === 'bug' ? 'Bug' : 'Melhoria' }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">
                    <span :class="getPrioridadeClass(chamado.prioridade)"
                      class="px-3 py-1 rounded-full text-xs font-medium">
                      {{ getPrioridadeLabel(chamado.prioridade) }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">
                    {{ chamado.analista || 'Não atribuído' }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span :class="getStatusClass(chamado.status)"
                      class="px-3 py-1 rounded-full text-sm text-xs font-medium whitespace-nowrap">
                      {{ getStatusLabel(chamado.status) }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-center">
                    <div class="flex justify-center gap-3">
                      <button @click="verDetalhes(chamado.id)"
                        class="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                        <Eye class="w-5 h-5" />
                      </button>
                      <button v-if="isDesenvolvedor" @click="editarChamado(chamado)"
                        class="text-amber-600 hover:text-amber-800 dark:text-amber-400 dark:hover:text-amber-300">
                        <Edit class="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Tickets cards (mobile) -->
          <div class="md:hidden space-y-6">
            <div v-for="chamado in chamadosFiltrados" :key="chamado.id"
              class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
              <div class="flex justify-between items-center mb-2">
                <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                  {{ chamado.titulo }}
                </h3>
                <span :class="getStatusClass(chamado.status)" class="px-4 py-1 rounded-full text-xs font-semibold">
                  {{ getStatusLabel(chamado.status) }}
                </span>
              </div>

              <div class="space-y-2">
                <p class="text-sm text-gray-700 dark:text-gray-300">
                  <span class="font-medium">ID:</span> {{ chamado.id }}
                </p>
                <p class="text-sm text-gray-700 dark:text-gray-300">
                  <span class="font-medium">Módulo:</span> {{ chamado.sistema }}
                </p>
                <p class="text-sm text-gray-700 dark:text-gray-300">
                  <span class="font-medium">Tipo:</span>
                  <span :class="getTipoClass(chamado.tipo)" class="px-2 py-0.5 rounded-full text-xs font-medium">
                    {{ chamado.tipo === 'bug' ? 'Bug' : 'Melhoria' }}
                  </span>
                </p>
                <p class="text-sm text-gray-700 dark:text-gray-300">
                  <span class="font-medium">Prioridade:</span>
                  <span :class="getPrioridadeClass(chamado.prioridade)"
                    class="px-2 py-0.5 rounded-full text-xs font-medium">
                    {{ getPrioridadeLabel(chamado.prioridade) }}
                  </span>
                </p>
                <p class="text-sm text-gray-700 dark:text-gray-300">
                  <span class="font-medium">Analista:</span> {{ chamado.analista || 'Não atribuído' }}
                </p>
                <p class="text-sm text-gray-700 dark:text-gray-300 line-clamp-2">
                  <span class="font-medium">Descrição:</span> {{ chamado.descricao }}
                </p>
              </div>

              <div class="mt-6 flex flex-col gap-2 sm:flex-row sm:gap-3">
                <button @click="verDetalhes(chamado.id)"
                  class="flex-1 bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 rounded-md shadow flex items-center justify-center gap-1">
                  <Eye class="w-4 h-4" />
                  <span>Ver</span>
                </button>
                <button v-if="isDesenvolvedor" @click="editarChamado(chamado)"
                  class="flex-1 bg-amber-600 hover:bg-amber-700 text-white text-sm font-semibold py-2 rounded-md shadow flex items-center justify-center gap-1">
                  <Edit class="w-4 h-4" />
                  <span>Editar</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Filter Modal -->
        <transition name="modal">
          <div v-if="modalFiltro"
            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70 z-50"
            @click.self="fecharModalFiltro">
            <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-lg animate-fade-in p-6">
              <div class="flex justify-between items-center mb-4">
                <h3 class="text-xl font-bold text-gray-800 dark:text-white">Filtros</h3>
                <button @click="fecharModalFiltro"
                  class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition-all">
                  <X class="w-5 h-5" />
                </button>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- Status filter -->
                <div>
                  <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Status</label>
                  <select v-model="filtroStatus"
                    class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
                    <option value="">Todos</option>
                    <option value="aberto">Aberto</option>
                    <option value="pendente">Pendente</option>
                    <option value="resolvido">Resolvido</option>
                  </select>
                </div>

                <!-- System filter -->
                <div>
                  <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Módulo</label>
                  <select v-model="filtroSistema"
                    class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
                    <option value="">Todos</option>
                    <option v-for="sistema in sistemas" :key="sistema" :value="sistema">{{ sistema }}</option>
                  </select>
                </div>

                <!-- Type filter -->
                <div>
                  <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Tipo</label>
                  <select v-model="filtroTipo"
                    class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
                    <option value="">Todos</option>
                    <option value="bug">Bug</option>
                    <option value="sugestao">Melhoria</option>
                    <option value="sugestao">Dúvida</option>
                  </select>
                </div>

                <!-- Priority filter -->
                <div>
                  <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Prioridade</label>
                  <select v-model="filtroPrioridade"
                    class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
                    <option value="">Todas</option>
                    <option value="baixa">Baixa</option>
                    <option value="media">Média</option>
                    <option value="alta">Alta</option>
                  </select>
                </div>
              </div>

              <!-- Filter buttons -->
              <div class="flex justify-between mt-6">
                <button @click="limparFiltros"
                  class="px-6 py-2.5 bg-gray-500 text-white rounded-lg font-medium hover:bg-gray-600 transition-all">
                  Limpar Filtros
                </button>
                <button @click="aplicarEFecharFiltro"
                  class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-all">
                  Aplicar
                </button>
              </div>
            </div>
          </div>
          
        </transition>

        <!-- Create/Edit Ticket Modal -->
        <transition name="modal">
          <div v-if="modalChamado"
            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70 z-50"
            @click.self="fecharModalChamado">
            <div v-if="mensagemErro"
              class="fixed top-5 right-5 mt-16 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in z-50">
              {{ mensagemErro }}
            </div>

            <div
              class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-4xl animate-fade-in p-6 max-h-[90vh] overflow-y-auto">
              <div class="flex justify-between items-center mb-4">
                <h3 class="text-xl font-bold text-gray-800 dark:text-white">
                  {{ modoEdicao ? 'Editar Tópico' : 'Novo Tópico' }}
                </h3>
                <button @click="fecharModalChamado"
                  class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition-all">
                  <X class="w-5 h-5" />
                </button>
              </div>

              <div class="space-y-4">
                <!-- Title -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Título
                  </label>
                  <input v-model="novoChamado.titulo" type="text" required placeholder="Digite o título do tópico"
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white" />
                </div>

                <!-- System -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Módulo
                  </label>
                  <select v-model="novoChamado.sistema" required
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                    <option value="" disabled>Selecione um Módulo</option>
                    <option v-for="sistema in sistemas" :key="sistema.nome" :value="sistema.nome">
                      {{ sistema.nome }}
                    </option>

                  </select>
                </div>

                <!-- Type -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Tipo
                  </label>
                  <div class="flex gap-4">
                    <label class="flex items-center gap-2 cursor-pointer">
                      <input type="radio" v-model="novoChamado.tipo" value="bug" class="w-4 h-4 text-blue-600" />
                      <span class="text-gray-700 dark:text-gray-300">Bug</span>
                    </label>
                    <label class="flex items-center gap-2 cursor-pointer">
                      <input type="radio" v-model="novoChamado.tipo" value="sugestao" class="w-4 h-4 text-blue-600" />
                      <span class="text-gray-700 dark:text-gray-300">Melhoria</span>
                    </label>
                    <label class="flex items-center gap-2 cursor-pointer">
                      <input type="radio" v-model="novoChamado.tipo" value="duvida" class="w-4 h-4 text-blue-600" />
                      <span class="text-gray-700 dark:text-gray-300">Dúvida</span>
                    </label>
                  </div>
                </div>

                <!-- Prioridade -->
                <div v-if="modoEdicao && isAdmin">
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Prioridade
                  </label>
                  <select v-model="novoChamado.prioridade"
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                    <option value="baixa">Baixa</option>
                    <option value="media">Média</option>
                    <option value="alta">Alta</option>
                  </select>
                </div>

                <!-- Analista -->
                <div v-if="modoEdicao && isAdmin">
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Atribuir Analista
                  </label>
                  <select v-model="novoChamado.analista"
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                    <option value="">Sem atribuição</option>
                    <option v-for="analista in analistas" :key="analista.id" :value="analista.id">
                      {{ analista.nome }}
                    </option>
                  </select>

                </div>

                <!-- Status -->
                <div v-if="modoEdicao && isAdmin">
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Status
                  </label>
                  <select v-model="novoChamado.status"
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                    <option value="aberto">Aberto</option>
                    <option value="pendente">Pendente</option>
                    <option value="resolvido">Resolvido</option>
                  </select>
                </div>


                <!-- Description -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Descrição
                  </label>
                  <textarea v-model="novoChamado.descricao" required
                    placeholder="Descreva detalhadamente o problema ou melhoria"
                    class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white min-h-[150px]"></textarea>
                </div>

                <!-- Exibir miniatura da imagem do chamado, se houver -->
                <div v-if="novoChamado.imagem_base64" class="mt-4">
                  <img :src="novoChamado.imagem_base64" alt="Imagem do tópico"
                    class="max-w-[100px] h-auto rounded-lg shadow-sm cursor-pointer"
                    @click="abrirModalImagem(novoChamado.imagem_base64)" />
                </div>

                <!-- Upload de imagem no chamado -->
                <div class="flex flex-col items-center justify-center gap-4 w-full mt-4">
                  <div
                    class="w-full max-w-[600px] h-[300px] border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg flex items-center justify-center overflow-hidden relative">
                    <img v-if="imagemChamadoPreview" :src="imagemChamadoPreview" alt="Imagem do tópico"
                      class="w-full h-full object-cover" />
                    <div v-else class="text-gray-400 text-center p-2">
                      <ImageIcon class="w-8 h-8 mx-auto mb-1" />
                      <span class="text-xs">Nenhuma imagem selecionada</span>
                    </div>
                    <input type="file" @change="onImagemChamadoSelecionada" accept="image/*"
                      class="absolute inset-0 opacity-0 cursor-pointer" ref="inputImagemChamado" />
                  </div>
                  <button v-if="imagemChamadoPreview" @click="removerImagemChamado"
                    class="px-3 py-1 bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300 rounded-md text-sm hover:bg-red-200 dark:hover:bg-red-800 transition-colors">
                    Remover imagem
                  </button>
                </div>
              </div>

              <!-- Form actions -->
              <div class="flex justify-end gap-3 mt-6">
                <button @click="fecharModalChamado"
                  class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                  Cancelar
                </button>
                <button @click="salvarChamado" :disabled="!isFormValid"
                  class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                  {{ modoEdicao ? 'Atualizar' : 'Criar' }} Tópico
                </button>
              </div>
            </div>
          </div>
        </transition>

        <!-- View Ticket Modal -->
        <transition name="fade">
          <div v-if="modalVisualizacao"
            class="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
            @click.self="modalVisualizacao = false">
            <div
              class="bg-white dark:bg-gray-900 w-full max-w-3xl rounded-2xl shadow-2xl border border-gray-200 dark:border-gray-700 overflow-hidden animate-fadeIn flex flex-col">
              <!-- Header -->
              <div class="flex justify-between items-center p-5 border-b border-gray-200 dark:border-gray-700">
                <h3 class="text-2xl font-bold text-[#005baa] dark:text-blue-300">Detalhes do Chamado</h3>
                <button @click="modalVisualizacao = false"
                  class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition">
                  <X class="w-6 h-6" />
                </button>
              </div>

              <!-- Body -->
              <div class="p-6 space-y-6 max-h-[70vh] overflow-y-auto flex-grow">
                <!-- Ticket info -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h2 class="text-xl font-bold text-[#005baa] dark:text-blue-200 mb-4">
                      {{ chamadoSelecionado?.titulo || '' }}
                    </h2>

                    <div class="space-y-2">
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">ID:</span> {{ chamadoSelecionado?.id }}
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Módulo:</span> {{ chamadoSelecionado?.sistema }}
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Tipo:</span>
                        <span :class="getTipoClass(chamadoSelecionado?.tipo)"
                          class="px-2 py-0.5 rounded-full text-xs font-medium">
                          {{ chamadoSelecionado?.tipo === 'bug' ? 'Bug' : 'Sugestão' }}
                        </span>
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Prioridade:</span>
                        <span :class="getPrioridadeClass(chamadoSelecionado?.prioridade)"
                          class="px-2 py-0.5 rounded-full text-xs font-medium">
                          {{ getPrioridadeLabel(chamadoSelecionado?.prioridade) }}
                        </span>
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Analista:</span> {{ chamadoSelecionado?.analista || 'Não atribuído' }}
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Status:</span>
                        <span :class="getStatusClass(chamadoSelecionado?.status)"
                          class="px-2 py-0.5 rounded-full text-xs font-medium">
                          {{ getStatusLabel(chamadoSelecionado?.status) }}
                        </span>
                      </p>
                      <p class="text-sm text-gray-700 dark:text-gray-300">
                        <span class="font-medium">Data de Abertura:</span> {{
                          formatarData(chamadoSelecionado?.data_criacao) }}
                      </p>
                    </div>
                  </div>

                  <div>
                    <h3 class="text-md font-semibold text-gray-800 dark:text-gray-200 mb-2">Descrição</h3>
                    <div
                      class="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md p-4 text-sm text-gray-800 dark:text-gray-200 leading-relaxed shadow-sm whitespace-pre-line"
                      v-html="formatarDescricao(chamadoSelecionado)"></div>
                  </div>
                </div>

                <!-- Comments section -->
                <div class="mt-8">
                  <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Comentários</h3>

                  <div v-if="!comentarios.length" class="text-center py-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <MessageSquare class="h-10 w-10 text-gray-400 mx-auto mb-2" />
                    <p class="text-gray-500 dark:text-gray-400">Nenhum comentário ainda</p>
                  </div>

                  <div v-else class="space-y-4 mb-6">
                    <div v-for="(comentario, index) in comentarios" :key="index" class="p-4 rounded-lg"
                      :class="comentario.autor_tipo === 'usuario' ? 'bg-blue-50 dark:bg-blue-900/20 ml-0 mr-8' : 'bg-gray-50 dark:bg-gray-800 ml-8 mr-0'">
                      <div class="flex justify-between items-start mb-2">
                        <div class="font-medium text-sm"
                          :class="comentario.autor_tipo === 'usuario' ? 'text-blue-700 dark:text-blue-300' : 'text-gray-700 dark:text-gray-300'">
                          {{ comentario.autor }}
                          <span class="text-xs text-gray-500 dark:text-gray-400 ml-2">
                            ({{ comentario.autor_tipo === 'usuario' ? 'Você' : 'Analista' }})
                          </span>
                        </div>
                        <div class="text-xs text-gray-500 dark:text-gray-400">
                          {{ formatarData(comentario.data) }}
                        </div>
                      </div>
                      <p class="text-sm text-gray-700 dark:text-gray-300 break-words whitespace-pre-line">
  {{ comentario.texto }}
</p>


                      <!-- Exibir a imagem do comentário, se houver -->
                      <div v-if="comentario.imagem_base64" class="mt-4">
                        <img :src="comentario.imagem_base64" alt="Imagem do comentário"
                          class="max-w-[100px] h-auto rounded-lg shadow-sm cursor-pointer"
                          @click="abrirModalImagem(comentario.imagem_base64)" />
                      </div>
                    </div>
                  </div>

                  <!-- Add comment -->
                  <div class="mt-4">
                    <textarea v-model="novoComentario" placeholder="Adicione um comentário..."
                      class="w-full p-3 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white min-h-[100px]"></textarea>
                    <div class="mt-4">
                      <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Anexar imagem (opcional)
                      </label>
                      <div
                        class="relative flex items-center justify-center border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg h-[200px] max-w-lg overflow-hidden">
                        <img v-if="imagemComentarioPreview" :src="imagemComentarioPreview"
                          class="w-full h-full object-cover" />
                        <div v-else class="text-gray-400 text-center p-2">
                          <ImageIcon class="w-8 h-8 mx-auto mb-1" />
                          <span class="text-xs">Nenhuma imagem</span>
                        </div>
                        <input type="file" @change="onImagemComentarioSelecionada" accept="image/*"
                          class="absolute inset-0 opacity-0 cursor-pointer" ref="inputImagemComentario" />
                      </div>
                      <button v-if="imagemComentarioPreview" @click="removerImagemComentario"
                        class="mt-2 px-3 py-1 bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300 rounded-md text-sm hover:bg-red-200 dark:hover:bg-red-800 transition-colors">
                        Remover imagem
                      </button>
                    </div>
                    <div class="flex justify-end mt-2">
                      <button @click="adicionarComentario" :disabled="!novoComentario.trim()"
                        class="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                        Enviar Comentário
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="px-6 py-4 border-t border-gray-200 dark:border-gray-700 flex justify-between">
                <button v-if="chamadoSelecionado?.status !== 'resolvido'" @click="marcarComoResolvido"
                  class="bg-green-600 hover:bg-green-700 text-white text-sm font-medium px-6 py-2 rounded-md transition-all">
                  Marcar como Resolvido
                </button>
                <button @click="modalVisualizacao = false"
                  class="bg-[#005baa] hover:bg-[#004a8c] text-white text-sm font-medium px-6 py-2 rounded-md transition-all ml-auto">
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
      <!-- Modal para exibir a imagem em tamanho original -->
      <transition name="fade">
        <div v-if="modalImagem" class="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50"
          @click.self="fecharModalImagem">
          <div class="bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-[90vw] max-h-[90vh] overflow-auto">
            <img :src="imagemModal" alt="Imagem ampliada" class="w-full h-auto rounded-lg" />
          </div>
        </div>
      </transition>
    </template>
  </BaseTemplate>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import BaseTemplate from './BaseTemplate.vue';
import {
  Filter,
  PlusCircle,
  RefreshCw,
  Search,
  Eye,
  Edit,
  X,
  MessageSquare,
  TicketIcon
} from 'lucide-vue-next';


const chamadosFiltrados = ref([]);
const isLoading = ref(false);
const mostrarResolvidos = ref(false);
const filtroTitulo = ref('');
const filtroStatus = ref('');
const filtroSistema = ref('');
const filtroTipo = ref('');
const filtroPrioridade = ref('');
const modalFiltro = ref(false);
const modalChamado = ref(false);
const modalVisualizacao = ref(false);
const modoEdicao = ref(false);
const comentarios = ref([]);
const chamados = ref([]);
//const apiToken = sessionStorage.getItem('api_token');
const headers = {
  'X-Api-Key': sessionStorage.getItem('api_token'),
  'X-User-Name': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`,
  'X-CSRFToken': sessionStorage.getItem('csrf_token'),
  'Content-Type': 'application/json',
};

const mensagemSucesso = ref('');
const mensagemErro = ref('');
const chamadoSelecionado = ref(null);
const isAdmin = ref(true); // For demo purposes
const userGroups = ref(JSON.parse(sessionStorage.getItem("user_groups")) || []);


const isDesenvolvedor = computed(() => {
  return (
    userGroups.value.includes("superadmin") ||
    userGroups.value.includes("desenvolvedor"))

});

// Sorting state
const colunaOrdenada = ref('id');
const ordemAscendente = ref(true);

const imagemChamadoPreview = ref(null);
const inputImagemChamado = ref(null);
const imagemBase64Chamado = ref(null);

const onImagemChamadoSelecionada = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      imagemChamadoPreview.value = e.target.result;
      imagemBase64Chamado.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const removerImagemChamado = () => {
  imagemChamadoPreview.value = null;
  imagemBase64Chamado.value = null;
  if (inputImagemChamado.value) inputImagemChamado.value.value = '';
};
// New/Edit ticket form
const novoChamado = ref({
  id: null,
  titulo: '',
  sistema: '',
  tipo: 'bug',
  prioridade: 'media',
  analista: '',
  status: 'aberto',
  descricao: '',
  data_criacao: new Date().toISOString()
});


const novoComentario = ref('');

// Form validation
const isFormValid = computed(() => {
  return (
    novoChamado.value.titulo &&
    novoChamado.value.sistema &&
    novoChamado.value.tipo &&
    novoChamado.value.prioridade &&
    novoChamado.value.descricao
  );
});

// Filter count
const totalFiltros = computed(() => {
  return [filtroStatus.value, filtroSistema.value, filtroTipo.value, filtroPrioridade.value]
    .filter(f => f !== '').length;
});

// Apply filters
const aplicarFiltros = () => {
  // Normalize search text
  const filtroTituloTexto = filtroTitulo.value ? filtroTitulo.value.toString().trim().toLowerCase() : '';

  // Filter tickets
  chamadosFiltrados.value = chamados.value.filter(chamado => {
    // Extract and normalize data
    const titulo = chamado.titulo ? chamado.titulo.toString().toLowerCase() : '';

    // Hide resolved by default
    if (!mostrarResolvidos.value && chamado.status === 'resolvido') return false;

    // Apply text filter
    if (filtroTituloTexto && !titulo.includes(filtroTituloTexto)) return false;

    // Apply status filter
    if (filtroStatus.value && chamado.status !== filtroStatus.value) return false;

    // Apply system filter
    if (filtroSistema.value && chamado.sistema !== filtroSistema.value) return false;

    // Apply type filter
    if (filtroTipo.value && chamado.tipo !== filtroTipo.value) return false;

    // Apply priority filter
    if (filtroPrioridade.value && chamado.prioridade !== filtroPrioridade.value) return false;

    return true;
  });
};

// Watch for filter changes
watch([
  filtroTitulo,
  filtroStatus,
  filtroSistema,
  filtroTipo,
  filtroPrioridade,
  mostrarResolvidos
], () => {
  aplicarFiltros();
});

// Apply filters and close modal
const aplicarEFecharFiltro = () => {
  aplicarFiltros();
  fecharModalFiltro();
};

// Clear filters
const limparFiltros = () => {
  filtroStatus.value = '';
  filtroSistema.value = '';
  filtroTipo.value = '';
  filtroPrioridade.value = '';
  filtroTitulo.value = '';
};

// Format date
const formatarData = (data) => {
  if (!data) return "Sem data";
  return new Date(data).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

const formatarDescricao = (chamado) => {
  let descricao = chamado.descricao || '';

  // Se houver uma imagem, adicione a tag <img> à descrição
  if (chamado.imagem_base64) {
    descricao += `<div class="mt-4"><img src="${chamado.imagem_base64}" alt="Imagem do chamado" class="max-w-full h-auto rounded-lg shadow-sm" /></div>`;
  }

  return descricao;
};
// Get status class
const getStatusClass = (status) => {
  switch (status) {
    case 'aberto':
      return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200';
    case 'pendente':
      return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
    case 'resolvido':
      return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
    default:
      return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200';
  }
};

// Get status label
const getStatusLabel = (status) => {
  switch (status) {
    case 'aberto':
      return 'Aberto';
    case 'pendente':
      return 'Pendente';
    case 'resolvido':
      return 'Resolvido';
    default:
      return 'Desconhecido';
  }
};

// Get type class
const getTipoClass = (tipo) => {
  return tipo === 'bug'
    ? 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200'
    : 'bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200';
};

// Get priority class
const getPrioridadeClass = (prioridade) => {
  switch (prioridade) {
    case 'baixa':
      return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
    case 'media':
      return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
    case 'alta':
      return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
    default:
      return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200';
  }
};

// Get priority label
const getPrioridadeLabel = (prioridade) => {
  switch (prioridade) {
    case 'baixa':
      return 'Baixa';
    case 'media':
      return 'Média';
    case 'alta':
      return 'Alta';
    default:
      return 'Desconhecida';
  }
};

// Sort function
const ordenarPor = (coluna) => {
  if (colunaOrdenada.value === coluna) {
    ordemAscendente.value = !ordemAscendente.value;
  } else {
    colunaOrdenada.value = coluna;
    ordemAscendente.value = true;
  }

  // Sort the tickets
  chamadosFiltrados.value.sort((a, b) => {
    let valorA = a[coluna];
    let valorB = b[coluna];

    // Alphabetical sorting
    if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
    if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
    return 0;
  });
};

// Modal functions
const abrirModalFiltro = () => modalFiltro.value = true;
const fecharModalFiltro = () => modalFiltro.value = false;
const abrirModalCriarChamado = () => {
  resetarFormulario();
  modoEdicao.value = false;
  modalChamado.value = true;
};
const fecharModalChamado = () => {
  modalChamado.value = false;
  resetarFormulario();
};


// Reset form
const resetarFormulario = () => {
  novoChamado.value = {
    id: null,
    titulo: '',
    sistema: '',
    tipo: 'bug',
    prioridade: 'media',
    analista: '',
    status: 'aberto',
    descricao: '',
    data_criacao: new Date().toISOString()
  };
};

const modalImagem = ref(false); // Controla a visibilidade do modal
const imagemModal = ref(''); // Armazena a URL da imagem a ser exibida no modal

// Abre o modal com a imagem em tamanho original
const abrirModalImagem = (imagem) => {
  imagemModal.value = imagem;
  modalImagem.value = true;
};

// Fecha o modal
const fecharModalImagem = () => {
  modalImagem.value = false;
  imagemModal.value = '';
};

const sistemas = ref([]);

const carregarSistemas = async () => {
  try {
    const response = await fetch("/api/v1/sistemas", { headers: headers });
    if (response.ok) {
      sistemas.value = await response.json();
    } else {
      console.error("Erro ao carregar sistemas:", response.statusText);
    }
  } catch (error) {
    console.error("Erro ao carregar sistemas:", error);
  }
};

// Listar comentários por ID do chamado
const listar_comentarios = async (chamado_id) => {
  try {
    const response = await fetch(`api/v1/chamados/${chamado_id}/comentarios`, { headers: headers });
    if (response.ok) {
      comentarios.value = await response.json();
    } else {
      console.error("Erro ao carregar comentários:", response.statusText);
    }
  } catch (error) {
    console.error("Erro ao carregar comentários:", error);
  }
};


onMounted(() => {
  carregarSistemas();
});


// View ticket details
// View ticket details
const verDetalhes = (id) => {
  const chamado = chamados.value.find(item => item.id === id);
  if (chamado) {
    chamadoSelecionado.value = { ...chamado };
    modalVisualizacao.value = true;
    listar_comentarios(id);
  }
};

// Variáveis para auditoria
const auditorias = ref([]);
const modalAuditoria = ref(false);

// Função para buscar os dados da auditoria
const listarAuditorias = async () => {
  try {
    const response = await fetch('/api/v1/auditoria/topicos', { headers });
    if (response.ok) {
      auditorias.value = await response.json();
    } else {
      console.error("Erro ao carregar auditoria:", response.statusText);
    }
  } catch (error) {
    console.error("Erro ao carregar auditoria:", error);
  }
};

// Funções para abrir/fechar o modal de auditoria
const abrirModalAuditoria = () => {
  listarAuditorias();
  modalAuditoria.value = true;
};

const fecharModalAuditoria = () => {
  modalAuditoria.value = false;
};

// Filtro para formatação de data (opcional)


// Edit ticket
const editarChamado = (chamado) => {
  const analistaEncontrado = analistas.value.find(a => a.nome === chamado.analista);
  novoChamado.value = {
    ...chamado,
    analista: analistaEncontrado ? analistaEncontrado.id : ''
  };
  modoEdicao.value = true;
  modalChamado.value = true;
};

const imagemComentarioPreview = ref(null);
const imagemBase64Comentario = ref(null);
const inputImagemComentario = ref(null);

const onImagemComentarioSelecionada = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      imagemComentarioPreview.value = e.target.result;
      imagemBase64Comentario.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const removerImagemComentario = () => {
  imagemComentarioPreview.value = null;
  imagemBase64Comentario.value = null;
  if (inputImagemComentario.value) inputImagemComentario.value.value = '';
};
const analistas = ref([]);

const carregarAnalistas = async () => {
  try {
    const response = await fetch("/api/v1/analistas", { headers: headers });
    if (response.ok) {
      analistas.value = await response.json();
    } else {
      console.error("Erro ao carregar analistas:", response.statusText);
    }
  } catch (error) {
    console.error("Erro ao carregar analistas:", error);
  }
};

// Save ticket (create or update)
const salvarChamado = async () => {
  try {
    const url = modoEdicao.value
      ? `/api/v1/chamados/${novoChamado.value.id}`
      : '/api/v1/chamados';
    const method = modoEdicao.value ? 'PUT' : 'POST';

    const response = await fetch(url, {
      method: method,
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        titulo: novoChamado.value.titulo,
        sistema: novoChamado.value.sistema,
        tipo: novoChamado.value.tipo,
        prioridade: novoChamado.value.prioridade,
        descricao: novoChamado.value.descricao,
        status: novoChamado.value.status || 'aberto',
        analista: novoChamado.value.analista || null,
        imagem_base64: imagemBase64Chamado.value || null  // Inclua o campo imagem_base64
      })
    });

    if (response.ok) {
      const data = await response.json();
      if (modoEdicao.value) {
        const index = chamados.value.findIndex(item => item.id === data.id);
        if (index !== -1) chamados.value[index] = data;
      } else {
        chamados.value.push(data);
      }

      mensagemSucesso.value = modoEdicao.value
        ? "Chamado atualizado com sucesso!"
        : "Chamado criado com sucesso!";
      aplicarFiltros();

      // Fechar o modal após 2 segundos (2000 milissegundos)
      setTimeout(() => {
        fecharModalChamado();
      }, 2000); // 2000 ms = 2 segundos
    } else if (response.status === 403) {
      const err = await response.json();
      mensagemErro.value = err.error || "Permissão negada para atualizar o chamado.";

      // Limpar a mensagem de erro após 3 segundos (3000 milissegundos)
      setTimeout(() => {
        mensagemErro.value = '';
      }, 3000);
    } else {
      const err = await response.json();
      console.error('Erro ao salvar chamado:', err);
      mensagemErro.value = err?.error || "Erro ao salvar chamado!";

      // Limpar a mensagem de erro após 3 segundos (3000 milissegundos)
      setTimeout(() => {
        mensagemErro.value = '';
      }, 3000);
    }
  } catch (error) {
    console.error("Erro ao salvar chamado:", error);
    mensagemErro.value = "Erro ao salvar chamado!";

    // Limpar a mensagem de erro após 3 segundos (3000 milissegundos)
    setTimeout(() => {
      mensagemErro.value = '';
    }, 3000);
  }
};


const adicionarComentario = async () => {
  if (!novoComentario.value.trim()) return;

  try {
    const response = await fetch(`/api/v1/chamados/${chamadoSelecionado.value.id}/comentarios`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        texto: novoComentario.value,
        imagem_base64: imagemBase64Comentario.value || null
      }),
    });

    if (response.ok) {
      const data = await response.json();
      comentarios.value.push(data);
      novoComentario.value = '';
      imagemComentarioPreview.value = null;
      imagemBase64Comentario.value = null;
      if (inputImagemComentario.value) inputImagemComentario.value.value = '';
    } else {
      const error = await response.json();
      mensagemErro.value = error?.error || "Erro ao adicionar comentário!";
      console.error("Erro ao adicionar comentário:", error);
    }
  } catch (error) {
    console.error("Erro ao adicionar comentário:", error);
    mensagemErro.value = "Erro ao adicionar comentário!";
  }
};


// Mark as resolved
const marcarComoResolvido = async () => {
  if (chamadoSelecionado.value) {
    try {
      const response = await fetch(`/api/v1/chamados/${chamadoSelecionado.value.id}/marcar-resolvido`, {
        method: 'POST', headers: headers
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          chamadoSelecionado.value.status = 'resolvido';
          const index = chamados.value.findIndex(item => item.id === chamadoSelecionado.value.id);
          if (index !== -1) {
            chamados.value[index].status = 'resolvido';
          }
          mensagemSucesso.value = "Chamado marcado como resolvido!";
          aplicarFiltros();

          // Fechar o modal após 2 segundos (2000 milissegundos)
          setTimeout(() => {
            modalVisualizacao.value = false;
          }, 2000); // 2000 ms = 2 segundos
        }
      } else {
        throw new Error('Erro ao marcar como resolvido');
      }
    } catch (error) {
      console.error("Erro ao marcar como resolvido:", error);
      mensagemErro.value = "Erro ao marcar como resolvido!";

      setTimeout(() => {
        mensagemErro.value = '';
      }, 3000); // 3000 ms = 3 segundos
    }
  }
};
// Initialize
onMounted(async () => {
  isLoading.value = true;

  try {
    const response = await fetch('/api/v1/chamados', {
      headers: headers, // <-- adicionado corretamente aqui
    });

    if (response.ok) {
      chamados.value = await response.json();
      aplicarFiltros();
    } else {
      console.error('Erro ao carregar chamados:', response.statusText);
    }

    await carregarAnalistas();
  } catch (error) {
    console.error("Erro ao carregar dados:", error);
  } finally {
    isLoading.value = false;
  }
});


</script>

<style scoped>
.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>