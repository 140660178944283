<template>
    <BaseTemplate app-name="Insights" page-title="Pendências">
        <template #content>
            <div class="p-6">
                <h1 class="text-xl font-bold mb-4">📊 Insights Estratégicos das Pendências</h1>

                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">⏳ Pendências Atrasadas</h2>
                        <p class="text-3xl font-bold text-red-500">{{ insights.percentual_pendencias_atrasadas }}%</p>
                    </div>

                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">📅 Tempo Médio de Resolução</h2>
                        <p class="text-3xl font-bold">{{ insights.media_tempo_resolucao }} dias</p>
                    </div>

                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">🏢 Setores com Mais Pendências</h2>
                        <ul>
                            <li v-for="(item, index) in insights.setores_mais_pendencias" :key="index">
                                {{ item.setor__nome }}: <strong>{{ item.total }}</strong>
                            </li>
                        </ul>
                    </div>

                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">💰 Tipos que Consomem Mais Orçamento</h2>
                        <ul>
                            <li v-for="(item, index) in insights.orcamento_por_tipo" :key="index">
                                {{ item.tipo__nome }}: <strong>R$ {{ formatCurrency(item.total_orcamento) }}</strong>
                            </li>
                        </ul>
                    </div>

                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">🔥 Pendências Críticas</h2>
                        <p class="text-3xl font-bold text-red-500">{{ insights.percentual_pendencias_criticas }}%</p>
                    </div>

                    <div class="bg-white p-4 shadow rounded-lg">
                        <h2 class="text-lg font-semibold mb-2">👨‍💼 Eficiência dos Responsáveis</h2>
                        <ul>
                            <li v-for="(item, index) in insights.eficiencia_responsaveis" :key="index">
                                {{ item.responsavel_interno__nome }}: <strong>{{ item.media_dias }} dias</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </BaseTemplate>
</template>


<script setup>
import { ref, onMounted, nextTick } from 'vue';
import BaseTemplate from './BaseTemplate.vue';
import axios from 'axios';
import Chart from 'chart.js/auto';

const apiToken = sessionStorage.getItem("api_token");
const headers = { "X-API-KEY": apiToken, "Content-Type": "application/json" };

const insights = ref({
    prioridade: [],
    status: [],
    setor: [],
    unidade: [],
    orcamento_total: 0,
});


const statusChart = ref(null);
const setorChart = ref(null);
const unidadeChart = ref(null);

const carregarInsights = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/insights/pendencias`, { headers });
        insights.value = {
            prioridade: response.data.prioridade || [],
            status: response.data.status || [],
            setor: response.data.setor || [],
            unidade: response.data.unidade || [],
            orcamento_total: response.data.orcamento_total || 0,
        };
        await nextTick(); // Espera a DOM renderizar antes de desenhar os gráficos
        desenharGraficos();
    } catch (error) {
        console.error("Erro ao carregar insights:", error);
    }
};

const desenharGraficos = () => {
    criarGrafico(statusChart.value, "Orçamento por Status", insights.value.status.map(s => s.status__nome), insights.value.status.map(s => s.total_orcamento));
    criarGrafico(setorChart.value, "Orçamento por Setor", insights.value.setor.map(s => s.setor__nome), insights.value.setor.map(s => s.total_orcamento));
    criarGrafico(unidadeChart.value, "Orçamento por Unidade", insights.value.unidade.map(u => u.unidade__nome), insights.value.unidade.map(u => u.total_orcamento));
};

const criarGrafico = (ctx, label, labels, data) => {
    if (!ctx) return;
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: label,
                data: data,
                backgroundColor: ['#4CAF50', '#2196F3', '#FF9800', '#E91E63', '#9C27B0'],
            }]
        },
        options: { responsive: true }
    });
};


const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
};


onMounted(() => carregarInsights());
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.dark {
    background-color: #1a202c;
    color: white;
}

.dark .bg-white {
    background-color: #2d3748 !important;
}
</style>
