<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
    <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-md">
      <h1 class="text-2xl font-bold text-gray-800 dark:text-white mb-6">Registro de Usuário</h1>
      <form @submit.prevent="registerUser" class="space-y-6">
        <!-- Primeiro Nome -->
        <div>
          <label for="first_name" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Primeiro Nome</label>
          <input
            id="first_name"
            type="text"
            v-model="form.first_name"
            class="mt-1 block w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-blue-500"
            placeholder="Digite seu primeiro nome"
            required
          />
        </div>

        <!-- Último Nome -->
        <div>
          <label for="last_name" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Último Nome</label>
          <input
            id="last_name"
            type="text"
            v-model="form.last_name"
            class="mt-1 block w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-blue-500"
            placeholder="Digite seu último nome"
            required
          />
        </div>

        <!-- Email -->
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
          <input
            id="email"
            type="email"
            v-model="form.email"
            class="mt-1 block w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-blue-500"
            placeholder="Digite seu email"
            required
          />
        </div>

        <!-- Senha -->
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Senha</label>
          <input
            id="password"
            type="password"
            v-model="form.password"
            class="mt-1 block w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-blue-500"
            placeholder="Digite sua senha"
            required
          />
        </div>

        <!-- Confirmação de Senha -->
        <div>
          <label for="confirm_password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirme sua Senha</label>
          <input
            id="confirm_password"
            type="password"
            v-model="form.confirm_password"
            class="mt-1 block w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-blue-500"
            placeholder="Confirme sua senha"
            required
          />
        </div>

        <!-- Botão de Enviar -->
        <div>
          <button
            type="submit"
            class="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Registrar
          </button>
        </div>
      </form>

      <div>
        <button
          @click="voltar"
          class="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors mt-5"
        >
          Voltar
        </button>
      </div>

      <!-- Mensagens de erro ou sucesso -->
      <transition name="fade">
        <div
          v-if="errorMessage"
          class="flex items-center p-4 mb-4 mt-5 bg-red-100 border-l-4 border-red-500 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-300 rounded"
        >
          <svg
            class="w-5 h-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{{ errorMessage }}</span>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="successMessage"
          class="flex items-center p-4 mb-4 mt-5 bg-green-100 border-l-4 border-green-500 text-green-700 dark:bg-green-900 dark:border-green-700 dark:text-green-300 rounded"
        >
          <svg
            class="w-5 h-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <span>{{ successMessage }}</span>
        </div>
      </transition>

      <!-- Rodapé fixo -->
      <p class="text-center text-sm mt-6 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
const API_URL = process.env.VUE_APP_API_URL

export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
      },
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
  async registerUser() {
    this.successMessage = "";
    this.errorMessage = "";

    // Verificação de confirmação de senha
    if (this.form.password !== this.form.confirm_password) {
      this.errorMessage = "As senhas não conferem. Por favor, verifique.";
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/v1/register/`, {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        password: this.form.password,
      });

      this.successMessage = `Usuário registrado com sucesso! Seu username é: ${response.data.username}. Aguarde a aprovação da gestão.`;
      this.form = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
      };
    } catch (error) {
      console.error("Erro ao registrar usuário:", error);

      if (error.response && error.response.data && error.response.data.detail) {
        const details = error.response.data.detail;

        if (typeof details === "string") {
          // Caso onde "detail" é uma string
          this.errorMessage = details;
        } else if (Array.isArray(details)) {
          // Caso onde "detail" é um array
          this.errorMessage = details
            .map((detail) => this.translateError(detail))
            .join(". ");
        } else {
          this.errorMessage = "Ocorreu um erro inesperado. Por favor, tente novamente.";
        }
      } else {
        this.errorMessage = "Ocorreu um erro inesperado. Por favor, tente novamente.";
      }
    }
  },

  translateError(detail) {
    // Mapeamento de mensagens de erro
    const errorTranslations = {
      "string_too_short": "A senha deve ter pelo menos 8 caracteres.",
      "value_error.email": "O email fornecido não é válido.",
      "value_error.missing": "O campo é obrigatório.",
    };

    // Tradução baseada no tipo do erro
    return (
      errorTranslations[detail.type] ||
      detail.msg || // Mensagem original, se existir
      "Erro desconhecido. Verifique os dados informados."
    );
  },
},

  setup() {
    const router = useRouter();
    const voltar = () => {
      router.push({ name: "Login" });
    };

    return {
      voltar,
    };
  },
};
</script>

<style>
body {
  @apply bg-gray-100 dark:bg-gray-900;
}
</style>
