<template>
  <div :class="['min-h-screen flex flex-col items-center justify-center relative', isDark ? 'dark' : '']">

    <button
      @click="toggleDarkMode"
      class="theme-toggle fixed top-4 right-4 p-2 rounded-full bg-white/90 dark:bg-gray-700/90 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-600 dark:text-gray-300 transition-all duration-300 ease-in-out shadow-md z-20"
      aria-label="Toggle dark mode"
    >
      <svg v-if="isDark" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <circle cx="12" cy="12" r="5"></circle>
        <line x1="12" y1="1" x2="12" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="23"></line>
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
        <line x1="1" y1="12" x2="3" y2="12"></line>
        <line x1="21" y1="12" x2="23" y2="12"></line>
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
      </svg>
      <svg v-else class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
      </svg>
    </button>

    <!-- Dynamic background -->
    <div class="fixed inset-0 w-full h-full overflow-hidden">
      <canvas ref="waveCanvas" class="absolute inset-0 w-full h-full transition-opacity duration-500"></canvas>
      <div class="absolute inset-0 bg-gradient-to-br from-white/80 via-blue-50/50 to-gray-100/80 dark:from-gray-900/90 dark:via-gray-800/70 dark:to-gray-950/90 transition-colors duration-500"></div>
    </div>

    <!-- Header Title -->
    <div class="z-10 text-center mb-12">
     <h1 class="welcome-title text-center">
  <span class="gradient-text">
    
  </span>
  <span class="block mt-2"></span>
</h1>
      
    </div> 

    <!-- Login Card -->
    <div class="z-10 w-full max-w-md bg-white/90 dark:bg-gray-800/90 backdrop-blur-xl rounded-2xl shadow-2xl transition-colors duration-500 ease-in-out p-8 border border-gray-200 dark:border-gray-700">
      <div class="flex justify-center mb-6">
        <img :src="LogoDark" alt="Logo CCDTI" class="h-16 object-contain" />
      </div>
    
      <p class="text-center text-gray-600 dark:text-gray-400 mb-6">
        Insira suas credenciais abaixo
      </p>

      <!-- Messages -->
      <transition name="message-fade">
        <div v-if="errorMessage" class="flex items-center p-4 mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 dark:bg-red-900/70 dark:border-red-700 dark:text-red-300 rounded">
          <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <circle cx="12" cy="12" r="10"/><line x1="12" y1="8" x2="12" y2="12"/><line x1="12" y1="16" x2="12.01" y2="16"/>
          </svg>
          <span>{{ errorMessage }}</span>
        </div>
      </transition>

      <transition name="message-fade">
        <div v-if="successMessage" class="flex items-center p-4 mb-4 bg-green-100 border-l-4 border-green-500 text-green-700 dark:bg-green-900/70 dark:border-green-700 dark:text-green-300 rounded">
          <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><polyline points="22 4 12 14.01 9 11.01"/>
          </svg>
          <span>{{ successMessage }}</span>
        </div>
      </transition>

      <!-- Login Form -->
      <form @submit.prevent="handleSubmit" class="space-y-5">
        <div class="relative">
          <input
            id="username"
            v-model="username"
            type="text"
            required
            class="w-full px-4 py-3 pl-10 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder="Usuário ou e-mail"
          />
          <svg class="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 dark:text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/>
          </svg>
        </div>

   <!-- Campo de Senha com botão "mostrar/ocultar" -->
   <div class="relative">
    <input
      :type="showPassword ? 'text' : 'password'"
      id="password"
      v-model="password"
      required
      class="w-full px-4 py-3 pl-10 pr-12 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
      placeholder="Senha"
    />
    <!-- Ícone do campo senha -->
    <svg class="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 dark:text-gray-500"
         viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
      <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
    </svg>


    <button
      type="button"
      @click="showPassword = !showPassword"
      class="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-[#005baa] focus:outline-none"
      aria-label="Mostrar/ocultar senha"
    >
      <component :is="showPassword ? EyeOff : Eye" class="w-5 h-5" />
    </button>

  </div>


        <div class="flex justify-between items-center">
          <label class="flex items-center space-x-2">
            <input type="checkbox" class="form-checkbox" v-model="rememberMe"/>
            <span class="text-sm text-gray-600 dark:text-gray-400">Lembrar-me</span>
          </label>
          <button @click="handleForgotPassword" type="button" class="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300">
            Esqueci minha senha
          </button>
        </div>

        <button type="submit" class="w-full py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md transition duration-300" :disabled="isLoading">
          <span v-if="isLoading" class="flex justify-center items-center">
            <svg class="animate-spin mr-2 h-5 w-5 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4">
              <circle class="opacity-25" cx="12" cy="12" r="10"/><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.4 0 0 5.4 0 12h4z"/>
            </svg>
            Aguarde...
          </span>
          <span v-else>Entrar</span>
        </button>
      </form>

      <div class="text-center mt-6">
        <p class="text-sm text-gray-600 dark:text-gray-400">
          Não possui conta? <button @click="handleRegister" class="text-blue-600 dark:text-blue-400 font-medium hover:text-blue-800 dark:hover:text-blue-300">Cadastre-se</button>
        </p>
      </div>
    </div>

    <!-- Footer -->
    <p class="z-10 text-center text-sm mt-6 text-gray-500 dark:text-gray-400">
      Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
    </p>
  </div>


      <!-- Right side: Welcome content -->
      <div class="welcome-container order-1 md:order-2 mb-8 md:mb-0 text-center md:text-left">
        <!-- Theme toggle button -->
  
        
        <div class="welcome-content">
          <h1 class="welcome-title text-center">
          <span class="gradient-text">SISTEMA</span>
          <span class="block mt-2">CCDTI</span>
        </h1>
          
          <!-- Feature cards in horizontal layout -->
          <div class="feature-cards">
            <div class="feature-card">
              <div class="feature-icon-wrapper">
                <svg class="feature-icon" viewBox="0 0 24 24">
                  <path d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <span>Seguro</span>
            </div>
            <div class="feature-card">
              <div class="feature-icon-wrapper">
                <svg class="feature-icon" viewBox="0 0 24 24">
                  <path d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <span>Rápido</span>
            </div>
            <div class="feature-card">
              <div class="feature-icon-wrapper">
                <svg class="feature-icon" viewBox="0 0 24 24">
                  <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span>Confiável</span>
            </div>
          </div>
        </div>
      </div>
    
    <!-- Install prompts -->
    <div v-if="showInstallMessage" class="install-prompt ios-prompt">
      <div class="install-prompt-content">
        <div class="install-prompt-arrow"></div>
        <div class="flex items-center mb-4">
          <svg class="w-6 h-6 mr-3 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
            <polyline points="16 6 12 2 8 6"/>
            <line x1="12" x2="12" y1="2" y2="15"/>
          </svg>
          <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Instalar Aplicativo</h2>
        </div>
        <p class="text-sm text-gray-600 dark:text-gray-400 mb-4">
          Para instalar o aplicativo, toque no botão 
          <svg class="inline-block w-4 h-4 mx-1 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
            <polyline points="16 6 12 2 8 6"/>
            <line x1="12" x2="12" y1="2" y2="15"/>
          </svg>
          e selecione "Adicionar à Tela de Início"
        </p>
        <button 
          @click="showInstallMessage = false" 
          class="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Entendi
        </button>
      </div>
    </div>

    <div v-if="showAndroidInstallMessage" class="install-prompt android-prompt">
      <div class="install-prompt-content">
        <div class="install-prompt-arrow"></div>
        <div class="flex items-center mb-4">
          <svg class="w-6 h-6 mr-3 text-green-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <circle cx="12" cy="12" r="1"/>
            <circle cx="12" cy="5" r="1"/>
            <circle cx="12" cy="19" r="1"/>
          </svg>
          <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Instalar Aplicativo</h2>
        </div>
        <p class="text-sm text-gray-600 dark:text-gray-400 mb-4">
          Toque nos 3 pontinhos 
          <svg class="inline-block w-4 h-4 mx-1 text-green-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <circle cx="12" cy="12" r="1"/>
            <circle cx="12" cy="5" r="1"/>
            <circle cx="12" cy="19" r="1"/>
          </svg>
          e selecione "Adicionar à Tela Inicial"
        </p>
        <button 
          @click="showAndroidInstallMessage = false" 
          class="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          Entendi
        </button>
      </div>
    </div>
  </template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import LogoDark from '@/assets/logo_ccdti.png'
import { Eye, EyeOff  } from 'lucide-vue-next'

const router = useRouter()
const waveCanvas = ref(null)

// Form state
const showPassword = ref(false);
const username = ref('')
const password = ref('')
const firstname = ref('')
const lastname = ref('')
const isLoading = ref(false)
const errorMessage = ref('')
const successMessage = ref('')
const isDark = ref(false)
const rememberMe = ref(false) // Added rememberMe ref
const API_URL = process.env.VUE_APP_API_URL

// Install prompts
const showInstallMessage = ref(false)
const showAndroidInstallMessage = ref(false)

// Wave animation variables
let ctx
let width
let height
let waves = []
let animationFrameId
let lastTime = 0

// Initialize wave animation
const initWaveAnimation = () => {
  if (!waveCanvas.value) return
  
  ctx = waveCanvas.value.getContext('2d')
  resizeCanvas()
  
  // Create waves with different properties
  waves = [
    createWave(0.02, 0.1, 4, isDark.value ? '#3b82f6' : '#60a5fa', 0.3),
    createWave(0.03, 0.15, 2, isDark.value ? '#8b5cf6' : '#a78bfa', 0.2),
    createWave(0.01, 0.05, 3, isDark.value ? '#2563eb' : '#93c5fd', 0.4)
  ]
  
  // Start animation loop
  animateWaves()
  
  // Handle window resize
  window.addEventListener('resize', resizeCanvas)
}

// Create a wave with specific properties
const createWave = (frequency, amplitude, speed, color, opacity) => {
  return { frequency, amplitude, speed, color, opacity, offset: 0 }
}

// Resize canvas to match window size
const resizeCanvas = () => {
  if (!waveCanvas.value) return
  
  width = window.innerWidth
  height = window.innerHeight
  
  waveCanvas.value.width = width
  waveCanvas.value.height = height
}

// Animate waves
const animateWaves = (currentTime = 0) => {
  const deltaTime = currentTime - lastTime
  lastTime = currentTime
  
  // Clear canvas
  ctx.clearRect(0, 0, width, height)
  
  // Draw each wave
  waves.forEach(wave => {
    drawWave(wave, deltaTime)
  })
  
  // Continue animation loop
  animationFrameId = requestAnimationFrame(animateWaves)
}

// Draw a single wave
const drawWave = (wave, deltaTime) => {
  const { frequency, amplitude, speed, color, opacity, offset } = wave
  
  // Update wave offset based on speed
  wave.offset += speed * 0.001 * deltaTime
  
  ctx.beginPath()
  ctx.moveTo(0, height / 2)
  
  // Draw wave path
  for (let x = 0; x < width; x++) {
    const y = Math.sin(x * frequency + offset) * amplitude * height / 2 + height / 2
    ctx.lineTo(x, y)
  }
  
  // Complete the path to fill the wave
  ctx.lineTo(width, height)
  ctx.lineTo(0, height)
  ctx.closePath()
  
  // Set wave color and opacity
  ctx.fillStyle = color
  ctx.globalAlpha = opacity
  ctx.fill()
  ctx.globalAlpha = 1
}

// Update wave colors when theme changes
watch(isDark, (newValue) => {
  if (waves.length) {
    waves[0].color = newValue ? '#3b82f6' : '#60a5fa'
    waves[1].color = newValue ? '#8b5cf6' : '#a78bfa'
    waves[2].color = newValue ? '#2563eb' : '#93c5fd'
  }
})

// Toggle dark mode
const toggleDarkMode = () => {
  isDark.value = !isDark.value
  localStorage.setItem('darkMode', isDark.value)
  document.documentElement.classList.toggle('dark', isDark.value)
}

// Get CSRF token
const getCsrfToken = async () => {
  try {
    const response = await fetch(`${API_URL}/csrf-token/`, {
      method: 'GET',
      credentials: 'include',
    })
    if (!response.ok) throw new Error('Erro ao obter o CSRF token')
    const data = await response.json()
    return data.csrfToken
  } catch (error) {
    console.error('Erro ao obter CSRF token:', error)
    throw error
  }
}

// Handle form submission
const handleSubmit = async () => {
  errorMessage.value = ''
  successMessage.value = ''
  isLoading.value = true

  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${API_URL}/api/v1/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify({
        username: username.value,
        password: password.value,
        rememberMe: rememberMe.value // Added rememberMe to request body
      }),
      credentials: 'include',
    })

    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.message || 'Erro ao autenticar')
    }

    const token = data.api_key
    const userFirstname = data.firstname
    const userLastname = data.lastname
    const userGroups = data.groups
    const tokenExpiry = data.token_expiry || 3600

    // Store data in sessionStorage
    sessionStorage.setItem('api_token', token)
    sessionStorage.setItem('username', username.value)
    sessionStorage.setItem('firstname', userFirstname)
    sessionStorage.setItem('lastname', userLastname)
    sessionStorage.setItem('user_groups', JSON.stringify(userGroups))
    sessionStorage.setItem('token_expiry', Date.now() + tokenExpiry * 1000)

    // Update frontend
    firstname.value = userFirstname
    lastname.value = userLastname

    successMessage.value = `Bem-vindo(a), ${userFirstname} ${userLastname}!`

    // Redirect to /home after successful login
    router.push({ name: 'HomePage' })
  } catch (error) {
    console.error('Erro durante a autenticação:', error.message)
    errorMessage.value = error.message || 'Erro ao conectar com o servidor. Tente novamente mais tarde.'
  } finally {
    isLoading.value = false
  }
}

// Navigation handlers
const handleForgotPassword = () => {
  router.push({ name: 'ResetPassword' })
}

const handleRegister = () => {
  router.push({ name: 'Register' })
}

// Device detection
const isIos = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

const isInStandaloneMode = () => {
  return ('standalone' in navigator) && (navigator.standalone)
}

const isAndroid = () => {
  return /Android/i.test(navigator.userAgent)
}

// Lifecycle hooks
onMounted(() => {
  // Initialize dark mode
  isDark.value = localStorage.getItem('darkMode') === 'true'
  document.documentElement.classList.toggle('dark', isDark.value)
  
  // Initialize wave animation
  initWaveAnimation()
  
  // Check for iOS and standalone mode
  if (isIos() && !isInStandaloneMode()) {
    showInstallMessage.value = true
  }
  
  // Check for Android and standalone mode
  if (isAndroid() && !window.matchMedia('(display-mode: standalone)').matches) {
    showAndroidInstallMessage.value = true
  }
  
  // Fix body scroll
  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
  document.body.style.height = '100%'
  
  // Check for access denied query parameter
  if (router.currentRoute.value.query.accessDenied) {
    errorMessage.value = 'Acesso negado. Por favor, faça login para continuar.'
    router.replace({ query: {} })
  }
})

onUnmounted(() => {
  // Clean up animation
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId)
  }
  
  // Remove event listeners
  window.removeEventListener('resize', resizeCanvas)
  
  // Restore body scroll
  document.body.style.overflow = ''
  document.body.style.position = ''
  document.body.style.width = ''
  document.body.style.height = ''
})
</script>

<style scoped>
/* Base styles */
.dark {
  color-scheme: dark;
}

/* Layout containers */
.login-container {
  @apply w-full max-w-md;
}

.welcome-container {
  @apply w-full max-w-lg relative;
}

/* Login card */
.login-card {
  @apply relative w-full bg-white/90 dark:bg-gray-800/90 backdrop-blur-xl rounded-3xl shadow-2xl transition-all duration-500 ease-in-out p-8;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1), 
              0 0 0 1px rgba(0, 0, 0, 0.05),
              0 0 20px rgba(0, 0, 0, 0.05);
}

.dark .login-card {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3), 
              0 0 0 1px rgba(255, 255, 255, 0.05),
              0 0 20px rgba(0, 0, 0, 0.2);
}

/* Welcome content */
.welcome-content {
  @apply p-6 rounded-3xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl shadow-xl;
}

.welcome-title {
  @apply text-4xl md:text-5xl font-bold mb-8 leading-tight text-gray-800 dark:text-white;
}

.gradient-text {
  @apply bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-400 dark:to-blue-400 bg-clip-text text-transparent;
}

/* Theme toggle */
.theme-toggle {
  @apply absolute top-4 right-4 p-2 rounded-full bg-white/90 dark:bg-gray-700/90 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-600 dark:text-gray-300 transition-all duration-300 ease-in-out shadow-md z-20;
}

/* Feature cards */
.feature-cards {
  @apply flex flex-wrap justify-center md:justify-start gap-4 mb-8;
}

.feature-card {
  @apply flex items-center space-x-3 p-3 rounded-xl bg-white/90 dark:bg-gray-700/90 shadow-md transition-all duration-300 hover:transform hover:-translate-y-1;
}

.feature-icon-wrapper {
  @apply flex items-center justify-center w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900/50;
}

.feature-icon {
  @apply w-5 h-5 stroke-purple-600 dark:stroke-purple-400 stroke-2 fill-none;
}

/* Testimonial */
.testimonial {
  @apply mt-8;
}

.testimonial-content {
  @apply relative p-6 rounded-xl bg-white/90 dark:bg-gray-700/90 shadow-md;
}

.quote-icon {
  @apply absolute -top-3 -left-3 w-8 h-8 text-purple-500 dark:text-purple-400 bg-white dark:bg-gray-800 rounded-full p-1;
}

.testimonial-author {
  @apply mt-4 flex flex-col;
}

.author-name {
  @apply font-medium text-gray-800 dark:text-white;
}

.author-title {
  @apply text-sm text-gray-500 dark:text-gray-400;
}

/* Form elements */
.form-group {
  @apply space-y-1;
}

.input-wrapper {
  @apply relative;
}

.input-icon {
  @apply absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 stroke-current stroke-2 fill-none;
}

.form-input {
  @apply w-full pl-10 pr-4 py-4 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-300;
}

.floating-label {
  @apply absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400 pointer-events-none transition-all duration-300;
}

.form-input:focus + .floating-label,
.form-input:not(:placeholder-shown) + .floating-label {
  @apply text-xs -top-0.5 left-3 text-purple-600 dark:text-purple-400;
}

.form-checkbox {
  @apply h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500 dark:border-gray-600 dark:bg-gray-700;
}

/* Login button */
.login-button {
  @apply w-full py-4 px-4 rounded-xl shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 disabled:opacity-50 disabled:cursor-not-allowed text-white font-medium text-lg;
  background: linear-gradient(135deg, #8b5cf6 0%, #3b82f6 100%);
}

.login-button:hover {
  background: linear-gradient(135deg, #7c3aed 0%, #2563eb 100%);
}

/* Messages */
.message {
  @apply flex items-center p-4 mb-4 rounded-xl;
}

.error-message {
  @apply bg-red-100 text-red-700 dark:bg-red-900/50 dark:text-red-300;
}

.success-message {
  @apply bg-green-100 text-green-700 dark:bg-green-900/50 dark:text-green-300;
}

/* Message animations */
.message-fade-enter-active,
.message-fade-leave-active {
  @apply transition-all duration-300 ease-in-out;
}

.message-fade-enter-from,
.message-fade-leave-to {
  @apply opacity-0 transform -translate-y-4;
}

/* Install prompts */
.install-prompt {
  @apply fixed z-50 pointer-events-auto;
}

.ios-prompt {
  @apply inset-0 flex items-end justify-center;
}

.android-prompt {
  @apply inset-0 flex items-end justify-center;
}

.install-prompt-content {
  @apply w-full max-w-md mx-4 mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-2xl p-6 relative;
}

.install-prompt-arrow {
  @apply absolute -top-2 right-8 w-0 h-0 border-l-[20px] border-r-[20px] border-l-transparent border-r-transparent border-b-[20px] border-b-white dark:border-b-gray-800;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .welcome-title {
    @apply text-3xl;
  }
  
  .feature-cards {
    @apply justify-center;
  }
}

/* Remove o botão nativo de mostrar senha no Edge e IE */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* Remove o botão nativo no Edge Chromium */
input[type="password"]::-webkit-credentials-auto-fill-button,
input[type="password"]::-webkit-clear-button {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}
</style>