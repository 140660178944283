                <template>
                  <BaseTemplate app-name="Gestão de Ativos" page-title="">
                    <template #content>
                      <div class="container mx-auto px-4 py-8 relative">

                        <div class="flex flex-col sm:flex-row items-center justify-between mb-8 space-y-4 sm:space-y-0">
                          <div v-if="isAuthenticated" class="w-full">
                            <div class="flex flex-col sm:flex-row gap-4">
<!-- Só visível no desktop -->
<div class="hidden md:block">
  <button 
    @click="goBack"
    class="btn btn-secondary flex items-center justify-center"
  >
    <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M10 19l-7-7m0 0l7-7m-7 7h18" />
    </svg>
    Voltar
  </button>
</div>


<button 
  @click="imprimirEtiquetas"
  class="btn btn-secondary w-full sm:w-auto flex items-center justify-center"
>
  <Printer class="w-5 h-5 mr-2" />
  Imprimir Etiquetas
</button>

                            </div>
                          </div>
                          <div v-else class="w-full sm:w-auto flex items-center justify-center">
                            <button @click="goToLogin"
                              class="btn btn-primary w-full sm:w-auto flex items-center justify-center">
                              Fazer Login
                              <LogIn class="w-5 h-5 ml-2" />
                            </button>
                          </div>
                        </div>
                        <!-- Detalhes do Kit com Auditoria -->
                        <div v-if="kit" class="flex flex-col md:flex-row gap-6">
                          <!-- Dados do Kit -->
                          <div class="flex-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 space-y-6">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                              <InfoCard label="Código do Kit" :value="kit.kit_code" />
                              <InfoCard label="Status">
                                <template v-if="isAuthenticated">
                                  <select v-model="kit.status" @change="atualizarStatusKit"
                                    class="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:outline-none">
                                    <option value="Disponível">Disponível</option>
                                    <option value="Indisponível">Indisponível</option>
                                    <option value="Em uso">Em uso</option>
                                    <option value="Em estoque">Em estoque</option>
                                  </select>
                                </template>
                                <template v-else>
                                  <span :class="getStatusClass(kit.status)" class="px-3 py-1 rounded-full text-sm">
                                    {{ kit.status }}
                                  </span>
                                </template>
                              </InfoCard>

                              <InfoCard label="Unidade" :value="kit.unidade ? kit.unidade : '-'" />
                              <InfoCard label="Setor" :value="kit.setor ? kit.setor : 'Geral'" />
                              <InfoCard label="Responsável Interno" :value="kit.responsavel_interno?.nome || '-'"/>


                            </div>

                            <!-- Equipamentos do Kit -->
                            <div class="border-t dark:border-gray-700 pt-6">
                              <h3 class="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                                Equipamentos
                              </h3>
                              <div v-if="isAuthenticated">
                                <button @click="openAddEquipmentModal" class="btn btn-primary text-sm">
                                  Adicionar Equipamento
                                </button>
                              </div>

                              <div class="rounded-lg">
                                <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                                  <li v-for="equip in kit.equipamentos" :key="equip.id"
                                    class="p-4 hover:bg-gray-100 dark:hover:bg-blue-600 transition duration-150 rounded-lg m-2 dark:bg-gray-900"
                                    @contextmenu.prevent="showContextMenu($event, equip)">
                                    <div
                                      class="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
                                      <div class="flex-1">
                                        <p class="text-sm font-medium text-gray-900 dark:text-white flex items-center">
                                          <component :is="getIcon(equip.tipo)" class="w-5 h-5 mr-2" />
                                          {{ equip.modelo_nome }}
                                        </p>
                                        <p class="text-sm text-gray-500 dark:text-white inline-flex items-center mt-2">
                                          <Hash class="w-5 h-5 mr-2" />
                                          <span>{{ equip.serial_number }}</span>
                                        </p>
                                      </div>
                                      <span
                                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                        {{ equip.tipo }}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- Auditoria do Kit (Sidebar) -->
                          <div class="w-full md:w-1/3 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                            <h3 class="text-2xl font-bold text-gray-800 dark:text-white border-b pb-2 mb-4">
                              Auditoria
                            </h3>
                            <div class="max-h-96 overflow-y-auto">
                              <template v-if="auditorias.length">
                                <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                                  <li v-for="audit in auditorias" :key="audit.id" class="py-2">
                                    <div class="flex justify-between">
                                      <span class="font-semibold text-gray-800 dark:text-gray-100">
                                        {{ audit.performed_by }} - {{ audit.action.toUpperCase() }}
                                      </span>
                                      <span class="text-xs text-gray-500 dark:text-gray-400">
                                        {{ formatarData(audit.timestamp) }}
                                      </span>
                                    </div>
                                    <div class="text-sm text-gray-600 dark:text-gray-300">
                                      {{ audit.details }}
                                    </div>
                                  </li>
                                </ul>
                              </template>
                              <template v-else>
                                <div class="text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
                                  Sem registros de auditoria.
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>

                        <!-- Seção de Observações -->
                        <div class="bg-white dark:bg-gray-700 rounded-2xl shadow-2xl p-6 space-y-6 mt-8">
                          <h3
                            class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                            Observações
                          </h3>

                          <!-- Bloco para adicionar observação: aparece apenas se isManager for true -->
                          <div v-if="isAuthenticated" class="space-y-4">
                            <textarea v-model="novaObservacao.texto"
                              class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[100px] transition-all placeholder-gray-400 dark:placeholder-gray-500"
                              placeholder="Digite sua observação aqui..." @input="validarObservacao"></textarea>
                            <p v-if="erroObservacao" class="text-red-500 text-sm">
                              ❌ O campo de observação não pode estar vazio.
                            </p>
                            <button @click="adicionarObservacao" :disabled="botaoDesativado || isAddingObservation"
                              class="w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg shadow-md transition-all duration-200 transform hover:scale-100 disabled:opacity-50 disabled:cursor-not-allowed bg-blue-600 text-white">
                              <Send class="w-5 h-5" />
                              <span v-if="!isAddingObservation">Adicionar Observação</span>
                              <span v-else>Adicionando...</span>
                            </button>
                          </div>

                          <!-- Lista de Observações: sempre visível -->
                          <div v-if="observacoes.length > 0" class="space-y-4 mt-6">
                            <div v-for="obs in observacoes" :key="obs.id"
                              class="p-4 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md transition-all hover:shadow-lg dark:hover:shadow-xl bg-white dark:bg-gray-800">
                              <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                                <div class="flex items-center space-x-3">
                                  <UserCircle class="w-6 h-6 text-gray-500 dark:text-gray-400" />
                                  <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">
                                    {{ obs.usuario_nome }}
                                  </span>
                                  <span class="text-xs text-gray-500 dark:text-gray-400">
                                    {{ formatarData(obs.data_criacao) }}
                                  </span>
                                </div>
                                <div v-if="obs.usuario_id === userId" class="flex space-x-2 mt-2 sm:mt-0">
                                  <button @click="editarObservacao(obs)"
                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-yellow-500 hover:bg-yellow-600 transition-all text-white shadow-md active:scale-90">
                                    <Pencil class="w-5 h-5" />
                                  </button>
                                  <button @click="abrirModalExclusaoObservacao(obs.id)"
                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-red-500 hover:bg-red-600 transition-all text-white shadow-md active:scale-90">
                                    <Trash2 class="w-5 h-5" />
                                  </button>
                                </div>
                              </div>
                              <div v-if="obs.editando" class="mt-3">
                                <textarea v-model="obs.textoEditado"
                                  class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent min-h-[80px] transition-all"></textarea>
                                <div class="flex justify-end space-x-2 mt-2">
                                  <button @click="cancelarEdicao(obs)"
                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-gray-500 hover:bg-gray-600 transition-all text-white shadow-md active:scale-90">
                                    <X class="w-5 h-5" />
                                  </button>
                                  <button @click="salvarEdicao(obs)"
                                    class="w-9 h-9 flex items-center justify-center rounded-full bg-green-500 hover:bg-green-600 transition-all text-white shadow-md active:scale-90">
                                    <Check class="w-5 h-5" />
                                  </button>
                                </div>
                              </div>
                              <p v-else class="text-gray-600 dark:text-gray-300 mt-2 leading-relaxed">{{ obs.texto }}
                              </p>
                            </div>
                          </div>
                          <div v-else class="text-gray-500 dark:text-gray-400 text-center mt-4">
                            <FileText class="w-8 h-8 mx-auto mb-2 text-gray-400 dark:text-gray-500" />
                            Nenhuma observação adicionada ainda.
                          </div>
                        </div>

                        <div v-if="isAuthenticated">
                          <div v-if="contextMenu.visible"
                            class="absolute z-50 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg"
                            :style="{ top: contextMenu.y + 'px', left: contextMenu.x + 'px' }">
                            <ul class="py-2">
                              <li @click="removerEquipamento(contextMenu.equipment)"
                                class="px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                Remover do kit
                              </li>
                              <li @click="openChangeEquipmentModal(contextMenu.equipment)"
                                class="px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                Alterar por outro disponível
                              </li>
                            </ul>
                          </div>
                        </div>

                        <!-- Modal para Alterar Equipamento -->
                        <transition name="modal">
                          <div v-if="showEquipmentModal"
                            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                            <div
                              class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                              <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
                                Alterar Equipamento
                              </h3>
                              <p class="mb-4 dark:text-white">
                                Selecione um novo equipamento para substituir
                                <strong>{{ equipmentToChange.modelo_nome }}</strong> (Serial:
                                {{ equipmentToChange.serial_number }})
                              </p>

                              <!-- Filtros -->
                              <div class="mb-4">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                  Tipo
                                </label>
                                <select v-model="filterTipo"
                                  class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2">
                                  <option value="" disabled>Selecione um tipo</option>
                                  <option v-for="t in tipos" :key="t.id" :value="t.nome">
                                    {{ t.nome }}
                                  </option>
                                </select>
                              </div>
                              <div class="mb-4">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                  Modelo
                                </label>
                                <input v-model="filterModelo" type="text" placeholder="Digite o modelo"
                                  class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2" />
                              </div>
                              <button @click="aplicarFiltros" class="mb-4 px-4 py-2 rounded-lg bg-green-600 text-white">
                                Aplicar Filtros
                              </button>

                              <select v-model="selectedNewEquipment"
                                class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2">
                                <option value="" disabled>Selecione um equipamento</option>
                                <option v-for="eq in equipamentosDisponiveis" :key="eq.id" :value="eq.id">
                                  {{ eq.modelo_nome }} - Serial: {{ eq.serial_number }}
                                </option>
                              </select>

                              <div class="flex justify-end gap-3 mt-4">
                                <button @click="closeEquipmentModal"
                                  class="px-4 py-2 rounded-lg bg-gray-500 text-white">
                                  Cancelar
                                </button>
                                <button @click="alterarEquipamento" class="px-4 py-2 rounded-lg bg-blue-600 text-white">
                                  Alterar
                                </button>
                              </div>
                            </div>
                          </div>
                        </transition>

                        <!-- Modal para Adicionar Equipamento -->
                        <transition name="modal">
                          <div v-if="showAddEquipmentModal"
                            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                            <div
                              class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                              <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
                                Adicionar Equipamento
                              </h3>
                              <p class="mb-4 dark:text-white">
                                Selecione um equipamento para adicionar ao kit:
                              </p>

                              <!-- Filtros -->
                              <div class="mb-4">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                  Tipo
                                </label>
                                <select v-model="filterTipo"
                                  class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2">
                                  <option value="" disabled>Selecione um tipo</option>
                                  <option v-for="t in tipos" :key="t.id" :value="t.nome">
                                    {{ t.nome }}
                                  </option>
                                </select>
                              </div>
                              <div class="mb-4">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                  Modelo
                                </label>
                                <input v-model="filterModelo" type="text" placeholder="Digite o modelo"
                                  class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2" />
                              </div>
                              <button @click="aplicarFiltros" class="mb-4 px-4 py-2 rounded-lg bg-green-600 text-white">
                                Aplicar Filtros
                              </button>

                              <select v-model="selectedEquipmentToAdd"
                                class="w-full border border-gray-300 dark:border-gray-600 rounded-lg p-2">
                                <option value="" disabled>Selecione um equipamento</option>
                                <option v-for="eq in equipamentosDisponiveis" :key="eq.id" :value="eq.id">
                                  {{ eq.modelo_nome }} - Serial: {{ eq.serial_number }}
                                </option>
                              </select>

                              <div class="flex justify-end gap-3 mt-4">
                                <button @click="closeAddEquipmentModal"
                                  class="px-4 py-2 rounded-lg bg-gray-500 text-white">
                                  Cancelar
                                </button>
                                <button @click="addEquipment" class="px-4 py-2 rounded-lg bg-blue-600 text-white">
                                  Adicionar
                                </button>
                              </div>
                            </div>
                          </div>
                        </transition>

                        <!-- Modal de Confirmação para Exclusão de Observação -->
                        <transition name="modal">
                          <div v-if="modalExcluiObservacao"
                            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                            <div
                              class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                              <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">⚠️ Confirmação de
                                Exclusão
                              </h3>
                              <p class="text-gray-600 dark:text-gray-300">
                                Tem certeza de que deseja excluir esta observação? <br />
                                <strong>Esta ação é irreversível!</strong>
                              </p>
                              <div class="flex justify-end gap-3 mt-6">
                                <button @click="modalExcluiObservacao = false"
                                  class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-all">
                                  Cancelar
                                </button>
                                <button @click="excluirObservacaoConfirmada" :disabled="isDeletingObservation"
                                  class="px-6 py-2.5 bg-red-600 text-white rounded-lg font-medium hover:bg-red-700 transition-all"
                                  :class="{ 'opacity-50 cursor-not-allowed': isDeletingObservation }">
                                  <span v-if="!isDeletingObservation">Sim, Excluir</span>
                                  <span v-else>Excluindo...</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </transition>
                      </div>
                      <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
                      </p>
                    </template>
                  </BaseTemplate>
                </template>

<script setup>
import { ref, onMounted, defineComponent, h, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import BaseTemplate from './BaseTemplate.vue';
import {
  Send, UserCircle, Pencil, Trash2, X, Check, FileText, Smartphone,
  Tablet, PcCase, Monitor, Server, Router, Wifi, Hash, Printer, LogIn, LaptopMinimal, Laptop
} from 'lucide-vue-next';
import QRCode from 'qrcode';

// Componente InfoCard (em JSX)
const InfoCard = defineComponent({
  props: {
    label: { type: String, required: true },
    value: { type: String, default: '' }
  },
  setup(props, { slots }) {
    return () =>
      h('div', { class: 'bg-gray-50 dark:bg-gray-900 p-4 rounded-lg' }, [
        h('div', { class: 'text-sm font-medium text-gray-500 dark:text-gray-400' }, props.label),
        h('div', { class: 'mt-1 text-gray-900 dark:text-white' }, slots.default ? slots.default() : props.value)
      ]);
  }
});

const route = useRoute();
const router = useRouter();
const kit = ref(null);
const userId = ref(parseInt(sessionStorage.getItem("user_id"), 10) || null);
const isAuthenticated = computed(() => Boolean(userId.value));
const apiToken = sessionStorage.getItem("api_token");
const headers = {
  'Content-Type': 'application/json',
  'X-API-KEY': apiToken
};

const novaObservacao = ref({ texto: '' });
const erroObservacao = ref(false);
const isAddingObservation = ref(false);
const isDeletingObservation = ref(false);
const observacoes = ref([]);
const modalExcluiObservacao = ref(false);
const observacaoParaExcluir = ref(null);
const botaoDesativado = computed(() => novaObservacao.value.texto.trim() === "");

// Estado do menu de contexto para equipamentos
const contextMenu = ref({
  visible: false,
  x: 0,
  y: 0,
  equipment: null
});

// Variáveis para modais e filtros
const showEquipmentModal = ref(false);
const equipmentToChange = ref(null);
const selectedNewEquipment = ref('');
const showAddEquipmentModal = ref(false);
const selectedEquipmentToAdd = ref('');
const tipos = ref([]);
const filterTipo = ref('');
const filterModelo = ref('');
const equipamentosDisponiveis = ref([]);

const atualizarStatusKit = async () => {
  try {
    await axios.put(`/api/v1/kits/${kit.value.id}/`, {
      status: kit.value.status
    }, { headers });

    fetchAuditorias(); // Opcional: atualiza lista de auditoria se o status for auditado
  } catch (error) {
    console.error("Erro ao atualizar status do kit:", error);
  }
};


// Função para buscar os tipos do endpoint /api/v1/tipos/
const fetchTipos = async () => {
  if (!isAuthenticated.value) {
    console.log("Usuário não autenticado. Requisição não efetuada.");
    return;
  }
  try {
    const response = await axios.get('/api/v1/tipos/', { headers });
    tipos.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar tipos:", error);
  }
};


onMounted(() => {
  fetchTipos();
});

// Função para buscar detalhes do kit, incluindo o token recebido via query string
// Função para buscar detalhes do kit, utilizando o verification_token recebido via query string
const fetchKitDetails = async (id) => {
  try {
    const verification_token = route.query.verification_token; // recupera o token da URL
    const response = await axios.get(`/api/v1/kits/${id}/`, {
      headers,
      params: { verification_token } // envia o token como query param com o nome correto
    });
    kit.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar detalhes do kit:", error);
    router.push({ name: 'NotFound' });
  }
};


// Função para retornar o ícone com base no tipo
const getIcon = (tipo) => {
  if (!tipo || typeof tipo !== "string") {
    return Hash;
  }
  const type = tipo.toLowerCase();
  switch (type) {
    case "smartphone":
      return Smartphone;
    case "notebook":
      return Laptop;
    case "monitor":
      return Monitor;
    case "tablet":
      return Tablet;
    case "desktop":
      return PcCase;
    case "all in one":
      return LaptopMinimal;
    case "servidor":
      return Server;
    case "switch":
      return Router;
    case "wi-fi":
      return Wifi;
    default:
      return Hash;
  }
};

const fetchObservacoes = async () => {
  try {
    const verification_token = route.query.verification_token; // recupera o token da URL
    const response = await axios.get(`/api/v1/kits/${kit.value.id}/observacoes/`, {
      headers,
      params: { verification_token } // envia o token como query param
    });
    observacoes.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar observações:", error);
  }
};

const auditorias = ref([]);
const fetchAuditorias = async () => {
  try {
    const verification_token = route.query.verification_token; // recupera o token da URL
    const response = await axios.get("/api/v1/auditoria/", {
      headers,
      params: {
        resource: "kit",
        kit_code: kit.value.kit_code,
        verification_token // envia o token como query param
      }
    });
    auditorias.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar auditorias:", error);
  }
};

onMounted(async () => {
  const id = route.params.id;
  await fetchKitDetails(id);
  await fetchObservacoes();
  await fetchAuditorias(); // Busca as auditorias após o kit estar carregado
  document.addEventListener("click", () => {
    contextMenu.value.visible = false;
  });
});

const goBack = () => router.go(-1);
const goToLogin = () => {
  router.push({ name: 'Login' }); // ajuste o nome da rota conforme sua configuração
};

const getStatusClass = (status) => {
  switch (status) {
    case 'Disponível':
      return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
    case 'Em uso':
      return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200';
    case 'Em manutenção':
      return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200';
    case 'Em estoque':
      return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200';
    default:
      return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
  }
};

const imprimirEtiquetas = async () => {
  if (!kit.value || !kit.value.equipamentos || kit.value.equipamentos.length === 0) {
    alert("Não há equipamentos para imprimir etiquetas.");
    return;
  }

  // Utiliza o validation_code retornado pela requisição (campo 'validation_code' do kit)
  const validationCode = kit.value.validation_code || route.query.validation_code;

  if (!validationCode) {
    alert("Código de validação não encontrado.");
    return;
  }

  // Monta o URL usando o validation code como parâmetro "verification_token"
  const pageUrl = `${window.location.origin}/kits/${kit.value.id}/?verification_token=${validationCode}`;

  const printWindow = window.open('', '_blank');
  let htmlContent = `
    <html>
      <head>
        <title>Etiquetas do Kit ${kit.value.kit_code}</title>
        <style>
          @page {
            size: auto;
            margin: 0mm;
          }
          
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
          }

          .container-etiquetas {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;
          }

          .etiqueta {
            width: 50mm;
            height: 20mm;
            box-sizing: border-box;
            display: flex;
            align-items: stretch;
            margin: 0;
            padding: 0;
            page-break-inside: avoid;
            border-left: 0.5px solid #000;
          }

          .qrcode {
            width: 20mm;
            height: 20mm;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.5px solid #000;
          }

          .qrcode img {
            width: 18mm;
            height: 18mm;
            object-fit: contain;
          }

          .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1mm 2mm;
          }

          .info {
            font-size: 8pt;
            line-height: 1.3;
            font-weight: bold;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .unidade {
            font-size: 6pt;
          }

          .header {
            display: flex;
            align-items: center;
            gap: 1mm;
          }

          .logo {
            height: 8mm;
            align-items: center;
            width: auto;
          }

          .ti-text {
            font-size: 6pt;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="container-etiquetas">`;

  for (let i = 0; i < kit.value.equipamentos.length; i++) {
    let qrCodeDataUrl = '';
    try {
      qrCodeDataUrl = await QRCode.toDataURL(pageUrl, {
        width: 180,
        height: 180,
        margin: 0
      });
    } catch (error) {
      console.error("Erro ao gerar QR Code:", error);
    }

    const kitCodeExibido = kit.value.kit_code.slice(4);
    htmlContent += `
          <div class="etiqueta">
            <div class="qrcode">
              <img src="${qrCodeDataUrl}" alt="QR Code">
            </div>
            <div class="info-container">
              <div class="header">
                <img src="/logo_ccdti.png" alt="Logo CCDTI" class="logo">
              </div>
              <div>
                <div class="info">${kitCodeExibido}</div>
                <div class="info unidade">${kit.value.unidade ? kit.value.unidade : '-'}</div>
              </div>
            </div>
          </div>
        `;
  }
  htmlContent += `
        </div>
        <script>
          window.onload = function() {
            window.print();
          };
        </` + `script>
      </body>
    </html>
  `;

  printWindow.document.open();
  printWindow.document.write(htmlContent);
  printWindow.document.close();
};

const validarObservacao = () => {
  erroObservacao.value = novaObservacao.value.texto.trim() === "";
};

const adicionarObservacao = async () => {
  if (novaObservacao.value.texto.trim() === "" || isAddingObservation.value) {
    erroObservacao.value = true;
    return;
  }
  try {
    isAddingObservation.value = true;
    await axios.post(`/api/v1/kits/${kit.value.id}/observacoes/`, { texto: novaObservacao.value.texto.trim() }, { headers });
    novaObservacao.value.texto = "";
    erroObservacao.value = false;
    await fetchObservacoes();
  } catch (error) {
    console.error("Erro ao adicionar observação:", error);
  } finally {
    isAddingObservation.value = false;
  }
};

const aplicarFiltros = async () => {
  try {
    const response = await axios.get("/api/v1/ativos/", {
      headers,
      params: {
        status: "Disponível",
        tipo: filterTipo.value,
        modelo: filterModelo.value
      }
    });
    equipamentosDisponiveis.value = response.data;
  } catch (error) {
    console.error("Erro ao aplicar filtros:", error);
  }
};

const openAddEquipmentModal = async () => {
  try {
    const response = await axios.get('/api/v1/ativos/', {
      headers,
      params: {
        status: "Disponível",
        tipo: filterTipo.value,
        modelo: filterModelo.value
      }
    });
    equipamentosDisponiveis.value = response.data;
  } catch (error) {
    console.error("Erro ao carregar equipamentos disponíveis:", error);
  }
  showAddEquipmentModal.value = true;
};

const closeAddEquipmentModal = () => {
  showAddEquipmentModal.value = false;
  selectedEquipmentToAdd.value = '';
};

const addEquipment = async () => {
  if (!selectedEquipmentToAdd.value) return;
  try {
    const response = await axios.post(`/api/v1/kits/${kit.value.id}/equipamentos/`, { equipamento_id: selectedEquipmentToAdd.value }, { headers });
    kit.value = response.data;
  } catch (error) {
    console.error("Erro ao adicionar equipamento:", error);
  }
  closeAddEquipmentModal();
  fetchAuditorias();
};

const editarObservacao = (obs) => {
  obs.editando = true;
  obs.textoEditado = obs.texto;
};

const cancelarEdicao = (obs) => {
  obs.editando = false;
  obs.textoEditado = "";
};

const salvarEdicao = async (obs) => {
  try {
    await axios.put(`/api/v1/kits/${kit.value.id}/observacoes/${obs.id}/`, { texto: obs.textoEditado }, { headers });
    obs.texto = obs.textoEditado;
    obs.editando = false;
  } catch (error) {
    console.error("Erro ao editar observação:", error);
  }
};

const abrirModalExclusaoObservacao = (obsId) => {
  observacaoParaExcluir.value = obsId;
  modalExcluiObservacao.value = true;
};

const excluirObservacaoConfirmada = async () => {
  if (!observacaoParaExcluir.value || isDeletingObservation.value) return;
  try {
    isDeletingObservation.value = true;
    await axios.delete(`/api/v1/kits/${kit.value.id}/observacoes/${observacaoParaExcluir.value}/`, { headers });
    observacoes.value = observacoes.value.filter(obs => obs.id !== observacaoParaExcluir.value);
    modalExcluiObservacao.value = false;
    observacaoParaExcluir.value = null;
  } catch (error) {
    console.error("Erro ao excluir observação:", error);
  } finally {
    isDeletingObservation.value = false;
  }
};

const formatarData = (data) => {
  const dt = new Date(data);
  return dt.toLocaleDateString("pt-BR") + " " + dt.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
};

const showContextMenu = (event, equip) => {
  event.preventDefault();
  contextMenu.value = { visible: true, x: event.clientX, y: event.clientY, equipment: equip };
};

const removerEquipamento = async (equip) => {
  try {
    await axios.delete(`/api/v1/kits/${kit.value.id}/equipamentos/${equip.id}/`, { headers });
    kit.value.equipamentos = kit.value.equipamentos.filter(e => e.id !== equip.id);
  } catch (error) {
    console.error("Erro ao remover equipamento:", error);
  }
  contextMenu.value.visible = false;
  fetchAuditorias();
};

const openChangeEquipmentModal = async (equip) => {
  equipmentToChange.value = equip;
  try {
    const response = await axios.get('/api/v1/ativos/', {
      headers,
      params: {
        status: "Disponível",
        tipo: equip.tipo,
        modelo_id: equip.modelo_id
      }
    });
    equipamentosDisponiveis.value = response.data;
  } catch (error) {
    console.error("Erro ao carregar equipamentos disponíveis:", error);
  }
  showEquipmentModal.value = true;
  contextMenu.value.visible = false;
};

const closeEquipmentModal = () => {
  showEquipmentModal.value = false;
  selectedNewEquipment.value = '';
  equipmentToChange.value = null;
};

const alterarEquipamento = async () => {
  if (!selectedNewEquipment.value) return;
  try {
    const response = await axios.put(
      `/api/v1/kits/${kit.value.id}/equipamentos/${equipmentToChange.value.id}/`,
      { new_equipment_id: selectedNewEquipment.value },
      { headers }
    );
    const index = kit.value.equipamentos.findIndex(e => e.id === equipmentToChange.value.id);
    if (index !== -1) {
      kit.value.equipamentos.splice(index, 1, { ...kit.value.equipamentos[index], ...response.data });
    }
    await fetchKitDetails(kit.value.id);
  } catch (error) {
    console.error("Erro ao alterar equipamento:", error);
  }
  closeEquipmentModal();
  fetchAuditorias();
};
</script>

<style scoped>
.btn {
  @apply font-bold py-2.5 px-4 rounded-lg transition duration-200 flex items-center justify-center;
}

.btn-primary {
  @apply bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 shadow-sm hover:shadow-md;
}

.btn-secondary {
  @apply bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 shadow-sm hover:shadow-md;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Transição para o Modal */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
