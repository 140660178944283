import { createRouter, createWebHistory } from 'vue-router';
import LoginScreen from '../components/LoginScreen.vue';
import RegisterScreen from '../components/RegisterScreen.vue';
import ResetPassword from '../components/ResetPassword.vue';
import EventosList from '../components/EventosList.vue';
import DetalhesEventos from '../components/DetalhesEventos.vue';
import MetricsView from '../components/MetricsView.vue';
import NotFound from '../components/NotFound.vue';
import Offline from '../components/OfflineScreen.vue';
import DashboardEquipamentos from '../components/DashboardEquipamentos.vue';
import DashboardInstancias from '@/components/DashboardInstancias.vue';
import DashboardUnidades from "@/components/DashboardUnidades.vue";
import PendenciasDirecao from '@/components/PendenciasList.vue';
import PendenciaDetalhes from '@/components/PendenciaDetalhes.vue';
import PendenciasKanban from '@/components/PendenciasKanban.vue';
import CadastroFornecedores from '@/components/CadastroFornecedores.vue';
import ConfigScreen from '@/components/ConfigScreen.vue';
import { ref,} from "vue";
import AtivosList from '@/components/AtivosList.vue';
import KitsDetalhes from "@/components/KitsDetalhes.vue";
import GestaoUsuarios from "@/components/GestaoUsuarios.vue";
import GestaoAcessos from "@/components/GestaoAcessos.vue";
import GestaoEquipments from "@/components/GestaoEquipments.vue";
import GestaoClinicasSetores from "@/components/GestaoClinicasSetores.vue";
import InsightsPendencias from '@/components/InsightsPendencias.vue';
import GerarToken from '@/components/GerarToken.vue';
import GestaoInformes from '@/components/GestaoInformes.vue';
import GestaoChamados from '@/components/HelpScreen.vue';
import HomePage from '@/components/HomePage.vue';
import FeedPage from '@/components/FeedPage.vue';
import UnidadesMedicasDetalhes from '@/components/UnidadesMedicasDetalhes.vue';
import MeuPerfil from '@/components/MeuPerfil.vue';
import CadastroCoordenadores from '@/components/CadastroCoordenadores.vue';
import VersionPage from '@/components/VersionPage.vue';
import DocumentPage from '@/components/DocumentPage.vue';



const userId = ref(null); // ✅ Armazena apenas o ID numérico do usuário
const API_URL = process.env.VUE_APP_API_URL;


const areNotificationsEnabled = ref(false); // Estado reativo para notificações

// Função para validar o token com o backend e obter estado das notificações
async function validateTokenWithBackend(token) {
  try {
    const response = await fetch(`${API_URL}/api/v1/auth/validate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (!response.ok || !data?.valid) {
      throw new Error(data?.message || "Token inválido");
    }

    // ✅ Armazena os dados do usuário no sessionStorage
    sessionStorage.setItem("user_id", data.user_id); // 🔹 Agora armazena o ID do usuário
    sessionStorage.setItem("user_groups", JSON.stringify(data.groups || []));
    sessionStorage.setItem("firstname", data.firstname || "");
    sessionStorage.setItem("lastname", data.lastname || "");
    sessionStorage.setItem(
      "notifications_enabled",
      data.notifications_enabled ? "true" : "false"
    );

    // ✅ Atualiza o estado reativo do Vue
    areNotificationsEnabled.value = !!data.notifications_enabled;
    userId.value = data.user_id; // 🔹 Define o estado do Vue com o ID do usuário

    return {
      valid: true,
      user_id: data.user_id, // 🔹 Agora retorna o ID do usuário
      firstname: data.firstname || "",
      lastname: data.lastname || "",
      groups: data.groups || [],
      notifications_enabled: !!data.notifications_enabled,
    };
  } catch (error) {
    console.error("Erro ao validar token:", error);

    // Se houver erro, garantir que `areNotificationsEnabled` volte ao estado padrão
    areNotificationsEnabled.value = false;
    userId.value = null; // 🔹 Reseta o estado do usuário

    return { valid: false };
  }
}

// Função de autenticação
async function isAuthenticated() {
  const token = sessionStorage.getItem('api_token');
  const expiry = sessionStorage.getItem('token_expiry');

  if (!token || !expiry) {
    return false;
  }

  const now = Date.now();
  if (now > parseInt(expiry, 10)) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
    return false;
  }

  const isValid = await validateTokenWithBackend(token);

  if (!isValid) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
  }

  return isValid;
}

// Função para obter os grupos do usuário
function getUserGroups() {
  const groups = sessionStorage.getItem('user_groups');
  return groups ? JSON.parse(groups) : [];
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginScreen,
    meta: { title: "Login - Dashboard" },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterScreen,
    meta: { title: "Cadastro - Dashboard" },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { title: "Recuperar Senha - Dashboard" },
  },
  {
    path: "/version",
    name: "VersionPage",
    component: VersionPage,
    meta: { title: "Versão do Software" },
  },
  {
    path: "/meuperfil",
    name: "MeuPerfil",
    component: MeuPerfil,
    meta: { title: "Meu Perfil",
      requiresAuth: true,
     },
  },
  {
    path: "/gerar_token",
    name: "GerarToken",
    component: GerarToken,
    meta: { title: "Gerar Token" },
  },
  {
    path: "/eventos",
    name: "EventosList",
    component: EventosList,
    meta: {
      requiresAuth: true,
      roles: ["engenhariaclinica", "direcao", "superadmin"],
      title: "Eventos - Dashboard",
    },
  },
  {
    path: "/eventos/:id",
    name: "DetalhesEvento",
    component: DetalhesEventos,
    meta: { requiresAuth: true, title: "Detalhes do Evento - Dashboard" },
    props: true,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: true, title: "Tela de Início" },
    props: true,
  },
  {
    path: "/dashboardequipamentos",
    name: "DashboardEquipamentos",
    component: DashboardEquipamentos,
    meta: { requiresAuth: true, title: "Equipamentos - Dashboard" },
  },
  {
    path: "/dashboardinstancias",
    name: "DashboardInstancias",
    component: DashboardInstancias,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Instâncias - Dashboard",
    },
  },
  {
    path: "/documents",
    name: "Documentos",
    component: DocumentPage,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin", "desenvolvedor"],
      title: "Documentos - Dashboard",
    },
  },
  {
    path: "/dashboardunidades",
    name: "DashboardUnidades",
    component: DashboardUnidades,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Unidades - Dashboard",
    },
  },
  {
    path: "/unidades/detalhes/:id",
    name: "UnidadesMedicasDetalhes",
    component: UnidadesMedicasDetalhes,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin", "engenhariaclinica"],
      title: "Unidades - Detalhes",
    },
    props: true,
  },
  {
    path: "/informes",
    name: "GestaoInformes",
    component: GestaoInformes,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Informes - Portal Paciente",
    },
  },
  {
    path: "/help",
    name: "GestaoChamados",
    component: GestaoChamados,
    meta: {
      requiresAuth: true,
      //roles: ["ti", "superadmin", "desenvolvedor", "admin", "manager", "user"],
      title: "Portal de Ajuda",
    },
  },
  {
    path: "/ativos",
    name: "Ativos",
    component: AtivosList,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Ativos - Dashboard",
    },
  },
  {
    path: "/pendencias",
    name: "PendenciasDirecao",
    component: PendenciasDirecao,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "coordenacao"],
      title: "Pendências - Dashboard",
    },
  },
  {
    path: "/pendencias/visualizer",
    name: "PendenciasKanban",
    component: PendenciasKanban,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "coordenacao"],
      title: "Kanban - Pendências",
    },
  },
  {
    path: "/insights",
    name: "InsightsKanban",
    component: InsightsPendencias,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "coordenacao"],
      title: "Kanban - Pendências",
    },
  },
  {
    path: "/metrics/:id",
    name: "Metrics",
    component: MetricsView,
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Métricas - Dashboard",
    },
    props: true,
  },
  {
    path: "/kits/:id",
    name: "KitsDetalhes",
    component: KitsDetalhes,
    meta: {
      
      title: "Detalhes do Kit - Dashboard",
    },
    props: true,
  },
  {
    path: "/feed",
    name: "Feed",
    component: FeedPage,
    meta: {
      requiresAuth: true,
      title: "Feed Noticias",
    },
    props: true,
  },
  {
    path: "/pendencia/detalhes/:id",
    name: "PendenciaDetalhes",
    component: PendenciaDetalhes,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "coordenacao"],
      title: "Detalhes da Pendência - Dashboard",
    },
    props: true,
  },
  {
    path: "/AtivosDetalhes/:id",
    name: "AtivosDetalhes",
    component: () => import('@/components/AtivosDetalhes.vue'),
    meta: {
      requiresAuth: true,
      roles: ["ti", "superadmin"],
      title: "Ativos Detalhes",
    },
    props: true,
},
  {
    path: "/offline",
    name: "Offline",
    component: Offline,
    meta: { title: "Offline - Dashboard" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: { title: "Página Não Encontrada - Dashboard" },
  },
  {
    path: "/config/fornecedores",
    name: "CadastroFornecedores",
    component: CadastroFornecedores,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin"],
      title: "Fornecedores - Dashboard",
    },
  },
  {
    path: "/config/coordenadores",
    name: "CadastroCoordenadores",
    component: CadastroCoordenadores,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin"],
      title: "Coordenadores - Dashboard",
    },
  },
  {
    path: "/config/users",
    name: "GestaoUsuarios",
    component: GestaoUsuarios,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin"],
      title: "Gestao Usuarios - Dashboard",
    },
  },
  {
    path: "/config/groups",
    name: "GestaoAcessos",
    component: GestaoAcessos,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin"],
      title: "Gestao Acessos - Dashboard",
    },
  },
  {
    path: "/config/equipments",
    name: "GestaoEquipments",
    component: GestaoEquipments,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "engenhariaclinica"],
      title: "Gestao de Equipamentos - Dashboard",
    },
  },
  {
    path: "/config/preferences",
    name: "GestaoClinicasSetores",
    component: GestaoClinicasSetores,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin"],
      title: "Cadastro de Clinicas e Setores - Dashboard",
    },
  },
  {
    path: "/config",
    name: "ConfigScreen",
    component: ConfigScreen,
    meta: {
      requiresAuth: true,
      roles: ["direcao", "superadmin", "ti", "engenhariaclinica"],
      title: "Configurações - Dashboard",
    },
  },
];



// Criação do roteador
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    // Atualiza o título da página com base na rota
    if (to.meta.title) {
      document.title = to.meta.title;
    } else {
      document.title = "Dashboard"; // Título padrão
    }

    // Verifica se o navegador está offline
    if (!navigator.onLine) {
      if (to.name !== "Offline") {
        // Redireciona para a tela "Offline" e preserva a rota de destino
        return next({
          name: "Offline",
          query: { redirect: to.fullPath }, // Salva a rota original na query
        });
      }
    } else if (to.name === "Offline") {
      // Se estiver online e na rota "Offline", redireciona para a página principal
      const redirect = to.query.redirect || "/";
      return next(redirect);
    }

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    // Verifica se a rota não exige autenticação
    if (!requiresAuth) {
      return next();
    }

    const isLoggedIn = await isAuthenticated();

    if (!isLoggedIn) {
      if (to.name === "Login") {
        // Evita redirecionamento para login se já estamos na tela de login
        return next();
      }
      return next({ name: "Login", query: { accessDenied: true } });
    }

    const roles = to.meta.roles || [];
    const userGroups = getUserGroups();

    if (roles.length > 0 && !roles.some((role) => userGroups.includes(role))) {
      return next({
        name: "DashboardEquipamentos",
        query: { unauthorized: true },
      });
    }

    next();
  } catch (error) {
    next({ name: "Login", query: { error: true } });
  }
});









export default router;
