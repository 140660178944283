<template>
    <BaseTemplate app-name="Pendências" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-4 md:px-6 lg:px-1">

                <!-- Apenas exibe a tela quando o carregamento estiver concluído -->
                <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-screen">
                    <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                </div>
                    
                    <!-- Se a API não retornou nenhuma pendência -->
                    <div v-if="pendencias.length === 0" class="flex items-center justify-center min-h-[60vh] text-center text-gray-600 dark:text-gray-300">
                    <p class="text-xl font-semibold">Não há nenhuma pendência atribuída a você 🎉</p>
                    </div>
                <!-- Exibe apenas se o carregamento estiver concluído -->
                <div v-else>




<!-- Header -->
<div class="flex flex-col gap-5 mb-8">
    <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div class="flex items-center gap-3">
            <h2 class="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-white tracking-tight">
                Lista de Pendências
            </h2>

            
            <Transition name="fade-scale">
  <span v-if="!exibirKanban"
        class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-semibold px-3 py-1 rounded-full flex items-center justify-center min-w-[72px]">
    {{ pendenciasFiltradas.length }} itens
  </span>
</Transition>

        </div>

        <div class="flex flex-wrap sm:flex-nowrap gap-3 w-full sm:w-auto">
            <transition name="fade-slide">
            <div v-if="!exibirKanban" class="flex flex-wrap sm:flex-nowrap gap-3 w-full sm:w-auto">
  <!-- Campo de busca -->
  <div class="relative w-full sm:w-72">
    <input
      v-model="filtroProblema"
      type="text"
      placeholder="Buscar por problema..."
      class="pl-10 pr-4 py-2.5 w-full border rounded-lg text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
    />
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-400">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
    </div>
  </div>

  <!-- Botão de Filtro -->
  <button
    @click="abrirModalFiltro"
    class="relative flex-1 sm:flex-none flex items-center justify-center gap-2 px-4 py-2.5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-all duration-200 focus:ring-2 focus:ring-gray-400 focus:outline-none shadow-md w-full sm:w-auto"
  >
    <Filter class="w-5 h-5" />
    <span class="font-medium">Filtros</span>
    <span
      v-if="totalFiltros > 0"
      class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center animate-pulse"
    >
      {{ totalFiltros }}
    </span>
  </button>
</div>

</transition>
<!-- Container responsivo para botões -->
<div class="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
  <!-- Botão Kanban / Lista -->
  <button
    @click="irParaKanban"
    class="flex items-center justify-center gap-2 px-5 py-2.5 rounded-xl shadow-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-400 w-full sm:w-auto"
  >
    <component :is="exibirKanban ? List : SquareKanban" class="w-5 h-5" />
    <span class="font-semibold tracking-wide">
      {{ exibirKanban ? 'Ver Lista' : 'Kanban' }}
    </span>
  </button>

  <!-- Botão Nova Pendência (visível apenas para criadores) -->
  <button
    v-if="isCriador"
    @click="abrirModalCriarPendencia"
    class="flex items-center justify-center gap-2 px-6 py-2.5 rounded-xl shadow-md bg-blue-600 text-white hover:bg-blue-700 transition-all duration-300 ease-in-out w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium"
  >
    <PlusCircle class="w-5 h-5" />
    <span>Nova Pendência</span>
  </button>
</div>

            </div>
        </div>
    </div>

<transition name="fade-slide">
<!-- Filtros adicionais - Checkbuttons melhorados -->
<div v-if="!exibirKanban" class="flex flex-wrap items-center gap-6 mt-1 mb-3">
  <label class="flex items-center gap-2 cursor-pointer group">
    <div class="relative">
      <input type="checkbox" v-model="mostrarConcluidos" class="peer sr-only" />
      <div class="w-5 h-5 border-2 border-gray-300 dark:border-gray-600 rounded-md peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all duration-200"></div>
      <div class="absolute top-[2px] left-[2px] w-4 h-4 text-white opacity-0 peer-checked:opacity-100 pointer-events-none transition-opacity duration-200">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      </div>
    </div>
    <span class="text-sm text-gray-700 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
      Mostrar Concluídos
    </span>
  </label>

  <label class="flex items-center gap-2 cursor-pointer group">
    <div class="relative">
      <input type="checkbox" v-model="mostrarExcluidos" class="peer sr-only" />
      <div class="w-5 h-5 border-2 border-gray-300 dark:border-gray-600 rounded-md peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all duration-200"></div>
      <div class="absolute top-[2px] left-[2px] w-4 h-4 text-white opacity-0 peer-checked:opacity-100 pointer-events-none transition-opacity duration-200">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      </div>
    </div>
    <span class="text-sm text-gray-700 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
      Mostrar Cancelados
    </span>
  </label>
</div>
</transition>
</div>
                    
                        <!-- Toast de Sucesso -->
                        <div v-if="mensagemSucesso"
                            class="fixed top-5 right-5 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in">
                            {{ mensagemSucesso }}
                        </div>



                    <!-- Se a API retornou pendências, mas o filtro não encontrou nenhuma -->
                    <div v-else-if="pendenciasFiltradas.length === 0" class="flex items-center justify-center min-h-[60vh] text-center text-gray-600 dark:text-gray-300">
                    <p class="text-xl font-semibold">
                        Nenhuma pendência encontrada com os filtros aplicados.
                        <br>
                        <span class="text-base font-normal">
                        Se desejar visualizar pendências concluídas ou canceladas, ative as opções "Mostrar Concluídos" e "Mostrar Cancelados".
                        </span>
                    </p>
                    </div>




                    <template v-else>
                        <Transition name="fade-slide" mode="out-in">
      <!-- Kanban ou Lista -->
      <div v-if="exibirKanban">
        <PendenciasKanban :pendencias="pendenciasFiltradas" />
      </div>

                    <!-- Caso existam pendências -->
                    <div v-else>

                    <!-- Tabela (Escondida em telas pequenas) -->
                    <div
                        class="bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-200 dark:border-gray-700 overflow-hidden hidden sm:block">
                        <table class="w-full">
                            <thead>
                                <tr
                                    class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
                                    <th @click="ordenarPor('codigo')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Código <span
                                            v-if="colunaOrdenada === 'codigo'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('problema')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Problema <span
                                            v-if="colunaOrdenada === 'problema'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('unidade.nome')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Unidade <span
                                            v-if="colunaOrdenada === 'unidade.nome'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('setor.nome')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Setor <span
                                            v-if="colunaOrdenada === 'setor.nome'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('status.nome')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Status <span
                                            v-if="colunaOrdenada === 'status.nome'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('responsavel_interno')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Interno <span
                                            v-if="colunaOrdenada === 'responsavel_interno'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    <th @click="ordenarPor('responsavel_externo')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Externo<span
                                            v-if="colunaOrdenada === 'responsavel_externo'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span></th>
                                    
                                    <th @click="ordenarPor('data_criacao')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Data de Criação
                                        <span v-if="colunaOrdenada === 'data_criacao'">{{ ordemAscendente ? '▲' :
                                            '▼' }}</span>
                                    </th>
                                    <th @click="ordenarPor('prioridade')"
                                        class="cursor-pointer text-left px-6 py-4 whitespace-nowrap">Prioridade
                                        <span v-if="colunaOrdenada === 'prioridade'">{{ ordemAscendente ? '▲' : '▼'
                                            }}</span>
                                    </th>
                                    <th
                                        class="px-6 py-4 text-sm font-semibold text-gray-200 dark:text-gray-300 text-center whitespace-nowrap">
                                        Ações</th>
                                </tr>
                            </thead>

                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                    <tr v-for="pendencia in pendenciasFiltradas" :key="pendencia.id"
                                        class="group hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200">
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                            pendencia.codigo
                                            }}</td>
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                            pendencia.problema }}</td>
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">
                                            {{ (pendencia.setor && pendencia.setor.unidade) ?
                                            pendencia.setor.unidade.nome :
                                            (pendencia.unidade ? pendencia.unidade.nome : 'Geral') }}
                                        </td>
                                        <td
                                            class="hidden md:table-cell px-6 py-4 text-sm text-gray-800 dark:text-gray-300">
                                            {{ pendencia.setor?.nome || 'Geral' }}</td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <span :class="getStatusClass(pendencia.status.nome)"
                                                class="px-3 py-1 rounded-full text-sm  text-xs font-medium whitespace-nowrap">
                                                {{ pendencia.status.nome }}
                                            </span>
                                        </td>
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                            pendencia.responsavel_interno || "Não informado" }}</td>
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                            pendencia.responsavel_externo || "Não informado" }}</td>
                                        <td class="px-6 py-4 text-sm text-gray-800 dark:text-gray-300">{{
                                            formatarData(pendencia.data_criacao) }}</td>
                                        <td class="hidden sm:table-cell px-6 py-4">
                                            <span :class="getPrioridadeClass(pendencia.prioridade)"
                                                class="px-3 py-1 uppercase rounded-full text-xs font-medium">
                                                {{ pendencia.prioridade }}
                                            </span>
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            <button @click="verDetalhes(pendencia.id)"
                                                class="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                                                Ver detalhes
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                   
                    

                    <!-- Cards no Mobile -->
                    <div class="sm:hidden space-y-6">
                        <div v-for="pendencia in pendenciasFiltradas" :key="pendencia.id"
                            class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105">
                            <div class="flex justify-between items-center">
                                <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                                    {{ pendencia.tipo.nome }}
                                </h3>
                                <span :class="getStatusClass(pendencia.status.nome)"
                                    class="px-4 py-1 rounded-full text-xs font-semibold">
                                    {{ pendencia.status.nome }}
                                </span>
                            </div>

                            <div class="mt-4 space-y-2">
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Unidade:</span>
                                    {{ (pendencia.setor && pendencia.setor.unidade) ? pendencia.setor.unidade.nome :
                                    (pendencia.unidade ? pendencia.unidade.nome : 'Geral') }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Setor:</span> {{ pendencia.setor?.nome || 'Geral' }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Problema:</span> {{ pendencia.problema || 'Não Informado'
                                    }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Interno:</span> {{ pendencia.responsavel_interno || 'NãoInformado' }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Externo:</span> {{ pendencia.responsavel_externo || 'Não Informado' }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300">
                                    <span class="font-medium">Valor:</span> {{ formatCurrency(pendencia?.orcamento) ||
                                    'Não Informado' }}
                                </p>
                                <p class="text-sm text-gray-700 dark:text-gray-300 flex items-center">
                                    <span class="font-medium">Prioridade:</span>
                                    <span :class="getPrioridadeClass(pendencia.prioridade)"
                                        class="ml-2 px-3 py-1 rounded-full text-xs font-medium uppercase">
                                        {{ pendencia.prioridade }}
                                    </span>
                                </p>
                            </div>

                            <button @click="verDetalhes(pendencia.id)"
                                class="mt-6 w-full bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 rounded-md shadow">
                                Ver detalhes
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
                </template>

                    <!-- Modal de Filtros -->
 <!-- Modal de Filtros -->
<transition name="modal">
  <div v-if="modalFiltro" class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70" @click.self="fecharModalFiltro">
    <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-lg animate-fade-in p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-xl font-bold text-gray-800 dark:text-white">Filtros</h3>
        <button @click="fecharModalFiltro" class="text-gray-600 dark:text-gray-300 hover:text-red-500 transition-all">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Filtro por Status -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Status</label>
          <select v-model="filtroStatus" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todos</option>
            <option v-for="status in statusPendencias" :key="status.id" :value="status.id">
              {{ status.nome }}
            </option>
          </select>
        </div>

        <!-- Filtro por Unidade -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Unidade</label>
          <select v-model="filtroUnidade" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todas</option>
            <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
              {{ unidade.nome }}
            </option>
          </select>
        </div>

        <!-- Filtro por Tipo -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Tipo de Pendência</label>
          <select v-model="filtroTipo" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todos</option>
            <option v-for="tipo in tiposPendencias" :key="tipo.id" :value="tipo.id">
              {{ tipo.nome }}
            </option>
          </select>
        </div>

        <!-- Filtro por Prioridade -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Prioridade</label>
          <select v-model="filtroPrioridade" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todas</option>
            <option value="baixa">Baixa</option>
            <option value="media">Média</option>
            <option value="alta">Alta</option>
            <option value="critica">Crítica</option>
          </select>
        </div>

        <!-- Filtro por Responsável Interno -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Responsável Interno</label>
          <select v-model="filtroResponsavelInterno" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todos</option>
            <option v-for="resp in responsaveisInternos" :key="resp.id" :value="resp.id">
              {{ resp.nome_completo }}
            </option>
          </select>
        </div>

        <!-- Filtro por Responsável Externo -->
        <div>
          <label class="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">Responsável Externo</label>
          <select v-model="filtroResponsavelExterno" class="w-full p-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm dark:text-white">
            <option value="">Todos</option>
            <option v-for="fornecedor in fornecedores" :key="fornecedor.id" :value="fornecedor.id">
              {{ fornecedor.nome }}
            </option>
          </select>
        </div>
      </div>

      <!-- Botões -->
      <div class="flex justify-between mt-6">
        <button @click="limparFiltros" class="px-6 py-2.5 bg-gray-500 text-white rounded-lg font-medium hover:bg-gray-600 transition-all">
          <i class="fas fa-filter-slash"></i> Limpar Filtros
        </button>
        <button @click="aplicarEFecarFiltro" class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-all">
          Aplicar
        </button>
      </div>
    </div>
  </div>
</transition>

                    <transition name="modal">
                        <!-- 📌 Modal Criar Pendência -->
                        <div v-if="modalCriarPendencia"
                            class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70 mb-20" @click.self="fecharModalCriarPendencia">
                            <div
                                class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-xl animate-fade-in p-6">
                                <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-6">
                                    Nova Pendência
                                </h3>

                                <div class="space-y-4">
                                    <div class="grid grid-cols-2 gap-4">
                                        <!-- Unidade (Obrigatório) -->
                                        <div class="col-span-2">
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Unidade
                                            </label>
                                            <select v-model.number="novaPendencia.unidade_id" @change="filtrarSetores"
                                                required
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="">Selecione uma unidade</option>
                                                <option v-for="unidade in unidades" :key="unidade.id"
                                                    :value="unidade.id">
                                                    {{ unidade.nome }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Setor (Opcional: Apenas se houver setores para a unidade selecionada) -->
                                        <div v-if="setoresFiltrados.length > 0">
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Setor (Opcional)
                                            </label>
                                            <select v-model="novaPendencia.setor_id"
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="">Nenhum</option>
                                                <option v-for="setor in setoresFiltrados" :key="setor.id"
                                                    :value="setor.id">
                                                    {{ setor.nome }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Tipo de Pendência (Obrigatório) -->
                                        <div>
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Tipo
                                            </label>
                                            <select v-model="novaPendencia.tipo_id" required
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="">Selecione um tipo</option>
                                                <option v-for="tipo in tiposPendencias" :key="tipo.id" :value="tipo.id">
                                                    {{ tipo.nome }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Tipo de Status (Obrigatório) -->
                                        <div>
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Status
                                            </label>
                                            <select v-model="novaPendencia.status" :disabled="!novaPendencia.tipo_id"
                                                required
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="" disabled>
                                                    {{
                                                    !novaPendencia.tipo_id
                                                    ? "Selecione um tipo primeiro"
                                                    : "Selecione um status"
                                                    }}
                                                </option>
                                                <option v-for="status in statusFiltrados" :key="status.id"
                                                    :value="status.id">
                                                    {{ status.nome }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Responsável Interno (Opcional) -->
                                        <div>
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Responsável Interno (Opcional)
                                            </label>
                                            <select v-model="novaPendencia.responsavel_interno_id"
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="">Nenhum</option>
                                                <option v-for="resp in responsaveisInternos" :key="resp.id"
                                                    :value="resp.id">
                                                    {{ resp.nome_completo }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Responsável Externo (Fornecedor) (Opcional) -->
                                        <div>
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Responsável Externo (Opcional)
                                            </label>
                                            <select v-model.number="novaPendencia.responsavel_externo_id"
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="">Nenhum</option>
                                                <option v-for="fornecedor in fornecedores" :key="fornecedor.id"
                                                    :value="fornecedor.id">
                                                    {{ fornecedor.nome }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Problema (Obrigatório) -->
                                        <div class="col-span-2">
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Problema
                                            </label>
                                            <textarea v-model="novaPendencia.problema" required
                                                placeholder="Digite o problema em poucas palavras"
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white min-h-[100px]"></textarea>
                                        </div>

                                        <!-- Prazo (Obrigatório) -->
                                        <div class="col-span-2">
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Prazo
                                            </label>
                                            <div class="flex gap-3">
                                                <select v-model="novaPendencia.prazoPeriodo" @change="calcularPrazo"
                                                    required
                                                    class="w-1/2 p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                    <option value="">Selecione um período</option>
                                                    <option value="7">7 dias</option>
                                                    <option value="15">15 dias</option>
                                                    <option value="30">30 dias</option>
                                                    <option value="60">60 dias</option>
                                                    <option value="180">6 meses</option>
                                                    <option value="365">1 ano</option>
                                                </select>

                                                <input type="date" v-model="novaPendencia.prazo" required
                                                    class="w-1/2 p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white" />
                                            </div>
                                        </div>

                                        <!-- Prioridade (Obrigatório) -->
                                        <div>
                                            <label
                                                class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                                Prioridade
                                            </label>
                                            <select v-model="novaPendencia.prioridade" required
                                                class="w-full p-2.5 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white">
                                                <option value="baixa">Baixa</option>
                                                <option value="media">Média</option>
                                                <option value="alta">Alta</option>
                                                <option value="critica">Crítica</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- Actions -->
                                    <div class="flex justify-end gap-3 mt-6">
                                        <button @click="fecharModalCriarPendencia"
                                            class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                                            Cancelar
                                        </button>
                                        <p v-if="mensagemSucesso" class="text-green-600 text-sm mb-3">
                                            {{ mensagemSucesso }}
                                        </p>
                                        <button @click="criarPendencia" :disabled="!isFormValid"
                                            class="px-6 py-2.5 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                                            Criar Pendência
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>
</template>


<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import axios from 'axios';
import BaseTemplate from './BaseTemplate.vue';
import { useRouter } from 'vue-router';
import { Filter, PlusCircle, RefreshCw, List, SquareKanban, } from 'lucide-vue-next'

// 📌 Obtendo Token da Sessão
const apiToken = sessionStorage.getItem("api_token");

const pendencias = ref([]);
const pendenciasFiltradas = ref([]);
const statusPendencias = ref([]);
const tiposPendencias = ref([]);
const unidades = ref([]);
const setores = ref([]);
const setoresFiltrados = ref([]);
const usuarios = ref([]);
const fornecedores = ref([]);
const filtroStatus = ref('');
const modalCriarPendencia = ref(false);
const mostrarConcluidos = ref(false);
const mostrarExcluidos = ref(false);
const filtroUnidade = ref('');
const filtroSetor = ref('');
const filtroTipo = ref('');

const filtroProblema = ref('');
const filtroPrioridade = ref('');
const modalFiltro = ref(false);
const router = useRouter();
// Crie uma variável para os responsáveis internos
const responsaveisInternos = ref([]);
const isLoading = ref(true);
const filtroResponsavelInterno = ref('');
const filtroResponsavelExterno = ref('');
const userGroups = ref(JSON.parse(sessionStorage.getItem("user_groups")) || []);
const isCriador = computed(() => {
    return (
        (
        userGroups.value.includes("superadmin") ||
        userGroups.value.includes("direcao"))
    );
});

// Atualize a função de filtragem:
// Atualize a função de filtragem:
const aplicarFiltros = () => {
  // Função para normalizar valores: converte para string, remove espaços em branco e transforma em minúsculas.
  const normalize = (val) => val ? val.toString().trim().toLowerCase() : "";

  // Normaliza os valores dos filtros dos campos que já são textos (problema, status, etc.)
  const filtroProblemaTexto = normalize(filtroProblema.value);
  const filtroStatusTexto = normalize(filtroStatus.value);
  const filtroUnidadeTexto = normalize(filtroUnidade.value);
  const filtroSetorTexto = normalize(filtroSetor.value);
  const filtroTipoTexto = normalize(filtroTipo.value);
  const filtroPrioridadeTexto = normalize(filtroPrioridade.value);

  // Para os responsáveis, o filtro vem como ID.
  let nomeRespInternoSelecionado = "";
  if (filtroResponsavelInterno.value) {
    const respObj = responsaveisInternos.value.find(
      (r) => String(r.id) === String(filtroResponsavelInterno.value)
    );
    if (respObj) {
      nomeRespInternoSelecionado = normalize(respObj.nome_completo);
    }
  }

  let nomeRespExternoSelecionado = "";
  if (filtroResponsavelExterno.value) {
    const fornecedorObj = fornecedores.value.find(
      (f) => String(f.id) === String(filtroResponsavelExterno.value)
    );
    if (fornecedorObj) {
      nomeRespExternoSelecionado = normalize(fornecedorObj.nome);
    }
  }

  // Filtra o array de pendências
  pendenciasFiltradas.value = pendencias.value.filter(pendencia => {
    // Extrai e normaliza os dados da pendência
    const problema = normalize(pendencia.problema);
    const statusNome = normalize(pendencia.status?.nome);
    const statusId = pendencia.status?.id ? String(pendencia.status.id) : "";
    const unidadeId = pendencia.unidade?.id ? String(pendencia.unidade.id) : "";
    const setorId = pendencia.setor?.id ? String(pendencia.setor.id) : "";
    const tipoId = pendencia.tipo?.id ? String(pendencia.tipo.id) : "";
    const prioridade = normalize(pendencia.prioridade);
    const respInterno = normalize(pendencia.responsavel_interno);
    const respExterno = normalize(pendencia.responsavel_externo);

    // 🔹 Oculta Concluídos e Cancelados por padrão
    if (!mostrarConcluidos.value && statusNome === "concluído") return false;
    if (!mostrarExcluidos.value && statusNome === "cancelado") return false;

    return (
      (!filtroProblemaTexto || problema.includes(filtroProblemaTexto)) &&
      (!filtroStatusTexto || statusId === filtroStatusTexto) &&
      (!filtroUnidadeTexto || unidadeId === filtroUnidadeTexto) &&
      (!filtroSetorTexto || setorId === filtroSetorTexto) &&
      (!filtroTipoTexto || tipoId === filtroTipoTexto) &&
      (!filtroPrioridadeTexto || prioridade === filtroPrioridadeTexto) &&
      (!filtroResponsavelInterno.value || respInterno === nomeRespInternoSelecionado) &&
      (!filtroResponsavelExterno.value || respExterno === nomeRespExternoSelecionado)
    );
  });
};

// 🔹 Monitorar mudanças nos filtros para atualizar os resultados automaticamente
watch([
  filtroProblema, 
  filtroStatus, 
  filtroUnidade, 
  filtroSetor, 
  filtroTipo, 
  filtroPrioridade, 
  filtroResponsavelInterno, 
  filtroResponsavelExterno, 
  mostrarConcluidos, 
  mostrarExcluidos
], () => {
    aplicarFiltros();
});

// 🔹 Aplicar filtros e fechar modal
const aplicarEFecarFiltro = () => {
    aplicarFiltros();
    fecharModalFiltro();
};

// Função para abrir o modal e definir a pendência
const verDetalhes = (id) => {
    router.push({ name: "PendenciaDetalhes", params: { id } });
};

// 🔹 Função para definir a classe CSS de prioridade
const getPrioridadeClass = (prioridade) => {
    const classes = {
        critica: "bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200",
        alta: "bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-200",
        media: "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200",
        baixa: "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
    };
    return classes[prioridade] || "bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200";
};


const exibirKanban = ref(false);
const irParaKanban = () => {
  exibirKanban.value = !exibirKanban.value;
};
import PendenciasKanban from '@/components/PendenciasKanban.vue' // ajuste o caminho conforme necessário
// 🔹 Funções para modal
const abrirModalFiltro = () => modalFiltro.value = true;
const fecharModalFiltro = () => modalFiltro.value = false;

// 🔹 Função de limpar filtros
const limparFiltros = () => {
    filtroStatus.value = '';
    filtroUnidade.value = '';
    filtroTipo.value = '';
    filtroPrioridade.value = '';
    filtroResponsavelExterno.value = '';
    filtroProblema.value = '';
    filtroResponsavelInterno.value = '';
};

// 🔹 Contador de filtros aplicados
const totalFiltros = computed(() => {
    return [filtroStatus.value, filtroUnidade.value, filtroTipo.value, filtroPrioridade.value, filtroResponsavelExterno.value, filtroResponsavelInterno.value]
        .filter(f => f !== '').length;
});


const novaPendencia = ref({
    unidade_id: '',
    setor_id: '',
    tipo_id: '',
    status: '', // valor inicial vazio
    responsavel_interno_id: '',
    responsavel_externo_id: '',
    problema: '',
    prazo: '',
    prazoPeriodo: '', // Novo campo para armazenar o período selecionado
    prioridade: 'media',
});

const isFormValid = computed(() => {
    return (
        novaPendencia.value.unidade_id &&
        novaPendencia.value.tipo_id &&
        novaPendencia.value.status &&
        novaPendencia.value.problema &&
        novaPendencia.value.prazoPeriodo &&
        novaPendencia.value.prazo &&
        novaPendencia.value.prioridade
    );
});

// Estado para armazenar a coluna atualmente ordenada e a direção (ascendente/descendente)
const colunaOrdenada = ref(null);
const ordemAscendente = ref(true);

// 🔹 Função auxiliar para acessar valores dentro de objetos aninhados
const getValorOrdenacao = (obj, caminho) => {
    return caminho.split('.').reduce((acumulador, chave) => acumulador?.[chave] ?? '', obj);
};

const ordenarPor = (coluna) => {
    if (colunaOrdenada.value === coluna) {
        ordemAscendente.value = !ordemAscendente.value;
    } else {
        colunaOrdenada.value = coluna;
        ordemAscendente.value = true;
    }

    // Realiza a ordenação da lista de pendências
    pendenciasFiltradas.value.sort((a, b) => {
        let valorA = getValorOrdenacao(a, coluna);
        let valorB = getValorOrdenacao(b, coluna);

        // Se a coluna for uma data, converte para timestamps para comparar corretamente
        if (coluna === "data_criacao" && valorA && valorB) {
            valorA = new Date(valorA).getTime();
            valorB = new Date(valorB).getTime();
        }

        // Ordenação alfabética
        if (valorA < valorB) return ordemAscendente.value ? -1 : 1;
        if (valorA > valorB) return ordemAscendente.value ? 1 : -1;
        return 0;
    });
};



const statusFiltrados = computed(() => {
    if (!novaPendencia.value.tipo_id) return [];

    const tipoSelecionado = tiposPendencias.value.find(tipo => tipo.id === novaPendencia.value.tipo_id);



    return tipoSelecionado && Array.isArray(tipoSelecionado.status_disponiveis)
        ? tipoSelecionado.status_disponiveis
        : [];
});


// 🔹 Função para calcular o prazo baseado no período selecionado
const calcularPrazo = () => {
    if (novaPendencia.value.prazoPeriodo) {
        const dias = parseInt(novaPendencia.value.prazoPeriodo, 10);
        const hoje = new Date();
        hoje.setDate(hoje.getDate() + dias);
        novaPendencia.value.prazo = hoje.toISOString().split('T')[0]; // Formata para yyyy-mm-dd
    }
};

// 📌 Headers de Autenticação
const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': `${apiToken}`,
};

// 📌 Buscar Dados
const carregarPendencias = async () => {
    try {
        const res = await axios.get('/api/v1/pendencias/', { headers });
        pendencias.value = res.data;
        pendenciasFiltradas.value = pendencias.value;
        aplicarFiltros(); // Aplica os filtros ao carregar

    } catch (error) {
        console.error("Erro ao buscar pendências:", error);
    }
};

// 📌 Formatar valores como dinheiro (R$ BRL)
const formatCurrency = (value) => {
    if (value === null || value === undefined || value === "") return "Não Informado";
    const numericValue = Number(value);
    if (isNaN(numericValue)) return "Não Informado";
    // Converte de centavos para reais dividindo por 100 e formata com o símbolo "R$"
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(numericValue / 100);
};







const formatarData = (data) => {
    if (!data) return "Sem data";
    return new Date(data).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};


const carregarStatus = async () => {
    try {
        const res = await axios.get('/api/v1/status_pendencias/', { headers });
        statusPendencias.value = res.data;
    } catch (error) {
        console.error("Erro ao buscar status:", error);
    }
};

const carregarTiposPendencias = async () => {
    try {
        const res = await axios.get('/api/v1/tipos_pendencias/', { headers });

        tiposPendencias.value = res.data.map(tipo => ({
            id: tipo.id,
            nome: tipo.nome,
            status_disponiveis: tipo.status_disponiveis || []  // ✅ Garante que o campo exista
        }));

    } catch (error) {
        console.error("Erro ao buscar tipos de pendências:", error);
    }
};

const carregarUnidades = async () => {
    try {
        const res = await axios.get('/api/v1/unidades/', { headers });
        unidades.value = res.data;
    } catch (error) {
        console.error("Erro ao buscar unidades:", error);
    }
};




// 📌 Buscar Usuários do Sistema
const carregarUsuarios = async () => {
    try {
        const res = await axios.get('/api/v1/usuarios/', { headers });
        usuarios.value = res.data.map(user => ({
            id: user.id,
            nome: user.first_name || user.nome,
            sobrenome: user.last_name || user.sobrenome// Ajusta caso a API retorne "username"
        }));
    } catch (error) {
        console.error("Erro ao buscar usuários:", error);
    }
};

const carregarFornecedores = async () => {
    try {
        const res = await axios.get('/api/v1/fornecedores/', { headers });
        fornecedores.value = res.data.items || res.data;
    } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
    }
};


const carregarSetores = async () => {
    try {
        const res = await axios.get('/api/v1/setores/', { headers });
        setores.value = res.data;
    } catch (error) {
        console.error("Erro ao buscar setores:", error);
    }
};




// Filtrar setores com base na unidade selecionada
const filtrarSetores = () => {
    if (!novaPendencia.value.unidade_id) {
        setoresFiltrados.value = [];
        return;
    }
    setoresFiltrados.value = setores.value.filter(setor => setor.unidade.id === novaPendencia.value.unidade_id);
};

const mensagemSucesso = ref("");

const criarPendencia = async () => {
    try {
        // 🔹 Faz a requisição para criar a pendência
        const response = await axios.post('/api/v1/pendencias/', {
            unidade_id: novaPendencia.value.unidade_id,
            setor_id: novaPendencia.value.setor_id || null,
            tipo_id: novaPendencia.value.tipo_id,
            status_id: novaPendencia.value.status,
            responsavel_interno_id: novaPendencia.value.responsavel_interno_id || null,
            responsavel_externo_id: novaPendencia.value.responsavel_externo_id || null,
            problema: novaPendencia.value.problema,
            prazo: novaPendencia.value.prazo,
            prioridade: novaPendencia.value.prioridade,
        }, { headers });

        // 🔹 Exibir mensagem de sucesso
        mensagemSucesso.value = "Pendência criada com sucesso!";

        setTimeout(() => {
            mensagemSucesso.value = "";
        }, 3000);

        // 🔹 Fechar o modal
        modalCriarPendencia.value = false;

        // 🔹 Atualizar a lista de pendências
        await carregarPendencias();

        // 🔹 Redirecionar para a página de detalhes da pendência recém-criada
        const pendenciaId = response.data.id;  // Obtém o ID retornado pela API
        router.push({ name: "PendenciaDetalhes", params: { id: pendenciaId } });

    } catch (error) {
        console.error("Erro ao criar pendência:", error);
        alert("Erro ao criar pendência!");
    }
};


// Função para carregar os responsáveis internos
const carregarResponsaveisInternos = async () => {
    try {
        const response = await axios.get("/api/v1/responsaveis/", { headers });
        // Se a API retornar um objeto com a chave "items", usa esse array; caso contrário, usa a resposta direta.
        responsaveisInternos.value = response.data.items || response.data;
    } catch (error) {
        console.error("Erro ao carregar responsáveis internos:", error);
    }
};

// 📌 Carregar todos os dados com estado de carregamento
// 📌 Carregar todos os dados com estado de carregamento
const carregarDados = async () => {
  isLoading.value = true;
  try {
    const [
      pendenciasRes, statusRes, tiposRes, unidadesRes,
      setoresRes, usuariosRes, fornecedoresRes
    ] = await Promise.all([
      axios.get('/api/v1/pendencias/', { headers }),
      axios.get('/api/v1/status_pendencias/', { headers }),
      axios.get('/api/v1/tipos_pendencias/', { headers }),
      axios.get('/api/v1/unidades/', { headers }),
      axios.get('/api/v1/setores/', { headers }),
      axios.get('/api/v1/usuarios/', { headers }),
      axios.get('/api/v1/fornecedores/', { headers }),
    ]);

    pendencias.value = pendenciasRes.data;
    // Chama a função de filtragem para definir a lista filtrada corretamente
    aplicarFiltros();

    statusPendencias.value = statusRes.data;
    tiposPendencias.value = tiposRes.data;
    unidades.value = unidadesRes.data;
    setores.value = setoresRes.data;
    usuarios.value = usuariosRes.data;
    fornecedores.value = fornecedoresRes.data;

  } catch (error) {
    console.error("Erro ao carregar os dados:", error);
  } finally {
    isLoading.value = false;
  }
};


// 📌 Função para definir classe CSS do status
const getStatusClass = (status) => {
    if (status.startsWith("Aguardando")) {
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
    }

    switch (status) {
        case 'Pendente':
            return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
        case 'GLPI Aberto':
            return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
        case 'Em Compras':
            return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
        case 'Em andamento':
            return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200';
        case 'Concluído':
            return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
        case 'Cancelado':
            return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
        case 'Suspenso':
            return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
        default:
            return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200';
    }
};


// 📌 Abrir e Fechar Modal
const abrirModalCriarPendencia = () => {
    modalCriarPendencia.value = true;
};

const fecharModalCriarPendencia = () => {
    modalCriarPendencia.value = false;
};

onMounted(() => {
    carregarStatus();
    carregarDados();
    carregarTiposPendencias();
    carregarUnidades();
    carregarSetores();
    carregarResponsaveisInternos();
    carregarUsuarios();
    carregarFornecedores();
});
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

</style>