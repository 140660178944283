<template>
    <BaseTemplate app-name="Configurações" page-title="">
        <template #content>
            <div class="min-h-screen flex flex-col bg-slate-200 dark:bg-gray-900">
                <!-- Campo de Pesquisa -->
                <div class="p-6">
                    <input v-model="searchQuery" type="text" placeholder="Pesquisar configurações..."
                        class="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <!-- Conteúdo principal -->
                <div class="flex-1 p-6 min-h-full">
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <!-- Cards filtrados -->
                        <div v-for="item in filteredItems" :key="item.title"
                            class="bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer border border-gray-200 dark:border-gray-700">
                            <a :href="item.href" class="block h-full">
                                <div class="p-6 flex items-center space-x-4">
                                    <div
                                        class="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg flex items-center justify-center">
                                        <component :is="getIcon(item.iconName)"
                                            class="h-6 w-6 text-blue-600 dark:text-blue-300" />
                                    </div>
                                    <div class="space-y-1 flex-1">
                                        <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-100">
                                            {{ item.title }}
                                        </h3>
                                        <p class="text-sm text-gray-600 dark:text-gray-300">
                                            {{ item.description }}
                                        </p>
                                    </div>
                                </div>
                                <div class="px-6 pb-4">
                                    <div class="flex justify-end">
                                        <span class="text-blue-600 dark:text-blue-300 font-medium hover:underline">
                                            Configurar →
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!-- Mensagem caso nenhum item seja encontrado -->
                        <div v-if="filteredItems.length === 0 && searchQuery !== ''"
                            class="col-span-full text-center text-gray-500 dark:text-gray-400">
                            Nenhum item encontrado para "{{ searchQuery }}".
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>
</template>

<script setup>
import  BaseTemplate from '../components/BaseTemplate.vue';  // Correction in import
import { ref, onMounted, computed} from 'vue';
import {
  LayoutDashboard,
  Users,
  Calendar,
  Hospital,
  UsersRound,
  Hammer,
  Webhook,
  FileSpreadsheet,
  IdCard,
  HandCoins,
  Activity,
  Stethoscope,
  Package,
  Pill,
  Info,
  Utensils
} from 'lucide-vue-next';

// Dados e estado da pesquisa
const searchQuery = ref("");

// Dados dos itens
const configItems = [
    {
        title: "Clínicas e Setores",
        description: "Gerencie e cadastre as clínicas e setores",
        iconName: "Hospital",
        href: "/config/preferences",
        visibleFor: ["superadmin"]
    }, 
  //  {
  //      title: "Relatórios",
  //      description: "Acesse e analise os relatórios do sistema",
  //      iconName: "FileSpreadsheet",
  //      href: "/config/reports",
  //      visibleFor: ["superadmin"]
        // Se não definir visibleFor, assume que é visível para todos.
 //   },
    {
        title: "Usuários",
        description: "Administre contas de usuário",
        iconName: "Users",
        href: "/config/users",
        visibleFor: ["superadmin"]
    },
    {
        title: "Grupos",
        description: "Organize usuários em grupos",
        iconName: "UsersRound",
        href: "/config/groups",
        visibleFor: ["superadmin"]
    },
    {
        title: "Equipamentos",
      description: "Gerencie e cadastre equipamentos",
        iconName: "Hammer",
        href: "/config/equipments",
        visibleFor: ["superadmin", "engenhariaclinica"]
    },
    {
        title: "Fornecedores",
        description: "Administre cadastro de coordenadores",
        iconName: "Package",
        href: "/config/fornecedores",
        visibleFor: ["superadmin", "direcao"]
    },
    {
        title: "Coordenadores",
        description: "Administre cadastro de fornecedores",
        iconName: "IdCard",
        href: "/config/coordenadores",
        visibleFor: ["superadmin", "direcao"]
    },
    {
        title: "Notícias - Portal do Paciente",
        description: "Cadastro de notícias para o Portal do Paciente",
        iconName: "Info",
        href: "/informes",
        visibleFor: ["superadmin", "direcao"]
    },
];

const userGroups = ref([]);

// Recupere os grupos do usuário (supondo que estejam salvos como JSON no sessionStorage)
onMounted(() => {
    const groups = sessionStorage.getItem("user_groups");
    if (groups) {
        userGroups.value = JSON.parse(groups);
    }
});

const filteredItems = computed(() =>
    configItems.filter((item) => {
        // Filtro pela pesquisa
        const searchOk =
            item.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            item.description.toLowerCase().includes(searchQuery.value.toLowerCase());
        // Se o item tiver "visibleFor", verifique se o usuário está em pelo menos um grupo permitido.
        const allowed =
            !item.visibleFor ||
            item.visibleFor.some((group) => userGroups.value.includes(group));
        return searchOk && allowed;
    })
);


// Função para obter ícone com base no nome
const getIcon = (iconName) => {
  const icons = {
      Calendar,
      Users,
      Hospital,
      UsersRound,
      Hammer,
      FileSpreadsheet,
      Webhook,
      IdCard,
      HandCoins,
      Activity,
      LayoutDashboard,
      Stethoscope,
      Package,
      Pill,
      Info,
      Utensils
  };
  return icons[iconName] || null;
};


onMounted(() => {

});
</script>
