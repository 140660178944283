<template>
    <BaseTemplate app-name="Gestão de Ativos" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-4 md:py-8">
                <!-- Abas -->
                <div class="mb-4 md:mb-6 border-b border-gray-200 dark:border-gray-700">
                    <ul class="flex flex-wrap justify-center -mb-px text-sm overflow-x-auto overflow-y-hidden">
                        <li v-for="tab in ['equipments', 'kits', 'models', 'auditoria']" :key="tab" class="mr-2">
                            <button @click="activeTab = tab" 
                                class="py-2 px-3 md:py-4 md:px-4 font-medium text-center rounded-t-lg transition-colors duration-200"
                                :class="activeTab === tab ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'">
                                {{ getTabName(tab) }}
                            </button>
                        </li>
                    </ul>
                
                </div>

                <!-- Métricas -->
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                    <div v-for="(metric, index) in activeMetrics" :key="index"
                        class="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
                        <h3 class="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">{{ metric.label }}</h3>
                        <p class="text-3xl font-bold" :class="metric.color">{{ metric.value }}</p>
                    </div>
                </div>




                <!-- ABA  EQUIPAMENTOS -->
                <div v-if="activeTab === 'equipments'">
                    <!-- Botões de Ação (Adicionar Equipamento e Filtrar) -->
                    <div class="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-3 md:space-y-0 md:space-x-3">

                    <!-- Grupo de Ações Principais -->
                    <div class="flex flex-col md:flex-row md:items-center w-full space-y-2 md:space-y-0 md:space-x-3">
                        <!-- Botão Adicionar Equipamento -->
                        <button @click="openModal('equipment')"
                            class="w-full md:w-auto bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
                            <PlusCircle class="w-5 h-5" />
                            <span>Adicionar Equipamento</span>
                        </button>

                        <!-- Botão Gerar Relatório -->
                        <button @click="showAddRelatoriosModal = true"
                            class="w-full md:w-auto bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
                            <Send class="w-5 h-5" />
                            <span>Gerar Relatório</span>
                        </button>

                        <!-- Botão Gerar Token -->
                        <button @click="goToGerarToken"
                            class="w-full md:w-auto bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
                            <KeyIcon class="w-5 h-5" />
                            <span>Gerar Token</span>
                        </button>
                    </div>

                    <!-- Botão Filtrar (Mobile e Desktop) -->
                    <button @click="showFilterModal = true"
                        class="w-full md:w-auto bg-gray-600 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
                        <Filter class="w-5 h-5" />
                        <span>Filtrar</span>
                    </button>
                    </div>

                    <!-- Tabela de Equipamentos (Desktop) -->
                    <div class="hidden md:block overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow">
                        <table class="min-w-full table-fixed divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                        <tr>
                            <th @click="sortBy('serial_number')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                                Serial Number
                                <span v-if="sortColumn === 'serial_number'">
                                    {{ sortDirection === 'asc' ? '⭡' : '⭣' }}
                                </span>
                            </th>
                            <th @click="sortBy('modelo_nome')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                                Nome
                                <span v-if="sortColumn === 'modelo_nome'">
                                    {{ sortDirection === 'asc' ? '⭡' : '⭣' }}
                                </span>
                            </th>
                            <th @click="sortBy('tipo')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                                Tipo
                                <span v-if="sortColumn === 'tipo'">
                                    {{ sortDirection === 'asc' ? '⭡' : '⭣' }}
                                </span>
                            </th>
                            <th @click="sortBy('unidade')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                                Unidade
                                <span v-if="sortColumn === 'unidade'">
                                    {{ sortDirection === 'asc' ? '⭡' : '⭣' }}
                                </span>
                            </th>
                            <th @click="sortBy('status')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                                Status
                                <span v-if="sortColumn === 'status'">
                                    {{ sortDirection === 'asc' ? '⭡' : '⭣'}}
                                </span>
                            </th>
                            <th @click="sortBy('token')" class="py-4 px-6 w-1/5 text-left cursor-pointer">
                        <span class="inline-flex items-center gap-1 pr-4">
                            Token
                            <span v-if="sortColumn === 'token'">
                                {{ sortDirection === 'asc' ? '⭡' : '⭣' }}
                            </span>
                        </span>
                    </th>
                            <th class="py-4 px-6 w-1/5 text-left">Ações</th>
                        </tr>
                    </thead>
                            <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                <tr v-for="equipment in filteredEquipments" :key="equipment.id"
                                    class="hover:bg-gray-50 dark:hover:bg-gray-700">
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                                        {{ equipment.serial_number }}
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                                        {{ equipment.modelo_nome }}
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                                        {{ equipment.tipo }}
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                                        {{ equipment.kits.length > 0 ? equipment.kits[0].unidade : 'N/A' }}
                                    </td>
                                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                                    <div class="flex items-center justify-center">
                                        <span :class="getStatusClass(equipment.status)">
                                        {{ equipment.status }}
                                        </span>
                                        <span v-if="equipment.alerta" class="alerta-icon ml-2 inline-flex items-center">
                                        <TriangleAlert class="w-5 h-5 text-yellow-600" />
                                        <div class="tooltip">
                                            {{ equipment.alerta }}
                                        </div>
                                        </span>
                                    </div>
                                    </td>

                                    <td class="py-4 px-1 text-xs font-medium text-gray-900 dark:text-white">
                                    <div class="relative inline-block">
                                        {{ equipment.token || 'N/A' }}
                                    </div>
                                    </td>

                                    <td class="py-4 px-6 text-sm font-medium">
                                        <!-- Botão de Editar -->
                                        <button @click="editEquipment(equipment)"
                                            class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400 mr-3">
                                            <Edit2 class="w-5 h-5" />
                                        </button>
                                        <button @click="verDetalhesAtivo(equipment.id)"
                                            class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400 mr-3">
                                            <EyeIcon v-if="equipment.validado" class="w-5 h-5" />
                                        </button>
                                        <!-- Botão de Excluir -->
                                        <button @click="deleteEquipment(equipment)"
                                            class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                                            <Trash2 class="w-5 h-5" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Cards de Equipamentos (Mobile) -->
                    
                    <!-- Modal de Filtros -->
                    <ModalTemplate v-if="showFilterModal" @close="showFilterModal = false">
                        <template #header>Filtrar Equipamentos</template>
                        <template #body>
                            
                            <div class="space-y-4">

                                <div>
                                    <label for="filterSerialNumber" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Token
                                    </label>
                                    <input id="filterToken" v-model="filters.equipment.token" type="text"
                                        placeholder="Filtrar por Token"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
                                </div>
                                <!-- Filtro por Serial Number -->
                                <div>
                                    <label for="filterSerialNumber" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Serial Number
                                    </label>
                                    <input id="filterSerialNumber" v-model="filters.equipment.serial_number" type="text"
                                        placeholder="Filtrar por Serial Number"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
                                </div>

                                <!-- Filtro por Tipo -->
                                <div>
                                    <label for="filterTipo" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Tipo
                                    </label>
                                    <select id="filterTipo" v-model="filters.equipment.tipo"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                        <option value="">Todos os tipos</option>
                                        <option v-for="t in uniqueEquipmentTypes" :key="t" :value="t">{{ t }}</option>
                                    </select>
                                </div>

                                <!-- Filtro por Modelo -->
                                <div>
                                    <label for="filterModelo" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Modelo
                                    </label>
                                    <select id="filterModelo" v-model="filters.equipment.modelo"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                        <option value="">Todos os modelos</option>
                                        <option v-for="m in uniqueEquipmentModels" :key="m" :value="m">{{ m }}</option>
                                    </select>
                                </div>

                                <!-- Filtro por Unidade -->
                                <div>
                                    <label for="filterUnidade" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Unidade
                                    </label>
                                    <select id="filterUnidade" v-model="filters.equipment.unidade"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                        <option value="">Todas as unidades</option>
                                        <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.nome">
                                            {{ unidade.nome }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Filtro por Status -->
                                <div>
                                    <label for="filterStatus" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Status
                                    </label>
                                    <select id="filterStatus" v-model="filters.equipment.status"
                                        class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                        <option value="">Todos os status</option>
                                        <option value="Disponível">Disponível</option>
                                        <option value="Em uso">Em uso</option>
                                        <option value="Em manutenção">Em manutenção</option>
                                    </select>
                                </div>
                            </div>

                            <!-- Botões do Modal -->
                            <div class="flex justify-end mt-6 space-x-4">
                                <button @click="showFilterModal = false"
                                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300">
                                    Cancelar
                                </button>
                                <button @click="applyFilters"
                                    class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700">
                                    Aplicar Filtros
                                </button>
                            </div>
                        </template>
                        <!-- Modal de Edição de Nome do Equipamento -->


                    </ModalTemplate>
                    <transition name="modal">
  <div v-if="showEditModal" class="fixed inset-0 z-50 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4">
    <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md p-6 animate-fade-in">
      <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
        Editar Nome do Equipamento
      </h3>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Nome do Modelo
        </label>
        <input
          v-model="editingEquipment.modelo_nome"
          class="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
        />
      </div>

      <div class="flex justify-end gap-3">
        <button @click="closeEditModal" class="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600">
          Cancelar
        </button>
        <button @click="saveEditedModel" class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
          Salvar
        </button>
      </div>
    </div>
  </div>
</transition>
                    <!-- Filtros para Equipamentos -->
                    <div class="block md:hidden space-y-4">
                    <div v-for="equipment in filteredEquipments" :key="equipment.id"
                        class="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
                        <!-- Serial Number -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Serial Number:</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ equipment.serial_number }}</span>
                        </div>

                        <!-- Modelo -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Modelo:</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ equipment.modelo_nome }}</span>
                        </div>

                        <!-- Tipo -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Tipo:</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ equipment.tipo }}</span>
                        </div>

                        <!-- Unidade -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Unidade:</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ equipment.kits.length > 0 ? equipment.kits[0].unidade : 'N/A' }}</span>
                        </div>

                        <!-- Status -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Status:</span>
                            <span class="text-sm" :class="getStatusClass(equipment.status)">{{ equipment.status }}</span>
                        </div>

                        <!-- Token -->
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Token:</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ equipment.token || 'N/A' }}</span>
                        </div>

                        <!-- Botões de Ação -->
                        <div class="flex justify-end space-x-2 mt-2">
                            <!-- Botão de Visualizar Detalhes -->
                            <button @click="verDetalhesAtivo(equipment.id)"
                                class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400">
                                <EyeIcon class="w-5 h-5" />
                            </button>

                            <!-- Botão de Editar -->
                            <button @click="editEquipment(equipment)"
                                class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400">
                                <Edit2 class="w-5 h-5" />
                            </button>

                            <!-- Botão de Excluir -->
                            <button @click="deleteEquipment(equipment)"
                                class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                                <Trash2 class="w-5 h-5" />
                            </button>
                        </div>
                    </div> 
                    
                    </div>
                <div class="sm:hidden space-y-6">
                    <div
                        v-for="pendencia in pendenciasFiltradas"
                        :key="pendencia.id"
                        class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105"
                    >
                        <div class="flex justify-between items-center">
                        <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                            {{ pendencia.tipo.nome }}
                        </h3>
                        <span
                            :class="getStatusClass(pendencia.status.nome)"
                            class="px-4 py-1 rounded-full text-xs font-semibold"
                        >
                            {{ pendencia.status.nome }}
                        </span>
                        </div>

                        <div class="mt-4 space-y-2">
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Unidade:</span>
                            {{ (pendencia.setor && pendencia.setor.unidade) ? pendencia.setor.unidade.nome : (pendencia.unidade ? pendencia.unidade.nome : 'Geral') }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Setor:</span> {{ pendencia.setor?.nome || 'Geral' }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Problema:</span> {{ pendencia.problema || 'Não Informado' }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Interno:</span> {{ pendencia.responsavel_interno || 'Não Informado' }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Externo:</span> {{ pendencia.responsavel_externo || 'Não Informado' }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300">
                            <span class="font-medium">Valor:</span> {{ formatCurrency(pendencia?.orcamento) || 'Não Informado' }}
                        </p>
                        <p class="text-sm text-gray-700 dark:text-gray-300 flex items-center">
                            <span class="font-medium">Prioridade:</span>
                            <span
                            :class="getPrioridadeClass(pendencia.prioridade)"
                            class="ml-2 px-3 py-1 rounded-full text-xs font-medium uppercase"
                            >
                            {{ pendencia.prioridade }}
                            </span>
                        </p>
                        </div>

                        <button
                        @click="verDetalhes(pendencia.id)"
                        class="mt-6 w-full bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 rounded-md shadow"
                        >
                        Ver detalhes
                        </button>
                    </div>
                    </div>
                </div>


<!--                                                  ABA MODELOS                   -->
                <div v-if="activeTab === 'models'">
    <div class="flex justify-between items-center mb-6">
        <!-- Botão Adicionar Modelo -->
        <button @click="showAddModelModal = true"
            class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg w-full sm:w-auto">
            <PlusCircle class="w-5 h-5" />
            <span>Adicionar Modelo</span>
        </button>
    </div>

    <!-- Filtros para Modelos -->
    <div class="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <input v-model="filters.model.serial_number" type="text"
            placeholder="Filtrar por nome do modelo"
            class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
    </div>

    <!-- Tabela de Modelos (Desktop) -->
    <div class="hidden md:block overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow">
        <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                <tr>
                    <th v-for="header in ['Nome do Modelo', 'Marca', 'Tipo', 'Ações']" :key="header"
                        class="py-4 px-6 text-left">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                <tr v-for="model in filteredModels" :key="model.id"
                    class="hover:bg-gray-50 dark:hover:bg-gray-700">
                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                        {{ model.nome }}
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ model.marca }}
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ model.tipo }}
                    </td>
                    <td class="py-4 px-6 text-sm font-medium">
                        <button @click="editModel(model)"
                            class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400 mr-3">
                            <Edit2 class="w-5 h-5" />
                        </button>
                        <button @click="deleteModel(model)"
                            class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                            <Trash2 class="w-5 h-5" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Cards de Modelos (Mobile) -->
    <div class="block md:hidden space-y-4">
        <div v-for="model in filteredModels" :key="model.id"
            class="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
            <!-- Nome do Modelo -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Nome:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ model.nome }}</span>
            </div>
            <!-- Marca -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Marca:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ model.marca }}</span>
            </div>
            <!-- Tipo -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Tipo:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ model.tipo }}</span>
            </div>
            <!-- Botões de Ação -->
            <div class="flex justify-end space-x-2 mt-2">
                <button @click="editModel(model)"
                    class="text-blue-600 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400">
                    <Edit2 class="w-5 h-5" />
                </button>
                <button @click="deleteModel(model)"
                    class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                    <Trash2 class="w-5 h-5" />
                </button>
            </div>
        </div>
    </div>
</div>
                    
                    <!--                 ABA KITS              -->

                    <div v-if="activeTab === 'kits'">
    <div class="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-3 md:space-y-0 md:space-x-3">
        <!-- Botão Adicionar Kit -->
        <button @click="showAddKitModal = true"
            class="w-full md:w-auto bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
            <PlusCircle class="w-5 h-5" />
            <span>Adicionar Kit</span>
        </button>

        <!-- Botão para Exibir/Ocultar Filtros -->
        <button @click="showFilters = !showFilters"
            class="w-full md:w-auto bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg">
            <Filter class="w-5 h-5" />
            <span>{{ showFilters ? 'Ocultar Filtros' : 'Mostrar Filtros' }}</span>
        </button>
    </div>

    <!-- Filtros para Kits (visível apenas se showFilters for true) -->
    <div v-if="showFilters" class="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        <!-- Filtro por código do kit -->
        <input v-model="filters.kit.kit_code" type="text" placeholder="Filtrar por código do kit"
            class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
        <!-- Filtro por Status -->
        <select v-model="filters.kit.status"
            class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <option value="">Todos os status</option>
            <option value="Disponível">Disponível</option>
            <option value="Indisponível">Indisponível</option>
            <option value="Em uso">Em uso</option>
            <option value="Em estoque">Em Estoque</option>
        </select>
        <!-- Filtro por Unidade -->
        <select v-model="filters.kit.unidade"
            class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <option value="">Todas as unidades</option>
            <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.nome">
                {{ unidade.nome }}
            </option>
        </select>
        <!-- Filtro por Setor -->
        <select v-model="filters.kit.setor"
            class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <option value="">Todos os setores</option>
            <option v-for="setor in setores" :key="setor.id" :value="setor.nome">
                {{ setor.nome }}
            </option>
        </select>
    </div>

    <!-- Tabela de Kits (Desktop) -->
    <div class="hidden md:block overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow">
        <table class="min-w-full table-fixed divide-y divide-gray-200 dark:divide-gray-700">
            <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                <tr>
                    <th v-for="header in ['ID', 'Itens', 'Status', 'Unidade', 'Setor', 'Ações']" :key="header"
                        class="py-4 px-6 text-left">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                <tr v-for="kit in filteredKits" :key="kit.id" class="hover:bg-gray-50 dark:hover:bg-gray-700">
                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                        {{ kit.kit_code }}
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ kit.equipamentos.map(e => e.serial_number).join(', ') }}
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        <span :class="getAvailabilityClass(kit.status)">{{ kit.status }}</span>
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ kit.unidade || '-' }}
                    </td>
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ kit.setor || "Geral" }}
                    </td>
                    <td class="py-4 px-6 text-sm font-medium">
                        <button @click="verDetalhesKit(kit.id)"
                            class="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 mr-3">
                            <EyeIcon class="h-5 w-5" />
                        </button>
                        <button @click="deleteKit(kit)"
                            class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                            <Trash2 class="w-5 h-5" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Cards de Kits (Mobile) -->
    <div class="block md:hidden space-y-4">
        <div v-for="kit in filteredKits" :key="kit.id"
            class="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
            <!-- ID -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">ID:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ kit.kit_code }}</span>
            </div>
            <!-- Itens -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Itens:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">
                    {{ kit.equipamentos.map(e => e.serial_number).join(', ') }}
                </span>
            </div>
            <!-- Status -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Status:</span>
                <span :class="getAvailabilityClass(kit.status)" class="text-sm">
                    {{ kit.status }}
                </span>
            </div>
            <!-- Unidade -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Unidade:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ kit.unidade || '-' }}</span>
            </div>
            <!-- Setor -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Setor:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ kit.setor || "Geral" }}</span>
            </div>
            <!-- Botões de Ação -->
            <div class="flex justify-end space-x-2 mt-2">
                <button @click="verDetalhesKit(kit.id)"
                    class="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                    <EyeIcon class="h-5 w-5" />
                </button>
                <button @click="deleteKit(kit)"
                    class="text-red-600 hover:text-red-900 dark:text-red-500 dark:hover:text-red-400">
                    <Trash2 class="w-5 h-5" />
                </button>
            </div>
        </div>
    </div>
</div>



                <!-- ABA AUDITORIA -->

                <div v-if="activeTab === 'auditoria'">
    <div class="flex justify-between items-center mb-6">
        <!-- Botões ou outros elementos podem ser adicionados aqui -->
    </div>

    <!-- Tabela de Auditoria (Desktop) -->
    <div class="hidden md:block overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow">
        <table class="min-w-full table-fixed divide-y divide-gray-200 dark:divide-gray-700">
            <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
                <tr>
                    <th v-for="header in ['ID', 'tipo', 'Colaborador', 'Detalhes', 'Feito em']" :key="header" class="py-4 px-6 w-1/9 text-left">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                <tr v-for="auditoria in filteredAudits" :key="auditoria.id" class="hover:bg-gray-50 dark:hover:bg-gray-700">
                    <!-- Coluna ID / Kit Code -->
                    <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                        {{ auditoria.id }}
                    </td>
                    <!-- Coluna Unidade -->
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400 uppercase">
                        {{ auditoria.action || '-' }}
                    </td>
                    <!-- Coluna Criado Por -->
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400 capitalize">
                        {{ auditoria.performed_by || '-' }}
                    </td>
                    <!-- Coluna Excluído Por -->
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ auditoria.details || '-' }}
                    </td>
                    <!-- Coluna Movimentado Por -->
                    <td class="py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {{ formatarData(auditoria.timestamp) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Cards de Auditoria (Mobile) -->
    <div class="block md:hidden space-y-4">
        <div v-for="auditoria in filteredAudits" :key="auditoria.id" class="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
            <!-- ID -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">ID:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ auditoria.id }}</span>
            </div>
            <!-- Tipo -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Tipo:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400 uppercase">{{ auditoria.action || '-' }}</span>
            </div>
            <!-- Colaborador -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Colaborador:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400 capitalize">{{ auditoria.performed_by || '-' }}</span>
            </div>
            <!-- Detalhes -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Detalhes:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ auditoria.details || '-' }}</span>
            </div>
            <!-- Feito em -->
            <div class="flex items-center space-x-2">
                <span class="text-sm font-semibold text-gray-700 dark:text-gray-300">Feito em:</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">{{ formatarData(auditoria.timestamp) }}</span>
            </div>
        </div>
    </div>


                </div>
            </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>

    <ModalTemplate v-if="showAddEquipmentModal" @close="showAddEquipmentModal = false">
        <template #header>Adicionar Novo Equipamento</template>
        <template #body>
            <form @submit.prevent="addEquipment" class="space-y-4">
                <div>
                    <label for="equipmentSerial"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Serial Number</label>
                    <input type="text" id="equipmentSerial" v-model="newEquipment.serial_number" required
                        placeholder="Digite o Serial Number"
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                </div>
                <div>
                    <label for="equipmentTipo"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Tipo</label>
                    <select id="equipmentTipo" v-model="selectedTipo" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>Selecione um tipo</option>
                        <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.nome">{{ tipo.nome }}</option>
                    </select>
                </div>
                <div>
                    <label for="equipmentModelo"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Modelo</label>
                    <select id="equipmentModelo" v-model="selectedModelo" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>Selecione um modelo</option>
                        <option v-for="model in filteredModelsByTipo" :key="model.id" :value="model.id">{{ model.nome }}
                        </option>
                    </select>
                </div>
                <div>
                    <label for="equipmentStatus"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Status</label>
                    <select id="equipmentStatus" v-model="newEquipment.status" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="Disponível">Disponível</option>
                        <option value="Em uso">Em uso</option>
                        <option value="Em manutenção">Em manutenção</option>
                    </select>
                </div>
                <div class="flex justify-end">
                    <button type="submit"
                        class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg w-full sm:w-auto">
                        <PlusCircle class="w-5 h-5" />
                        <p>Adicionar</p>
                    </button>
                </div>
            </form>
        </template>
    </ModalTemplate>


    <ModalTemplate v-if="showAddRelatoriosModal" @close="showAddRelatoriosModal = false">
    <template #header>Gerar Relatórios</template>
    <template #body>
        <form @submit.prevent="gerarRelatorios" class="space-y-4">
            <div>
                <label for="relatorioUnidade" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Unidade</label>
                <select id="relatorioUnidade" v-model="selectedUnidade" required
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                    <option value="" disabled>Selecione a unidade</option>
                    <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{ unidade.nome }}</option>
                </select>
            </div>

            <div>
                <label for="relatorioPeriodo" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Período</label>
                <select id="relatorioPeriodo" v-model="selectedPeriodo" required
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                    <option value="" disabled>Selecione o período</option>
                    <option value="ultimos_7_dias">Últimos 7 dias</option>
                    <option value="ultimos_30_dias">Últimos 30 dias</option>
                    <option value="customizado">Personalizado</option>
                </select>
            </div>

            <div v-if="selectedPeriodo === 'customizado'">
                <label for="dataInicio" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Data de Início</label>
                <input type="date" id="dataInicio" v-model="dataInicio" required
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">

                <label for="dataFim" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Data de Fim</label>
                <input type="date" id="dataFim" v-model="dataFim" required
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
            </div>

            <div>
                <label for="relatorioStatus" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Status</label>
                <select id="relatorioStatus" v-model="selectedStatus" required
                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                    <option value="" disabled>Selecione o status</option>
                    <option value="Todos os Equipamentos">Todos</option>
                    <option value="Disponível">Disponível</option>
                    <option value="Em uso">Em uso</option>
                    <option value="Em manutenção">Em manutenção</option>
                </select>
            </div>

            <div class="flex justify-end">
                <button type="submit"
                    class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg w-full sm:w-auto ml-auto">
                    <Send class="w-5 h-5" />
                    <p>Gerar Relatório</p>
                </button>
            </div>
        </form>
    </template>
</ModalTemplate>

    <ModalTemplate v-if="showAddKitModal" @close="showAddKitModal = false">
        <template #header>Adicionar Novo Kit</template>
        <template #body>
            <form @submit.prevent="addKit" class="space-y-4">
                <div>
                    <label for="kitEquipamentos" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Selecione os Equipamentos
                    </label>
                    <!-- Campo de filtro para equipamentos -->
                    <div class="mb-2">
                        <input type="text" id="equipmentsFilter" v-model="filterEquipment"
                            placeholder="Filtrar equipamentos..."
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                    </div>
                    <select id="kitEquipamentos" v-model="newKit.equipamentos" multiple required
                        :disabled="availableEquipments.length === 0"
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <template v-if="filteredEquipmentsForSelect.length > 0">
                            <option v-for="equipment in filteredEquipmentsForSelect" :key="equipment.id"
                                :value="equipment.id">
                                {{ equipment.serial_number }} - {{ equipment.modelo_nome }} - {{ equipment.tipo }}
                            </option>
                        </template>
                        <template v-else>
                            <option disabled>Nenhum equipamento disponível</option>
                        </template>
                    </select>
                    <p class="mt-1 text-xs text-gray-500 dark:text-gray-400">
                        Segure a tecla Ctrl (Windows) ou Command (Mac) para selecionar múltiplos itens.
                    </p>
                </div>
                <div>
                    <label for="kitStatus" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Status
                    </label>
                    <select id="kitStatus" v-model="newKit.status" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="Disponível">Disponível</option>
                        <option value="Indisponível">Indisponível</option>
                        <option value="Em uso">Em Uso</option>
                        <option value="Em estoque">Em Estoque</option>
                    </select>
                </div>
                <div>
                    <label for="kitUnidade" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Unidade
                    </label>
                    <select id="kitUnidade" v-model="newKit.unidade" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>Selecione uma unidade</option>
                        <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                            {{ unidade.nome }}
                        </option>
                    </select>
                </div>
                <div>
                    <label for="kitSetor" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Setor
                    </label>
                    <select id="kitSetor" v-model="newKit.setor" required
                        :disabled="!newKit.unidade || filteredSetores.length === 0"
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>
                            {{ !newKit.unidade ? 'Selecione uma unidade primeiro'
                            : (filteredSetores.length === 0 ? 'Nenhum setor disponível para essa unidade' : 'Selecione um setor') }}
                        </option>
                        <option v-for="setor in filteredSetores" :key="setor.id" :value="setor.id">
                            {{ setor.nome }}
                        </option>
                    </select>
                </div>
                <div>
  <label for="kitResponsavel" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
    Responsável Interno (opcional)
  </label>
  <select id="kitResponsavel" v-model="selectedResponsavel"
    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
    <option value="">-- Selecione um responsável --</option>
    <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario.id">
      {{ usuario.first_name }} {{ usuario.last_name }}
    </option>
  </select>
</div>


                <div class="flex justify-end">
                    <button type="submit"
                        class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg w-full sm:w-auto">
                        <PlusCircle class="w-5 h-5" />
                        <p>Adicionar</p>
                    </button>
                </div>
            </form>
        </template>
    </ModalTemplate>


    <ModalTemplate v-if="showAddModelModal" @close="showAddModelModal = false">
        <template #header>Adicionar Novo Modelo</template>
        <template #body>
            <form @submit.prevent="addModel" class="space-y-4">
                <div>
                    <label for="modelNome" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Nome do
                        Modelo</label>
                    <input type="text" id="modelNome" v-model="newModel.nome" placeholder="Digite o nome do modelo"
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                </div>
                <div>
                    <label for="modelBrand"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Marca</label>
                    <select id="modelBrand" v-model="selectedMarca" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>Selecione uma marca</option>
                        <option v-for="marca in marcas" :key="marca.id" :value="marca.nome">{{ marca.nome }}</option>
                    </select>
                </div>
                <div>
                    <label for="modelType"
                        class="block text-sm font-medium text-gray-700 dark:text-gray-300">Tipo</label>
                    <select id="modelType" v-model="selectedTipo" required
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                        <option value="" disabled>Selecione um tipo</option>
                        <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.nome">{{ tipo.nome }}</option>
                    </select>
                </div>
                <div class="flex justify-end">
                    <button type="submit"
                        class="flex-1 sm:flex-none bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2 font-medium shadow-lg w-full sm:w-auto">
                        <PlusCircle class="w-5 h-5" />
                        <p>Adicionar</p>
                    </button>
                </div>
            </form>
        </template>
    </ModalTemplate>

</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRouter} from 'vue-router';
import BaseTemplate from './BaseTemplate.vue';
import ModalTemplate from './ModalTemplate.vue';
import {Edit2, Trash2, KeyIcon, PlusCircle, Send, EyeIcon, Filter, TriangleAlert} from 'lucide-vue-next';
import { jsPDF } from "jspdf";



// Estado do modal de filtros
const showFilterModal = ref(false);
const showEditModal = ref(false);
const editingEquipment = ref(null);
const selectedResponsavel = ref(null);


const closeEditModal = () => {
  showEditModal.value = false;
};



// Função para aplicar os filtros
const applyFilters = () => {
    showFilterModal.value = false; // Fecha o modal após aplicar os filtros
    // Aqui você pode adicionar lógica adicional, se necessário
};
const showFilters = ref(false); // Controla a visibilidade dos filtros
const sortColumn = ref('serial_number'); // Coluna padrão para ordenação
const sortDirection = ref('asc'); // Direção padrão da ordenação (ascendente)
const sortBy = (column) => {
    if (sortColumn.value === column) {
        // Alternar a direção da ordenação se a mesma coluna for clicada novamente
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
        // Definir a nova coluna e a direção padrão como ascendente
        sortColumn.value = column;
        sortDirection.value = 'asc';
    }
};


const selectedUnidade = ref("");
const selectedStatus = ref("");
const equipamentosRelatorio = ref([]);



const goToGerarToken = () => {
    router.push({ name: "GerarToken" });
};


const gerarRelatorios = async () => {
    try {
        const apiToken = sessionStorage.getItem("api_token");
        if (!apiToken) {
            console.error("Token de autenticação não encontrado");
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'X-API-KEY': apiToken,
            'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
        };

        const unidadeSelecionada = selectedUnidade.value ? selectedUnidade.value.toString() : null;

        const params = {
            unidade: unidadeSelecionada,
            status: selectedStatus.value !== "Todos os Equipamentos" ? selectedStatus.value : null,
            tipo: selectedTipo.value || null,
            modelo: selectedModelo.value || null
        };

        console.log("📤 Enviando requisição com params:", params);

        const response = await axios.get("/api/v1/ativos/", { params, headers });

        console.log("📥 Resposta da API:", response.data);

        // 🔹 Em vez de filtrar, vamos passar os dados da API diretamente para o PDF
        equipamentosRelatorio.value = response.data;

        console.log("📊 Relatório gerado:", equipamentosRelatorio.value);

        if (!equipamentosRelatorio.value || equipamentosRelatorio.value.length === 0) {
            alert(`Nenhum equipamento encontrado para a unidade ${unidadeSelecionada}.`);
            return;
        }

        // 🔹 Gera o PDF com os dados completos da API
        gerarPDF(equipamentosRelatorio.value);
        showAddRelatoriosModal.value = false;

    } catch (error) {
        console.error("❌ Erro ao gerar relatório:", error.response?.data || error);
    }
};

const usuarios = ref([]);


const fetchUsuarios = async () => {
  try {
    const response = await axios.get("/api/v1/usuarios", { headers });
    usuarios.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar usuários:", error);
  }
};

onMounted(() => {
  fetchUsuarios();
});


// ⚠️ Chame no onMounted
onMounted(() => {
  fetchUsuarios();
});


const gerarPDF = (dados) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Margem para o rodapé
    let startY = 55;
    const headerHeight = 10;
    const rowHeight = 8; // Altura de cada linha


    // 🔹 Buscar o nome da unidade pelo ID
    const unidadeSelecionadaId = selectedUnidade.value ? selectedUnidade.value.toString() : "Todas";
    const unidadeSelecionadaNome = unidades.value.find(u => u.id.toString() === unidadeSelecionadaId)?.nome || "Todas";

    if (!dados || dados.length === 0) {
        alert(`Nenhum equipamento encontrado para a unidade ${unidadeSelecionadaNome}.`);
        return;
    }

    const usuarioNome = `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}` || "Desconhecido";

    // 📌 Adicionar a LOGO com proporções ajustadas
    try {
        const logoPath = "/logo_ccdti.png";  
        const logoWidth = 20;  
        const logoHeight = 6; 
        doc.addImage(logoPath, "PNG", 6, 3, logoWidth, logoHeight);
    } catch (error) {
        console.warn("⚠️ Não foi possível carregar a logo:", error);
    }

    // 📌 Configuração do título
    doc.setFontSize(22);
    doc.text("Relatório de Ativos", 105, 25, { align: "center" });

    // 📌 Informações do usuário e unidade
    doc.setFontSize(10);
    doc.text(`Emitido por: ${usuarioNome}`, 105, 42, { align: "center" });
    doc.text(`Unidade: ${unidadeSelecionadaNome}`, 105, 49, { align: "center" });

    // 📌 Função para desenhar cabeçalho da tabela
    const desenharCabecalho = (doc, y) => {
        doc.setFillColor("#000000");
        doc.rect(10, y, 190, headerHeight, 'F');
        doc.setTextColor('#FFFFFF');
        doc.text("ID", 12, y + 7);
        doc.text("Unidade", 50, y + 7);
        doc.text("Modelo", 80, y + 7);
        doc.text("Serial", 120, y + 7);
        doc.text("Tipo", 150, y + 7);
        doc.text("Status", 180, y + 7);
        doc.setTextColor("#000000");
    };

    desenharCabecalho(doc, startY);
    startY += headerHeight;

    dados.forEach((ativo) => {
        // Verifica se precisa adicionar uma nova página
        if ((startY + rowHeight) > (pageHeight - marginBottom)) {
            doc.addPage();
            startY = 20; // Novo Y inicial na nova página
            desenharCabecalho(doc, startY);
            startY += headerHeight;
        }

        // Usa apenas o startY atual para definir a posição da linha
        const rowY = startY;

        const id = ativo.id ? ativo.id.toString() : "N/A";
        const unidade = ativo.kits?.[0]?.unidade || "N/A";
        const modelo = ativo.modelo_nome || "Não informado";
        const serial = ativo.serial_number || "N/A";
        const tipo = ativo.tipo || "Não especificado";
        const status = ativo.status || "Sem status";

        doc.text(id, 12, rowY + 6);
        doc.text(unidade, 50, rowY + 6);
        doc.text(modelo, 80, rowY + 6);
        doc.text(serial, 120, rowY + 6);
        doc.text(tipo, 150, rowY + 6);
        doc.text(status, 180, rowY + 6);

        // Atualiza a posição para a próxima linha
        startY += rowHeight;
    });

    // 📌 Linha final com total de ativos
    let totalRowY = startY + 5;
    if ((totalRowY + 8) > (pageHeight - marginBottom)) {
        doc.addPage();
        totalRowY = 20;
    }
    doc.setFillColor("#EAEDED");
    doc.rect(10, totalRowY, 190, 8, 'F');
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.text(`Total de Ativos: ${dados.length}`, 15, totalRowY + 6);

    // 📌 Adicionar rodapé
    doc.setFontSize(8);
    doc.text("© 2025 T.I CCDTI. Todos os direitos reservados", 105, pageHeight - 10, { align: "center" });

    // 📌 Salvar o PDF com o **NOME DA UNIDADE**
    const nomeArquivo = `relatorio_ativos_${unidadeSelecionadaNome.replace(/\s+/g, '_')}.pdf`;
    doc.save(nomeArquivo);
};


// 📌 Watch para período customizado
const dataInicio = ref('');
const dataFim = ref('');
const selectedPeriodo = ref('');

watch(selectedPeriodo, (newValue) => {
    if (newValue === 'customizado') {
        const today = new Date().toISOString().split('T')[0];
        dataInicio.value = today;
        dataFim.value = today;
    } else {
        dataInicio.value = '';
        dataFim.value = '';
    }
});

const router = useRouter();
const activeTab = ref('equipments');
const equipments = ref([]);
const equipmentKits = ref([]);
const models = ref([]);
const marcas = ref([]);

// Novas variáveis para tipos e seleção

const showAddRelatoriosModal = ref(false);
const tipos = ref([]);
const auditoria = ref([]);
const selectedTipo = ref("");
const selectedModelo = ref("");
const selectedMarca = ref("");
const showAddEquipmentModal = ref(false);
const showAddKitModal = ref(false);
const showAddModelModal = ref(false);
const setores = ref([]);           // Lista de setores (endpoint /api/v1/setores/ - exemplo)
const unidades = ref([]);          // Lista de unidades (endpoint /api/v1/unidades/ - exemplo)
const filterEquipment = ref('');



const filters = ref({
    equipment: {
        serial_number: '',
        tipo: '',
        modelo: '',
        status: '',
        unidade: ''
    },
    kit: {
        kit_code: "",
        status: "",
        unidade: "",
        setor: ""
    },
    model: { name: '' },
    auditoria: { kit_code: ''}
});


const newEquipment = ref({
    serial_number: '',
    modelo_id: '',
    status: 'Disponível',
});

const newKit = ref({
    status: 'Disponível',
    setor: '',         // Deve ser o ID do setor (opcional)
    unidade: '',       // Deve ser o ID da unidade (opcional)
    equipamentos: [],   // Array de IDs dos equipamentos
    responsavel_interno: null
});

const newModel = ref({
    nome: '',
    marca: '',
    tipo: ''
});



const openModal = (type) => {
    // Fecha todos os modais antes de abrir um novo
    showAddEquipmentModal.value = false;
    showAddRelatoriosModal.value = false;

    if (type === 'equipment') {
        showAddEquipmentModal.value = true;
    } else if (type === 'relatorio') {
        showAddRelatoriosModal.value = true;
    }
};



// 📌 Obtendo Token da Sessão
const apiToken = sessionStorage.getItem("api_token");

// 📌 Headers de Autenticação
const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': `${apiToken}`,
    'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
};

const fetchEquipments = async () => {
    try {
        const params = {
            status: filters.value.equipment.status || null,
            tipo: filters.value.equipment.tipo || null,
            modelo: filters.value.equipment.modelo || null,
            unidade: filters.value.equipment.unidade || null  // 📌 Enviar unidade no filtro
        };

        const response = await axios.get('/api/v1/ativos/', { params, headers });
        equipments.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar equipamentos:", error);
    }
};

const fetchAuditoria = async () => {
    try {
        const response = await axios.get('/api/v1/auditoria/', { headers });
        auditoria.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar equipamentos:", error);
    }
};


const fetchKits = async () => {
    try {
        const response = await axios.get('/api/v1/kits/', { headers });
        equipmentKits.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar kits:", error);
    }
};

// Função para buscar setores
const fetchSetores = async () => {
    try {
        const response = await axios.get('/api/v1/setores/', { headers });
        setores.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar setores:", error);
    }
};

// Função para buscar unidades
const fetchUnidades = async () => {
    try {
        const response = await axios.get('/api/v1/unidades/', { headers });
        unidades.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar unidades:", error);
    }
};

// Função para buscar modelos
const fetchModels = async () => {
    try {
        const response = await axios.get('/api/v1/modelos/', { headers });
        models.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar modelos:", error);
    }
};


// Função para buscar modelos
const fetchMarcas = async () => {
    try {
        const response = await axios.get('/api/v1/marcas/', { headers });
        marcas.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar modelos:", error);
    }
};


// Função para buscar tipos
const fetchTipos = async () => {
    try {
        const response = await axios.get('/api/v1/tipos/', { headers });
        tipos.value = response.data;
    } catch (error) {
        console.error("Erro ao buscar tipos:", error);
    }
};

onMounted(() => {
    fetchEquipments();
    fetchKits();
    fetchModels();
    fetchTipos();
    fetchSetores();
    fetchUnidades();
    fetchMarcas();
    fetchAuditoria();
});

const totalEquipments = computed(() => filteredEquipments.value.length);
const availableEquipmentsvalue = computed(() =>
    filteredEquipments.value.filter(e => e.status === 'Disponível').length
);
const inUseEquipments = computed(() =>
    filteredEquipments.value.filter(e => e.status === 'Em uso').length
);
const inMnt = computed(() =>
    filteredEquipments.value.filter(e => e.status === 'Em manutenção').length
);
// Métricas para Kits
const totalKits = computed(() => filteredKits.value.length);
const availableKits = computed(() =>
    filteredKits.value.filter(k => k.status === 'Disponível').length
);
const inUseKits = computed(() =>
    filteredKits.value.filter(k => k.status === 'Em uso').length
);


// Métrica ativa conforme a aba
const activeMetrics = computed(() => {
    if (activeTab.value === 'equipments') {
        return [
            { label: 'Total de Equipamentos', value: totalEquipments.value, color: 'text-blue-600 dark:text-blue-400' },
            { label: 'Disponíveis', value: availableEquipmentsvalue.value, color: 'text-green-600 dark:text-green-400' },
            { label: 'Em Uso', value: inUseEquipments.value, color: 'text-blue-600 dark:text-blue-400' },
            { label: 'Em Manutenção', value: inMnt.value, color: 'text-yellow-600 dark:text-blue-400' },
        ];
    } else if (activeTab.value === 'kits') {
        return [
            { label: 'Total de Kits', value: totalKits.value, color: 'text-blue-600 dark:text-blue-400' },
            { label: 'Disponíveis', value: availableKits.value, color: 'text-green-600 dark:text-green-400' },
            { label: 'Em Uso', value: inUseKits.value, color: 'text-blue-600 dark:text-blue-400' },
        ];
    } else {
        return [];
    }
});

// Propriedade computada que filtra os setores conforme a unidade selecionada
const filteredSetores = computed(() => {
  const unidadeId = parseInt(newKit.value.unidade);
  if (!unidadeId) return [];
  return setores.value.filter(setor =>
    setor?.unidade?.id === unidadeId
  );
});


const uniqueEquipmentTypes = computed(() => {
    const types = new Set();
    equipments.value.forEach(equipment => {
        if (equipment.tipo) {
            types.add(equipment.tipo);
        }
    });
    return Array.from(types);
});

// Propriedade computada para extrair os modelos únicos
const uniqueEquipmentModels = computed(() => {
    const modelsSet = new Set();
    equipments.value.forEach(equipment => {
        if (equipment.modelo_nome) {
            modelsSet.add(equipment.modelo_nome);
        }
    });
    return Array.from(modelsSet);
});

// Propriedade computada que filtra os equipamentos com base nos filtros
const filteredEquipments = computed(() => {
    const serialFilter = filters.value.equipment.serial_number?.toLowerCase() || "";
    const tipoFilter = filters.value.equipment.tipo || "";
    const modeloFilter = filters.value.equipment.modelo || "";
    const statusFilter = filters.value.equipment.status || "";
    const unidadeFilter = filters.value.equipment.unidade || "";
    const tokenFilter = filters.value.equipment.token?.toLowerCase() || "";

    let filtered = equipments.value.filter(equipment => {
        const serial = equipment.serial_number ? equipment.serial_number.toLowerCase() : "";
        const tipo = equipment.tipo ? equipment.tipo : "";
        const modeloNome = equipment.modelo_nome ? equipment.modelo_nome : "";
        const unidadeNome = equipment.kits.length > 0 ? equipment.kits[0].unidade : "N/A";
        const token = equipment.token ? equipment.token.toLowerCase() : "";

        return serial.includes(serialFilter) &&
            (tipoFilter === "" || tipo === tipoFilter) &&
            (modeloFilter === "" || modeloNome === modeloFilter) &&
            (statusFilter === "" || equipment.status === statusFilter) &&
            (unidadeFilter === "" || unidadeNome === unidadeFilter) &&
            (tokenFilter === "" || token.includes(tokenFilter));
    });

    // Aplicar ordenação
    if (sortColumn.value) {
        filtered.sort((a, b) => {
            const valueA = a[sortColumn.value]?.toLowerCase?.() || a[sortColumn.value] || "";
            const valueB = b[sortColumn.value]?.toLowerCase?.() || b[sortColumn.value] || "";

            if (valueA < valueB) {
                return sortDirection.value === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection.value === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    return filtered;
});

const filteredEquipmentsForSelect = computed(() => {
    if (!filterEquipment.value) return availableEquipments.value;
    const search = filterEquipment.value.toLowerCase();
    return availableEquipments.value.filter(equipment => {
        return (
            (equipment.serial_number && equipment.serial_number.toLowerCase().includes(search)) ||
            (equipment.modelo_nome && equipment.modelo_nome.toLowerCase().includes(search))
        );
    });
});


const filteredKits = computed(() => {
    // Extraia os filtros
    const kitCodeFilter = filters.value.kit.kit_code?.toLowerCase() || "";
    const statusFilter = filters.value.kit.status || "";
    const unidadeFilter = filters.value.kit.unidade || "";
    const setorFilter = filters.value.kit.setor || "";

    return equipmentKits.value.filter(kit => {
        // Para kit_code, assumindo que o campo já vem formatado
        const code = kit.kit_code ? kit.kit_code.toLowerCase() : "";
        // Se os campos de unidade e setor vierem como strings, compare diretamente;
        // caso sejam objetos, ajuste para kit.unidade.nome, etc.
        const kitUnidade = kit.unidade ? kit.unidade.toLowerCase() : "";
        const kitSetor = kit.setor ? kit.setor.toLowerCase() : "";

        return code.includes(kitCodeFilter) &&
            (statusFilter === "" || kit.status === statusFilter) &&
            (unidadeFilter === "" || kitUnidade === unidadeFilter.toLowerCase()) &&
            (setorFilter === "" || kitSetor === setorFilter.toLowerCase());
    });
});


const availableEquipments = computed(() => {
    return equipments.value.filter(equipment =>
        equipment.status === "Disponível" && (!equipment.kits || equipment.kits.length === 0)
    );
});


const filteredModels = computed(() => {
    const searchTerm = filters.value.model?.name?.toLowerCase() || "";
    if (!searchTerm) return models.value;
    return models.value.filter(model =>
        model.nome.toLowerCase().includes(searchTerm)
    );
});


// Funções de chamada aos endpoints

const addEquipment = async () => {
    try {
        // Atualiza o modelo_id com o valor selecionado
        newEquipment.value.modelo_id = selectedModelo.value;
        const response = await axios.post('/api/v1/ativos/', newEquipment.value, { headers });
        equipments.value.push(response.data);
        newEquipment.value = { name: '', serial_number: '', modelo_id: 0, status: 'Disponível', value: 0, lastMaintenance: '' };
        selectedTipo.value = "";
        selectedModelo.value = "";
        showAddEquipmentModal.value = false;
    } catch (error) {
        console.error("Erro ao adicionar equipamento:", error);
    }
    fetchAuditoria();
};



// Função para adicionar um kit utilizando o endpoint
const addKit = async () => {
    try {
        const payload = {
            status: newKit.value.status,
            setor: newKit.value.setor ? parseInt(newKit.value.setor) : null,
            unidade: newKit.value.unidade ? parseInt(newKit.value.unidade) : null,
            equipamentos: newKit.value.equipamentos.map(id => parseInt(id)),
            responsavel_interno: selectedResponsavel.value || null

        };
        const response = await axios.post('/api/v1/kits/', payload, { headers });
        equipmentKits.value.push(response.data);
        newKit.value = { status: 'Disponível', unidade: '', setor: '', equipamentos: [] };
        showAddKitModal.value = false;
    } catch (error) {
        console.error("Erro ao adicionar kit:", error);
    }
    fetchEquipments();
    fetchKits();
    fetchAuditoria();
};

const addModel = async () => {
    try {
        // Atualiza os campos do newModel com os valores selecionados
        newModel.value.marca = selectedMarca.value;
        newModel.value.tipo = selectedTipo.value;
        const response = await axios.post('/api/v1/modelos/', newModel.value, { headers });
        models.value.push(response.data);
        newModel.value = { nome: '', marca: '', tipo: '' };
        selectedMarca.value = "";
        selectedTipo.value = "";
        showAddModelModal.value = false;
    } catch (error) {
        console.error("Erro ao adicionar modelo:", error);
    }
    fetchAuditoria();
};

const saveEditedModel = async () => {
  try {
    const payload = {
      nome: editingEquipment.value.modelo_nome,  // nome novo do modelo
    };

    await axios.put(`/api/v1/modelos/${editingEquipment.value.modelo_id}/`, payload, { headers });
    showEditModal.value = false;
    editingEquipment.value = null;
    await fetchModels();
    await fetchEquipments();
    await fetchAuditoria();
  } catch (error) {
    console.error("Erro ao salvar nome do modelo:", error.response?.data || error);
  }
};




const editEquipment = (equipment) => {
  editingEquipment.value = { ...equipment }; // Preenche com os dados atuais
  showEditModal.value = true;                // Abre o modal de edição
};




const verDetalhesAtivo = (id) => {
    router.push({ name: "AtivosDetalhes", params: { id } });
};


const getKitCode = (auditoria) => {
    // Se existir audit.kit_code, use-o; senão, gere a partir de resource_id ou id
    if (auditoria.kit_code) {
        return auditoria.kit_code;
    } else if (auditoria.resource_id) {
        return `KIT-${auditoria.resource_id.toString().padStart(4, '0')}`;
    }
    return `KIT-${auditoria.id.toString().padStart(4, '0')}`;
};

const filteredAudits = computed(() => {
    const searchTerm = filters.value.auditoria.kit_code?.toLowerCase() || "";
    if (!searchTerm) return auditoria.value;
    return auditoria.value.filter(auditoria => {
        // Verifica se o kit_code ou o código gerado contém o termo pesquisado
        const code = getKitCode(auditoria).toLowerCase();
        return code.includes(searchTerm);
    });
});

const formatarData = (data) => {
    if (!data) return "Sem data";
    return new Date(data).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

// Função para abrir o modal e definir a pendência
const verDetalhes = (id) => {
    router.push({ name: "GerarToken", params: { id } });
};

const verDetalhesKit = (id) => {
    router.push({ name: "KitsDetalhes", params: { id } });
};

const deleteEquipment = async (equipment) => {
    if (confirm("Deseja realmente excluir este equipamento?")) {
        try {
            await axios.delete(`/api/v1/ativos/${equipment.id}/`, { headers });
            equipments.value = equipments.value.filter(e => e.id !== equipment.id);
        } catch (error) {
            console.error("Erro ao excluir equipamento:", error);
        }
    }
    fetchAuditoria();
};

const deleteKit = async (kit) => {
    if (confirm("Deseja realmente excluir este kit?")) {
        try {
            await axios.delete(`/api/v1/kits/${kit.id}/`, { headers });
            equipmentKits.value = equipmentKits.value.filter(k => k.id !== kit.id);
        } catch (error) {
            console.error("Erro ao excluir kit:", error);
        }
        fetchEquipments();
        fetchKits();
        fetchAuditoria();
    }
};


// Computed para filtrar os modelos conforme o tipo selecionado
const filteredModelsByTipo = computed(() => {
    if (!selectedTipo.value) return [];
    return models.value.filter(model => model.tipo.toLowerCase() === selectedTipo.value.toLowerCase());
});

const editModel = async (model) => {
    const novoNome = prompt("Novo nome para o modelo:", model.nome);
    if (novoNome && novoNome !== model.nome) {
        try {
            const payload = { nome: novoNome };
            const response = await axios.put(`/api/v1/modelos/${model.id}/`, payload, { headers });

            const index = models.value.findIndex(m => m.id === model.id);
            if (index !== -1) {
                models.value[index] = response.data;
            }

            await fetchAuditoria();
        } catch (error) {
            console.error("Erro ao editar modelo:", error.response?.data || error);
        }
    }
};


const deleteModel = async (model) => {
    if (confirm("Deseja realmente excluir este modelo?")) {
        try {
            await axios.delete(`/api/v1/modelos/${model.id}/`, { headers });
            models.value = models.value.filter(m => m.id !== model.id);
        } catch (error) {
            console.error("Erro ao excluir modelo:", error);
        }
    }
    fetchAuditoria();
};

const getTabName = (tab) => {
    switch (tab) {
        case 'equipments':
            return 'Equipamentos';
        case 'kits':
            return 'Kits de Equipamentos';
        case 'models':
            return 'Modelos';
        case 'auditoria':
            return 'Auditoria de Ativos';
        default:
            return '';
    }
};

const getStatusClass = (status) => {
    switch (status) {
        case 'Disponível':
            return 'text-green-600 dark:text-green-400';
        case 'Em uso':
            return 'text-blue-600 dark:text-blue-400';
        case 'Em manutenção':
            return 'text-yellow-600 dark:text-yellow-400';
        default:
            return 'text-gray-600 dark:text-gray-400';
    }
};

const getAvailabilityClass = (availability) => {
    switch (availability) {
        case 'Disponível':
            return 'text-green-600 dark:text-green-400';
        case 'Em uso':
            return 'text-yellow-600 dark:text-yellow-400';
        case 'Indisponível':
            return 'text-red-600 dark:text-red-400';
        default:
            return 'text-gray-600 dark:text-gray-400';
    }
};

</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.alerta-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip {
  display: none;
  position: absolute;
  top: -45px;
  right: 0;
  background-color: #f44336;
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}
.alerta-icon:hover .tooltip {
  display: block;
}


</style>
