<template>
    <BaseTemplate app-name="Unidades Médicas" page-title="">
      <template #content>
        <div class="container mx-auto px-4 py-6 max-w-6xl">
          <button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
          
          <!-- Loading state -->
          <div v-if="loading" class="flex justify-center items-center py-12">
            <Loader2 class="h-12 w-12 animate-spin text-blue-600 dark:text-blue-400" />
            <span class="ml-3 text-gray-600 dark:text-gray-300">Carregando dados...</span>
          </div>
          
          <!-- Error state -->
          <div v-if="error" class="bg-red-50 border-l-4 border-red-500 p-4 rounded-md mb-6 dark:bg-red-900/20 dark:border-red-400">
            <div class="flex items-center">
              <AlertCircle class="text-red-500 dark:text-red-400 mr-3 h-6 w-6" />
              <p class="text-red-700 dark:text-red-300 font-medium">Erro ao buscar dados: {{ error.message }}</p>
            </div>
          </div>
          
          <!-- Medical unit details -->
          <div v-if="!loading && !error && unidade" class="space-y-6">
            <!-- Header card -->
            <div class="bg-white dark:bg-gray-800 rounded-xl shadow-md overflow-hidden dark:shadow-lg">
              <div class="bg-blue-600 dark:bg-blue-700 px-6 py-4">
                <div class="flex flex-col md:flex-row md:items-center justify-between">
                  <h2 class="text-2xl font-bold text-white">
                    {{ unidade.nome }}
                  </h2>
                  <span :class="statusClasses(unidade.status)" class="mt-2 md:mt-0 px-3 py-1 rounded-full text-sm font-medium">
                    {{ formatStatus(unidade.status) }}
                  </span>
                </div>
              </div>
              <div class="p-6 grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-800 dark:text-gray-200">
                <div v-if="unidade.dns" class="flex items-start">
                  <span class="font-medium w-32 text-gray-600 dark:text-gray-400">DNS:</span>
                  <span>{{ unidade.dns }}</span>
                </div>
                <div v-if="unidade.ip_servidor" class="flex items-start">
                  <span class="font-medium w-32 text-gray-600 dark:text-gray-400">IP Servidor:</span>
                  <span>{{ unidade.ip_servidor }}</span>
                </div>
                <div v-if="unidade.mascara" class="flex items-start">
                  <span class="font-medium w-32 text-gray-600 dark:text-gray-400">Máscara:</span>
                  <span>{{ unidade.mascara }}</span>
                </div>
              </div>
            </div>
            

            
            <!-- Endereços IP Externos -->
            <div v-if="unidade.enderecos_ip && unidade.enderecos_ip.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <Server class="mr-2 h-5 w-5" />
                Endereços IP Externos
              </h3>
              <div class="space-y-3">
                <div v-for="endereco in unidade.enderecos_ip" :key="endereco.id" class="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                  <div class="flex flex-col sm:flex-row sm:items-center justify-between">
                    <div class="font-medium text-gray-800 dark:text-gray-200">
                      {{ endereco.ip }} 
                      <span class="text-blue-600 dark:text-blue-400">({{ endereco.tipo }})</span>
                    </div>
                    <div class="mt-2 sm:mt-0 text-sm text-gray-600 dark:text-gray-400">
                      <span v-if="endereco.provedor">Provedor: {{ endereco.provedor }}</span>
                      <span v-if="endereco.codigo_cliente" class="ml-2">Código: {{ endereco.codigo_cliente }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Endereços IP Internos -->
            <div v-if="unidade.enderecos_ip_internos && unidade.enderecos_ip_internos.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <Network class="mr-2 h-5 w-5" />
                Endereços IP Internos
              </h3>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div v-for="endereco in unidade.enderecos_ip_internos" :key="endereco.id" class="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                  <div class="font-medium text-gray-800 dark:text-gray-200">
                    {{ endereco.ip }} 
                    <span class="text-blue-600 dark:text-blue-400">({{ endereco.tipo }})</span>
                  </div>
                  <div v-if="endereco.descricao" class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                    {{ endereco.descricao }}
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Equipamentos Médicos -->
            <div v-if="unidade.equipamentos_medicos && unidade.equipamentos_medicos.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <Stethoscope class="mr-2 h-5 w-5" />
                Equipamentos Médicos
              </h3>
              <div class="space-y-3">
                <div v-for="equip in unidade.equipamentos_medicos" :key="equip.id" class="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                  <div class="font-medium text-gray-800 dark:text-gray-200">{{ equip.nome }}</div>
                  <div v-if="equip.ip" class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                    {{ equip.ip }} 
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Configurações de Recepção -->
            <div v-if="unidade.modalidade_unidades && unidade.modalidade_unidades.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <Settings class="mr-2 h-5 w-5" />
                Configurações de Recepção
              </h3>
              <div class="overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Modalidade
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        IP
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Porta
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        AETitle
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    <tr v-for="config in unidade.modalidade_unidades" :key="config.id">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                        {{ config.modalidade.nome }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                        {{ config.ip }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                        {{ config.porta }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                        {{ config.aetitle }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
                        <!-- Setores -->
                        <div v-if="unidade.setores && unidade.setores.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <LayoutGrid class="mr-2 h-5 w-5" />
                Setores
              </h3>
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                <div v-for="setor in unidade.setores" :key="setor.id" class="bg-gray-50 dark:bg-gray-700 rounded-lg p-3 text-gray-800 dark:text-gray-200">
                  {{ setor.nome }}
                </div>
              </div>
            </div>
            <!-- Observações -->
            <div v-if="unidade.observacoes && unidade.observacoes.length" class="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 dark:shadow-lg">
              <h3 class="text-xl font-semibold text-blue-700 dark:text-blue-400 mb-4 flex items-center">
                <MessageSquare class="mr-2 h-5 w-5" />
                Observações
              </h3>
              <div class="space-y-4">
                <div v-for="obs in unidade.observacoes" :key="obs.id" class="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                  <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
                    <span class="font-medium text-blue-600 dark:text-blue-400">{{ obs.usuario }}</span>
                    <span class="text-sm text-gray-600 dark:text-gray-400">{{ formatarData(obs.data_criacao) }}</span>
                  </div>
                  <p class="text-gray-800 dark:text-gray-200">{{ obs.texto }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseTemplate>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import axios from 'axios'
  import BaseTemplate from './BaseTemplate.vue'
  import { 
    Loader2, 
    AlertCircle, 
    LayoutGrid, 
    Server, 
    Network, 
    Stethoscope, 
    SquareArrowLeft,
    Settings, 
    MessageSquare 
  } from 'lucide-vue-next'
  
  const unidade = ref(null)
  const loading = ref(true)
  const error = ref(null)
  const route = useRoute()
  const router = useRouter()

  
  const apiToken = sessionStorage.getItem("api_token")
  const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': apiToken,
    'X-USER-NAME': `${sessionStorage.getItem("firstname")} ${sessionStorage.getItem("lastname")}`
  }
  
  
const voltar = () => router.go(-1);

  const fetchUnidade = async (id) => {
    try {
      const response = await axios.get(`/api/v1/unidades/detalhes/${id}`, { headers })
      unidade.value = response.data
    } catch (err) {
      error.value = err
      console.error('Erro ao buscar a unidade médica:', err)
    } finally {
      loading.value = false
    }
  }
  

  
  onMounted(() => {
    fetchUnidade(route.params.id || 20)
  })
  
  const formatarData = (data) => {
    if (!data) return "Sem data";
    return new Date(data).toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  
  // Função para definir classes dinâmicas de acordo com o status
  const statusClasses = (status) => {
    if (!status) return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
    
    switch(status.toLowerCase()) {
      case 'em_funcionamento':
      case 'ativo':
      case 'ativa':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300';
      case 'em_atendimento':
      case 'parcial':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300';
      case 'parado':
      case 'inativo':
      case 'inativa':
        return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
    }
  }
  
  // Função para formatar o status removendo underlines e capitalizando as palavras
  const formatStatus = (status) => {
    if (!status) return '';
    return status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  </script>
  
  <style scoped>
  /* Responsividade para telas pequenas */
  @media (max-width: 640px) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  
  /* Transições suaves para modo escuro/claro */
  .bg-white, .bg-gray-50, .text-gray-800, .text-gray-600, .text-blue-700, .text-blue-600 {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Melhorar contraste em tabelas no modo escuro */
  .dark .divide-gray-700 {
    --tw-divide-opacity: 0.5;
  }
  
  /* Melhorar sombras no modo escuro */
  .dark .shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
  }
  </style>