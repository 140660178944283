<template>
    <BaseTemplate app-name="Monitoramento" page-title="">
        <template #content>

            <div class="min-h-screen bg-slate-200 dark:bg-gray-900 transition-colors duration-300 p-2">
                <div class="container mx-auto max-w-[1920px]">
                    <div class="flex flex-col items-center justify-center mx-auto">
                        <!-- Imagem muda dinamicamente com base no estado do tema -->
                        <img :src=LogoDark alt="Logo" class="xl:h-22 sm:h-12 w-auto mb-8 animate-float" />
                    </div>

                    <div class="grid grid-cols-1 sm:grid-cols-4 gap-6 mb-5 ml-3">
                        <!-- Card: Pleno Funcionamento -->
                        <div @click="setFiltro('em_funcionamento')"
                            class="bg-green-500 dark:bg-green-700 text-white p-4 rounded-xl shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 cursor-pointer">
                            <div class="flex items-center justify-center space-x-2">
                                <p class="text-5xl font-extrabold">{{ unidadesEmFuncionamento }}</p>
                                <p class="text-1xl font-extrabold">|</p>
                                <p class="text-3xl font-semibold">{{ calcularPercentual(unidadesEmFuncionamento) }}%</p>
                            </div>
                            <p class="text-sm font-medium mt-2">Unidades em pleno funcionamento</p>
                        </div>

                        <!-- Card: Funcionamento Parcial -->
                        <div @click="setFiltro('em_atendimento')"
                            class="bg-yellow-400 dark:bg-yellow-600 text-white p-4 rounded-xl shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 cursor-pointer">
                            <div class="flex items-center justify-center space-x-2">
                                <p class="text-5xl font-extrabold">{{ unidadesEmFuncionamentoParcial }}</p>
                                <p class="text-1xl font-extrabold">|</p>
                                <p class="text-3xl font-semibold">{{ calcularPercentual(unidadesEmFuncionamentoParcial)
                                    }}%</p>
                            </div>
                            <p class="text-sm font-medium mt-2">Unidades com funcionamento parcial</p>
                        </div>

                        <!-- Card: Não Funcionamento -->
                        <div @click="setFiltro('parado')"
                            class="bg-red-500 dark:bg-red-700 text-white p-4 rounded-xl shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 cursor-pointer">
                            <div class="flex items-center justify-center space-x-2">
                                <p class="text-5xl font-extrabold">{{ unidadesNaoFuncionando }}</p>
                                <p class="text-1xl font-extrabold">|</p>
                                <p class="text-3xl font-semibold">{{ calcularPercentual(unidadesNaoFuncionando) }}%</p>
                            </div>
                            <p class="text-sm font-medium mt-2">Unidades fora de operação</p>
                        </div>

                        <!-- Novo Card: Soma do Orçamento dos Eventos em Atendimento -->
                        <div
                            class="bg-yellow-400 dark:bg-yellow-500 text-white p-4 rounded-xl shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 cursor-pointer">

                            <p class="text-4xl font-extrabold mt-2">{{ formatarOrcamento(somaOrcamentoEmAtendimento) }}
                            </p>
                            <p class="text-sm font-medium mt-2">Custos total para reparos</p>
                        </div>
                    </div>


                    <transition name="fade-scale">
                        <div v-if="filtroStatus" class="text-center my-4">
                            <button @click="limparFiltro"
                                class="text-sm font-medium bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 transition">
                                Exibir Todas as Unidades
                            </button>
                        </div>
                    </transition>



                    <!-- Indicador de carregamento -->
                    <div v-if="isLoadingFiltro" class="flex animate-spin justify-center items-center min-h-[300px]">
                        <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                    </div>

                    <!-- Lista de unidades (escondida durante o loading) -->
                    <transition-group v-if="!isLoadingFiltro" name="unit-list" tag="div"
                        class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-5 gap-4">
                        <div v-for="(unidade) in unidadesFiltradas" :key="unidade.id" :data-id="unidade.id"
                            ref="unidadeRefs"
                            class="flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-700 overflow-hidden transition-transform transform hover:scale-105 duration-300">
                            <div
                                class="bg-blue-200 dark:bg-gray-700 xl:text-lg p-3 flex justify-between items-center text-gray-800 dark:text-white font-semibold uppercase tracking-wide">
                                <div class="truncate">
                                    {{ unidade.nome }}
                                </div>
                                <span :class="{
                                    'bg-green-500': unidade.status === 'em_funcionamento',
                                    'bg-yellow-500': unidade.status === 'em_atendimento',
                                    'bg-red-500': unidade.status === 'parado',
                                    'bg-orange-500': unidade.status === 'em_manutencao'
                                }"
                                    class="w-4 h-4 rounded-full border-2 border-white dark:border-gray-800 shadow-lg"></span>
                            </div>

                            <!-- Conteúdo do Card -->
                            <div class="p-3 space-y-3 bg-gray-50 dark:bg-gray-900 rounded-b-lg">

                                <!-- Equipamentos -->
                                <div>
                                    <h3 class="text-sm font-bold text-gray-700 dark:text-gray-300 uppercase mb-1">
                                        Equipamentos
                                    </h3>
                                    <div
                                        class="space-y-1 max-h-24 overflow-y-auto text-xs bg-gray-100 dark:bg-gray-800 p-2 rounded-md">
                                        <div v-for="equipamento in unidade.equipamentos" :key="equipamento.id" class="flex justify-between items-center bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 p-1 rounded-md">
    
                                    <!-- Exibição no formato "CANON (CANON) - 1 Canais" -->
                                    <span class="truncate">
                                    {{ equipamento.nome }} - {{ equipamento.canais }} Canais
                                    </span>

                                    <!-- Status -->
                                    <span :class="{'bg-green-500': equipamento.status === 'em_funcionamento', 'bg-yellow-500': equipamento.status === 'pendente', 'bg-red-500': equipamento.status === 'em_atendimento'}" class="w-3 h-3 rounded-full"></span>
                                    </div>
                                    </div>
                                </div>

                                <!-- Botões Lado a Lado -->
                                <div class="flex space-x-2 mt-2">
                                    <!-- Botão para abrir o modal de eventos -->
                                    <button v-if="unidade.eventos && unidade.eventos.length > 0"
                                        @click="abrirModal(unidade)"
                                        class="text-sm font-medium bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 transition">
                                        {{ unidade.eventos.length }} Evento(s) em Aberto
                                    </button>

                                    <!-- Botão para abrir o modal de contingências -->
                                    <button v-if="getContingencias(unidade.nome).length > 0"
                                        @click="abrirModalContingencia(unidade)"
                                        class="text-sm font-medium dark:bg-red-700 bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-red-600 dark:hover:bg-red-500 transition">
                                        Ver Contingências
                                    </button>
                                </div>
                            </div>
                        </div>
                    </transition-group>


                </div>
            </div>
            <!-- Rodapé fixo -->
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
            <!-- Modal de Unidades de Contingência -->
            <transition name="fade-scale">
                <!-- Modal de Eventos -->
                <div v-if="modalAberto"
                    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    @click.self="fecharModal">
                    <div
                        class="bg-white dark:bg-gray-800 w-11/12 sm:w-3/4 lg:w-1/2 rounded-2xl shadow-2xl overflow-hidden">
                        <!-- Cabeçalho -->
                        <div
                            class="bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-600 dark:to-blue-800 text-white px-6 py-4 flex justify-between items-center rounded-t-2xl">
                            <h3 class="text-lg font-bold tracking-wide">
                                <span class="block">Eventos da Unidade {{ unidadeSelecionada?.nome }}</span>
                            </h3>
                            <button @click="fecharModal"
                                class="text-red-500 hover:text-gray-300 transition duration-200 text-2xl font-semibold">
                                ✖
                            </button>
                        </div>

                        <!-- Corpo -->
                        <div class="p-6 space-y-4 max-h-[60vh] overflow-y-auto bg-gray-50 dark:bg-gray-900">
                            <div v-for="evento in unidadeSelecionada?.eventos" :key="evento.id"
                                @click="navegarParaEvento(evento.id)" :class="[
                                'p-4 ml-2 mr-2 rounded-lg shadow-md cursor-pointer transition transform hover:scale-105',
                                evento.status === 'pendente' ? 'bg-gray-400 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500' : '',
                                evento.status === 'em_atendimento' ? 'bg-red-500 dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-600' : '',
                                evento.status === 'concluido' ? 'bg-green-100 dark:bg-green-700 hover:bg-green-200 dark:hover:bg-green-600' : '',
                                evento.status === 'outro' ? 'bg-blue-100 dark:bg-blue-700 hover:bg-blue-200 dark:hover:bg-blue-600' : ''
                            ]">
                                <h4 class="text-md font-bold text-gray-800 dark:text-gray-100 mb-1">{{
                                    evento.equipamento_nome }}</h4>
                                <p class="text-sm text-gray-800 dark:text-gray-300">Motivo: {{ evento.motivo }}</p>
                                <p class="text-xs text-gray-800 dark:text-gray-300">
                                    {{ evento.status === 'pendente'
                                    ? `Início: ${formatarData(evento.data_inicio)} | Previsão:
                                    ${formatarDataFinal(evento.data_previsao)}`
                                    : `Início: ${formatarData(evento.data_inicio)} | Término:
                                    ${formatarDataFinal(evento.data_final)}` }}
                                </p>
                                <p
                                    class="text-xs text-white bg-blue-500 hover:bg-blue-600 rounded-lg p-2 mt-4 max-h-50">
                                    Ver
                                    Detalhes ➝</p>

                            </div>

                        </div>

                        <!-- Rodapé -->
                        <div class="px-6 py-4 bg-gray-100 dark:bg-gray-800 flex justify-end rounded-b-2xl">
                            <button @click="fecharModal"
                                class="bg-red-500 hover:bg-red-600 text-white font-semibold px-6 py-2 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </transition>


            <!-- Modal de Unidades de Contingência -->
            <transition name="fade-scale">
                <div v-if="modalContingenciaAberto"
                    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    @click.self="fecharModalContingencia">
                    <div
                        class="bg-white dark:bg-gray-800 w-11/12 sm:w-3/4 lg:w-1/2 rounded-2xl shadow-2xl overflow-hidden">
                        <!-- Cabeçalho -->
                        <div
                            class="bg-gradient-to-r from-red-500 to-red-700 dark:from-red-600 dark:to-red-800 text-white px-6 py-4 flex justify-between items-center rounded-t-2xl">
                            <h3 class="text-lg font-bold tracking-wide">
                                <span class="block">Unidades de Contingência para {{ unidadeSelecionada?.nome }}</span>
                            </h3>
                            <button @click="fecharModalContingencia"
                                class="text-white hover:text-gray-300 transition duration-200 text-2xl font-semibold">
                                ✖
                            </button>
                        </div>


                        <!-- Corpo -->
                        <div class="p-6 space-y-6 max-h-[60vh] overflow-y-auto bg-gray-50 dark:bg-gray-900">
                            <!-- Mensagem de Unidade Parada -->
                            <div
                                class="bg-red-500 dark:bg-red-900 p-3 rounded-md shadow-md border border-red-300 dark:border-red-800">
                                <div class="flex items-center gap-3 mb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6 text-white dark:text-red-400 animate-pulse" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="12" y1="8" x2="12" y2="12"></line>
                                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                    </svg>
                                    <h3 class="text-white dark:text-red-300 font-bold uppercase tracking-wider">
                                        Unidade Parada
                                    </h3>
                                </div>
                                <p class="text-sm text-white dark:text-gray-300 font-medium mb-2">
                                    Opções de Contingência:
                                </p>
                                <ul class="list-disc pl-5 space-y-1 text-sm text-blue-300 dark:text-blue-300">
                                    <!-- Verifica se há contingências disponíveis -->
                                    <template v-if="getContingencias(unidadeSelecionada?.nome).length > 0">
                                        <li v-for="contingencia in getContingencias(unidadeSelecionada?.nome)"
                                            :key="contingencia.id"
                                            class="hover:text-blue-800 dark:hover:text-blue-200 transition-colors cursor-pointer">
                                            {{ contingencia.nome }}
                                        </li>
                                    </template>
                                    <!-- Exibe mensagem caso não haja contingências -->
                                    <template v-else>
                                        <li class="text-gray-500 dark:text-gray-400">Nenhuma contingência disponível.
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>


                        <!-- Rodapé -->
                        <div class="px-6 py-4 bg-gray-100 dark:bg-gray-800 flex justify-end rounded-b-2xl">
                            <button @click="fecharModalContingencia"
                                class="bg-red-500 hover:bg-red-600 text-white font-semibold px-6 py-2 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </transition>


        </template>


    </BaseTemplate>

</template>

<script setup>
import BaseTemplate from './BaseTemplate.vue';
import LogoDark from '@/assets/LogoDark.png';
import { ref, onMounted, computed, onUnmounted} from 'vue';
import axios from 'axios';
import router from '@/router';
import {
    RefreshCw,

} from 'lucide-vue-next';

axios.defaults.headers.common['X-API-KEY'] = sessionStorage.api_token; // Substitua pela sua API Key real

const unidadeRefs = ref([]);
const hasParada = ref(false);
const isLoading = ref(true);
const unidades = ref([]);

const contingencias = ref({
    "HM Miguel Couto": [
        "Centro de Imagem da Rocinha",
        "Centro de Diagnóstico CCDTI",
        "HM Lourenço Jorge"
    ],
    "HM Souza Aguiar": [
        "Centro de Diagnóstico CCDTI",
        "HM Salgado Filho",
        "HM Miguel Couto"
    ],
    "HM Salgado Filho": [
        "Policlínica Rodolpho Rocco",
        "Centro de Diagnóstico CCDTI",
        "HM Souza Aguiar"
    ],
    "HM Lourenço Jorge": [
        "UPA 24H Cidade de Deus",
        "HM Miguel Couto",
        "HM Souza Aguiar"
    ],
    "HM Ronaldo Gazolla": [
        "HM Francisco da Silva Telles",
        "UPA 24H Madureira",
        "HM da Piedade"
    ],
    "HM Francisco da Silva Telles": [
        "HM Ronaldo Gazolla",
        "HM da Piedade"
    ],
    "HM Rocha Faria": [
        "CMS Belizário Penna",
        "Policlínica Lincoln de Freitas"
    ],
    "UPA 24H Madureira": [
        "Policlínica Rodolpho Rocco",
        "HM Salgado Filho"
    ],
    "UPA 24H Cidade de Deus": [
        "HM Lourenço Jorge",
        "CF Otto Alves de Carvalho",
        "HM Miguel Couto"
    ],
    "HM da Piedade": [
        "UPA 24H Madureira",
        "Policlínica Rodolpho Rocco",
        "HM Salgado Filho"
    ],
    "Policlínica Rodolpho Rocco": [
        "HM Salgado Filho",
        "UPA 24H Madureira"
    ],
    "CF Otto Alves de Carvalho": ["UPA 24H Cidade de Deus"],

    "CF Harvey Ribeiro": ["Policlínica Lincoln de Freitas"],

    "Policlínica Manoel Guilherme": ["CMS Belizário Penna"],

    "Policlínica Lincoln de Freitas": ["CMS Belizário Penna"],
    "CF Adib Jatene": [
        "Policlínica Rodolpho Rocco",
        "Centro de Diagnóstico CCDTI"
    ],
    "Centro de Imagem da Rocinha": [
        "HM Miguel Couto",
        "SISREG"
    ],
    "CMS Belizário Penna": [
        "Policlínica Manoel Guilherme",
        "Policlínica Lincoln de Freitas"
    ],
    "HM Jesus": ["Centro de Diagnóstico CCDTI"]
});

// Buscar unidades do backend
async function fetchUnidades() {
    try {
        const response = await axios.get('/api/v1/monitoramento/unidades/');
        const fetchedUnidades = response.data;

        // Ordenar as unidades: primeiro com mais de 1 equipamento
        unidades.value = fetchedUnidades.sort((a, b) => b.equipamentos.length - a.equipamentos.length);

        // Verifica se há unidades paradas
        hasParada.value = unidades.value.some(unidade =>
            unidade.equipamentos.every(
                equipamento =>
                    equipamento.status === 'em_atendimento' || equipamento.status === 'pendente'
            )
        );
    } catch (error) {
        console.error('Erro ao carregar unidades:', error);
    } finally {
        isLoading.value = false;
    }
}

// Contingências de uma unidade
function getContingencias(unidadeNome) {
    // Localiza a unidade principal pelo nome
    const unidade = unidades.value.find(u => u.nome === unidadeNome);

    // Verifica se a unidade está em condições para verificar contingências
    if (
        unidade &&
        unidade.equipamentos.every(
            equipamento =>
                equipamento.status === 'em_atendimento' || equipamento.status === 'parado'
        )
    ) {
        // Obter as alternativas de contingência para a unidade
        const alternativas = contingencias.value[unidadeNome] || [];

        // Filtrar e retornar apenas unidades alternativas válidas
        return alternativas.map(nome => {
            const alternativa = unidades.value.find(u => u.nome === nome);

            // A unidade alternativa deve ter pelo menos um equipamento funcionando
            if (
                alternativa &&
                alternativa.equipamentos.some(equipamento => equipamento.status === 'em_funcionamento')
            ) {
                return alternativa;
            }

            return null; // Retorna null para alternativas inválidas
        }).filter(Boolean); // Remove unidades nulas
    }

    return []; // Caso a unidade não atenda aos critérios, retorna uma lista vazia
}
// Navegar para o evento
function navegarParaEvento(eventoId) {
    router.push(`/eventos/${eventoId}`);
}


// Formatar data no formato dd/MM/yyyy
function formatarData(data) {
    if (!data) return "Não informado";

    // Interpretar a string de data corretamente como UTC
    const date = new Date(data);

    // Garantir que a data seja ajustada corretamente para o fuso horário local
    const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return adjustedDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

// Formatar data no formato dd/MM/yyyy
function formatarDataFinal(data_final) {
    if (!data_final) return "Sem Previsão";

    // Interpretar a string de data corretamente como UTC
    const date = new Date(data_final);

    // Garantir que a data seja ajustada corretamente para o fuso horário local
    const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return adjustedDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

// Computed Property para somar os orçamentos dos eventos em atendimento
const somaOrcamentoEmAtendimento = computed(() => {
    return unidades.value.reduce((total, unidade) => {
        return total + unidade.eventos
            .filter(evento => evento.status === "em_atendimento")
            .reduce((subtotal, evento) => subtotal + (parseFloat(evento.orcamento) || 0), 0);
    }, 0);
});


// Função para formatar o orçamento como moeda BRL (R$)
const formatarOrcamento = (valor) => {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
    }).format(valor);
};


let intervalId;

// Atualizar refs ao montar
onMounted(() => {
    unidadeRefs.value = [];
    fetchUnidades();
});

onMounted(() => {
    // Carregar dados inicialmente
    fetchUnidades();

    // Atualizar dados a cada 1 minuto
    intervalId = setInterval(() => {
        fetchUnidades();
    }, 60000); // 60.000 ms = 1 minuto
});

const totalUnidades = computed(() =>
    unidadesEmFuncionamento.value +
    unidadesEmFuncionamentoParcial.value +
    unidadesNaoFuncionando.value
);


const unidadesEmFuncionamento = computed(() =>
    unidades.value.filter(unidade => unidade.status === 'em_funcionamento').length
);

const unidadesEmFuncionamentoParcial = computed(() =>
    unidades.value.filter(unidade => unidade.status === 'em_atendimento').length
);

const unidadesNaoFuncionando = computed(() =>
    unidades.value.filter(unidade => unidade.status === 'parado').length
);



function calcularPercentual(valor) {
    if (totalUnidades.value === 0) return 0;
    return ((valor / totalUnidades.value) * 100).toFixed(1);
}

// Variáveis para o Modal
const modalAberto = ref(false);
const unidadeSelecionada = ref(null);

// Abrir Modal
function abrirModal(unidade) {
    unidadeSelecionada.value = unidade;
    modalAberto.value = true;
}

// Fechar Modal
function fecharModal() {
    unidadeSelecionada.value = null;
    modalAberto.value = false;
}

// Variáveis para o Modal de Contingência
const modalContingenciaAberto = ref(false);

// Abrir Modal de Contingência
function abrirModalContingencia(unidade) {
    unidadeSelecionada.value = unidade;
    modalContingenciaAberto.value = true;
}

// Fechar Modal de Contingência
function fecharModalContingencia() {
    unidadeSelecionada.value = null;
    modalContingenciaAberto.value = false;
}

const filtroStatus = ref(null); // Armazena o status selecionado



// Computa as unidades filtradas
const unidadesFiltradas = computed(() => {
    if (!filtroStatus.value) {
        return unidades.value; // Retorna todas as unidades se nenhum filtro estiver aplicado
    }
    return unidades.value.filter(unidade => unidade.status === filtroStatus.value);
});


const isLoadingFiltro = ref(false); // Indica se está carregando o filtro

// Define o filtro com carregamento
function setFiltro(status) {
    isLoadingFiltro.value = true; // Ativa o estado de carregamento
    setTimeout(() => {
        filtroStatus.value = status; // Aplica o filtro
        isLoadingFiltro.value = false; // Desativa o estado de carregamento
    }, 500); // Tempo para simular o carregamento
}

// Limpar o filtro com carregamento
function limparFiltro() {
    isLoadingFiltro.value = true; // Ativa o estado de carregamento
    setTimeout(() => {
        filtroStatus.value = null; // Remove o filtro
        isLoadingFiltro.value = false; // Desativa o estado de carregamento
    }, 500); // Tempo para simular o carregamento
}


onUnmounted(() => {
    // Limpar o intervalo ao desmontar o componente
    if (intervalId) {
        clearInterval(intervalId);
    }
});

</script>

<style>

.fade-scale-enter-active,
.fade-scale-leave-active {
    transition: all 0.3s ease;
}

.fade-scale-enter-from,
.fade-scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

.fade-scale-leave-from,
.fade-scale-enter-to {
    opacity: 1;
    transform: scale(1);
}

/* Estilo padrão da scrollbar */
::-webkit-scrollbar {
    width: 8px; /* Largura da scrollbar */
    height: 8px; /* Altura da scrollbar horizontal */
}

/* Estilo do trilho da scrollbar */
::-webkit-scrollbar-track {
    background: #f4f4f4; /* Cor de fundo do trilho */
    border-radius: 10px; /* Bordas arredondadas */
}

/* Estilo do polegar da scrollbar */
::-webkit-scrollbar-thumb {
    background: #888; /* Cor do polegar */
    border-radius: 10px; /* Bordas arredondadas */
}

/* Hover no polegar da scrollbar */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do polegar ao passar o mouse */
}

/* Estilização em modo escuro */
body.dark ::-webkit-scrollbar-track {
    background: #2d2d2d; /* Cor do trilho no modo escuro */
}

body.dark ::-webkit-scrollbar-thumb {
    background: #555; /* Cor do polegar no modo escuro */
}

body.dark ::-webkit-scrollbar-thumb:hover {
    background: #888; /* Cor do polegar ao passar o mouse no modo escuro */
}

</style>
