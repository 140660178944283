<template>
    <BaseTemplate app-name="Gestão de Fornecedores e Responsáveis" page-title="">
        <template #content>
            <div class="container mx-auto p-6">
                <!-- Cabeçalho -->
                <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">

                    <div class="flex flex-wrap gap-3">
                        <button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
                        <button @click="abrirModalFornecedor('novo')"
                            class="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow">
                            <PackageIcon class="w-5 h-5" />
                            <span class="sm:inline hidden">Novo Fornecedor</span>
                            <span class="sm:hidden inline">Fornecedor</span>
                        </button>
                    </div>
                </div>

                <!-- Filtros -->
                <div class="flex flex-col sm:flex-row gap-4 mb-6">
                    <input v-model="filtro" type="text" placeholder="Buscar por nome ou empresa..."
                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                    <select v-model="tipoFiltro"
                        class="w-full sm:w-auto border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                        <option value="todos">Todos</option>
                        <option value="fornecedor">Fornecedores</option>
                        <option value="responsável">Responsáveis Internos</option>
                    </select>
                </div>

                <!-- Mensagem de erro -->
                <p v-if="error" class="text-red-500 text-center mb-4">{{ error }}</p>

                <!-- Versão Desktop - Tabela (visível apenas em telas maiores) -->
                <div class="hidden md:block bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto dark:text-white text-gray-800">
                    <table class="w-full">
                        <thead
                            class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
                            <tr>
                                <th class="px-4 py-3 text-left">Nome</th>
                                <th class="px-4 py-3 text-left">Detalhes</th>
                                <th class="px-4 py-3 text-left">Email</th>
                                <th class="px-4 py-3 text-left">Tipo</th>
                                <th class="px-4 py-3 text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="registro in registrosFiltrados" :key="registro.id"
                                class="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700">
                                <td class="px-4 py-3">{{ registro.nome }}</td>
                                <td class="px-4 py-3">
                                    <span v-if="registro.tipo === 'fornecedor'">
                                        <PhoneIcon class="inline-block w-4 h-4 text-green-500 mr-1" />
                                        {{ registro.telefone }}
                                    </span>
                                    <span v-else-if="registro.tipo === 'responsável'">
                                        <BuildingIcon class="inline-block w-4 h-4 text-purple-500 mr-1" />
                                        {{ registro.unidade ? getUnidadeName(registro.unidade) : '-' }}
                                        <span v-if="registro.setor">
                                            -
                                            <TagIcon class="inline-block w-4 h-4 text-blue-500 mr-1" />
                                            {{ getSetorName(registro.setor) }}
                                        </span>
                                    </span>
                                </td>
                                <td class="px-4 py-3">{{ registro.email || '-' }}</td>
                                <td class="px-4 py-3 capitalize">{{ registro.tipo }}</td>
                                <td class="px-4 py-3 text-center">
                                    <div class="flex justify-center space-x-2">
                                        <button v-if="registro.tipo === 'responsável'"
                                            @click="abrirModal('editar', registro)"
                                            class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                                            <Edit2Icon class="w-4 h-4" /> Editar
                                        </button>
                                        <button v-else @click="abrirModalFornecedor('editar', registro)"
                                            class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                                            <Edit2Icon class="w-4 h-4" /> Editar
                                        </button>
                                        <button @click="confirmarExclusao(registro.id, registro.tipo)"
                                            :disabled="isDeleting"
                                            class="flex items-center gap-1 text-red-500 hover:text-red-700">
                                            <Trash2Icon class="w-4 h-4" /> Excluir
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Versão Mobile - Cards (visível apenas em telas menores) -->
                <div class="md:hidden space-y-4">
                    <div v-for="registro in registrosFiltrados" :key="registro.id"
                        class="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md dark:text-white text-gray-800 border-l-4"
                        :class="{
                            'border-blue-500': registro.tipo === 'responsável',
                            'border-green-500': registro.tipo === 'fornecedor'
                        }">
                        <div class="flex justify-between items-start mb-2">
                            <h3 class="text-lg font-semibold">{{ registro.nome }}</h3>
                            <span class="text-xs px-2 py-1 rounded-full capitalize"
                                :class="{
                                    'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200': registro.tipo === 'responsável',
                                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200': registro.tipo === 'fornecedor'
                                }">
                                {{ registro.tipo }}
                            </span>
                        </div>

                        <div class="space-y-2 mb-3">
                            <div class="flex items-center gap-2">
                                <MailIcon class="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                <span class="text-sm">{{ registro.email || 'Não informado' }}</span>
                            </div>

                            <div v-if="registro.tipo === 'fornecedor'" class="flex items-center gap-2">
                                <PhoneIcon class="w-4 h-4 text-green-500" />
                                <span class="text-sm">{{ registro.telefone || 'Não informado' }}</span>
                            </div>

                            <div v-if="registro.tipo === 'responsável'" class="flex items-center gap-2">
                                <BuildingIcon class="w-4 h-4 text-purple-500" />
                                <span class="text-sm">{{ registro.unidade ? getUnidadeName(registro.unidade) : 'Sem unidade' }}</span>
                            </div>

                            <div v-if="registro.tipo === 'responsável' && registro.setor" class="flex items-center gap-2">
                                <TagIcon class="w-4 h-4 text-blue-500" />
                                <span class="text-sm">{{ getSetorName(registro.setor) }}</span>
                            </div>
                        </div>

                        <div class="flex justify-end gap-3 border-t pt-3 dark:border-gray-700">
                            <button v-if="registro.tipo === 'responsável'"
                                @click="abrirModal('editar', registro)"
                                class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                                <Edit2Icon class="w-4 h-4" /> Editar
                            </button>
                            <button v-else @click="abrirModalFornecedor('editar', registro)"
                                class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                                <Edit2Icon class="w-4 h-4" /> Editar
                            </button>
                            <button @click="confirmarExclusao(registro.id, registro.tipo)"
                                :disabled="isDeleting"
                                class="flex items-center gap-1 text-red-500 hover:text-red-700">
                                <Trash2Icon class="w-4 h-4" /> Excluir
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Modal de Responsáveis (Mesmo código, mas com melhorias de responsividade) -->
                <div v-if="modalAberto"
                    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
                    <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md animate-fade-in">
                        <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                            <UserIcon class="w-5 h-5 text-blue-500" />
                            {{ modoEdicao ? 'Editar Responsável' : 'Novo Responsável' }}
                        </h2>
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Usuário
                            Responsável</label>
                        <select v-model="form.usuario"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                            <option value="" disabled>Selecione um usuário</option>
                            <option v-for="user in usuariosDisponiveis" :key="user.id" :value="user.id">
                                {{ user.nome }}
                            </option>
                        </select>
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
                        <input v-model="form.email" type="email" placeholder="Digite o email"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade
                            Médica</label>
                        <select v-model="form.unidade"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3">
                            <option value="" disabled>Selecione uma unidade</option>
                            <option v-for="unidade in unidadesDisponiveis" :key="unidade.id" :value="unidade.id">
                                {{ unidade.nome }}
                            </option>
                        </select>
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Setor</label>
                        <select v-model="form.setor"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500 mb-4"
                            :disabled="!form.unidade || setoresFiltrados.length === 0">
                            <option value="" disabled>
                                {{ !form.unidade ? 'Selecione uma unidade primeiro' : (setoresFiltrados.length === 0 ?
                                'Sem setor disponível' : 'Selecione um setor') }}
                            </option>
                            <option v-for="setor in setoresFiltrados" :key="setor.id" :value="setor.id">
                                {{ setor.nome }}
                            </option>
                        </select>
                        <div class="flex flex-col sm:flex-row justify-end gap-3">
                            <button @click="fecharModal"
                                class="flex items-center justify-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                                <XIcon class="w-4 h-4" /> Cancelar
                            </button>
                            <button @click="salvarCadastro" :disabled="isSaving"
                                class="flex items-center justify-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                                <SaveIcon class="w-4 h-4" />
                                <span v-if="!isSaving">Salvar</span>
                                <span v-else>Salvando...</span>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Modal de Fornecedores (Mesmo código, mas com melhorias de responsividade) -->
                <div v-if="modalFornecedor"
                    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
                    <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md animate-fade-in">
                        <h2 class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4">
                            <PackageIcon class="w-5 h-5 text-green-500" />
                            {{ modoEdicaoFornecedor ? 'Editar Fornecedor' : 'Novo Fornecedor' }}
                        </h2>
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Nome</label>
                        <input v-model="formFornecedor.nome" type="text" placeholder="Digite o nome"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">CNPJ</label>
                        <input v-model="formFornecedor.cnpj" type="text" placeholder="Digite o CNPJ"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Telefone</label>
                        <input v-model="formFornecedor.telefone" type="text" placeholder="Digite o telefone"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
                        <input v-model="formFornecedor.email" type="email" placeholder="Digite o email"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Endereço</label>
                        <input v-model="formFornecedor.endereco" type="text" placeholder="Digite o endereço"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Contato
                            Responsável</label>
                        <input v-model="formFornecedor.contato_responsavel" type="text"
                            placeholder="Digite o contato responsável"
                            class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-3" />
                        <div class="flex flex-col sm:flex-row justify-end gap-3">
                            <button @click="fecharModalFornecedor"
                                class="flex items-center justify-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">
                                <XIcon class="w-4 h-4" /> Cancelar
                            </button>
                            <button @click="salvarFornecedor" :disabled="isSaving"
                                class="flex items-center justify-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                                <SaveIcon class="w-4 h-4" />
                                <span v-if="!isSaving">Salvar</span>
                                <span v-else>Salvando...</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>
    </BaseTemplate>
</template>

<script setup>
import BaseTemplate from './BaseTemplate.vue'

import { ref, computed, onMounted, watch } from 'vue'
import axios from 'axios'
import {
    PackageIcon,
    PhoneIcon,
    TagIcon,
    BuildingIcon,
    Edit2Icon,
    Trash2Icon,
    UserIcon,
    XIcon,
    SquareArrowLeft,
    SaveIcon
} from 'lucide-vue-next'

const API_FORNECEDORES = '/api/v1/fornecedores/'
import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
const usuariosDisponiveis = ref([])
const registros = ref([])
const isLoading = ref(false)
const error = ref(null)
const filtro = ref('')
const tipoFiltro = ref('todos')
const modalAberto = ref(false)
const modoEdicao = ref(false)
const isSaving = ref(false)
const isDeleting = ref(false)

// Estado para modal de fornecedores
const modalFornecedor = ref(false)
const modoEdicaoFornecedor = ref(false)

const form = ref({
    id: null,
    usuario: "",
    nome: "",
    email: "",
    setor: "",
    unidade: ""
})

// Estado para fornecedores – incluindo os novos campos
const formFornecedor = ref({
    id: null,
    nome: "",
    cnpj: "",
    telefone: "",
    email: "",
    endereco: "",
    contato_responsavel: ""
})

const getHeaders = () => {
    const token = sessionStorage.getItem("api_token")
    return {
        "Content-Type": "application/json",
        "X-API-KEY": token
    }
}

const registrosFiltrados = computed(() => {
    return registros.value.filter(registro =>
        (tipoFiltro.value === "todos" || registro.tipo === tipoFiltro.value) &&
        (registro.nome.toLowerCase().includes(filtro.value.toLowerCase()) ||
            (registro.email || "").toLowerCase().includes(filtro.value.toLowerCase()))
    )
})

const carregarUsuarios = async () => {
    const headers = getHeaders()
    try {
        const response = await axios.get("/api/v1/usuarios/", { headers })
        if (Array.isArray(response.data.items)) {
            usuariosDisponiveis.value = response.data.items.map(user => ({
                id: user.id,
                nome: `${user.first_name} ${user.last_name}`,
                email: user.email
            }))
        } else if (Array.isArray(response.data)) {
            usuariosDisponiveis.value = response.data.map(user => ({
                id: user.id,
                nome: `${user.first_name} ${user.last_name}`,
                email: user.email
            }))
        } else {
            console.error("Erro: Estrutura de resposta inesperada", response.data)
        }
    } catch (err) {
        console.error("Erro ao carregar usuários:", err)
    }
}

watch(() => form.value.usuario, novoUsuario => {
    const usuarioSelecionado = usuariosDisponiveis.value.find(u => u.id === novoUsuario)
    if (usuarioSelecionado && usuarioSelecionado.email) {
        form.value.email = usuarioSelecionado.email
    }
})

// Atualize o mapeamento para incluir os campos "endereco" e "contato_responsavel"
const carregarRegistros = async () => {
    isLoading.value = true
    error.value = null
    const headers = getHeaders()
    if (!headers) return

    try {
        const [fornecedoresRes] = await Promise.all([
            axios.get(API_FORNECEDORES, { headers }),
        ])

        const fornecedores = Array.isArray(fornecedoresRes.data.items)
            ? fornecedoresRes.data.items
            : []


        registros.value = [
            ...fornecedores.map(f => ({
                id: f.id,
                nome: f.nome,
                tipo: "fornecedor",
                telefone: f.telefone ? formatPhone(f.telefone) : "-",
                email: f.email || "-",
                cnpj: f.cnpj ? formatCNPJ(f.cnpj) : "",
                endereco: f.endereco || "-",
                contato_responsavel: f.contato_responsavel || "-"
            }))
        ]
    } catch (err) {
        console.error("Erro detalhado:", err)
        error.value = err.response?.data?.message || "Erro ao carregar registros."
    } finally {
        isLoading.value = false
    }
}

const carregarSetoresEUnidades = async () => {
    const headers = getHeaders()
    try {
        const [setoresRes, unidadesRes] = await Promise.all([
            axios.get("/api/v1/setores/", { headers }),
            axios.get("/api/v1/unidades/", { headers })
        ])

        setoresDisponiveis.value = Array.isArray(setoresRes.data.items)
            ? setoresRes.data.items
            : setoresRes.data
        unidadesDisponiveis.value = Array.isArray(unidadesRes.data.items)
            ? unidadesRes.data.items
            : unidadesRes.data
    } catch (err) {
        console.error("Erro ao carregar setores e unidades:", err)
    }
}

const setoresDisponiveis = ref([])
const unidadesDisponiveis = ref([])

const setoresFiltrados = computed(() => {
    if (!form.value.unidade) {
        return []
    }
    return setoresDisponiveis.value.filter(setor => setor.unidade.id === form.value.unidade)
})

function getUnidadeName(id) {
    const unidade = unidadesDisponiveis.value.find(u => u.id === id)
    return unidade ? unidade.nome : id
}

function getSetorName(id) {
    const setor = setoresDisponiveis.value.find(s => s.id === id)
    return setor ? setor.nome : id
}

// Função para abrir o modal de fornecedores
const abrirModalFornecedor = (modo, registro = null) => {
    modoEdicaoFornecedor.value = modo === "editar"
    if (modoEdicaoFornecedor.value && registro) {
        // Aqui, certifique-se de que os campos "endereco" e "contato_responsavel" estão presentes
        formFornecedor.value = {
            id: registro.id,
            nome: registro.nome,
            cnpj: registro.cnpj || "",
            telefone: registro.telefone || "",
            email: registro.email || "",
            endereco: registro.endereco || "",
            contato_responsavel: registro.contato_responsavel || ""
        }
    } else {
        formFornecedor.value = { id: null, nome: "", cnpj: "", telefone: "", email: "", endereco: "", contato_responsavel: "" }
    }
    modalFornecedor.value = true
}

const fecharModalFornecedor = () => {
    modalFornecedor.value = false
    formFornecedor.value = { id: null, nome: "", cnpj: "", telefone: "", email: "", endereco: "", contato_responsavel: "" }
}

// Funções de formatação
function formatCNPJ(value) {
    if (!value) return ""
    value = value.replace(/\D/g, '').slice(0, 14)
    if (value.length > 12) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5')
    } else if (value.length > 8) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4')
    } else if (value.length > 5) {
        return value.replace(/(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3')
    } else if (value.length > 2) {
        return value.replace(/(\d{2})(\d{0,3})/, '$1.$2')
    }
    return value
}

function formatPhone(value) {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    if (value.length >= 11) {
        return value.replace(/^(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3')
    } else {
        return value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3')
    }
}

// Watchers para aplicar a formatação enquanto o usuário digita
watch(() => formFornecedor.value.cnpj, (newVal) => {
    formFornecedor.value.cnpj = formatCNPJ(newVal)
})

watch(() => formFornecedor.value.telefone, (newVal) => {
    formFornecedor.value.telefone = formatPhone(newVal)
})

const salvarFornecedor = async () => {
    if (isSaving.value) return
    isSaving.value = true
    const headers = getHeaders()
    if (!headers) return

    if (!formFornecedor.value.nome) {
        error.value = "Preencha o campo Nome para o fornecedor."
        isSaving.value = false
        return
    }

    try {
        const payload = {
            nome: formFornecedor.value.nome,
            cnpj: formFornecedor.value.cnpj ? formFornecedor.value.cnpj.replace(/\D/g, "") : null,
            telefone: formFornecedor.value.telefone ? formFornecedor.value.telefone.replace(/\D/g, "") : null,
            email: formFornecedor.value.email || null,
            endereco: formFornecedor.value.endereco || null,
            contato_responsavel: formFornecedor.value.contato_responsavel || null
        }
        const endpoint = API_FORNECEDORES
        if (modoEdicaoFornecedor.value) {
            await axios.put(`${endpoint}/${formFornecedor.value.id}/`, payload, { headers })
        } else {
            await axios.post(endpoint, payload, { headers })
        }
        await carregarRegistros()
        fecharModalFornecedor()
    } catch (err) {
        console.error("Erro ao salvar fornecedor:", err)
        error.value = "Erro ao salvar o fornecedor."
    } finally {
        isSaving.value = false
    }
}

const confirmarExclusao = async (id) => {
    if (isDeleting.value) return
    isDeleting.value = true
    const headers = getHeaders()
    if (!headers) return

    try {
        const endpoint = `${API_FORNECEDORES}${id}/`
        await axios.delete(endpoint, { headers })
        registros.value = registros.value.filter(r => r.id !== id)
    } catch (err) {
        error.value = "Erro ao excluir o registro."
    } finally {
        isDeleting.value = false
    }
}

onMounted(() => {
    carregarRegistros()
    carregarUsuarios()
    carregarSetoresEUnidades()
})
</script>

<style scoped>
.animate-fade-in {
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
