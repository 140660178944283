<template>
  <BaseTemplate app-name="Feed" page-title="">
    <template #content>
      <div class="container mx-auto px-4 py-8 max-w-5xl">
        <!-- Avatar Section -->
        <div class="flex justify-center mb-8">
          <div class="avatar-container">
            <div class="avatar-wrapper">
              <img
                :src="profileImage || '/default-avatar.png'"
                alt="Avatar"
                class="avatar-image"
              />
            </div>
            <button
              @click="triggerFileInput"
              class="avatar-edit-button"
              aria-label="Alterar imagem de perfil"
            >
              <Camera class="w-5 h-5 sm:w-10 sm:h-10" />
            </button>
            <input
              type="file"
              ref="fileInputRef"
              @change="handleImageUpload"
              accept="image/*"
              class="hidden"
            />
          </div>
        </div>

        <!-- Profile Information Card -->
        <div class="bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden">
          <div class="p-6 md:p-8">
            <!-- Name Section -->
            <div class="mb-8">
              <div v-if="isEditingName" class="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                <input
                  v-model="firstName"
                  class="w-full sm:flex-1 border-b-2 border-blue-500 px-2 py-1 text-xl md:text-2xl font-semibold focus:outline-none bg-transparent dark:text-white"
                  placeholder="Nome"
                />
                <input
                  v-model="lastName"
                  class="w-full sm:flex-1 border-b-2 border-blue-500 px-2 py-1 text-xl md:text-2xl font-semibold focus:outline-none mt-4 sm:mt-0 bg-transparent dark:text-white"
                  placeholder="Sobrenome"
                />
                <div class="flex gap-2 mt-4 sm:mt-0">
                  <button @click="cancelEditingName" class="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 p-2 transition-colors">
                    <X class="w-5 h-5" />
                  </button>
                  <button @click="saveName" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
                    Salvar
                  </button>
                </div>
              </div>
              <div v-else class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div>
                  <h2 class="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white">
                    {{ `${firstName} ${lastName}` }}
                  </h2>
                </div>
                <button @click="startEditingName" class="text-blue-500 hover:text-blue-600 text-sm flex items-center gap-2 mt-2 sm:mt-0 transition-colors">
                  <Edit class="w-4 h-4" /> Editar
                </button>
              </div>
            </div>

            <!-- Profile Information -->
            <div class="space-y-6">
              <div class="space-y-2">
                <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300">E-mail:</label>
                <div class="flex items-center gap-3 border-b border-gray-300 dark:border-gray-600 focus-within:border-blue-500 transition-colors">
                  <Mail class="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  <input 
                    id="email" 
                    v-model="email" 
                    class="w-full px-2 py-2 focus:outline-none bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div class="flex justify-end mt-8">
                <button 
                  @click="saveChanges" 
                  class="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-all duration-300 transform hover:scale-105"
                >
                  Salvar Alterações
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal de Recorte de Imagem -->
        <div v-if="showCropper" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
    <div class="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-4xl">
      <h3 class="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Ajustar Imagem de Perfil</h3>
      
      <div class="flex justify-center mb-4">
        <div class="relative rounded-full overflow-hidden border-4 border-white dark:border-gray-700 shadow-lg" 
             style="width: 300px; height: 300px;">
          <cropper
            ref="cropper"
            :src="imageToCrop"
            :stencil-component="circleStencil"
            :stencil-props="{
              aspectRatio: 1,
              movable: true,  
              resizable: true,
              handlers: {
                eastNorth: true,
                westNorth: true,
                eastSouth: true,
                westSouth: true
              },
              handlerComponent: CustomHandler
            }"
            :canvas="{
              width: 300,
              height: 300
            }"
            class="cropper"
          />
        </div>
      </div>

            
            <div class="flex justify-end space-x-3">
              <button 
                @click="cancelCrop" 
                class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                Cancelar
              </button>
              <button 
                @click="cropAndSaveImage" 
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>

        <p class="text-center text-sm mt-10 text-gray-500 dark:text-gray-400">
          © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
        </p>
      </div>
    </template>
  </BaseTemplate>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import { CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { Camera, Mail, X, Edit } from 'lucide-vue-next'
import axios from 'axios'
import BaseTemplate from './BaseTemplate.vue'

export default {
  components: {
    Cropper,
    Camera,
    Mail,
    X,
    Edit,
    BaseTemplate
  },
  data() {
    return {
      circleStencil: CircleStencil, // Adicione esta linha
      firstName: '',
      lastName: '',
      email: '',
      profileImage: '',
      isEditingName: false,
      showCropper: false,
      imageToCrop: '',
      fileInputRef: null
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInputRef.click()
    },
    handleImageUpload(event) {
      const file = event.target.files?.[0]
      if (file) {
        if (file.size > 1024 * 1024 * 5) {
          alert("Imagem muito grande. Máximo 5MB.")
          return
        }
        
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target?.result) {
            this.imageToCrop = e.target.result
            this.showCropper = true
          }
        }
        reader.readAsDataURL(file)
      }
    },
    cropAndSaveImage() {
  const cropper = this.$refs.cropper;
  if (cropper) {
    const result = cropper.getResult({
      size: { width: 300, height: 300 },
      fillColor: 'rgba(0, 0, 0, 0)'  // Cor transparente em RGBA
    });
    if (result && result.canvas) {
      // Opcional: Limpar o canvas para remover possíveis resíduos
      const ctx = result.canvas.getContext('2d');
      ctx.clearRect(0, 0, result.canvas.width, result.canvas.height);
      
      this.profileImage = result.canvas.toDataURL('image/png');
      this.showCropper = false;
      this.saveChanges();
    }
  }
}

,
    cancelCrop() {
      this.showCropper = false
    },
    startEditingName() {
      this.isEditingName = true
    },
    cancelEditingName() {
      this.isEditingName = false
    },
    saveName() {
      this.isEditingName = false
    },
    async carregarPerfil() {
  try {
    const apiToken = sessionStorage.getItem("api_token")
    const headers = { "X-API-KEY": apiToken }
    const { data } = await axios.get('/api/v1/profile', { headers })

    this.firstName = data.nome
    this.lastName = data.sobrenome
    this.email = data.email
    
    if (data.imagem_base64) {
      this.profileImage = data.imagem_base64.startsWith('http')
        ? data.imagem_base64 
        : `data:image/jpeg;base64,${data.imagem_base64}`
    } else {
      this.profileImage = '/default-avatar.png'
    }
  } catch (error) {
    console.error("Erro ao carregar perfil:", error)
    alert("Erro ao carregar perfil.")
  }
},

    async saveChanges() {
      try {
        const apiToken = sessionStorage.getItem("api_token")
        const payload = {
          nome: this.firstName,
          sobrenome: this.lastName,
          email: this.email,
          imagem_base64: this.profileImage.startsWith('data:image') 
            ? this.profileImage.split(',')[1] 
            : this.profileImage
        }
        
        await axios.put('/api/v1/profile', payload, {
          headers: {
            "X-API-KEY": apiToken,
            "Content-Type": "application/json"
          }
        })
        alert("Perfil atualizado com sucesso!")
      } catch (error) {
        console.error("Erro ao salvar alterações:", error)
        alert("Erro ao salvar perfil.")
      }
    }
  },
  mounted() {
    this.carregarPerfil()
    this.fileInputRef = this.$refs.fileInputRef
  }
}
</script>

<style scoped>
/* Avatar Section */
.avatar-container {
  position: relative;
  display: inline-block;
}

.avatar-wrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: white;
}

.dark .avatar-wrapper {
  border-color: #1f2937;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.avatar-edit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #3b82f6;
  color: white;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.avatar-edit-button:hover {
  background-color: #2563eb;
  transform: scale(1.1);
}

/* Cropper Styles */
.cropper {
  width: 100%;
  height: 100%;
  background: #ddd;
}

.cropper__image {
  object-fit: contain;
}

.cropper__stencil {
  overflow: hidden;
  border-radius: 50%;
}

.cropper__handler {
  display: none;
}

@media (max-width: 640px) {
  .avatar-wrapper {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 400px) {
  .avatar-wrapper {
    width: 200px;
    height: 200px;
  }
}

.handler {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handler-circle {
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  transition: transform 0.2s;
}

.handler:hover .handler-circle {
  transform: scale(1.2);
}

/* Posicionamento dos handles */
.handler[data-direction="east-north"] {
  top: -10px;
  right: -10px;
  cursor: ne-resize;
}

.handler[data-direction="west-north"] {
  top: -10px;
  left: -10px;
  cursor: nw-resize;
}

.handler[data-direction="east-south"] {
  bottom: -10px;
  right: -10px;
  cursor: se-resize;
}

.handler[data-direction="west-south"] {
  bottom: -10px;
  left: -10px;
  cursor: sw-resize;
}

/* Estilo do stencil */
.stencil {
  border: 2px dashed rgba(255,255,255,0.5);
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 9999px rgba(0,0,0,0.5);
}

/* Ajustes para dark mode */
.dark .stencil {
  border-color: rgba(0,0,0,0.5);
  box-shadow: 0 0 0 9999px rgba(255,255,255,0.3);
}

</style>