<template>

            <div
                class="flex flex-col bg-slate-200 dark:bg-gray-900 transition-all duration-200 overflow-y-hidden overflow-x-hidden overrelative ">

                <!-- 📢 Banner de Sucesso -->
                <transition name="fade">
                    <div v-if="showSuccessBanner"
                        class="fixed top-5 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg z-50 text-sm sm:text-base">
                        ✅ Movimentação realizada com sucesso!
                    </div>
                </transition>

                <div class="w-full px-4 py-3 sm:py-5">
                    <div class="grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-4 overflow-x-auto">
                        <!-- 📌 Card - Pendências -->
                        <div class="rounded-lg bg-white dark:bg-gray-800 p-3 sm:p-4 shadow-md flex items-center">
                            <LucideClipboardList class="text-gray-500 dark:text-gray-300 w-5 h-5 mr-2" />
                            <div>
                                <h3 class="text-xs sm:text-sm font-medium text-gray-600 dark:text-gray-300">Pendências
                                </h3>
                                <p class="mt-1 text-lg sm:text-xl font-bold text-gray-900 dark:text-white">
                                    {{ totalPendencias }}
                                </p>
                            </div>
                        </div>

                        <!-- 📌 Card - Atrasadas -->
                        <div class="rounded-lg bg-white dark:bg-gray-800 p-3 sm:p-4 shadow-md flex items-center">
                            <LucideAlertCircle class="text-red-500 w-5 h-5 mr-2" />
                            <div>
                                <h3 class="text-xs sm:text-sm font-medium text-gray-600 dark:text-gray-300">Atrasadas
                                </h3>
                                <p class="mt-1 text-lg sm:text-xl font-bold text-red-600 dark:text-red-400">
                                    {{ atrasadas }}
                                </p>
                            </div>
                        </div>

                        <!-- 📌 Card - Concluídas -->
                        <div class="rounded-lg bg-green-100 dark:bg-green-800 p-3 sm:p-4 shadow-md flex items-center">
                            <LucideCheckCircle class="text-green-500 w-5 h-5 mr-2" />
                            <div>
                                <h3 class="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-200">Concluídas
                                </h3>
                                <p class="mt-1 text-lg sm:text-xl font-bold text-green-600 dark:text-green-400">
                                    {{ concluidas }}
                                </p>
                            </div>
                        </div>
                        <!-- 💰 NOVO CARD - Orçamento -->
                        <div class="rounded-lg bg-yellow-100 dark:bg-yellow-800 p-3 sm:p-4 shadow-md flex items-center">
                            <Coins class="text-yellow-600 dark:text-yellow-400 w-5 h-5 mr-2" />
                            <div>
                                <h3
                                    class="flex items-center text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-200">
                                    Orçamento Pendências
                                    <!-- Ícone de informação com tooltip -->
                                    <span title="Recursos humanos não entram na contagem" class="ml-1">
                                        <InfoIcon class="w-4 h-4 dark:text-white text-gray-700" />
                                    </span>
                                </h3>
                                <p class="mt-1 text-lg sm:text-xl font-bold text-yellow-700 dark:text-yellow-300">
                                    {{ orcamentoTotal }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- 📌 Kanban Board -->
                <div
                    class="kanban-container mx-5 w-full h-[calc(100vh-17rem)] md:h-[calc(100vh-14rem)] pb-16 md:pb-26 overflow-x-auto snap-x snap-mandatory scroll-smooth">
                    <div class="kanban-wrapper flex w-auto h-full space-x-6 pr-48">
  <div v-for="(status, index) in orderedStatuses" :key="status.id" :class="[
      'snap-start min-w-[280px] sm:min-w-[300px] lg:min-w-[340px] flex-shrink-0 h-full p-3 sm:p-4 rounded-lg shadow-md transition-all flex flex-col',
      // Você pode ajustar a cor de fundo para cada coluna conforme o status
      status.id === 'concluido' ? 'bg-gray-100 dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-800',
      isDraggingColumn && draggedColumnId === status.id ? 'opacity-50' : 'opacity-100',
      isDraggingOverColumn === status.id ? 'border-2 border-blue-400' : ''
    ]" draggable="true" @dragstart="handleColumnDragStart($event, status.id, index)"
      @dragend="handleColumnDragEnd"
      @dragover.prevent="handleColumnDragOver($event, status.id, index)"
      @drop="handleColumnDrop($event, status.id, index)">
    


                            <!-- Column Header -->
                            <div class="mb-3 flex items-center justify-between px-3 py-2 rounded-lg shadow-sm cursor-move"
                                :class="{
                                    'bg-gradient-to-r from-green-400 to-green-600 text-white': status.nome === 'Concluído',
                                    'bg-gradient-to-r from-yellow-400 to-yellow-600 text-white': status.nome === 'Em Andamento',
                                    'bg-gradient-to-r from-blue-400 to-blue-600 text-white': status.nome === 'Pendente',
                                    'bg-gradient-to-r from-red-400 to-red-600 text-white': status.nome === 'Cancelado/Suspenso'

                                }">
                                <h2 class="text-sm sm:text-lg font-bold">{{ status.nome }}</h2>
                                <span
                                    class="rounded-full px-3 py-1 text-xs font-semibold bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-300 shadow-md">
                                    {{ filteredPendencias(status.id).length }}
                                </span>
                            </div>


                            <div class="flex-1 overflow-y-auto px-1 py-2"
                                @dragover.prevent="handleDragOver($event, status.id)"
                                @drop="handleDrop($event, status.id)">

                                <!-- Task Cards -->
                                <div v-for="pendencia in filteredPendencias(status.id)" :key="pendencia?.id"
                                    class="group mb-3 cursor-pointer transform transition-all duration-200 hover:-translate-y-1"
                                    draggable="true" @dragstart="handleDragStart($event, pendencia?.id)"
                                    @touchstart="handleDragStart($event, pendencia?.id)" @touchmove="handleTouchMove"
                                    @dragend="handleDragEnd" @touchend="handleTouchEnd"
                                    @click="pendencia?.id ? goToPendencia(pendencia.id) : null">




                                    <div class="relative rounded-lg shadow-sm hover:shadow-md bg-white dark:bg-gray-700 overflow-hidden"
                                        :class="[
                                            status.nome === 'Concluído'
                                                ? 'border-l-4 border-green-500'
                                                : new Date(pendencia?.prazo) < new Date()
                                                    ? 'border-l-4 border-red-500'
                                                    : 'border-l-4 border-gray-300'
                                        ]">

                                        <!-- Status Indicators -->
                                        <div class="absolute top-3 right-3 flex gap-1">
                                            <LucideAlertCircle
                                                v-if="new Date(pendencia.prazo) < new Date() && status.nome !== 'Concluído'"
                                                class="w-5 h-5 text-red-500 animate-pulse" />
                                            <LucideCheckCircle v-if="status.nome === 'Concluído'"
                                                class="w-5 h-5 text-green-500" />
                                        </div>

                                        <!-- Card Content -->
                                        <div class="p-4 max-w-[300px]"> <!-- 🔹 Define largura máxima -->
                                            <!-- Header -->
                                            <div class="mb-3">
                                                <div class="flex items-center gap-1 mb-1">
                                                    <LucideClipboard class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <h3
                                                        class="font-medium text-sm text-gray-900 dark:text-white truncate max-w-[260px]">
                                                        <!-- 🔹 Trunca o código se for muito longo -->
                                                        {{ pendencia.codigo }}
                                                    </h3>
                                                </div>
                                                <h3
                                                    class="font-medium text-sm text-gray-900 dark:text-white truncate max-w-[260px]">
                                                    {{ pendencia.status.nome }}
                                                </h3>

                                                <!-- 🔹 Define altura máxima e quebra de linha controlada para evitar expansão -->
                                                <p
                                                    class="text-sm text-gray-600 dark:text-gray-300 line-clamp-2 max-h-[48px] overflow-hidden">
                                                    {{ pendencia.problema }}
                                                </p>
                                            </div>

                                            <!-- Details -->
                                            <div class="space-y-2 text-xs">
                                                <!-- Unidade -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400">
                                                    <Hospital class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <span class="font-medium">Unidade:</span>
                                                    <span class="truncate max-w-[200px]">{{ pendencia.unidade.nome ||
                                                        "Não definido" }}</span>
                                                </div>

                                                <!-- Responsável -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400">
                                                    <LucideUser class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <span class="font-medium">Responsável:</span>
                                                    <span class="truncate max-w-[200px]">{{
                                                        pendencia.responsavel_interno || "Não definido" }}</span>
                                                </div>

                                                <!-- Fornecedor -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400">
                                                    <BriefcaseBusiness
                                                        class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <span class="font-medium">Fornecedor:</span>
                                                    <span class="truncate max-w-[200px]">{{
                                                        pendencia.responsavel_externo || "Não definido" }}</span>
                                                </div>

                                                <!-- Orçamento -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400">
                                                    <Coins class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <span class="font-medium">Orçamento:</span>
                                                    <span class="truncate max-w-[200px]">{{
                                                        formatCurrency(pendencia?.orcamento) || 'Não Informado'
                                                        }}</span>
                                                </div>

                                                <!-- GLPI -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400">
                                                    <Logs class="w-4 h-4 text-gray-400 dark:text-gray-500" />
                                                    <span class="font-medium">Nº GLPI:</span>
                                                    <span class="truncate max-w-[200px]">{{ pendencia.glpi || "Não definido" }}</span>
                                                </div>

                                                <!-- Prazo -->
                                                <div class="flex items-center gap-1 text-gray-500 dark:text-gray-400"
                                                    :class="{ 'text-red-500 dark:text-red-400': new Date(pendencia.prazo) < new Date() && status.nome !== 'Concluído' }">
                                                    <LucideClock class="w-4 h-4" />
                                                    <span class="font-medium">Prazo:</span>
                                                    <span>{{ formatDate(pendencia.prazo) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Progress Bar for tasks close to deadline -->
                                        <div v-if="status.nome !== 'Concluído'"
                                            class="h-1 w-full bg-gray-100 dark:bg-gray-600">
                                            <div class="h-full rounded-3xl transition-all duration-500  " :class="{
                                                'bg-green-500': getDaysUntilDeadline(pendencia.prazo) > 7,
                                                'bg-yellow-500': getDaysUntilDeadline(pendencia.prazo) <= 7 && getDaysUntilDeadline(pendencia.prazo) > 0,
                                                'bg-red-500': getDaysUntilDeadline(pendencia.prazo) <= 0}"
                                                :style="{width: `${getProgressPercentage(pendencia.prazo)}%`}">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <!-- Empty State -->
                            <div v-if="filteredPendencias(status.id).length === 0"
                                class="h-32 rounded-lg border-2 border-dashed border-gray-200 dark:border-gray-600 flex flex-col items-center justify-center">
                                <LucideInbox class="w-8 h-8 text-gray-300 dark:text-gray-500 mb-2" />
                                <p class="text-sm text-gray-400 dark:text-gray-500">Sem pendências</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Modal de Confirmação -->
            <transition name="modal">
                <div v-if="showModal"
                    class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 dark:bg-black/70">
                    <div class="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md animate-fade-in p-6">
                        <h3 class="text-xl font-bold text-gray-800 dark:text-white mb-4">
                            ⚠️ Confirmação de Movimentação
                        </h3>
                        <p class="text-gray-600 dark:text-gray-300">
                            Você está movendo a pendência <strong>#{{ pendenciaMovida?.codigo }}</strong> para um novo
                            status.
                        </p>

                        <!-- Dropdown para escolher o novo status -->
                        <div class="mt-4">
                            <label class="block text-gray-700 dark:text-gray-300 text-sm font-medium">
                                Selecione o Status:
                            </label>

                            <select v-if="statusFiltradosModal.length > 0" v-model="statusSelecionado"
                                class="mt-2 w-full px-4 py-2 border rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white">

                                <!-- Opção padrão -->
                                <option value="" disabled selected>Selecione o novo status</option>

                                <option v-for="status in statusFiltradosModal" :key="status.id" :value="status.id">
                                    {{ status.nome }}
                                </option>
                            </select>

                            <!-- Exibe mensagem se não houver status disponíveis -->
                            <p v-else class="text-gray-500 dark:text-gray-400 text-sm mt-2">
                                ⚠️ Nenhum status disponível para esta pendência. <br>
                                Verifique se o tipo de pendência foi corretamente definido.
                            </p>
                        </div>




                        <div class="flex justify-end gap-3 mt-6">
                            <button @click="cancelarMovimentacao"
                                class="px-6 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 dark:hover:bg-gray-700 transition-all">
                                Cancelar
                            </button>
                            <button @click="confirmarMovimentacao" :disabled="!statusSelecionado"
                                class="px-6 py-2.5 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-all disabled:bg-gray-400 disabled:cursor-not-allowed">
                                Sim, Mover
                            </button>
                        </div>
                    </div>
                </div>
            </transition>





</template>







<script setup>
import { useRouter } from "vue-router"; // ⬅️ Importa o Vue Router
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import {
    LucideClipboardList,
    LucideAlertCircle,
    LucideCheckCircle,
    LucideClock,
    Coins,
    InfoIcon,
    LucideUser,
    BriefcaseBusiness,
    Hospital,
    Logs,
    LucideClipboard,
    LucideInbox,
} from 'lucide-vue-next';

const statusSelecionado = ref(null); // Status escolhido pelo usuário
const router = useRouter(); // ⬅️ Obtém a instância do roteador
const isDraggingColumn = ref(false);
const draggedColumnId = ref(null);
const isDraggingOverColumn = ref(null);
const statusOrder = ref([]);
const showSuccessBanner = ref(false);
const statuses = ref([]);
const tiposPendencias = ref([]);
const isDraggingOver = ref(null); // REMOVA esta linha se não for necessária
const pendencias = ref([]);
const isDragging = ref(null);
const apiToken = sessionStorage.getItem("api_token");
const showModal = ref(false); // Controle do modal
const pendenciaMovida = ref(null); // Armazena a pendência sendo movida
const novoStatus = ref(null); // Armazena o novo status
const dragItem = ref(null);
const dragStartX = ref(null);
const dragStartY = ref(null);
const currentColumnId = ref(null);


// 📌 Cálculo dinâmico para contadores no header
const totalPendencias = computed(() => pendencias.value.length);
const concluidas = computed(() => pendencias.value.filter(p => p.status.nome === 'Concluído').length);
const atrasadas = computed(() => pendencias.value.filter(p => new Date(p.prazo) < new Date() && p.status.nome !== 'Concluído').length);

const goToPendencia = (id) => {
    router.push({ name: "PendenciaDetalhes", params: { id } });
};


const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': `${apiToken}`,
};



// Fetch and initialize status order
const fetchStatuses = async () => {
    try {
        const res = await axios.get('/api/v1/status_pendencias/', { headers });
        statuses.value = res.data;
        // Initialize status order if empty
        if (statusOrder.value.length === 0) {
            statusOrder.value = res.data
                .sort((a, b) => {
                    if (a.nome === "Concluído") return 1;
                    if (b.nome === "Concluído") return -1;
                    return 0;
                })
                .map(status => status.id);
        }
    } catch (error) {
        console.error("Erro ao buscar status:", error);
    }
};

const orderedStatuses = computed(() => {
  return [
    { nome: "Pendente", id: "pendente" },
    { nome: "Em Andamento", id: "em_andamento" },
    { nome: "Concluído", id: "concluido" },
    { nome: "Cancelado/Suspenso", id: "cancelado" },
  ];
});






const filteredPendencias = computed(() => {
  return (statusGroup) => {
    return pendencias.value.filter(p => {
      const nomeStatus = p.status.nome;
      if (statusGroup === "pendente") {
        // Considera pendências cujo status inicia com "Aguardando"
        return nomeStatus.startsWith("Aguardando");
      }
      if (statusGroup === "em_andamento") {
        // Considera pendências que não estão concluídas, nem aguardando, nem canceladas/suspensas
        return nomeStatus !== "Concluído" &&
               !nomeStatus.startsWith("Aguardando") &&
               nomeStatus !== "Cancelado" &&
               nomeStatus !== "Suspenso";
      }
      if (statusGroup === "concluido") {
        return nomeStatus === "Concluído";
      }
      if (statusGroup === "cancelado") {
        // Exibe somente as pendências cujo status seja "Cancelado" ou "Suspenso"
        return nomeStatus === "Cancelado" || nomeStatus === "Suspenso";
      }
      return false;
    });
  };
});


// 📌 Buscar pendências
const fetchPendencias = async () => {
    try {
        const res = await axios.get('/api/v1/pendencias/', { headers });
        pendencias.value = res.data;
    } catch (error) {
        console.error("Erro ao buscar pendências:", error);
    }
};



// 📌 Observar mudanças nas pendências e atualizar o header dinamicamente
watch(pendencias, () => {
    console.log("⚡ Atualizando contadores...");
});



const handleDragOver = (event, statusId) => {
    isDraggingOver.value = statusId;
};



// 📌 Formatar datas
const formatDate = (dateStr) => {
    if (!dateStr) return "Não definido";
    return new Date(dateStr).toLocaleDateString("pt-BR");
};

// Variáveis adicionais para controle touch
const touchState = ref({
    isDragging: false,
    startX: 0,
    startY: 0,
    currentItem: null,
    currentColumn: null
});

// 📌 Evento para iniciar o arrastar (touch ou mouse)
const handleDragStart = (event, pendenciaId) => {
    isDragging.value = pendenciaId;
    dragItem.value = event.target;

    if (event.type === "touchstart") {
        const touch = event.touches[0];
        dragStartX.value = touch.clientX;
        dragStartY.value = touch.clientY;
        event.target.style.opacity = "0.6"; // Reduz opacidade ao arrastar
    } else {
        event.dataTransfer.setData("pendenciaId", pendenciaId);
        event.dataTransfer.effectAllowed = "move";
    }
};


const handleTouchMove = (event) => {
    if (!touchState.value.isDragging) return;

    const touch = event.touches[0];
    const elements = document.elementsFromPoint(touch.clientX, touch.clientY);
    const column = elements.find(el => el.classList.contains('kanban-column'));

    if (column) {
        touchState.value.currentColumn = column.dataset.statusId;
        // Adiciona feedback visual para a coluna de destino
        document.querySelectorAll('.kanban-column').forEach(col =>
            col.classList.remove('drag-over')
        );
        column.classList.add('drag-over');
    }

    event.preventDefault();
};
// 📌 Evento para finalizar o arrastar
const handleTouchEnd = () => {
    if (isDragging.value && currentColumnId.value) {
        handleDrop({ preventDefault: () => { } }, currentColumnId.value);
    }

    if (dragItem.value) {
        dragItem.value.style.opacity = "1";
    }

    isDragging.value = null;
    currentColumnId.value = null;
};




const handleDragEnd = () => {
    isDragging.value = null;
};

// Exibir banner por 3 segundos
const showBanner = () => {
    showSuccessBanner.value = true;
    setTimeout(() => {
        showSuccessBanner.value = false;
    }, 3000);
};

// 📌 Evento para soltar no desktop
const handleDrop = (event, newStatusId) => {
    event.preventDefault();
    let pendenciaId = isDragging.value || event.dataTransfer.getData("pendenciaId");

    if (!pendenciaId) {
        console.error("❌ Erro: Pendência não encontrada.");
        return;
    }

    const pendencia = pendencias.value.find(p => p.id == pendenciaId);
    if (!pendencia) {
        console.error("❌ Erro: Pendência não encontrada.");
        return;
    }

    pendenciaMovida.value = pendencia;
    novoStatus.value = { id: newStatusId, nome: newStatusId };
    showModal.value = true;

    isDragging.value = null; // Finaliza o processo de arrastar
};




const confirmarMovimentacao = async () => {
    if (!pendenciaMovida.value || !statusSelecionado.value) {
        console.error("❌ Erro: Nenhuma pendência ou status selecionado.");
        return;
    }

    try {
        await axios.put(
            `/api/v1/pendencias/${pendenciaMovida.value.id}/`,
            {
                status_id: statusSelecionado.value,
                glpi: pendenciaMovida.value.glpi || "", // 🔹 Garante que `glpi` seja enviado
            },
            { headers }
        );

        // Atualizar lista de pendências
        await fetchPendencias();

        // Fechar modal e limpar estado
        showModal.value = false;
        pendenciaMovida.value = null;
        statusSelecionado.value = null;
        novoStatus.value = null;

        // Exibir banner de sucesso
        showBanner();
    } catch (error) {
        console.error("❌ Erro ao atualizar status da pendência:", error);
    }
};


const orcamentoTotal = computed(() => {
    const total = pendencias.value
        .filter(p =>
            p.status.nome !== 'Concluído' &&
            p.status.nome !== 'Cancelado' &&
            p.status.nome !== 'Suspenso' &&
            p.orcamento &&
            p.tipo.nome !== 'Recursos Humanos'
        )
        .reduce((acc, p) => acc + parseFloat(p.orcamento || 0), 0);
    return (total / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
});


const formatCurrency = (value) => {
    if (value === null || value === undefined || value === "") return "Não Informado";
    const numericValue = Number(value);
    if (isNaN(numericValue)) return "Não Informado";
    // Converte de centavos para reais dividindo por 100 e formata com o símbolo "R$"
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(numericValue / 100);
};




const statusFiltradosModal = computed(() => {
    if (!pendenciaMovida.value || !pendenciaMovida.value.tipo || !pendenciaMovida.value.tipo.id) {
        console.warn("⚠️ Nenhuma pendência movida ou `tipo_id` indefinido.");
        return [];
    }

    if (!novoStatus.value || !novoStatus.value.id) {
        console.warn("⚠️ Nenhum novo status foi selecionado.");
        return [];
    }

    // Encontrar o tipo correspondente em `tiposPendencias`
    const tipoSelecionado = tiposPendencias.value.find(tipo => tipo.id === pendenciaMovida.value.tipo.id);

    if (!tipoSelecionado) {
        console.warn("⚠️ Tipo de pendência não encontrado para `tipo_id`:", pendenciaMovida.value.tipo.id);
        return [];
    }

    console.log("✅ Tipo de Pendência Selecionado:", tipoSelecionado);
    console.log("📌 Status Disponíveis:", tipoSelecionado.status_disponiveis);

    // Filtro baseado na coluna de destino
    return tipoSelecionado.status_disponiveis.filter(status => {
        if (novoStatus.value.id === "pendente") {
            return status.nome.startsWith("Aguardando");
        }
        if (novoStatus.value.id === "em_andamento") {
            return status.nome !== "Concluído" &&
                   !status.nome.startsWith("Aguardando") &&
                   status.nome !== "Cancelado" &&
                   status.nome !== "Suspenso";
        }
        if (novoStatus.value.id === "concluido") {
            return status.nome === "Concluído";
        }
        if (novoStatus.value.id === "cancelado") {
            return status.nome === "Cancelado" || status.nome === "Suspenso";
        }
        return false;
    });
});




// Função para cancelar a movimentação
const cancelarMovimentacao = () => {
    showModal.value = false;
    pendenciaMovida.value = null;
    novoStatus.value = null;
};



// Column drag and drop handlers
const handleColumnDragStart = (event, statusId, index) => {
    isDraggingColumn.value = true;
    draggedColumnId.value = statusId;
    event.dataTransfer.setData('columnId', statusId);
    event.dataTransfer.setData('columnIndex', index);
};

const handleColumnDragEnd = () => {
    isDraggingColumn.value = false;
    draggedColumnId.value = null;
    isDraggingOverColumn.value = null;
};

const handleColumnDragOver = (event, statusId) => {
    if (isDraggingColumn.value) {
        isDraggingOverColumn.value = statusId;
    }
};

// Add these utility functions
const getDaysUntilDeadline = (deadline) => {
    const today = new Date()
    const deadlineDate = new Date(deadline)
    const diffTime = deadlineDate - today
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

const getProgressPercentage = (deadline) => {
    const daysLeft = getDaysUntilDeadline(deadline)
    if (daysLeft <= 0) return 100
    if (daysLeft > 14) return 25
    return 100 - (daysLeft / 14 * 100)
}

const handleColumnDrop = (event, targetStatusId) => {
    event.preventDefault();

    // Obtém o ID da coluna de origem
    const sourceStatusId = event.dataTransfer.getData('columnId');

    if (!sourceStatusId || !targetStatusId) {
        console.warn("❌ Erro ao mover a coluna: status de origem ou destino não definido.");
        return;
    }

    console.log(`🔄 Movendo de ${sourceStatusId} para ${targetStatusId}`);

    // Ajuste para encontrar corretamente os status por nome (pendente, em andamento, concluído)
    const statusMap = {
        pendente: "Aguardando",
        em_andamento: "Em Andamento",
        concluido: "Concluído"
    };

    const sourceStatus = Object.keys(statusMap).find(key => key === sourceStatusId);
    const targetStatus = Object.keys(statusMap).find(key => key === targetStatusId);

    if (!sourceStatus || !targetStatus) {
        console.warn("❌ Erro ao mover: Status de origem ou destino não encontrado na lista.");
        return;
    }

    console.log(`✅ Movendo da coluna ${statusMap[sourceStatus]} para ${statusMap[targetStatus]}`);

    // Atualiza a ordem das colunas
    const newOrder = [...statusOrder.value];
    const sourceIndex = newOrder.indexOf(sourceStatus);
    const targetIndex = newOrder.indexOf(targetStatus);

    if (sourceIndex === -1 || targetIndex === -1) {
        console.warn("❌ Erro ao encontrar índices da coluna.");
        return;
    }

    // Reordenar as colunas
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(targetIndex, 0, sourceStatus);
    statusOrder.value = newOrder;

    // Salvar no localStorage para persistência
    localStorage.setItem('statusOrder', JSON.stringify(newOrder));

    handleColumnDragEnd();
};

const fetchTiposPendencias = async () => {
    try {
        const res = await axios.get('/api/v1/tipos_pendencias/', { headers });
        tiposPendencias.value = res.data;
        console.log("📥 Tipos de Pendências carregados:", tiposPendencias.value);
    } catch (error) {
        console.error("❌ Erro ao buscar tipos de pendências:", error);
    }
};



// Chamar a função ao montar o componente
onMounted(async () => {
    await fetchTiposPendencias();
    await fetchStatuses();
    await fetchPendencias();
});

// Load saved status order on mount
onMounted(async () => {
    const savedOrder = localStorage.getItem('statusOrder');
    if (savedOrder) {
        statusOrder.value = JSON.parse(savedOrder);
    }
    await fetchStatuses();
    await fetchPendencias();

    watch(statusFiltradosModal, (novosStatus) => {
        if (novosStatus.length > 0 && !statusSelecionado.value) {
            statusSelecionado.value = "";
        }
    });
});

</script>


<style scoped>
/* 🔥 Remove completamente o scroll lateral indesejado */
html,
body {
    overflow-x: hidden;
}

/* 🔹 Container do Kanban sem scroll externo */
.kanban-container {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* 🔹 Responsividade do Kanban */
.kanban-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: stretch;

    /* Estilização da scrollbar horizontal */
    scrollbar-width: thin;
    scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* Estilização da scrollbar horizontal para Webkit (Chrome, Safari, etc) */
.kanban-wrapper::-webkit-scrollbar {
    height: 8px;
}

.kanban-wrapper::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

.kanban-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(39, 136, 245, 0.8);
    border-radius: 4px;
    border: 2px solid transparent;
}

.kanban-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(14, 96, 238, 0.7);
}

/* 🔹 Cada coluna ocupa a largura total no mobile */
.kanban-column {
    flex: 0 0 100vw;
    max-width: 92vw;
    scroll-snap-align: start;
    background-color: var(--tw-bg-opacity);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Estilização da scrollbar vertical para as colunas */
.overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* Estilização da scrollbar vertical para Webkit */
.overflow-y-auto::-webkit-scrollbar {
    width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: rgba(13, 80, 194, 0.5);
    border-radius: 4px;
    border: 2px solid transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-color: rgba(16, 83, 199, 0.7);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Modo escuro */
@media (prefers-color-scheme: dark) {
    .kanban-wrapper {
        scrollbar-color: rgba(75, 85, 99, 0.5) transparent;
    }

    .kanban-wrapper::-webkit-scrollbar-thumb {
        background-color: rgba(75, 85, 99, 0.5);
    }

    .kanban-wrapper::-webkit-scrollbar-thumb:hover {
        background-color: rgba(7, 70, 158, 0.7);
    }

    .overflow-y-auto {
        scrollbar-color: rgba(12, 87, 192, 0.5) transparent;
    }

    .overflow-y-auto::-webkit-scrollbar-thumb {
        background-color: rgba(9, 72, 161, 0.5);
    }

    .overflow-y-auto::-webkit-scrollbar-thumb:hover {
        background-color: rgba(14, 93, 204, 0.7);
    }
}
</style>
