<template>
    <BaseTemplate app-name="Eventos" page-title="">
        <template #content>
            <div class="min-h-screen bg-slate-200 dark:bg-gray-900 transition-colors duration-300">
                <div class="container mx-auto px-4 py-4 md:px-6 lg:px-8">

                    <!-- Filtros Melhorados -->
                    <div
                        class="flex flex-col md:flex-row md:items-end gap-4 md:gap-6 mb-6 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
                        <!-- Unidade -->
                        <div class="flex flex-col w-full md:w-auto">
                            <label
                                class="text-base md:text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade</label>
                            <select v-model="filtroUnidade"
                                class="w-full md:w-56 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 bg-gray-100 dark:bg-gray-700 dark:text-white text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-600 transition">
                                <option value="">Todas as Unidades</option>
                                <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.nome">
                                    {{ unidade.nome }}
                                </option>
                            </select>
                        </div>

                        <!-- Categoria -->
                        <div class="flex flex-col w-full md:w-auto">
                            <label
                                class="text-base md:text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Categoria</label>
                            <select v-model="filtroCategoria"
                                class="w-full md:w-56 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 bg-gray-100 dark:bg-gray-700 dark:text-white text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-600 transition">
                                <option value="">Todas as Categorias</option>
                                <option value="preventiva">Preventiva</option>
                                <option value="corretiva">Corretiva</option>
                                <option value="adverso">Adverso</option>
                            </select>
                        </div>

                        <!-- Status -->
                        <div class="flex flex-col w-full md:w-auto">
                            <label
                                class="text-base md:text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Status</label>
                            <select v-model="filtroStatus"
                                class="w-full md:w-56 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 bg-gray-100 dark:bg-gray-700 dark:text-white text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-600 transition">
                                <option value="">Todos os Status</option>
                                <option value="Finalizado">Finalizado</option>
                                <option value="Pendente">Pendente</option>
                                <option value="Em Atendimento">Em Atendimento</option>
                            </select>
                        </div>

                        <!-- Botão corretamente alinhado -->
                        <button @click="openCreateModal"
                        class="w-full md:w-auto bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700 text-sm md:text-base self-end md:self-auto">
                             Novo Evento
                        </button>
                    </div>



                    <!-- Exibição Condicional -->
                    <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
                        <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                    </div>

                    <!-- Exibição para Desktop (Tabela) -->
                    <div v-else-if="eventosFiltrados.length > 0">
                        <div class="hidden lg:block bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-x-auto">
                            <table class="w-full">
                                <!-- Cabeçalho da Tabela -->
                                <thead
                                    class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide rounded-t-lg">
                                    <tr>
                                        <th @click="ordenarPor('id')"
                                            class="px-6 py-3 cursor-pointer text-center hover:bg-blue-600 dark:hover:bg-blue-700 transition-all duration-200">
                                            ID <span class="ml-1 text-xs opacity-80">{{ iconeOrdenacao('id') }}</span>
                                        </th>

                                        <th @click="ordenarPor('unidade_nome')"
                                            class="px-6 py-3 cursor-pointer text-center hover:bg-blue-600 dark:hover:bg-blue-700 transition-all duration-200">
                                            Unidade <span class="ml-1 text-xs opacity-80">{{
                                                iconeOrdenacao('unidade_nome') }}</span>
                                        </th>

                                        <th @click="ordenarPor('categoria_evento')"
                                            class="px-6 py-3 cursor-pointer text-center hover:bg-blue-600 dark:hover:bg-blue-700 transition-all duration-200">
                                            Categoria <span class="ml-1 text-xs opacity-80">{{
                                                iconeOrdenacao('categoria_evento') }}</span>
                                        </th>

                                        <th @click="ordenarPor('status')"
                                            class="px-6 py-3 cursor-pointer text-center hover:bg-blue-600 dark:hover:bg-blue-700 transition-all duration-200">
                                            Status <span class="ml-1 text-xs opacity-80">{{ iconeOrdenacao('status')
                                                }}</span>
                                        </th>

                                        <th @click="ordenarPor('data_abertura')"
                                            class="px-6 py-3 cursor-pointer text-center hover:bg-blue-600 dark:hover:bg-blue-700 transition-all duration-200">
                                            Data Abertura <span class="ml-1 text-xs opacity-80">{{
                                                iconeOrdenacao('data_abertura') }}</span>
                                        </th>

                                        <th class="px-6 py-3 text-center">Ações</th>
                                    </tr>
                                </thead>

                                <!-- Corpo da Tabela -->
                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                    <tr v-for="evento in eventosFiltrados" :key="evento.id"
                                        class="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors text-center">

                                        <td class="px-6 py-3 text-sm text-gray-900 dark:text-gray-200">{{ evento.id }}
                                        </td>
                                        <td class="px-6 py-3 text-sm text-gray-900 dark:text-gray-200">{{
                                            evento.unidade_nome }}</td>
                                        <td class="px-6 py-3 text-sm text-gray-900 dark:text-gray-200">{{
                                            formatarTexto('categoria', evento.categoria_evento) }}</td>
                                        <td class="px-6 py-3">
                                            <span :class="{
                                                'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': formatarTexto('status', evento.status) === 'Finalizado',
                                                'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': formatarTexto('status', evento.status) === 'Pendente',
                                                'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': formatarTexto('status', evento.status) === 'Em Atendimento'
                                            }" class="px-3 py-1 rounded-full text-xs font-medium shadow-md">
                                                {{ formatarTexto('status', evento.status) }}
                                            </span>
                                        </td>
                                        <td class="px-6 py-3 text-sm text-gray-900 dark:text-gray-200">{{
                                            formatarData(evento.data_abertura) }}</td>
                                        <td class="px-6 py-3">
                                            <button
                                                @click="$router.push({ name: 'DetalhesEvento', params: { id: evento.id } })"
                                                class="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600 transition-all">
                                                <eye-icon class="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Exibição para Mobile (Cards) -->
                        <div class="block lg:hidden space-y-4">
                            <div v-for="evento in eventosFiltrados" :key="evento.id"
                                class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-5 border border-gray-200 dark:border-gray-700 transition transform hover:scale-[1.02] hover:shadow-lg">

                                <!-- Cabeçalho do Card -->
                                <div class="flex justify-between items-center mb-3">
                                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                                        Evento #{{ evento.id }}
                                    </h3>
                                    <span :class="{
                                        'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': formatarTexto('status', evento.status) === 'Finalizado',
                                        'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': formatarTexto('status', evento.status) === 'Pendente',
                                        'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': formatarTexto('status', evento.status) === 'Em Atendimento'
                                    }" class="px-4 py-1 rounded-full text-xs font-semibold shadow-md">
                                        {{ formatarTexto('status', evento.status) }}
                                    </span>
                                </div>

                                <!-- Conteúdo do Card -->
                                <div class="text-gray-700 dark:text-gray-300 space-y-2 text-sm">
                                    <p><strong class="text-gray-900 dark:text-gray-100">Unidade:</strong> {{
                                        evento.unidade_nome }}</p>
                                    <p><strong class="text-gray-900 dark:text-gray-100">Categoria:</strong> {{
                                        formatarTexto('categoria', evento.categoria_evento) }}</p>
                                    <p><strong class="text-gray-900 dark:text-gray-100">Data Abertura:</strong> {{
                                        formatarData(evento.data_abertura) }}</p>
                                </div>

                                <!-- Botão de Ação -->
                                <button @click="$router.push({ name: 'DetalhesEvento', params: { id: evento.id } })"
                                    class="mt-4 w-full bg-blue-500 text-white py-2 rounded-lg text-center font-medium text-sm hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700 shadow-md">
                                    Ver Detalhes
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div v-if="isCreateModalOpen"
                class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
                <div
                    class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl">
                    <h2 class="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Novo Evento</h2>
                    <form @submit.prevent="createEvento" class="space-y-6">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <!-- Unidade -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Unidade</label>
                                <select v-model="novoEvento.unidade"
                                    @change="fetchEquipamentosPorUnidade(novoEvento.unidade)"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    required>
                                    <option value="" disabled>Selecione a Unidade</option>
                                    <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                                        {{ unidade.nome }}
                                    </option>
                                </select>
                            </div>

                            <!-- Equipamento -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Equipamento
                                </label>
                                <select v-model="novoEvento.equipamento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione o Equipamento</option>
                                    <option v-for="equipamento in equipamentos" :key="equipamento.id"
                                        :value="equipamento.id">
                                        {{ equipamento.nome }}
                                    </option>
                                </select>
                            </div>
                            <!-- Entidade -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Entidade</label>
                                <select v-model="novoEvento.entidade"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione a entidade</option>
                                    <option v-for="ent in entidades" :key="ent.id" :value="ent.id">
                                        {{ ent.nome }}
                                    </option>
                                </select>
                            </div>


                            <!-- Categoria -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Categoria</label>
                                <select v-model="novoEvento.categoria_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    @change="handleCategoriaChange" required>
                                    <option value="preventiva">Manutenção Preventiva</option>
                                    <option value="corretiva">Manutenção Corretiva</option>
                                    <option value="adverso">Evento Adverso</option>
                                </select>
                            </div>

                            <!-- Status -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Status</label>
                                <select v-model="novoEvento.status"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    required>
                                    <option value="pendente">Pendente</option>
                                    <option value="em_atendimento">Em Atendimento</option>
                                    <option value="finalizado">Finalizado</option>
                                </select>
                            </div>



                            <!-- Motivo do Evento -->
                            <div v-if="novoEvento.categoria_evento !== 'preventiva'">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Motivo</label>
                                <select v-model="novoEvento.motivo_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione o Motivo</option>
                                    <option v-for="motivo in motivosEventos" :key="motivo.id" :value="motivo.id">{{
                                        motivo.nome }}</option>
                                </select>
                            </div>

                            <!-- Solução do Evento -->
                            <div v-if="novoEvento.categoria_evento !== 'preventiva'">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Solução</label>
                                <select v-model="novoEvento.solucao_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione a Solução</option>
                                    <option v-for="solucao in solucoesEventos" :key="solucao.id" :value="solucao.id">{{
                                        solucao.nome }}</option>
                                </select>
                            </div>
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Orçamento</label>
                                <input v-model="valorOrcamento" @input="formatOrcamento" type="text"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    placeholder="R$ 0,00">
                            </div>
                            <!-- Descrição -->
                            <div class="col-span-full">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Descrição</label>
                                <textarea v-model="novoEvento.descricao"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    placeholder="Digite a descrição" required rows="4"></textarea>
                            </div>

                            <!-- Datas -->
                            <div>
                                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Data
                                    de
                                    Início</label>
                                <input type="date" v-model="novoEvento.data_inicio" :min="dataAtual"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                            </div>
                            <div>
                                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Data
                                    Final</label>
                                <div class="flex items-center gap-4">
                                    <input type="date" v-model="novoEvento.data_final" :disabled="semPrevisaoFinal"
                                        :required="novoEvento.categoria_evento === 'preventiva'"
                                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                                    <div class="flex items-center" v-if="novoEvento.categoria_evento !== 'preventiva'">
                                        <input type="checkbox" id="sem-previsao" v-model="semPrevisaoFinal"
                                            class="h-4 w-4 text-blue-600 dark:bg-gray-700 dark:border-gray-600 rounded focus:ring-blue-500" />
                                        <label for="sem-previsao"
                                            class="ml-2 text-sm text-gray-700 dark:text-gray-300">Sem Previsão
                                            Final</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal Actions -->
                        <div class="flex justify-end space-x-3 mt-6">
                            <button type="button" @click="closeCreateModal"
                                class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">Cancelar</button>
                            <button type="submit" :disabled="isSubmitting"
                                class="bg-blue-500 dark:bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                                {{ isSubmitting ? 'Salvando...' : 'Salvar' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Rodapé fixo -->
            <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
                © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
            </p>
        </template>

    </BaseTemplate>
</template>
<script setup>

import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import {
    EyeIcon,
    RefreshCw
} from 'lucide-vue-next';
const eventos = ref([]);
const entidades = ref([]);
const router = useRouter(); // Instância do Vue Router
const unidades = ref([]);
const equipamentos = ref([]);
const isCreateModalOpen = ref(false);
const filtroUnidade = ref('');
const filtroCategoria = ref('');
const isSubmitting = ref(false); // Estado para evitar múltiplos cliques
const filtroStatus = ref('');
const colunaOrdenada = ref('');
const ordemAscendente = ref(true);
const isLoading = ref(false); // Indica se está carregando dados
const error = ref(null); // Armazena mensagens de erro
const motivosEventos = ref([]);
const semPrevisaoFinal = ref(false);
const solucoesEventos = ref([]);
axios.defaults.headers.common['X-API-KEY'] = sessionStorage.api_token; // Substitua pela sua API Key real
const valorOrcamento = ref(''); // Armazena o valor bruto sem formatação
const novoEvento = ref({
    unidade: "",
    equipamento: "",
    entidade: "",
    orcamento: "",
    status: "em_atendimento",
    motivo_evento: "",
    solucao_evento: "",
    categoria_evento: "adverso",
    descricao: "",
    data_inicio: "",
    data_final: "",
});



const formatOrcamento = () => {
    let valor = valorOrcamento.value.replace(/\D/g, ''); // Remove tudo que não for número

    if (valor.length === 0) {
        valorOrcamento.value = '';
        novoEvento.value.orcamento = '';
        return;
    }

    // Divide por 100 para adicionar casas decimais corretamente
    let numero = parseFloat(valor) / 100;

    // Atualiza apenas o valor formatado para exibição
    valorOrcamento.value = numero.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    // Mantém o valor correto para o backend (sem formatação)
    novoEvento.value.orcamento = numero.toFixed(2); // Exemplo: 750000.00
};






// Fetch Motivos e Soluções
async function fetchMotivosEventos() {
    try {
        const response = await axios.get('/api/v1/motivos-eventos/');
        motivosEventos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar motivos dos eventos:', error);
    }
}

async function fetchSolucoesEventos() {
    try {
        const response = await axios.get('/api/v1/solucoes-eventos/');
        solucoesEventos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar soluções dos eventos:', error);
    }
}

// Buscar eventos
async function fetchEventos() {
    isLoading.value = true;
    try {
        const response = await axios.get('/api/v1/eventos/');
        eventos.value = response.data;
    } catch (err) {
        error.value = 'Erro ao carregar eventos';
        console.error(err);
    } finally {
        isLoading.value = false;
    }
}

// Fetch Unidades
async function fetchUnidades() {
    try {
        const response = await axios.get('/api/v1/unidades/');
        unidades.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar unidades:', error);
    }
}

// Fetch Equipamentos por Unidade
async function fetchEquipamentosPorUnidade(unidadeId) {
    equipamentos.value = []; // Limpa antes de carregar
    if (!unidadeId) return; // Não busca se nenhuma unidade está selecionada

    try {
        const response = await axios.get('/api/v1/equipamentos/', { params: { unidade_id: unidadeId } });
        equipamentos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar equipamentos:', error);
    }
}

watch(semPrevisaoFinal, (newValue) => {
    if (newValue) {
        novoEvento.value.data_final = null; // Limpa o campo se estiver desativado
    }
});




// Watch para formatar sempre que o valor mudar
watch(() => novoEvento.value.orcamento, formatOrcamento);


// Filtro de eventos
const eventosFiltrados = computed(() => {
    return eventos.value
        .filter(evento =>
            (!filtroUnidade.value || evento.unidade_nome === filtroUnidade.value) &&
            (!filtroCategoria.value || evento.categoria_evento === filtroCategoria.value) &&
            (!filtroStatus.value || formatarTexto('status', evento.status) === filtroStatus.value)
        )
        .sort((a, b) => {
            if (!colunaOrdenada.value) return 0;
            const valorA = a[colunaOrdenada.value];
            const valorB = b[colunaOrdenada.value];

            if (typeof valorA === 'string') {
                return ordemAscendente.value ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
            } else {
                return ordemAscendente.value ? valorA - valorB : valorB - valorA;
            }
        });
});

// Função para ordenar colunas
function ordenarPor(coluna) {
    if (colunaOrdenada.value === coluna) {
        ordemAscendente.value = !ordemAscendente.value;
    } else {
        colunaOrdenada.value = coluna;
        ordemAscendente.value = true;
    }
}

// Ícone de ordenação
function iconeOrdenacao(coluna) {
    if (colunaOrdenada.value === coluna) {
        return ordemAscendente.value ? '🠕' : '🠗';
    }
    return '';
}


// Função para formatar texto (capitalize ou mapear)
function formatarTexto(chave, valor) {
    const map = {
        categoria_evento: {
            corretiva: 'Corretiva',
            preventiva: 'Preventiva',
            adverso: 'Adverso',
            outro: 'Outro',
        },
        status: {
            em_atendimento: 'Em Atendimento',
            finalizado: 'Finalizado',
            em_espera: 'Em Espera',
        },
    };

    return map[chave]?.[valor] || valor.charAt(0).toUpperCase() + valor.slice(1).replace('_', ' ');
}




async function fetchEntidades() {
    try {
        const response = await axios.get('/api/v1/entidades/');
        entidades.value = response.data;
    } catch (err) {
        console.error('Erro ao carregar entidades:', err);
    }
}

// Formatar data
function formatarData(data) {
    return new Date(data).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}




async function createEvento() {
    if (isSubmitting.value) return; // Evita múltiplas requisições simultâneas

    isSubmitting.value = true;

    try {
        // Validação básica
        if (!novoEvento.value.unidade || !novoEvento.value.status) {
            alert("Preencha todos os campos obrigatórios.");
            isSubmitting.value = false;
            return;
        }

        // Validação das datas
        if (
            novoEvento.value.data_inicio &&
            novoEvento.value.data_final &&
            new Date(novoEvento.value.data_final) < new Date(novoEvento.value.data_inicio)
        ) {
            alert("A data final não pode ser anterior à data de início.");
            isSubmitting.value = false;
            return;
        }

        // Remove a formatação do orçamento antes de enviar
        const eventoParaEnvio = { ...novoEvento.value };
        eventoParaEnvio.orcamento = converterOrcamentoParaNumero(novoEvento.value.orcamento);

        // Envio dos dados
        const response = await axios.post('/api/v1/eventos/', eventoParaEnvio);
        eventos.value.push(response.data);

        // Fecha o modal
        closeCreateModal();

        // Redireciona para a tela de detalhes do evento criado
        router.push({ name: 'DetalhesEvento', params: { id: response.data.id } });

    } catch (error) {
        alert('Erro ao criar evento. Verifique os dados enviados.');
    } finally {
        isSubmitting.value = false; // Libera o botão após o processamento
    }
}

const converterOrcamentoParaNumero = (valor) => {
    if (!valor) return 0; // Se estiver vazio, retorna 0
    return parseFloat(valor.replace(/[^0-9,]/g, '').replace(',', '.')); // Remove caracteres e ajusta decimal
};


// Abrir e Fechar Modal
function openCreateModal() {
    isCreateModalOpen.value = true;
    resetNovoEvento(); // Reseta campos do formulário
}

function closeCreateModal() {
    isCreateModalOpen.value = false;
}

function handleCategoriaChange() {
    if (novoEvento.value.categoria_evento === 'preventiva') {
        const motivoPreventiva = motivosEventos.value.find(motivo => motivo.nome === 'Manutenção Preventiva Programada');
        const solucaoPreventiva = solucoesEventos.value.find(solucao => solucao.nome === 'Manutenção Preventiva Programada');

        // Use os IDs correspondentes para motivo e solução
        novoEvento.value.motivo_evento = motivoPreventiva ? motivoPreventiva.id : null;
        novoEvento.value.solucao_evento = solucaoPreventiva ? solucaoPreventiva.id : null;

        semPrevisaoFinal.value = false; // Não permite "Sem Previsão"
    } else {
        novoEvento.value.motivo_evento = null;
        novoEvento.value.solucao_evento = null;
    }
}


watch(() => novoEvento.value.status, (newStatus) => {
    if (newStatus === 'pendente') {
        novoEvento.value.data_inicio = ''; // Limpa para evitar inconsistências
    }
});


// Resetar Formulário
function resetNovoEvento() {
    novoEvento.value = {
        unidade: null,
        entidade: null,
        equipamento: null,
        categoria_evento: '',
        descricao: '',
        data_inicio: null,
        data_final: null
    };
    equipamentos.value = []; // Limpa a lista de equipamentos
}

onMounted(async () => {
    await Promise.all([
        fetchUnidades(),
        fetchEntidades(),
        fetchEquipamentosPorUnidade(),
        fetchMotivosEventos(),
        fetchSolucoesEventos(),
        fetchEventos(),
    ]);
});

</script>



<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
