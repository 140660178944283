<template>
  <BaseTemplate app-name="Gestão de Acessos" page-title="">
    <template #content>
      <div class="container mx-auto p-6">
        <!-- Cabeçalho -->

           <!-- Cabeçalho --><button
  @click="voltar"
  class="hidden md:inline-flex mb-5 items-center gap-2 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors dark:hover:bg-blue-700"
>
  <SquareArrowLeft class="w-5 h-5" />
  <span>Voltar</span>
</button>
        <div class="flex justify-between items-center mb-6">
          <h1 class="flex items-center gap-2 text-2xl font-bold text-gray-900 dark:text-white">
            <LockIcon class="w-6 h-6 text-blue-500" />
            Gestão de Acessos
          </h1>
        </div>

        <!-- Filtros -->
        <div class="flex flex-col sm:flex-row gap-4 mb-6">
          <input
            v-model="filter"
            type="text"
            placeholder="Buscar por nome, usuário ou email..."
            class="w-full sm:w-1/2 border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <!-- Mensagem de erro -->
        <p v-if="error" class="text-red-500 text-center mb-4">{{ error }}</p>

        <!-- Layout responsivo -->
        <div class="hidden sm:block bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto dark:text-white text-gray-800">
          <table class="w-full">
            <thead class="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-300 text-sm uppercase tracking-wide">
              <tr>
                <th
                  @click="ordenarPor('nome')"
                  class="cursor-pointer px-4 py-3 text-left"
                >
                  Nome <span v-if="colunaOrdenada === 'nome'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th
                  @click="ordenarPor('usuario')"
                  class="cursor-pointer px-4 py-3 text-left"
                >
                  Usuário <span v-if="colunaOrdenada === 'usuario'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th
                  @click="ordenarPor('email')"
                  class="cursor-pointer px-4 py-3 text-left"
                >
                  Email <span v-if="colunaOrdenada === 'email'">{{ ordemAscendente ? '🠕' : '🠗' }}</span>
                </th>
                <th class="px-4 py-3 text-center">Grupos</th>
                <th class="px-4 py-3 text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in usuariosOrdenados"
                :key="user.id"
                class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <td class="px-4 py-3">{{ user.first_name }} {{ user.last_name }}</td>
                <td class="px-4 py-3">{{ user.username }}</td>
                <td class="px-4 py-3">{{ user.email || '-' }}</td>
                <td class="px-4 py-3 text-center">
                  <span
                    v-for="group in user.groups"
                    :key="group"
                    class="inline-block bg-blue-100 text-blue-800 text-xs uppercase font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                  >
                    {{ group }}
                  </span>
                </td>
                <td class="px-4 py-3 text-center">
                  <button @click="openModal(user)" class="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                    <Edit2Icon class="w-4 h-4" /> Editar Grupos
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Versão mobile em cards -->
        <div class="sm:hidden space-y-6">
          <div
            v-for="user in usuariosOrdenados"
            :key="user.id"
            class="bg-white dark:bg-gray-900 shadow-lg rounded-xl p-6 border border-gray-100 dark:border-gray-700 transition transform hover:scale-105"
          >
            <div class="flex justify-between items-center">
              <h3 class="text-xl font-bold text-gray-900 dark:text-white">
                {{ user.first_name }} {{ user.last_name }}
              </h3>
              <button @click="openModal(user)" class="text-blue-500 hover:text-blue-700">
                <Edit2Icon class="w-5 h-5" />
              </button>
            </div>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Usuário:</strong> {{ user.username }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300"><strong>Email:</strong> {{ user.email || '-' }}</p>
            <p class="text-sm text-gray-700 dark:text-gray-300">
              <strong>Grupos:</strong>
              <span v-for="group in user.groups" :key="group" class="ml-1 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded dark:bg-blue-200 dark:text-blue-800">
                {{ group }}
              </span>
            </p>
          </div>
        </div>

        <!-- Modal de Edição de Grupos -->
        <div
          v-if="isModalOpen"
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96 animate-fade-in">
            <h2
              class="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-white mb-4"
            >
              <LockIcon class="w-5 h-5 text-blue-500" />
              Editar Grupos de {{ currentUser.first_name }} {{ currentUser.last_name }}
            </h2>

            <!-- Lista de Grupos -->
            <div class="space-y-2 mb-4">
              <div
                v-for="group in availablePermissions"
                :key="group.id"
                class="flex items-center"
              >
                <input
                  :id="'group-' + group.id"
                  type="checkbox"
                  :value="group.name"
                  v-model="currentUser.permissions"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label :for="'group-' + group.id" class="ml-2 text-sm font-medium uppercase text-gray-900 dark:text-gray-300">
                  {{ group.name }}
                </label>
              </div>
            </div>

            <div class="flex justify-end space-x-3">
              <button
                @click="closeModal"
                class="flex items-center gap-1 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
              >
                <XIcon class="w-4 h-4" /> Cancelar
              </button>
              <button
                @click="savePermissions"
                class="flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
              >
                <SaveIcon class="w-4 h-4" />
                <span>Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center text-sm mb-11 mt-10 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
    </template>
  </BaseTemplate>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { LockIcon, Edit2Icon, XIcon, SaveIcon, SquareArrowLeft } from 'lucide-vue-next'
import BaseTemplate from './BaseTemplate.vue'

const API_URL = process.env.VUE_APP_API_URL
const apiToken = sessionStorage.getItem("api_token")
import { useRouter } from 'vue-router';
const router = useRouter();
const voltar = () => router.go(-1);
  
const users = ref([])
const filter = ref('')
const error = ref(null)
const isModalOpen = ref(false)
const currentUser = ref({})

// Variáveis para ordenação
const colunaOrdenada = ref('nome') // Coluna padrão para ordenação
const ordemAscendente = ref(true) // Ordem ascendente por padrão

// Os grupos disponíveis serão buscados via API e armazenados aqui
const availablePermissions = ref([])

// Filtra os usuários com base no campo de busca
const filteredUsers = computed(() =>
  users.value.filter(user =>
    ((user.first_name || '') + ' ' + (user.last_name || ''))
      .toLowerCase()
      .includes(filter.value.toLowerCase()) ||
    (user.username || '').toLowerCase().includes(filter.value.toLowerCase()) ||
    (user.email || '').toLowerCase().includes(filter.value.toLowerCase())
  )
)

// Ordena os usuários com base na coluna selecionada
const usuariosOrdenados = computed(() => {
  const usuariosFiltrados = filteredUsers.value.slice() // Cria uma cópia dos usuários filtrados

  return usuariosFiltrados.sort((a, b) => {
    let valorA, valorB

    switch (colunaOrdenada.value) {
      case 'nome':
        valorA = `${a.first_name} ${a.last_name}`.toLowerCase()
        valorB = `${b.first_name} ${b.last_name}`.toLowerCase()
        break
      case 'usuario':
        valorA = a.username.toLowerCase()
        valorB = b.username.toLowerCase()
        break
      case 'email':
        valorA = a.email.toLowerCase()
        valorB = b.email.toLowerCase()
        break
      default:
        return 0
    }

    if (valorA < valorB) return ordemAscendente.value ? -1 : 1
    if (valorA > valorB) return ordemAscendente.value ? 1 : -1
    return 0
  })
})

// Função para ordenar por coluna
function ordenarPor(coluna) {
  if (colunaOrdenada.value === coluna) {
    // Inverte a ordem se a mesma coluna for clicada novamente
    ordemAscendente.value = !ordemAscendente.value
  } else {
    // Define a nova coluna e a ordem ascendente por padrão
    colunaOrdenada.value = coluna
    ordemAscendente.value = true
  }
}

onMounted(() => {
  fetchUsers()
  fetchGroups()
})

async function fetchUsers() {
  try {
    const response = await fetch(`${API_URL}/api/v1/usuarios/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiToken
      }
    })
    const data = await response.json()

    // Atualiza os usuários garantindo que 'groups' seja reconhecido corretamente
    users.value = data.map(user => ({
      ...user,
      groups: user.groups || []  // ⬅️ Alterado para 'groups' em vez de 'permissions'
    }))
  } catch (err) {
    error.value = 'Erro ao carregar usuários'
  }
}

async function fetchGroups() {
  try {
    const response = await fetch(`${API_URL}/api/v1/grupos/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiToken
      }
    })
    const data = await response.json()
    // data deve ser um array de grupos com { id, name }
    availablePermissions.value = data
  } catch (err) {
    error.value = 'Erro ao carregar grupos'
  }
}

function openModal(user) {
  currentUser.value = { ...user, permissions: [...user.groups] }
  isModalOpen.value = true
}

function closeModal() {
  isModalOpen.value = false
}

async function savePermissions() {
  try {
    const payload = {
      groups: currentUser.value.permissions.map(g => {
        const group = availablePermissions.value.find(p => p.name === g);
        return group ? group.id : null;
      }).filter(g => g !== null) // Remove valores nulos
    };

    console.log("📤 Enviando payload para API:", JSON.stringify(payload));

    const response = await fetch(`${API_URL}/api/v1/usuarios/${currentUser.value.id}/grupos/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',  // ✅ Garante que o backend reconheça o JSON
        'X-API-KEY': apiToken
      },
      body: JSON.stringify(payload)
    });

    const responseData = await response.json();
    console.log("📥 Resposta da API:", responseData);

    if (!response.ok) {
      console.error("❌ Erro na API:", responseData);
      throw new Error(`Erro ao atualizar grupos: ${JSON.stringify(responseData)}`);
    }

    const index = users.value.findIndex(u => u.id === currentUser.value.id);
    if (index !== -1) {
      users.value[index].permissions = [...currentUser.value.permissions];
    }
    fetchUsers()
    fetchGroups()
    closeModal();
  } catch (err) {
    error.value = `Erro ao salvar grupos: ${err.message}`;
  }
}
</script>