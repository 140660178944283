<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-900">
      <div :class="{ 'opacity-100': visible, 'opacity-0': !visible }" class="transition-opacity duration-700">
        <h1 class="text-4xl md:text-6xl font-bold text-blue-600 dark:text-blue-400 mb-2 text-center">
          Versão
        </h1>
        <div class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xl md:text-3xl font-semibold px-8 py-4 rounded-lg flex items-center justify-center min-w-[200px] text-center">
          2.4
        </div>
      </div>
      <p class="text-center text-sm fixed bottom-10 text-gray-500 dark:text-gray-400">
        © Desenvolvido por Tecnologia da Informação - CCDTI © Todos os direitos reservados
      </p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const visible = ref(false);
  
  onMounted(() => {
    // Show the version with a fade-in animation
    setTimeout(() => {
      visible.value = true;
    }, 300);
  });
  </script>
  
  <style scoped>
  .transition-opacity {
    transition: opacity 0.7s ease-in-out;
  }
  </style>